import React, { useState, useEffect, useRef } from 'react';  // Make sure to import useEffect and useRef
import { useNavigate , useLocation } from 'react-router-dom';
import '../assets/Css/Header.css';
import image2 from '../assets/Images/cloud_sync_company_logo.png';
import profilePic from '../assets/Images/profile.png';
import notificationIcon from '../assets/Images/notification.png';
import { Search } from 'react-feather';
import enrollmentIcon from '../assets/Images/Enrollments.png';
import achievementIcon from '../assets/Images/Achievements.png'; 
import coursesIcon from '../assets/Images/courses.png';
 import myLearningIcon from '../assets/Images/my_learning2.png';
import supportIcon from '../assets/Images/message-question.png';
import settingsIcon1 from '../assets/Images/headerSettinfgs.png';
import logoutIcon from '../assets/Images/headerLogout.png';
import challengeicon from '../assets/Images/Group (1).png';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { logoutaxios } from '../axios';
import { clearCredentials } from '../reducers/authSlice'; // assuming you have an action to clear credentials in your authSlice
import { courseType } from '../actions/typeOfCourseActions';
import { setSearchQuery } from '../actions/searchActions';
import skill_synq_logo from '../assets/Images/Skillsynqlogo.png';



const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const progress = 80;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const popupRef = useRef(null); // Use ref to keep track of the popup
  const moreDropdownRef = useRef(null);

  const handleNavigation = (path) => {
    navigate(path);
};

const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
};

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };



  // Function to toggle the popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside of the popup
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setDropdownVisible(false);
      setIsPopupVisible(false);
    }
    if (moreDropdownRef.current && !moreDropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);

  }
  };

  useEffect(() => {
    if (dropdownVisible || isDropdownOpen ||isPopupVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible,isDropdownOpen ,isPopupVisible]);

  const navigateToSettings = () => {
    navigate('/profile-settings');
  };

  const localRefreshToken = localStorage.getItem("refresh_token")
    const username =  localStorage.getItem('user_name');
    const role =   localStorage.getItem('user_role');
 
  const handleToCourseListPage = () => {
    navigate('/course-list')
    dispatch(courseType());
    localStorage.removeItem('localStorageCourseId');
    localStorage.removeItem('role_based_courseId');
  }
  
  const logoutWithJwt = async () => {
    const data = { refresh_token: localRefreshToken };
    setLoading(true);
  
    try {
      const response = await logoutaxios.post('/logout/', data);
  
      if (response.status === 401) {
        console.log('Unauthorized - Logging out');
        localStorage.removeItem('tokens');
        dispatch(clearCredentials());
        navigate('/');
      } else if (response.data.message === "Successfully logged out.") {
        console.log('Logout successful:', response.data.message);
        localStorage.removeItem('tokens');
        dispatch(clearCredentials());
        navigate('/');
      } else {
        console.error('Unexpected logout response:', response);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.log('Logout error - Unauthorized:', err.response.status);
        localStorage.removeItem('tokens');
        dispatch(clearCredentials());
        navigate('/');
      } else {
        console.error('Logout error:', err);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAchievements = () => {
    navigate('/certificate');
  };
  

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchText(query);
    dispatch(setSearchQuery(query));  // Dispatch the query to Redux store
  };

  return (
    <>
    <header className="basic-header-header-container">
      <div className="basic-header-logo" onClick={handleToCourseListPage}>
        <img src={skill_synq_logo} alt="DataWorks Logo" />
      </div>
      <div className="basic-header-search-bar">
        <input type="text" value={searchText} placeholder="Search in Courses..." className="basic-header-search-bar-input" onChange={handleSearchChange} />
        <Search className="basic-header-search-icon" />
      </div>
      <div className="header-items">
      <div className={`header-item ${getActiveClass('/course-list')}`}
                    onClick={() => handleNavigation('/course-list')}>Courses</div>
      <div  className={`header-item ${getActiveClass('/learning')}`}
                    onClick={() => handleNavigation('/learning')}>My Learning</div>
      <div className={`header-item ${getActiveClass('/challenges')}`}
                    onClick={() => handleNavigation('/challenges')}>Challenges</div>
      <div className={`header-item ${getActiveClass('/dashboard')}`}
                    onClick={() => handleNavigation('/dashboard')}>DashBoard</div>              
      <div className="header-item" onClick={toggleDropdown1} ref={moreDropdownRef}>
          more
          <span className={`more-icon ${isDropdownOpen ? "up" : "down"}`}></span>
          <div className={`dropdown ${isDropdownOpen ? 'show' : ''}`}>
            <ol className="dropdown-list">
              <li className="dropdown-item">Hackathon</li>
              <li className="dropdown-item">Mock Interview <span className="ai-tag"><sup className='sup-ai-tag'>AI</sup></span></li>
              <li className="dropdown-item">Resume <span className="ai-tag"><sup className='sup-ai-tag'>AI</sup></span></li>
              <li className="dropdown-item">Jobs</li>
            </ol>
          </div>
        </div>
        </div>
      <div className="basic-header-user-info">
        <img src={notificationIcon} alt="Settings" className="basic-header-settings-icon" />
        <div className="basic-header-divider"></div>
        <div className="basic-header-profile-container" onClick={toggleDropdown}>
          <img src={profilePic} alt="User Profile" className="basic-header-profile-img" />
          <div className='popup-details'>
            <p className="popup-username">{username.split(' ')[0]}</p>
            <div className='popup-role-arrow' onClick={togglePopup} >
    
            <p className="popup-role">{role}</p>
            <span className={`expand-arrow-functionality ${isPopupVisible ? "up" : "down"}`}></span>
            <div className={`expand-arrow-show ${isPopupVisible  ? 'show' : ''}`}>
              </div>
          </div>
          </div>
        </div>
        {dropdownVisible && (
          <div className="basic-header-popup-menu" ref={popupRef}>
            <div className="popup-actions">
              <div className="popup-action-item" >
                <img src={enrollmentIcon} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text">Enrollments</span>
              </div> 
              <div className="popup-action-item" >
                <img src={achievementIcon} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text" onClick={handleAchievements}>Achievements</span>
              </div>
              <div className="popup-action-item" onClick={navigateToSettings}>
                <img src={settingsIcon1} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text">Settings</span>
              </div>
              <div className="popup-action-item" >
                <img src={supportIcon} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text">Support</span>
              </div>
              <div className="popup-action-item" onClick={logoutWithJwt}>
                <img src={logoutIcon} alt="Logout Icon" className="popup-action-icon" />
                <span className="popup-action-text">Logout</span>
                {loading && (
                  <CircularProgress size={8} color="inherit" style={{ marginLeft: '10px', marginTop: '3px' }} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
    </>
  );
};

export default Header;







