import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/Css/Quiz.css';
import Timer from '../assets/Images/Quiz-Timer.png';
import Question from '../assets/Images/Quiz-Question.png';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import { adminaxios } from '../axios/index';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Sidebar2 from '../Sidebar/Sidebar2';
 
 
const TimedQuiz = () => {
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState(null);
  const navigate = useNavigate();
  const { id: courseId } = useParams();
 
  const dynamicModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const dynamicTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const dynamicQuizId = useSelector((state) => state.dynamicApiId.quizConfigId);
 
  
  useEffect(() => {
    localStorage.removeItem("selectedAnswers"); // Clear the specific localStorage item
    console.log("selectedAnswers cleared from localStorage");
  }, []);
  useEffect(() => {
    
    const fetchQuizData = async () => {
      try {
        const response = await adminaxios.get(`/modules/${dynamicModuleId}/topics/${dynamicTopicId}/question_bank_config/${dynamicQuizId}/`);
        setQuizData(response.data.question_bank_configs);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setLoading(false);
      }
    };
    fetchQuizData();
  }, []);
 
  const startQuiz = () => {
    navigate(`/timedquiz-page/${courseId}`, { state: { quizData } });
  };
 
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
        <Oval height={50} width={50} color="#000080" ariaLabel="loading" />
      </div>
    );
  }
 
  return (
    <div style={{display:'flex',justifyContent:'flex-start'}}>
      {/* <Sidebar2 courseId={courseId} /> */}
  
    <div className="zuiq-homepage-container">
      <div className="zuiq-homepage-box">
        <div className="zuiq-homepage-headerPage">
        <p className="zuiq-homepage-paragraph">{quizData?.is_assessment ? 'Assessment 1' : 'Quiz'}</p>
          <h2 className="zuiq-homepage-h2">{quizData?.type || 'Quiz'} - Knowledge Check</h2>
         
          <div className="zuiq-homepage-info-wrapper">
            <div className="zuiq-homepage-info">
              <div className="zuiq-homepage-info-item">
                <img src={Timer} alt="Time Limit" className="zuiq-homepage-icon" />
                <div className="zuiq-homepage-info-text">
                  <p className="zuiq-homepage-duration">Test Duration</p>
                  <p className="zuiq-homepage-value">
                    {quizData?.time_limit_min ? `${quizData.time_limit_min} minutes` : 'No time limit'}
                  </p>
                </div>
              </div>
              <div className="zuiq-homepage-info-item">
                <img src={Question} alt="Questions" className="zuiq-homepage-icon" />
                <div className="zuiq-homepage-info-text">
                  <p className="zuiq-homepage-questions">Questions</p>
                  {/* Handle different count fields dynamically */}
                  <p className="zuiq-homepage-value">{quizData?.count || 0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="zuiq-homepage-instructions">
          <strong>Instructions</strong>
          <p className="zuiq-homepage-paragraph">{quizData?.instructions || 'Follow the instructions for the quiz.'}</p>
        </div>
        <div className="zuiq-homepage-skills-wrapper">
          <div className="zuiq-homepage-skills-box">
            <span className="zuiq-homepage-skill">Skills</span>
            {quizData?.skills ? (
              Array.isArray(quizData.skills) ? quizData.skills.map((skill, index) => (
                <span key={index} className="zuiq-homepage-skill-language">{skill}</span>
              )) : (
                <span className="zuiq-homepage-skill-language">{quizData.skills}</span>
              )
            ) : (
              <>
                <span className="zuiq-homepage-skill-language">HTML</span>
                <span className="zuiq-homepage-skill-language">CSS</span>
                <span className="zuiq-homepage-skill-language">JavaScript</span>
              </>
            )}
          </div>
          <div className="zuiq-homepage-sub-skills-box">
            <span className="zuiq-homepage-sub-skill">Sub-skills</span>
            {quizData?.sub_skills ? (
              quizData.sub_skills.map((subSkill, index) => (
                <span key={index} className="zuiq-homepage-skill-basic">{subSkill}</span>
              ))
            ) : (
              <span className="zuiq-homepage-skill-basic">Basics</span>
            )}
          </div>
        </div>
        <button className="zuiq-homepage-start-quiz-btn" onClick={startQuiz} disabled={!quizData}>
          Start quiz
        </button>
      </div>
    </div>
    </div>
  );
};
 
export default TimedQuiz;