// import React,{useState,useEffect} from "react";
// import { useSelector } from "react-redux";
// import { adminaxios } from "../axios";
// import coins from "../assets/Images/reward_each_module.png";
// import "../assets/Css/ClaimRewardModule.css";

// const ClaimRewardModule = ({modulesFromProgress,progressId}) => {
//   console.log(modulesFromProgress,'modulesFromProgress');
//   const storeModuleId = useSelector((state) => state.dynamicApiId.moduleId);
//   const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
//   const [gamificationData,setGamificationData] = useState('');
//   const [coinsData, setCoinsData] = useState(null);
//   const [courseGamificationId,setCourseGamificationId]=useState(null); 
 
//   useEffect(() => {
//     if (courseGamificationId) { // Ensure the ID exists before making the API call
//       adminaxios
//         .get(`get_coins/${courseGamificationId}/`)
//         .then((response) => {
//           setCoinsData(response.data); // Store the response data in state
//           console.log(response.data.coins_info.course_coins);
//           localStorage.setItem('course_coins',response.data.coins_info.course_coins);
//         })
//         .catch((error) => {
//           console.error("Error fetching coins data:", error);
//         });
//     }
//   }, [courseGamificationId]); 

//   const handleGamifiedPost = async() =>{
//     try {
//       const response = await adminaxios.post(`award-coins/user_progress/${progressId}/${storeCourseId}/${storeModuleId}/`, {
//           headers: {'Content-Type': 'application/json'},
//       });
//       if (response.status === 200 || response.status === 201) {
//         setGamificationData(response.data);
//         setCourseGamificationId(response.data.gamification_document_id);
//         console.log(response.data);

//       } 
//   } catch (err) {
//       console.error('Error gaming question post:', err);
//   }
//   }

//   return (
//     <div className="module-reward-container">
//       <div className="module-reward-header">
//         <div className="reward-info">
//           <img
//             src={coins} // Replace with your icon path
//             alt="Coins Icon"
//             className="reward-coins-icon"
//           />
//           <p style={{fontSize:'15px'}}>Complete module</p>
//         <p className="days-left">3 days left</p>
//         </div>
//       </div>
//       <div className="gaming-in-sidebar-exp-info">
//         <div className="gaming-reward-progress-bar-container">
//           <div className="progress-bar" style={{ width: "90%" }}></div>
//         </div>
//       </div>
//       { modulesFromProgress.map((module, index) => (
//         <div key={module.module_id || index}>
//           {module.module_id === storeModuleId && module.completed &&
//             <button className="claim-reward-btn" onClick={handleGamifiedPost}>Claim Reward</button>
//           }
          
//         </div>
//        ))
//       }

//     </div>
//   );
// };

// export default ClaimRewardModule;


// import React,{useState,useEffect} from "react";
// import { useSelector } from "react-redux";
// import { adminaxios } from "../axios";
// import coins from "../assets/Images/reward_each_module.png";
// import "../assets/Css/ClaimRewardModule.css";

// const ClaimRewardModule = ({modulesFromProgress,progressId}) => {
//   console.log(modulesFromProgress,'modulesFromProgress');
//   const storeModuleId = useSelector((state) => state.dynamicApiId.moduleId);
//   const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
//   const [gamificationData,setGamificationData] = useState('');
//   const [coinsData, setCoinsData] = useState(null);
//   const [courseGamificationId,setCourseGamificationId]=useState(null); 
 
//   useEffect(() => {
//     if (courseGamificationId) { // Ensure the ID exists before making the API call
//       adminaxios
//         .get(`get_coins/${courseGamificationId}/`)
//         .then((response) => {
//           setCoinsData(response.data); // Store the response data in state
//           console.log(response.data.coins_info.course_coins);
//           localStorage.setItem('course_coins',response.data.coins_info.course_coins);
//         })
//         .catch((error) => {
//           console.error("Error fetching coins data:", error);
//         });
//     }
//   }, [courseGamificationId]); 

//   const handleGamifiedPost = async() =>{
//     try {
//       const response = await adminaxios.post(`award-coins/user_progress/${progressId}/${storeCourseId}/${storeModuleId}/`, {
//           headers: {'Content-Type': 'application/json'},
//       });
//       if (response.status === 200 || response.status === 201) {
//         setGamificationData(response.data);
//         setCourseGamificationId(response.data.gamification_document_id);
//         console.log(response.data);

//       } 
//   } catch (err) {
//       console.error('Error gaming question post:', err);
//   }
//   }

//   return (
//     <div className="module-reward-container">
//       <div className="module-reward-header">
//         <div className="reward-info">
//           <img
//             src={coins} // Replace with your icon path
//             alt="Coins Icon"
//             className="reward-coins-icon"
//           />
//           <p style={{fontSize:'15px'}}>Complete module</p>
//         <p className="days-left">3 days left</p>
//         </div>
//       </div>
//       <div className="gaming-in-sidebar-exp-info">
//         <div className="gaming-reward-progress-bar-container">
//           <div className="progress-bar" style={{ width: "90%" }}></div>
//         </div>
//       </div>
//       { modulesFromProgress.map((module, index) => (
//         <div key={module.module_id || index}>
//           {module.module_id === storeModuleId  &&
//             <button className="claim-reward-btn" onClick={handleGamifiedPost}>Claim Reward</button>
//           }
          
//         </div>
//        ))
//       }

//     </div>
//   );
// };

// export default ClaimRewardModule;

// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { adminaxios } from "../axios";
// import coins from "../assets/Images/reward_each_module.png";
// import coinsgif from "../assets/Images/CoinsGIF.gif"; 
// import gif_one from '../assets/Images/gif_one.gif';
// import "../assets/Css/ClaimRewardModule.css";

// const ClaimRewardModule = ({ modulesFromProgress, progressId }) => {
//   const storeModuleId = useSelector((state) => state.dynamicApiId.moduleId);
//   const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
//   const [gamificationData, setGamificationData] = useState(null);
//   const [coinsCount, setCoinsCount] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [isLoading, setIsLoading] = useState(false); // To track loading state

//   // Handle API Call
//   const handleGamifiedPost = async (event) => {
//     event.stopPropagation(); // Prevent event bubbling
//     setShowModal(true); // Show the modal immediately
//     setIsLoading(true); // Set loading state

//     try {
//       const response = await adminaxios.post(
//         `award-coins/user_progress/${progressId}/${storeCourseId}/${storeModuleId}/`,
//         {
//           headers: { "Content-Type": "application/json" },
//         }
//       );
//       if (response.status === 200 || response.status === 201) {
//         setGamificationData(response.data);
//         setCoinsCount(response.data.coins_earned || 0); // Update coins earned
//       }
//     } catch (err) {
//       console.error("Error posting gamification data:", err);
//     } finally {
//       setIsLoading(false); // Stop loading state
//     }
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setGamificationData(null);
//     setCoinsCount(null);
//   };

//   return (
//     <div className="module-reward-container" onClick={(e) => e.stopPropagation()}>
//       <div className="module-reward-header">
//         <div className="reward-info">
//           <img src={coins} alt="Coins Icon" className="reward-coins-icon" />
//           <p style={{ fontSize: "15px" }}>Complete module</p>
//           <p className="days-left">3 days left</p>
//         </div>
//       </div>
//       <div className="gaming-in-sidebar-exp-info">
//         <div className="gaming-reward-progress-bar-container">
//           <div className="progress-bar" style={{ width: "90%" }}></div>
//         </div>
//       </div>
//       {modulesFromProgress.map((module, index) => (
//         <div key={module.module_id || index}>
//           {module.module_id === storeModuleId && module.completed &&(
//             <button
//               className="claim-reward-btn"
//               onClick={handleGamifiedPost}
//             >
//               Claim Reward
//             </button>
//           )}
//         </div>
//       ))}

//       {/* Modal Implementation */}
//       {/* {showModal && (
//         <div className="reward-modal-overlay" onClick={(e) => e.stopPropagation()}>
//           <div className="reward-modal">
//             {isLoading ? (
//               // Show loading spinner while API call is in progress
//               <div className="loading-spinner">
//                 <p>Processing your reward...</p>
//               </div>
//             ) : (
//             <>
//   <div className="reward-message-container">
//     <h2 className="rewardmodule-cong">🎉 Congratulations! 🎉</h2>
//     <p className="reward-success-message">You have successfully completed the module!</p>
//     <p className="reward-success-message">
//       You’ve earned <strong>{coinsCount} coins</strong> for your achievement.
//     </p>
//   </div>
//   <button onClick={closeModal} className="close-modal-btn">
//     Close
//   </button>
// </>

//             )}
//           </div>
//         </div>
//       )} */}
// {showModal && (
//   <div
//     className="reward-modal-overlay"
//     onClick={() => setShowModal(false)} // Close modal when clicking outside
//   >
//     <div
//       className="reward-modal"
//       onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
//     >
     

//       {isLoading ? (
//         // Show loading spinner while API call is in progress
//         <div className="loading-spinner">
//           <p>Processing your reward...</p>
//         </div>
//       ) : (
//         <>
//           <div className="reward-message-container">
//           <span
//         className="close-modal-btn"
//         onClick={() => setShowModal(false)} // Close modal when "X" is clicked
//       >
//         ✖
//       </span>
//             <h2 className="rewardmodule-cong">🎉 Congratulations! 🎉</h2>
//             <p className="reward-success-message">You have successfully completed the module!</p>
//             <div className="reward-success-msg-coins">
//             <p className="reward-success-message">
//               You’ve earned <strong>{coinsCount} coins</strong> for your achievement.
//             </p>
//             <span className="reward-gif-container">
//   <img src={coinsgif} alt="Celebration GIF" className="reward-gif" />
// </span>
// </div>
//           </div>
//         </>
//       )}
//     </div>
//   </div>
// )}


//     </div>
//   );
// };

// export default ClaimRewardModule;


import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { adminaxios } from "../axios";
import coins from "../assets/Images/reward_each_module.png";
import coinsgif from "../assets/Images/CoinsGIF.gif";
import "../assets/Css/ClaimRewardModule.css";

const ClaimRewardModule = ({ modulesFromProgress, progressId }) => {
  const storeModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
  const [gamificationData, setGamificationData] = useState(null);
  const [coinsCount, setCoinsCount] = useState(null);
  const [coinsData, setCoinsData] = useState(null);
  const [courseGamificationId, setCourseGamificationId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch coins data when courseGamificationId changes
  useEffect(() => {
    if (courseGamificationId) {
      adminaxios
        .get(`get_coins/${courseGamificationId}/`)
        console.log('courseGamification', courseGamificationId)
        .then((response) => {
          const courseCoins = response.data?.coins_info?.course_coins || 0;
          console.log('courseCoins',courseCoins)
          setCoinsCount(courseCoins); // Update coins count
          localStorage.setItem("course_coins", courseCoins); // Save coins count in localStorage
        })
        .catch((error) => {
          console.error("Error fetching coins data:", error);
        });
    }
  }, [courseGamificationId]);

  // Handle the gamified post (API call to award coins)
  const handleGamifiedPost = async (event) => {
    event.stopPropagation(); // Prevent event bubbling
    setShowModal(true); // Show the modal immediately
    setIsLoading(true); // Start loading state

    try {
      const response = await adminaxios.post(
        `award-coins/user_progress/${progressId}/${storeCourseId}/${storeModuleId}/`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setGamificationData(response.data);
        setCourseGamificationId(response.data.gamification_document_id); // Save the ID for fetching coins
      }
    } catch (err) {
      console.error("Error posting gamification data:", err);
    } finally {
      setIsLoading(false); // Stop loading state
    }
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false);
    setGamificationData(null);
    setCoinsCount(null);
  };

  return (
    <div className="module-reward-container" onClick={(e) => e.stopPropagation()}>
      <div className="module-reward-header">
        <div className="reward-info">
          <img src={coins} alt="Coins Icon" className="reward-coins-icon" />
          <p style={{ fontSize: "15px" }}>Complete module</p>
          <p className="days-left">3 days left</p>
        </div>
      </div>
      <div className="gaming-in-sidebar-exp-info">
        <div className="gaming-reward-progress-bar-container">
          <div className="progress-bar" style={{ width: "90%" }}></div>
        </div>
      </div>
      {modulesFromProgress.map((module, index) => (
        <div key={module.module_id || index}>
          {module.module_id === storeModuleId && module.completed && (
            <button className="claim-reward-btn" onClick={handleGamifiedPost}>
              Claim Reward
            </button>
          )}
        </div>
      ))}

      {/* Modal for displaying the reward */}
      {showModal && (
        <div
          className="reward-modal-overlay"
          onClick={() => setShowModal(false)} // Close modal when clicking outside
        >
          <div
            className="reward-modal"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            {isLoading ? (
              // Show loading spinner while API call is in progress
              <div className="loading-spinner">
                <p>Processing your reward...</p>
              </div>
            ) : (
              <>
                <div className="reward-message-container">
                  <span
                    className="close-modal-btn"
                    onClick={closeModal} // Close modal when "X" is clicked
                  >
                    ✖
                  </span>
                  <h2 className="rewardmodule-cong">🎉 Congratulations! 🎉</h2>
                  <p className="reward-success-message">
                    You have successfully completed the module!
                  </p>
                  <div className="reward-success-msg-coins">
                    <p className="reward-success-message">
                      You’ve earned <strong>{coinsCount} coins</strong> for your achievement.
                    </p>
                    <span className="reward-gif-container">
                      <img src={coinsgif} alt="Celebration GIF" className="reward-gif" />
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimRewardModule;
