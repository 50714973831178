// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// // Custom Tooltip Component to handle weekly data
// const CustomTooltip = ({ active, payload, label, selectedMonth }) => {
//   if (active && payload && payload.length) {
//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedMonth === "Months"
//           ? <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//           : (
//             <Box>
//               <Typography variant="body2">{`Week: ${label}`}</Typography>
//               <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//             </Box>
//           )
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");

//   // Color mapping for each course
//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//     "Machine Learning 101": "#ffeb3b",
//     "Web Development Fundamentals": "#00bcd4",
//     "Database Management": "#8bc34a",
//     "Cybersecurity Essentials": "#ff9800",
//     "Cloud Computing": "#009688",
//     "AI & Deep Learning": "#e91e63"
//   };

//   // Sample data with weekly progress for each month
//   const courseData = {
//     "React Basics": [
//       { month: 'January', progress: 75, weeks: [{ week: 'Week 1', progress: 70 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 80 }, { week: 'Week 4', progress: 85 }] },
//       { month: 'February', progress: 80, weeks: [{ week: 'Week 1', progress: 78 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 84 }] },
//       { month: 'March', progress: 90, weeks: [{ week: 'Week 1', progress: 85 }, { week: 'Week 2', progress: 88 }, { week: 'Week 3', progress: 92 }, { week: 'Week 4', progress: 95 }] },
//     ],
//     "Advanced JavaScript": [
//       { month: 'April', progress: 60, weeks: [{ week: 'Week 1', progress: 55 }, { week: 'Week 2', progress: 60 }, { week: 'Week 3', progress: 62 }, { week: 'Week 4', progress: 65 }] },
//       { month: 'May', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 68 }, { week: 'Week 3', progress: 72 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'June', progress: 85, weeks: [{ week: 'Week 1', progress: 80 }, { week: 'Week 2', progress: 83 }, { week: 'Week 3', progress: 87 }, { week: 'Week 4', progress: 90 }] },
//     ],
//     "Python for Beginners": [
//       { month: 'January', progress: 50, weeks: [{ week: 'Week 1', progress: 45 }, { week: 'Week 2', progress: 50 }, { week: 'Week 3', progress: 52 }, { week: 'Week 4', progress: 55 }] },
//       { month: 'February', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 63 }, { week: 'Week 3', progress: 67 }, { week: 'Week 4', progress: 70 }] },
//       { month: 'March', progress: 75, weeks: [{ week: 'Week 1', progress: 72 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 77 }, { week: 'Week 4', progress: 80 }] },
//     ],
//     "Web Development Fundamentals": [
//       { month: 'April', progress: 40, weeks: [{ week: 'Week 1', progress: 35 }, { week: 'Week 2', progress: 40 }, { week: 'Week 3', progress: 42 }, { week: 'Week 4', progress: 45 }] },
//       { month: 'May', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 53 }, { week: 'Week 3', progress: 57 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'June', progress: 68, weeks: [{ week: 'Week 1', progress: 63 }, { week: 'Week 2', progress: 66 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 72 }] },
//     ],
//     "Database Management": [
//       { month: 'July', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 65 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'August', progress: 72, weeks: [{ week: 'Week 1', progress: 68 }, { week: 'Week 2', progress: 72 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 80 }] },
//       { month: 'September', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 78 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 85 }] },
//     ],
//     "Cybersecurity Essentials": [
//       { month: 'October', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 55 }, { week: 'Week 3', progress: 58 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'November', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 70 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 78 }] },
//       { month: 'December', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 83 }, { week: 'Week 4', progress: 85 }] },
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") {
//       return [
//         'January', 'February', 'March', 'April', 'May', 'June', 
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ];
//     }
//     return courseData[course].map((data) => data.month);
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   // Filter data for weekly display if a specific month is chosen
//   const prepareDataForChart = (data, selectedMonth) => {
//     if (selectedMonth === "Months") {
//       return data;
//     }
//     return Array.isArray(data)
//       ? data.find((item) => item.month === selectedMonth)?.weeks || []
//       : data.map((course) => ({
//           ...course,
//           data: course.data.find((item) => item.month === selectedMonth)?.weeks || []
//         }));
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy);
//   const courseMonths = getCourseMonths(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '80%', maxWidth: '600px', mx: 'auto' }}> {/* Decreased width and height */}
//       <Box display="flex" justifyContent="space-around" alignItems="center">
//         <Typography variant="h6">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           {/* All Courses Dropdown */}
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select value={selectedCourse} onChange={(e) => {
//               setSelectedCourse(e.target.value);
//               setSortBy("Months");
//             }} label="All Courses">
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* Sort By Dropdown */}
//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} label="Sort By">
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       </Box>

//       {/* Line Chart */}
//       <ResponsiveContainer width="100%" height={400}>
//         <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}  cursor="default">
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={sortBy === "Months" ? "month" : "week"} type="category" allowDuplicatedCategory={false} />
//           <YAxis />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} />}cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Line
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey="progress"
//                   data={prepareDataForChart(courseData.data, sortBy)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                 />
//               ))
//             : <Line type="monotone" dataKey="progress" data={data} stroke={courseColors[selectedCourse]} />
//           }
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;



// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedMonth, courseName }) => {
//   if (active && payload && payload.length) {
//     // If we're showing all courses, dynamically show the course title
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography> {/* Display course name */}
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedMonth === "Months"
//           ? <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//           : (
//             <Box>
//               <Typography variant="body2">{`Week: ${label}`}</Typography>
//               <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//             </Box>
//           )
//         }
//       </Box>
//     );
//   }
//   return null;
// };




// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");

//   // Color mapping for each course
//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//     "Machine Learning 101": "#ffeb3b",
//     "Web Development Fundamentals": "#00bcd4",
//     "Database Management": "#8bc34a",
//     "Cybersecurity Essentials": "#ff9800",
//     "Cloud Computing": "#009688",
//     "AI & Deep Learning": "#e91e63"
//   };

//   // Sample data with weekly progress for each month
//   const courseData = {
//     "React Basics": [
//       { month: 'January', progress: 75, weeks: [{ week: 'Week 1', progress: 70 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 80 }, { week: 'Week 4', progress: 85 }] },
//       { month: 'February', progress: 80, weeks: [{ week: 'Week 1', progress: 78 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 84 }] },
//       { month: 'March', progress: 90, weeks: [{ week: 'Week 1', progress: 85 }, { week: 'Week 2', progress: 88 }, { week: 'Week 3', progress: 92 }, { week: 'Week 4', progress: 95 }] },
//     ],
//     "Advanced JavaScript": [
//       { month: 'April', progress: 60, weeks: [{ week: 'Week 1', progress: 55 }, { week: 'Week 2', progress: 60 }, { week: 'Week 3', progress: 62 }, { week: 'Week 4', progress: 65 }] },
//       { month: 'May', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 68 }, { week: 'Week 3', progress: 72 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'June', progress: 85, weeks: [{ week: 'Week 1', progress: 80 }, { week: 'Week 2', progress: 83 }, { week: 'Week 3', progress: 87 }, { week: 'Week 4', progress: 90 }] },
//     ],
//     "Python for Beginners": [
//       { month: 'January', progress: 50, weeks: [{ week: 'Week 1', progress: 45 }, { week: 'Week 2', progress: 50 }, { week: 'Week 3', progress: 52 }, { week: 'Week 4', progress: 55 }] },
//       { month: 'February', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 63 }, { week: 'Week 3', progress: 67 }, { week: 'Week 4', progress: 70 }] },
//       { month: 'March', progress: 75, weeks: [{ week: 'Week 1', progress: 72 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 77 }, { week: 'Week 4', progress: 80 }] },
//     ],
//     "Web Development Fundamentals": [
//       { month: 'April', progress: 40, weeks: [{ week: 'Week 1', progress: 35 }, { week: 'Week 2', progress: 40 }, { week: 'Week 3', progress: 42 }, { week: 'Week 4', progress: 45 }] },
//       { month: 'May', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 53 }, { week: 'Week 3', progress: 57 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'June', progress: 68, weeks: [{ week: 'Week 1', progress: 63 }, { week: 'Week 2', progress: 66 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 72 }] },
//     ],
//     "Database Management": [
//       { month: 'July', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 65 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'August', progress: 72, weeks: [{ week: 'Week 1', progress: 68 }, { week: 'Week 2', progress: 72 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 80 }] },
//       { month: 'September', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 78 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 85 }] },
//     ],
//     "Cybersecurity Essentials": [
//       { month: 'October', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 55 }, { week: 'Week 3', progress: 58 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'November', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 70 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 78 }] },
//       { month: 'December', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 83 }, { week: 'Week 4', progress: 85 }] },
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") {
//       return [
//         'January', 'February', 'March', 'April', 'May', 'June', 
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ];
//     }
//     return courseData[course].map((data) => data.month);
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   // Filter data for weekly display if a specific month is chosen
//   const prepareDataForChart = (data, selectedMonth) => {
//     if (selectedMonth === "Months") {
//       return data;
//     }
//     return Array.isArray(data)
//       ? data.find((item) => item.month === selectedMonth)?.weeks || []
//       : data.map((course) => ({
//           ...course,
//           data: course.data.find((item) => item.month === selectedMonth)?.weeks || []
//         }));
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy);
//   const courseMonths = getCourseMonths(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '80%', maxWidth: '600px', mx: 'auto' }}>
//       <Box display="flex" justifyContent="space-around" alignItems="center">
//         <Typography variant="h6">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select value={selectedCourse} onChange={(e) => {
//               setSelectedCourse(e.target.value);
//               setSortBy("Months");
//             }} label="All Courses">
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} label="Sort By">
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       </Box>

//       {/* Line Chart */}
//       <ResponsiveContainer width="100%" height={400}>
//         <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }} cursor="default">
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={sortBy === "Months" ? "month" : "week"} type="category" allowDuplicatedCategory={false} />
//           <YAxis />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} courseName={selectedCourse} />} cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Line
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey="progress"
//                   data={prepareDataForChart(courseData.data, sortBy)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                 />
//               ))
//             : <Line type="monotone" dataKey="progress" data={data} stroke={courseColors[selectedCourse]} />
//           }
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;

// // dummy
// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedMonth, courseName }) => {
//   if (active && payload && payload.length) {
//     // If we're showing all courses, dynamically show the course title
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography> {/* Display course name */}
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedMonth === "Months"
//           ? <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//           : (
//             <Box>
//               <Typography variant="body2">{`Week: ${label}`}</Typography>
//               <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//             </Box>
//           )
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");

//   // Color mapping for each course
//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//     "Machine Learning 101": "#ffeb3b",
//     "Web Development Fundamentals": "#00bcd4",
//     "Database Management": "#8bc34a",
//     "Cybersecurity Essentials": "#ff9800",
//     "Cloud Computing": "#009688",
//     "AI & Deep Learning": "#e91e63"
//   };

//   // Sample data with weekly progress for each month
//   const courseData = {
//     "React Basics": [
//       { month: 'Jan', progress: 75, weeks: [{ week: 'Week 1', progress: 70 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 80 }, { week: 'Week 4', progress: 85 }] },
//       { month: 'Feb', progress: 80, weeks: [{ week: 'Week 1', progress: 78 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 84 }] },
//       { month: 'Mar', progress: 90, weeks: [{ week: 'Week 1', progress: 85 }, { week: 'Week 2', progress: 88 }, { week: 'Week 3', progress: 92 }, { week: 'Week 4', progress: 95 }] },
//     ],
//     "Advanced JavaScript": [
//       { month: 'Apr', progress: 60, weeks: [{ week: 'Week 1', progress: 55 }, { week: 'Week 2', progress: 60 }, { week: 'Week 3', progress: 62 }, { week: 'Week 4', progress: 65 }] },
//       { month: 'May', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 68 }, { week: 'Week 3', progress: 72 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'Jun', progress: 85, weeks: [{ week: 'Week 1', progress: 80 }, { week: 'Week 2', progress: 83 }, { week: 'Week 3', progress: 87 }, { week: 'Week 4', progress: 90 }] },
//     ],
//     "Python for Beginners": [
//       { month: 'Jan', progress: 50, weeks: [{ week: 'Week 1', progress: 45 }, { week: 'Week 2', progress: 50 }, { week: 'Week 3', progress: 52 }, { week: 'Week 4', progress: 55 }] },
//       { month: 'Feb', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 63 }, { week: 'Week 3', progress: 67 }, { week: 'Week 4', progress: 70 }] },
//       { month: 'Mar', progress: 75, weeks: [{ week: 'Week 1', progress: 72 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 77 }, { week: 'Week 4', progress: 80 }] },
//     ],
//     "Web Development Fundamentals": [
//       { month: 'Apr', progress: 40, weeks: [{ week: 'Week 1', progress: 35 }, { week: 'Week 2', progress: 40 }, { week: 'Week 3', progress: 42 }, { week: 'Week 4', progress: 45 }] },
//       { month: 'May', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 53 }, { week: 'Week 3', progress: 57 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'Jun', progress: 68, weeks: [{ week: 'Week 1', progress: 63 }, { week: 'Week 2', progress: 66 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 72 }] },
//     ],
//     "Database Management": [
//       { month: 'Jul', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 65 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'Aug', progress: 72, weeks: [{ week: 'Week 1', progress: 68 }, { week: 'Week 2', progress: 72 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 80 }] },
//       { month: 'Sep', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 78 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 85 }] },
//     ],
//     "Cybersecurity Essentials": [
//       { month: 'Oct', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 55 }, { week: 'Week 3', progress: 58 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'Nov', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 70 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 78 }] },
//       { month: 'Dec', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 83 }, { week: 'Week 4', progress: 85 }] },
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") {
//       return [
//         'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
//         'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
//       ];
//     }
//     return courseData[course].map((data) => data.month);
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   // Filter data for weekly display if a specific month is chosen
//   const prepareDataForChart = (data, selectedMonth) => {
//     if (selectedMonth === "Months") {
//       return data;
//     }
//     return Array.isArray(data)
//       ? data.find((item) => item.month === selectedMonth)?.weeks || []
//       : data.map((course) => ({
//           ...course,
//           data: course.data.find((item) => item.month === selectedMonth)?.weeks || []
//         }));
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy);
//   const courseMonths = getCourseMonths(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '96%', mx: 'auto',    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)',  borderRadius: '15px',}}>
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select value={selectedCourse} onChange={(e) => {
//               setSelectedCourse(e.target.value);
//               setSortBy("Months");
//             }} label="All Courses">
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} label="Sort By">
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       </Box>

//       {/* Line Chart */}
//       <ResponsiveContainer width="100%" height={350}>
//         <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }} cursor="default">
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={sortBy === "Months" ? "month" : "week"} type="category" allowDuplicatedCategory={false} />
//           <YAxis />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} courseName={selectedCourse} />} cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Line
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey="progress"
//                   data={prepareDataForChart(courseData.data, sortBy)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                 />
//               ))
//             : <Line type="monotone" dataKey="progress" data={data} stroke={courseColors[selectedCourse]} />
//           }
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;


// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedMonth, selectedWeek, courseName }) => {
//   if (active && payload && payload.length) {
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography>
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedWeek
//           ? (
//             <Box>
//               <Typography variant="body2">{`Day: ${label}`}</Typography>
//               <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//               <Typography variant="body2">Hours Worked: {payload[0].payload.hoursWorked} hrs</Typography>
//             </Box>
//           )
//           : <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");
//   const [selectedWeek, setSelectedWeek] = useState(null);

//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//     "Machine Learning 101": "#ffeb3b",
//     "Web Development Fundamentals": "#00bcd4",
//     "Database Management": "#8bc34a",
//     "Cybersecurity Essentials": "#ff9800",
//     "Cloud Computing": "#009688",
//     "AI & Deep Learning": "#e91e63"
//   };

//   const courseData = {
//     "React Basics": [
//       {
//         month: 'Jan', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 75, 80, 85, 90], hoursWorked: [3, 4, 5, 6, 7] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 76, 80, 83, 85], hoursWorked: [2, 3, 4, 5, 6] },
//         ]
//       },
//       {
//         month: 'Feb', progress: 80, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [78, 80, 82, 85, 88], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 81, 83, 84, 87], hoursWorked: [2, 4, 5, 4, 6] },
//         ]
//       },
//     ],
//     "Advanced JavaScript": [
//       {
//         month: 'Apr', progress: 60, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [55, 58, 60, 63, 65], hoursWorked: [2, 3, 4, 5, 5] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [62, 65, 67, 68, 70], hoursWorked: [3, 4, 5, 6, 4] },
//         ]
//       },
//       {
//         month: 'May', progress: 70, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [68, 70, 72, 74, 75], hoursWorked: [2, 3, 3, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 72, 75, 78, 80], hoursWorked: [4, 5, 6, 5, 5] },
//         ]
//       },
//     ],
//     "Python for Beginners": [
//       {
//         month: 'Mar', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 74, 76, 78, 80], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [75, 76, 78, 79, 80], hoursWorked: [4, 5, 6, 7, 5] },
//         ]
//       },
//     ],
//     "Data Science with Python": [
//       {
//         month: 'Jul', progress: 85, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 82, 85, 87, 90], hoursWorked: [4, 5, 6, 6, 7] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [85, 86, 87, 88, 90], hoursWorked: [3, 5, 7, 6, 5] },
//         ]
//       },
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     return courseData[course].map((data) => data.month);
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   const prepareDataForChart = (data, selectedMonth, selectedWeek) => {
//     if (selectedWeek) {
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       const weekData = courseDataForMonth?.weeks.find((week) => week.week === selectedWeek);
//       return weekData ? weekData.days.map((day, index) => ({
//         day,
//         progress: weekData.progress[index],
//         hoursWorked: weekData.hoursWorked[index]
//       })) : [];
//     }
//     if (selectedMonth === "Months") return data;
//     return data.filter((item) => item.month === selectedMonth);
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy, selectedWeek);
//   const courseMonths = getCourseMonths(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '96%', mx: 'auto', boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)', borderRadius: '15px' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select
//               value={selectedCourse}
//               onChange={(e) => {
//                 setSelectedCourse(e.target.value);
//                 setSortBy("Months");
//                 setSelectedWeek(null);
//               }}
//               label="All Courses"
//             >
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select
//               value={sortBy}
//               onChange={(e) => {
//                 setSortBy(e.target.value);
//                 setSelectedWeek(null);
//               }}
//               label="Sort By"
//             >
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small" disabled={selectedCourse === "All Courses" || sortBy === "Months"}>
//             <InputLabel>Weekly</InputLabel>
//             <Select
//               value={selectedWeek || ""}
//               onChange={(e) => setSelectedWeek(e.target.value)}
//               label="Weekly"
//             >
//               {['Week 1', 'Week 2', 'Week 3', 'Week 4'].map((week) => (
//                 <MenuItem key={week} value={week}>{week}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       </Box>

//       <ResponsiveContainer width="100%" height={350}>
//         <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }} cursor="default">
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={selectedWeek ? "day" : sortBy === "Months" ? "month" : "week"} type="category" />
//           <YAxis />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} selectedWeek={selectedWeek} courseName={selectedCourse} />} cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Line
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey="progress"
//                   data={prepareDataForChart(courseData.data, sortBy, selectedWeek)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                 />
//               ))
//             : <Line type="monotone" dataKey="progress" data={data} stroke={courseColors[selectedCourse]} />
//           }
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;


// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedMonth, selectedWeek, courseName }) => {
//   if (active && payload && payload.length) {
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography>
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedWeek
//           ? (
//             <Box>
//               <Typography variant="body2">{`Day: ${label}`}</Typography>
//               <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//               <Typography variant="body2">Hours Worked: {payload[0].payload.hoursWorked} hrs</Typography>
//             </Box>
//           )
//           : <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");
//   const [selectedWeek, setSelectedWeek] = useState(null);

//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//     "Machine Learning 101": "#ffeb3b",
//     "Web Development Fundamentals": "#00bcd4",
//     "Database Management": "#8bc34a",
//     "Cybersecurity Essentials": "#ff9800",
//     "Cloud Computing": "#009688",
//     "AI & Deep Learning": "#e91e63"
//   };

//   const courseData = {
//     "React Basics": [
//       {
//         month: 'Jan', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 75, 80, 85, 90], hoursWorked: [3, 4, 5, 6, 7] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 76, 80, 83, 85], hoursWorked: [2, 3, 4, 5, 6] },
//         ]
//       },
//       {
//         month: 'Feb', progress: 80, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [78, 80, 82, 85, 88], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 81, 83, 84, 87], hoursWorked: [2, 4, 5, 4, 6] },
//         ]
//       },
//     ],
//     "Advanced JavaScript": [
//       {
//         month: 'Apr', progress: 60, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [55, 58, 60, 63, 65], hoursWorked: [2, 3, 4, 5, 5] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [62, 65, 67, 68, 70], hoursWorked: [3, 4, 5, 6, 4] },
//         ]
//       },
//       {
//         month: 'May', progress: 70, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [68, 70, 72, 74, 75], hoursWorked: [2, 3, 3, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 72, 75, 78, 80], hoursWorked: [4, 5, 6, 5, 5] },
//         ]
//       },
//     ],
//     "Python for Beginners": [
//       {
//         month: 'Mar', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 74, 76, 78, 80], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [75, 76, 78, 79, 80], hoursWorked: [4, 5, 6, 7, 5] },
//         ]
//       },
//     ],
//     "Data Science with Python": [
//       {
//         month: 'Jul', progress: 85, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 82, 85, 87, 90], hoursWorked: [4, 5, 6, 6, 7] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [85, 86, 87, 88, 90], hoursWorked: [3, 5, 7, 6, 5] },
//         ]
//       },
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     return courseData[course].map((data) => data.month);
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   const prepareDataForChart = (data, selectedMonth, selectedWeek) => {
//     if (selectedWeek) {
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       const weekData = courseDataForMonth?.weeks.find((week) => week.week === selectedWeek);
//       return weekData ? weekData.days.map((day, index) => ({
//         day,
//         progress: weekData.progress[index],
//         hoursWorked: weekData.hoursWorked[index]
//       })) : [];
//     }
//     if (selectedMonth === "Months") return data;
//     return data.filter((item) => item.month === selectedMonth);
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy, selectedWeek);
//   const courseMonths = getCourseMonths(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '96%', mx: 'auto', boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)', borderRadius: '15px' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select
//               value={selectedCourse}
//               onChange={(e) => {
//                 setSelectedCourse(e.target.value);
//                 setSortBy("Months");
//                 setSelectedWeek(null);
//               }}
//               label="All Courses"
//             >
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select
//               value={sortBy}
//               onChange={(e) => {
//                 setSortBy(e.target.value);
//                 setSelectedWeek(null);
//               }}
//               label="Sort By"
//             >
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small" disabled={selectedCourse === "All Courses" || sortBy === "Months"}>
//             <InputLabel>Weekly</InputLabel>
//             <Select
//               value={selectedWeek || ""}
//               onChange={(e) => setSelectedWeek(e.target.value)}
//               label="Weekly"
//             >
//               {['Week 1', 'Week 2', 'Week 3', 'Week 4'].map((week) => (
//                 <MenuItem key={week} value={week}>{week}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       </Box>

//       <ResponsiveContainer width="100%" height={350}>
//         <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }} cursor="default">
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={selectedWeek ? "day" : sortBy === "Months" ? "month" : "week"} type="category" />
//           <YAxis />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} selectedWeek={selectedWeek} courseName={selectedCourse} />} cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Line
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey="progress"
//                   data={prepareDataForChart(courseData.data, sortBy, selectedWeek)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                 />
//               ))
//             : <Line type="monotone" dataKey="progress" data={data} stroke={courseColors[selectedCourse]} />
//           }
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;


// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedMonth, selectedWeek, courseName }) => {
//   if (active && payload && payload.length) {
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography><hr/>
//         <Typography variant="body1">Day:<strong>{label}</strong></Typography>
//         {selectedWeek
//           ? (
//             <Box>
//               {/* <Typography variant="body2">{`Day: ${label}`}</Typography> */}
//               {/* <Typography variant="body2">Progress: {payload[0].value}%</Typography> */}
//               <Typography variant="body2">Hours Worked:<strong> {payload[0].payload.hoursWorked} hrs</strong></Typography>
//             </Box>
//           )
//           : <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");
//   const [selectedWeek, setSelectedWeek] = useState(null);

//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//   };

//   const courseData = {
//     "React Basics": [
//       {
//         month: 'Jan', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 75, 80, 85, 90], hoursWorked: [3, 4, 5, 6, 7] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 76, 80, 83, 85], hoursWorked: [2, 3, 4, 5, 6] },
//         ]
//       },
//       {
//         month: 'Feb', progress: 80, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [78, 80, 82, 85, 88], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 81, 83, 84, 87], hoursWorked: [2, 4, 5, 4, 6] },
//         ]
//       },
//     ],
//     "Advanced JavaScript": [
//       {
//         month: 'Mar', progress: 60, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [55, 58, 60, 63, 65], hoursWorked: [2, 3, 4, 5, 5] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [62, 65, 67, 68, 70], hoursWorked: [3, 4, 5, 6, 4] },
//         ]
//       },
//       {
//         month: 'Apr', progress: 70, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [68, 70, 72, 74, 75], hoursWorked: [2, 3, 3, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 72, 75, 78, 80], hoursWorked: [4, 5, 6, 5, 5] },
//         ]
//       },
//     ],
//     "Python for Beginners": [
//       {
//         month: 'May', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 74, 76, 78, 80], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [75, 76, 78, 79, 80], hoursWorked: [4, 5, 6, 7, 5] },
//         ]
//       },
//     ],
//     "Data Science with Python": [
//       {
//         month: 'Jun', progress: 85, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 82, 85, 87, 90], hoursWorked: [4, 5, 6, 6, 7] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [85, 86, 87, 88, 90], hoursWorked: [3, 5, 7, 6, 5] },
//         ]
//       },
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     return courseData[course].map((data) => data.month);
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   const prepareDataForChart = (data, selectedMonth, selectedWeek) => {
//     if (selectedWeek) {
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       const weekData = courseDataForMonth?.weeks.find((week) => week.week === selectedWeek);
//       return weekData ? weekData.days.map((day, index) => ({
//         day,
//         progress: weekData.progress[index],
//         hoursWorked: weekData.hoursWorked[index]
//       })) : [];
//     }
//     if (selectedMonth !== "Months") {
//       // Group by weeks if a month is selected
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       return courseDataForMonth ? courseDataForMonth.weeks.map((week) => ({
//         week: week.week,
//         progress: week.progress.reduce((acc, val) => acc + val, 0) / week.progress.length, // Average progress for the week
//       })) : [];
//     }
//     return data;
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy, selectedWeek);
//   const courseMonths = getCourseMonths(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '96%', mx: 'auto', boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)', borderRadius: '15px' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select
//               value={selectedCourse}
//               onChange={(e) => {
//                 setSelectedCourse(e.target.value);
//                 setSortBy("Months");
//                 setSelectedWeek(null);
//               }}
//               label="All Courses"
//             >
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select
//               value={sortBy}
//               onChange={(e) => {
//                 setSortBy(e.target.value);
//                 setSelectedWeek(null);
//               }}
//               label="Sort By"
//             >
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {sortBy !== "Months" && (
//             <FormControl variant="outlined" size="small" sx={{ width: 150 }}>
//               <InputLabel>Weekly</InputLabel>
//               <Select
//                 value={selectedWeek || ""}
//                 onChange={(e) => setSelectedWeek(e.target.value)}
//                 label="Weekly"
//               >
//                 {['Week 1', 'Week 2', 'Week 3', 'Week 4'].map((week) => (
//                   <MenuItem key={week} value={week}>{week}</MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//         </Box>
//       </Box>

//       <ResponsiveContainer width="100%" height={350}>
//         <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }}>
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={selectedWeek ? "day" : sortBy === "Months" ? "month" : "week"} type="category" />
//           <YAxis label={{ value: selectedWeek ? "Hours Worked" : "Progress (%)", angle: -90, position: 'insideLeft' }} />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} selectedWeek={selectedWeek} courseName={selectedCourse} />} cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Line
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey={selectedWeek ? "hoursWorked" : "progress"}
//                   data={prepareDataForChart(courseData.data, sortBy, selectedWeek)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                 />
//               ))
//             : <Line type="monotone" dataKey={selectedWeek ? "hoursWorked" : "progress"} data={data} stroke={courseColors[selectedCourse]} />
//           }
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;

// weeks

// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedMonth, selectedWeek, courseName }) => {
//   if (active && payload && payload.length) {
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography><hr/>
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedWeek
//           ? (
//             <Box>
//               <Typography variant="body2">Hours Worked:<strong> {payload[0].payload.hoursWorked} hrs</strong></Typography>
//             </Box>
//           )
//           : <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");
//   const [selectedWeek, setSelectedWeek] = useState(null);

//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//   };

//   const courseData = {
//     "React Basics": [
//       {
//         month: 'Jan', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 75, 80, 85, 90], hoursWorked: [1, 6, 4, 2, 3] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 76, 80, 83, 85], hoursWorked: [3, 5, 2, 4, 1] },
//         ]
//       },
//       {
//         month: 'Feb', progress: 80, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [78, 80, 82, 85, 88], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 81, 83, 84, 87], hoursWorked: [2, 4, 5, 4, 6] },
//         ]
//       },
//     ],
//     "Advanced JavaScript": [
//       {
//         month: 'Mar', progress: 60, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [55, 58, 60, 63, 65], hoursWorked: [2, 3, 4, 5, 5] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [62, 65, 67, 68, 70], hoursWorked: [3, 4, 5, 6, 4] },
//         ]
//       },
//       {
//         month: 'Apr', progress: 70, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [68, 70, 72, 74, 75], hoursWorked: [2, 3, 3, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 72, 75, 78, 80], hoursWorked: [4, 5, 6, 5, 5] },
//         ]
//       },
//     ],
//     "Python for Beginners": [
//       {
//         month: 'May', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 74, 76, 78, 80], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [75, 76, 78, 79, 80], hoursWorked: [4, 5, 6, 7, 5] },
//         ]
//       },
//     ],
//     "Data Science with Python": [
//       {
//         month: 'Jun', progress: 85, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 82, 85, 87, 90], hoursWorked: [4, 5, 6, 6, 7] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [85, 86, 87, 88, 90], hoursWorked: [3, 5, 7, 6, 5] },
//         ]
//       },
//     ],
//   };

//   // Define the getData function to retrieve data for the selected course
//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course] || [];
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     return courseData[course].map((data) => data.month);
//   };

//   // Function to get available weeks for the selected course
//   const getAvailableWeeks = (course) => {
//     if (course === "All Courses") return [];  // No weeks dropdown for All Courses
//     const weeks = new Set();
//     courseData[course].forEach((monthData) => {
//       monthData.weeks.forEach((weekData) => {
//         weeks.add(weekData.week);
//       });
//     });
//     return Array.from(weeks);
//   };

//   const prepareDataForChart = (data, selectedMonth, selectedWeek) => {
//     if (selectedWeek) {
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       const weekData = courseDataForMonth?.weeks.find((week) => week.week === selectedWeek);
//       return weekData ? weekData.days.map((day, index) => ({
//         day,
//         progress: weekData.progress[index],
//         hoursWorked: weekData.hoursWorked[index]
//       })) : [];
//     }
//     if (selectedMonth !== "Months") {
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       return courseDataForMonth ? courseDataForMonth.weeks.map((week) => ({
//         week: week.week,
//         progress: week.progress.reduce((acc, val) => acc + val, 0) / week.progress.length,
//       })) : [];
//     }
//     return data;
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy, selectedWeek);
//   const courseMonths = getCourseMonths(selectedCourse);
//   const availableWeeks = getAvailableWeeks(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '96%', mx: 'auto', boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)', borderRadius: '15px' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="18px">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select
//               value={selectedCourse}
//               onChange={(e) => {
//                 setSelectedCourse(e.target.value);
//                 setSortBy("Months");
//                 setSelectedWeek(null);
//               }}
//               label="All Courses"
//             >
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select
//               value={sortBy}
//               onChange={(e) => {
//                 setSortBy(e.target.value);
//                 setSelectedWeek(null);
//               }}
//               label="Sort By"
//             >
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {sortBy !== "Months" && selectedCourse !== "All Courses" && (
//             <FormControl variant="outlined" size="small" sx={{ width: 150 }}>
//               <InputLabel>Weekly</InputLabel>
//               <Select
//                 value={selectedWeek || ""}
//                 onChange={(e) => setSelectedWeek(e.target.value)}
//                 label="Weekly"
//               >
//                 {availableWeeks.map((week) => (
//                   <MenuItem key={week} value={week}>{week}</MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//         </Box>
//       </Box>

//       <ResponsiveContainer width="100%" height={350}>
//         <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }}>
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={selectedWeek ? "day" : sortBy === "Months" ? "month" : "week"} type="category" />
//           <YAxis label={{ value: selectedWeek ? "Hours Worked" : "Progress (%)", angle: -90, position: 'insideLeft' }} />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} selectedWeek={selectedWeek} courseName={selectedCourse} />} cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Line
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey={selectedWeek ? "hoursWorked" : "progress"}
//                   data={prepareDataForChart(courseData.data, sortBy, selectedWeek)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                 />
//               ))
//             : <Line type="monotone" dataKey={selectedWeek ? "hoursWorked" : "progress"} data={data} stroke={courseColors[selectedCourse]} />
//           }
//         </LineChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;


// import React, { useState } from 'react';
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   ResponsiveContainer,
// } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedMonth, selectedWeek, courseName }) => {
//   if (active && payload && payload.length) {
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography><hr/>
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedWeek
//           ? (
//             <Box>
//               <Typography variant="body2">Hours Worked: <strong>{payload[0].payload.hoursWorked} hrs</strong></Typography>
//             </Box>
//           )
//           : <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");
//   const [selectedWeek, setSelectedWeek] = useState(null);

//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science with Python": "#9c27b0",
//   };

//   const courseData = {
//     "React Basics": [
//       {
//         month: 'Jan', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 75, 80, 85, 90], hoursWorked: [1, 6, 4, 2, 3] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 76, 80, 83, 85], hoursWorked: [3, 5, 2, 4, 1] },
//         ]
//       },
//       {
//         month: 'Feb', progress: 80, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [78, 80, 82, 85, 88], hoursWorked: [3, 3, 4, 5, 6] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 81, 83, 84, 87], hoursWorked: [2, 4, 5, 4, 6] },
//         ]
//       },
//     ],
//     "Advanced JavaScript": [
//       {
//         month: 'Mar', progress: 60, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [50, 55, 60, 65, 70], hoursWorked: [2, 4, 3, 5, 4] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [55, 58, 60, 62, 65], hoursWorked: [1, 3, 2, 3, 2] },
//         ]
//       },
//       {
//         month: 'Apr', progress: 65, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [60, 62, 63, 64, 65], hoursWorked: [2, 3, 2, 4, 2] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [63, 64, 65, 66, 67], hoursWorked: [1, 2, 3, 3, 4] },
//         ]
//       },
//     ],
//     "Python for Beginners": [
//       {
//         month: 'May', progress: 85, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [80, 82, 84, 85, 88], hoursWorked: [2, 2, 2, 3, 4] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [82, 84, 86, 88, 90], hoursWorked: [3, 3, 3, 3, 3] },
//         ]
//       },
//       {
//         month: 'Jun', progress: 90, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [86, 88, 90, 92, 94], hoursWorked: [3, 3, 4, 5, 5] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [88, 90, 92, 94, 96], hoursWorked: [4, 4, 4, 5, 5] },
//         ]
//       },
//     ],
//     "Data Science with Python": [
//       {
//         month: 'Jul', progress: 70, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [65, 67, 68, 70, 72], hoursWorked: [3, 3, 3, 3, 4] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [66, 68, 70, 72, 74], hoursWorked: [2, 2, 3, 3, 4] },
//         ]
//       },
//       {
//         month: 'Aug', progress: 75, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 72, 74, 76, 78], hoursWorked: [2, 3, 3, 4, 4] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [72, 74, 76, 78, 80], hoursWorked: [3, 3, 3, 4, 5] },
//         ]
//       },
//     ],
//     "Web Development with Django": [
//       {
//         month: 'Sep', progress: 68, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [60, 62, 65, 67, 70], hoursWorked: [3, 3, 3, 3, 3] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [62, 64, 66, 68, 70], hoursWorked: [3, 3, 4, 4, 4] },
//         ]
//       },
//       {
//         month: 'Oct', progress: 74, weeks: [
//           { week: 'Week 1', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [68, 70, 72, 74, 76], hoursWorked: [2, 2, 3, 4, 4] },
//           { week: 'Week 2', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], progress: [70, 72, 74, 76, 78], hoursWorked: [3, 3, 4, 4, 5] },
//         ]
//       },
//     ],
//   };
  

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course] || [];
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     return courseData[course].map((data) => data.month);
//   };

//   const getAvailableWeeks = (course) => {
//     if (course === "All Courses") return [];
//     const weeks = new Set();
//     courseData[course].forEach((monthData) => {
//       monthData.weeks.forEach((weekData) => {
//         weeks.add(weekData.week);
//       });
//     });
//     return Array.from(weeks);
//   };

//   const prepareDataForChart = (data, selectedMonth, selectedWeek) => {
//     if (selectedWeek) {
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       const weekData = courseDataForMonth?.weeks.find((week) => week.week === selectedWeek);
//       return weekData ? weekData.days.map((day, index) => ({
//         day,
//         progress: weekData.progress[index],
//         hoursWorked: weekData.hoursWorked[index]
//       })) : [];
//     }
//     if (selectedMonth !== "Months") {
//       const courseDataForMonth = data.find((item) => item.month === selectedMonth);
//       return courseDataForMonth ? courseDataForMonth.weeks.map((week) => ({
//         week: week.week,
//         progress: week.progress.reduce((acc, val) => acc + val, 0) / week.progress.length,
//       })) : [];
//     }
//     return data;
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy, selectedWeek);
//   const courseMonths = getCourseMonths(selectedCourse);
//   const availableWeeks = getAvailableWeeks(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '96%', mx: 'auto', boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)', borderRadius: '15px' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select
//               value={selectedCourse}
//               onChange={(e) => {
//                 setSelectedCourse(e.target.value);
//                 setSortBy("Months");
//                 setSelectedWeek(null);
//               }}
//               label="All Courses"
//             >
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select
//               value={sortBy}
//               onChange={(e) => {
//                 setSortBy(e.target.value);
//                 setSelectedWeek(null);
//               }}
//               label="Sort By"
//             >
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {sortBy !== "Months" && selectedCourse !== "All Courses" && (
//             <FormControl variant="outlined" size="small" sx={{ width: 150 }}>
//               <InputLabel>Weekly</InputLabel>
//               <Select
//                 value={selectedWeek || ""}
//                 onChange={(e) => setSelectedWeek(e.target.value)}
//                 label="Weekly"
//               >
//                 {availableWeeks.map((week) => (
//                   <MenuItem key={week} value={week}>{week}</MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//         </Box>
//       </Box>
//       <ResponsiveContainer width="100%" height={350}>
//   <AreaChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }} cursor={true}>
//     <CartesianGrid stroke="none" />
//     <XAxis dataKey={selectedWeek ? "day" : sortBy === "Months" ? "month" : "week"} type="category" />
//     <YAxis
//       label={{
//         value: selectedWeek ? "Hours Worked" : "Progress (%)",
//         angle: -90,
//         position: 'insideLeft',
//       }}
//     />
//     <Tooltip
//       content={
//         <CustomTooltip
//           selectedMonth={sortBy}
//           selectedWeek={selectedWeek}
//           courseName={selectedCourse}
//         />
//       }
//       cursor={false} // Disable cursor changes in Tooltip (this is handled by AreaChart cursor)
//     />
//     {selectedCourse === "All Courses"
//       ? getData("All Courses").map((courseData) => (
//           <Area
//             key={courseData.name}
//             type="monotone"
//             dataKey={selectedWeek ? "hoursWorked" : "progress"}
//             data={prepareDataForChart(courseData.data, sortBy, selectedWeek)}
//             name={courseData.name}
//             stroke={courseColors[courseData.name]}
//             fill="none" // No fill effect for "All Courses"
//             dot={{ r: 2 }} // Custom dot size for circle visibility
//           />
//         ))
//       : <Area
//           type="monotone"
//           dataKey={selectedWeek ? "hoursWorked" : "progress"}
//           data={data}
//           stroke={courseColors[selectedCourse]}
//           fill={courseColors[selectedCourse]} // Fill effect for individual courses
//           fillOpacity={0.3}
//           dot={{ r: 3 }} // Custom dot size for circle visibility
//         />
//     }
//   </AreaChart>
// </ResponsiveContainer>


//     </Box>
//   );
// };

// export default AnalyticsGraph;

// import React, { useState, useEffect } from "react";
// import {AreaChart,Area,XAxis,YAxis,Tooltip,CartesianGrid, ResponsiveContainer} from "recharts";
// import { useSelector } from "react-redux";
// import { adminaxios } from "../axios";
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

// const courses = [
//   { _id: "66a0960f4e1d9d2df92ea06c", course_title: "Advanced Java Programming" },
//   { _id: "66a253b4659280db29bfece7", course_title: "Data Science with Python" },
//   { _id: "66eee4acc2bde2e5874728e5", course_title: "Full-Stack Web Development" },
// ];
// const monthsArray = [
//   "January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"
// ];
// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");
//   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
//   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
//   const [selectedWeek, setSelectedWeek] = useState(null);
//   const [graphData, setGraphData] = useState([]);
//   const [documentId, setDocumentId] = useState(documentId);
//   const currentcourseId = useSelector((state) => state.dynamicApiId.courseId);

//   const getCourseTitle = (id) => {
//     const course = courses.find((c) => c._id === id);
//     return course ? course.course_title : "Unknown Course";
//   };

//   // Sync the selected course with the current course ID
//   useEffect(() => {
//     const matchedCourse = courses.find((course) => course._id === currentcourseId);
//     if (matchedCourse) {
//       setSelectedCourse(matchedCourse._id);
//     } else {
//       setSelectedCourse("All Courses");
//     }
//   }, [currentcourseId]);

//   // Fetch document_id from POST API
//   useEffect(() => {
//     if (selectedCourse !== "All Courses" && !documentId) {
//       const token = localStorage.getItem("token");

//       console.log("Payload being sent to /activity/:", { course_id: selectedCourse });

//       adminaxios
//         .post(
//           "/activity/",
//           { course_id: selectedCourse },
//           { headers: { Authorization: `Bearer ${token}` } }
//         )
//         .then((response) => {
//           if (response.data?.document_id) {
//             console.log("API Response:", response.data);
//             setDocumentId(response.data.document_id);
//           } else {
//             console.error("Document ID missing in response:", response.data);
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching document ID:", error.response || error.message);
//         });
//     }
//   }, [selectedCourse, documentId]);

//   // Fetch graph data for months via WebSocket
//   useEffect(() => {
//     if (documentId && sortBy === "Months") {
//       const ws = new WebSocket(
//         `wss://lmsbackend.nquantum.in/ws/time_spent/learner_analytics/${documentId}/courses/${selectedCourse}/`
//       );

//       ws.onopen = () => {
//         console.log("WebSocket connection established.");
//         ws.send(JSON.stringify({ year: selectedYear, month: selectedMonth})); // Example payload for a specific month
//       };

//       ws.onmessage = (event) => {
//         const response = JSON.parse(event.data);
//         if (response.weeks) {
//           console.log("WebSocket data received:", response);
//           setGraphData(
//             response.weeks.map((week, index) => ({
//               name: `Week ${index + 1}`, // Week 1, Week 2, etc.
//               total: Math.floor(week.total_time_spent_seconds / 3600), // Numeric value for the graph
    
//             }))
//           );
//         }
//       };

//       ws.onerror = (error) => {
//         console.error("WebSocket Error:", error);
//       };

//       ws.onclose = () => {
//         console.log("WebSocket connection closed.");
//       };

//       return () => ws.close();
//     }
//   }, [documentId, selectedMonth, selectedYear]);

//   // Fetch graph data for weeks via WebSocket
//   const handleWeekSelection = (week) => {
//     setSelectedWeek(week);

//     if (documentId) {
//       const ws = new WebSocket(
//         `wss://lmsbackend.nquantum.in/ws/time_spent/learner_analytics/${documentId}/courses/${selectedCourse}/`
//       );

//       ws.onopen = () => {
//         console.log("WebSocket connection established for week selection.");
//         ws.send(
//           JSON.stringify({ document_id: documentId, week_start: week.week_start })
//         );
//       };

//       ws.onmessage = (event) => {
//         const response = JSON.parse(event.data);
//         if (response.days) {
//           console.log("WebSocket week data received:", response);
//           setGraphData(
//             response.days.map((day) => ({
//               name: day.date,
//               total: Math.floor(day.total_time_spent_seconds / 3600), // Convert to hours
//             }))
//           );
//         }
//       };

//       ws.onerror = (error) => console.error("WebSocket Error:", error);

//       ws.onclose = () => {
//         console.log("WebSocket connection closed for week selection.");
//       };

//       return () => ws.close();
//     }
//   };

//   return (
//     <Box
//       sx={{
//         p: 2,
//         width: "96%",
//         mx: "auto",
//         boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.08)",
//         borderRadius: "15px",
//       }}
//     >
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">
//           User Progress Analytics
//         </Typography>

//         <Box display="flex" gap={2}>
//           {/* Course Dropdown */}
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select
//               value={selectedCourse}
//               onChange={(e) => {
//                 setSelectedCourse(e.target.value);
//                 setSortBy("Months");
//                 setSelectedWeek(null);
//               }}
//               label="All Courses"
//             >
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {courses.map((course) => (
//                 <MenuItem key={course._id} value={course._id}>
//                   {course.course_title}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* Sort By Dropdown */}
//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select
//               value={sortBy}
//               onChange={(e) => {
//                 setSortBy(e.target.value);
//                 setSelectedWeek(null);
//               }}
//               label="Sort By"
//             >
//               <MenuItem value="Months">Months</MenuItem>
//               <MenuItem value="Weeks">Weeks</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//       </Box>

//       {/* Week Selector */}
//       {sortBy === "Weeks" && (
//         <FormControl variant="outlined" size="small" sx={{ mt: 2 }}>
//           <InputLabel>Select Week</InputLabel>
//           <Select
//             value={selectedWeek}
//             onChange={(e) => handleWeekSelection(e.target.value)}
//             label="Select Week"
//           >
//             {graphData.map((week) => (
//               <MenuItem key={week.name} value={week}>
//                 {week.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       )}

//       {/* Graph */}
//       <ResponsiveContainer width="100%" height={350}>
//         <AreaChart data={graphData} margin={{ top: 20, right: 30, left: 0, bottom: 10 }}>
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey="name" type="category" />
//           <YAxis
//             label={{
//               value: selectedWeek ? "Hours Worked" : "Progress (in hrs)",
//               angle: -90,
//               position: "insideLeft",
//             }}
//           />
//           <Tooltip />
//           <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" />
//         </AreaChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;

// import React, { useState, useEffect } from "react";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   ResponsiveContainer,
// } from "recharts";

// import { adminaxios } from "../axios";
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

// const monthsArray = [
//   "January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"
// ];

// const courses = [
//   { _id: "66a0960f4e1d9d2df92ea06c", course_title: "Advanced Java Programming" },
//   { _id: "66a253b4659280db29bfece7", course_title: "Data Science with Python" },
//   { _id: "66eee4acc2bde2e5874728e5", course_title: "Full-Stack Web Development" },
// ];

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses"); // Default to "All Courses"
//   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
//   const [selectedWeek, setSelectedWeek] = useState(null)
//   const [graphData, setGraphData] = useState([]);
//   const [weeksData, setWeeksData] = useState([]);
//   const [documentId, setDocumentId] = useState("documentId"); // Replace with actual document ID logic

//   // Fetch document ID for the selected course
//   useEffect(() => {
//     if (selectedCourse !== "All Courses") {
//       const token = localStorage.getItem("token");
//       console.log("Fetching document ID for course:", selectedCourse);

//       adminaxios
//         .post(
//           "/activity/",
//           { course_id: selectedCourse },
//           { headers: { Authorization: `Bearer ${token}` } }
//         )
//         .then((response) => {
//           if (response.data?.document_id) {
//             setDocumentId(response.data.document_id);
//             console.log("Document ID fetched:", response.data.document_id);
//           } else {
//             console.error("Document ID missing in response:", response.data);
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching document ID:", error.response || error.message);
//         });
//     }
//   }, [selectedCourse]);

//   // Fetch weeks data for the selected month
//   useEffect(() => {
//     if (documentId && selectedMonth) {
//       const ws = new WebSocket(
//         `wss://lmsbackend.nquantum.in/ws/time_spent/learner_analytics/${documentId}/courses/${selectedCourse}/`
//       );

//       ws.onopen = () => {
//         console.log("Fetching weeks data for month:", selectedMonth);
//         ws.send(JSON.stringify({ year: 2024, month: selectedMonth })); // Use dynamic month
//       };

//       ws.onmessage = (event) => {
//         const response = JSON.parse(event.data);
//         if (response.weeks) {
//           const formattedWeeks = response.weeks.map((week, index) => ({
//             ...week,
//             week: index + 1, // Assign a `week` number if not present
//           }));
        
//           console.log("Formatted Weeks Data:", formattedWeeks); // Verify structure
//           setWeeksData(formattedWeeks);
//         }
        
//       };

//       ws.onerror = (error) => console.error("WebSocket Error for weeks:", error);

//       ws.onclose = () => {
//         console.log("WebSocket connection closed for weeks.");
//       };

//       return () => ws.close();
//     }
//   }, [documentId, selectedMonth]);

//   // Fetch days data for the selected week
//   const handleWeekSelection = (week) => {
//     console.log("Selected week:", week); // Debugging
//     setSelectedWeek(week);
  
//     if (documentId) {
//       const ws = new WebSocket(
//         `wss://lmsbackend.nquantum.in/ws/time_spent/learner_analytics/${documentId}/courses/${selectedCourse}/`
//       );
  
//       ws.onopen = () => {
//         console.log(
//           `Fetching days data for year: 2024, month: ${selectedMonth}, week: ${week.week}`
//         );
//         ws.send(
//           JSON.stringify({
//             year: 2024,
//             month: selectedMonth,
//             week: week.week,
//           })
//         );
//       };
  
//       ws.onmessage = (event) => {
//         const response = JSON.parse(event.data);
//         if (response.days) {
//           setGraphData(
//             response.days.map((day) => ({
//               name: new Date(day.day_start).toLocaleDateString("default", {
//                 weekday: "short",
//               }),
//               total: Math.floor(day.total_time_spent_seconds / 3600),
//             }))
//           );
//         }
//       };
  
//       ws.onclose = () => {
//         console.log("WebSocket connection closed.");
//       };
  
//       return () => ws.close();
//     }
//   };
  
  


//   return (
//     <Box
//       sx={{
//         p: 2,
//         width: "96%",
//         mx: "auto",
//         boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.08)",
//         borderRadius: "15px",
//       }}
//     >
//       <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">
//         User Progress Analytics
//       </Typography>

//       <Box display="flex" gap={2} mt={2}>
//         {/* Course Dropdown */}
//         <FormControl variant="outlined" size="small">
//           <InputLabel>All Courses</InputLabel>
//           <Select
//             value={selectedCourse}
//             onChange={(e) => {
//               setSelectedCourse(e.target.value);
//               setSelectedWeek(null);
//               setSelectedMonth(new Date().getMonth() + 1); // Reset to default month
//             }}
//             label="All Courses"
//           >
//             <MenuItem value="All Courses">All Courses</MenuItem>
//             {courses.map((course) => (
//               <MenuItem key={course._id} value={course._id}>
//                 {course.course_title}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>

//         {/* Month Dropdown */}
//         <FormControl variant="outlined" size="small">
//           <InputLabel>Month</InputLabel>
//           <Select
//             value={selectedMonth}
//             onChange={(e) => {
//               setSelectedMonth(e.target.value);
//               setSelectedWeek(null); // Reset week selection
//             }}
//             label="Month"
//           >
//             {monthsArray.map((month, index) => (
//               <MenuItem key={index + 1} value={index + 1}>
//                 {month}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>

//         {/* Week Dropdown */}
//         {weeksData.length > 0 && (
//   <FormControl variant="outlined" size="small">
//     <InputLabel>Week</InputLabel>
//     <Select
//       value={selectedWeek}
//       onChange={(e) => handleWeekSelection(e.target.value)} // Pass the selected week object
//       label="Week"
//     >
//       {weeksData.map((week) => (
//         <MenuItem key={week.week} value={week}>
//           Week {week.week} {/* Display week number */}
//         </MenuItem>
//       ))}
//     </Select>
//   </FormControl>
// )}

//       </Box>

//       {/* Graph */}
//       <ResponsiveContainer width="100%" height={350} mt={3}>
//         <AreaChart data={graphData} margin={{ top: 20, right: 30, left: 0, bottom: 10 }}>
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey="name" type="category" />
//           <YAxis label={{ value: "Hours Worked", angle: -90, position: "insideLeft" }} />
//           <Tooltip />
//           <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" />
//         </AreaChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;

import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { adminaxios } from "../axios";

const monthsArray = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const courses = [
  { _id: "66a0960f4e1d9d2df92ea06c", course_title: "Advanced Java Programming" },
  { _id: "66a253b4659280db29bfece7", course_title: "Data Science with Python" },
  { _id: "66eee4acc2bde2e5874728e5", course_title: "Full-Stack Web Development" },
];

const AnalyticsGraph = () => {
  const [selectedCourse, setSelectedCourse] = useState("All Courses");
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [weeksData, setWeeksData] = useState([]);
  const [documentId, setDocumentId] = useState("");

  // Fetch document ID for the selected course
  useEffect(() => {
    if (selectedCourse !== "All Courses") {
      const token = localStorage.getItem("token");
      adminaxios
        .post(
          "/activity/",
          { course_id: selectedCourse },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.data?.document_id) {
            setDocumentId(response.data.document_id);
          } else {
            console.error("Document ID missing in response:", response.data);
          }
        })
        .catch((error) => console.error("Error fetching document ID:", error));
    }
  }, [selectedCourse]);

  // Fetch data for the selected month
  useEffect(() => {
    if (documentId && selectedMonth) {
      const ws = new WebSocket(
        `wss://lmsbackend.nquantum.in/ws/time_spent/learner_analytics/${documentId}/courses/${selectedCourse}/`
      );

      ws.onopen = () => {
        ws.send(JSON.stringify({ year: 2024, month: selectedMonth }));
      };

      ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response.weeks) {
          const formattedWeeks = response.weeks.map((week, index) => ({
            ...week,
            week: index + 1,
          }));
          setWeeksData(formattedWeeks);
          // Update graphData for the month
          setGraphData(
            formattedWeeks.map((week) => ({
              name: `Week ${week.week}`,
              total: Math.floor(week.total_time_spent_seconds / 3600),
            }))
          );
        }
      };

      ws.onclose = () => console.log("WebSocket closed for month data.");
      ws.onerror = (error) => console.error("WebSocket error:", error);

      return () => ws.close();
    }
  }, [documentId, selectedMonth]);

  // Fetch data for the selected week
  const handleWeekSelection = (week) => {
    setSelectedWeek(week);

    if (documentId) {
      const ws = new WebSocket(
        `wss://lmsbackend.nquantum.in/ws/time_spent/learner_analytics/${documentId}/courses/${selectedCourse}/`
      );

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            year: 2024,
            month: selectedMonth,
            week: week.week,
          })
        );
      };

      ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response.days) {
          setGraphData(
            response.days.map((day) => ({
              name: new Date(day.day_start).toLocaleDateString("default", {
                weekday: "short",
              }),
              total: Math.floor(day.total_time_spent_seconds / 3600),
            }))
          );
        }
      };

      ws.onclose = () => console.log("WebSocket closed for week data.");
      ws.onerror = (error) => console.error("WebSocket error:", error);

      return () => ws.close();
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        width: "96%",
        mx: "auto",
        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.08)",
        borderRadius: "15px",
      }}
    >
      <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">
        User Progress Analytics
      </Typography>

      <Box display="flex" gap={2} mt={2}>
        {/* Course Dropdown */}
        <FormControl variant="outlined" size="small">
          <InputLabel>All Courses</InputLabel>
          <Select
            value={selectedCourse}
            onChange={(e) => {
              setSelectedCourse(e.target.value);
              setSelectedMonth(new Date().getMonth() + 1);
              setSelectedWeek(null);
            }}
            label="All Courses"
          >
            <MenuItem value="All Courses">All Courses</MenuItem>
            {courses.map((course) => (
              <MenuItem key={course._id} value={course._id}>
                {course.course_title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Month Dropdown */}
        <FormControl variant="outlined" size="small">
          <InputLabel>Month</InputLabel>
          <Select
            value={selectedMonth}
            onChange={(e) => {
              setSelectedMonth(e.target.value);
              setSelectedWeek(null);
            }}
            label="Month"
          >
            {monthsArray.map((month, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Week Dropdown */}
        {weeksData.length > 0 && (
          <FormControl variant="outlined" size="small" >
            <InputLabel>Week</InputLabel>
            <Select
              value={selectedWeek}
              onChange={(e) => handleWeekSelection(e.target.value)}
              label="Week"
            >
              {weeksData.map((week) => (
                <MenuItem key={week.week} value={week}>
                  Week {week.week}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {/* Graph */}
      <ResponsiveContainer width="100%" height={350} mt={3}>
        <AreaChart data={graphData} margin={{ top: 20, right: 30, left: 0, bottom: 10 }}>
          <CartesianGrid stroke="none" />
          <XAxis dataKey="name" type="category" />
          <YAxis label={{ value: "Hours Worked", angle: -90, position: "insideLeft" }} />
          <Tooltip />
          <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AnalyticsGraph;




// import React, { useState } from 'react';
// import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// // Custom Tooltip Component to handle weekly data
// const CustomTooltip = ({ active, payload, label, selectedMonth }) => {
//   if (active && payload && payload.length) {
//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedMonth === "Months"
//           ? <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//           : (
//             <Box>
//               <Typography variant="body2">{`Week: ${label}`}</Typography>
//               <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//             </Box>
//           )
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");

//   // Color mapping for each course with light color shades or mixed colors
//   const courseColors = {
//     "React Basics": "#3f51b5", // Deep Blue
//     "Advanced JavaScript": "#ff5722", // Orange
//     "Python for Beginners": "#4caf50", // Green
//     "Data Science with Python": "#9c27b0", // Purple
//     "Machine Learning 101": "#ffeb3b", // Yellow
//     "Web Development Fundamentals": "#00bcd4", // Cyan
//     "Database Management": "#8bc34a", // Light Green
//     "Cybersecurity Essentials": "#ff9800", // Orange
//     "Cloud Computing": "#009688", // Teal
//     "AI & Deep Learning": "#e91e63" // Pink
//   };

//   // Sample data with weekly progress for each month
//   const courseData = {
//     /* Your course data */
//     "React Basics": [
//       { month: 'January', progress: 75, weeks: [{ week: 'Week 1', progress: 70 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 80 }, { week: 'Week 4', progress: 85 }] },
//       { month: 'February', progress: 80, weeks: [{ week: 'Week 1', progress: 78 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 84 }] },
//       { month: 'March', progress: 90, weeks: [{ week: 'Week 1', progress: 85 }, { week: 'Week 2', progress: 88 }, { week: 'Week 3', progress: 92 }, { week: 'Week 4', progress: 95 }] },
//     ],
//     "Advanced JavaScript": [
//       { month: 'April', progress: 60, weeks: [{ week: 'Week 1', progress: 55 }, { week: 'Week 2', progress: 60 }, { week: 'Week 3', progress: 62 }, { week: 'Week 4', progress: 65 }] },
//       { month: 'May', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 68 }, { week: 'Week 3', progress: 72 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'June', progress: 85, weeks: [{ week: 'Week 1', progress: 80 }, { week: 'Week 2', progress: 83 }, { week: 'Week 3', progress: 87 }, { week: 'Week 4', progress: 90 }] },
//     ],
//     "Python for Beginners": [
//       { month: 'January', progress: 50, weeks: [{ week: 'Week 1', progress: 45 }, { week: 'Week 2', progress: 50 }, { week: 'Week 3', progress: 52 }, { week: 'Week 4', progress: 55 }] },
//       { month: 'February', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 63 }, { week: 'Week 3', progress: 67 }, { week: 'Week 4', progress: 70 }] },
//       { month: 'March', progress: 75, weeks: [{ week: 'Week 1', progress: 72 }, { week: 'Week 2', progress: 75 }, { week: 'Week 3', progress: 77 }, { week: 'Week 4', progress: 80 }] },
//     ],
//     "Web Development Fundamentals": [
//       { month: 'April', progress: 40, weeks: [{ week: 'Week 1', progress: 35 }, { week: 'Week 2', progress: 40 }, { week: 'Week 3', progress: 42 }, { week: 'Week 4', progress: 45 }] },
//       { month: 'May', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 53 }, { week: 'Week 3', progress: 57 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'June', progress: 68, weeks: [{ week: 'Week 1', progress: 63 }, { week: 'Week 2', progress: 66 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 72 }] },
//     ],
//     "Database Management": [
//       { month: 'July', progress: 65, weeks: [{ week: 'Week 1', progress: 60 }, { week: 'Week 2', progress: 65 }, { week: 'Week 3', progress: 70 }, { week: 'Week 4', progress: 75 }] },
//       { month: 'August', progress: 72, weeks: [{ week: 'Week 1', progress: 68 }, { week: 'Week 2', progress: 72 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 80 }] },
//       { month: 'September', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 78 }, { week: 'Week 3', progress: 82 }, { week: 'Week 4', progress: 85 }] },
//     ],
//     "Cybersecurity Essentials": [
//       { month: 'October', progress: 55, weeks: [{ week: 'Week 1', progress: 50 }, { week: 'Week 2', progress: 55 }, { week: 'Week 3', progress: 58 }, { week: 'Week 4', progress: 60 }] },
//       { month: 'November', progress: 70, weeks: [{ week: 'Week 1', progress: 65 }, { week: 'Week 2', progress: 70 }, { week: 'Week 3', progress: 75 }, { week: 'Week 4', progress: 78 }] },
//       { month: 'December', progress: 80, weeks: [{ week: 'Week 1', progress: 75 }, { week: 'Week 2', progress: 80 }, { week: 'Week 3', progress: 83 }, { week: 'Week 4', progress: 85 }] },
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") {
//       return [
//         'January', 'February', 'March', 'April', 'May', 'June', 
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ];
//     }
//     return courseData[course].map((data) => data.month);
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   // Filter data for weekly display if a specific month is chosen
//   const prepareDataForChart = (data, selectedMonth) => {
//     if (selectedMonth === "Months") {
//       return data;
//     }
//     return Array.isArray(data)
//       ? data.find((item) => item.month === selectedMonth)?.weeks || []
//       : data.map((course) => ({
//           ...course,
//           data: course.data.find((item) => item.month === selectedMonth)?.weeks || []
//         }));
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy);
//   const courseMonths = getCourseMonths(selectedCourse);

//   // Function to create lighter shades of a color using RGBA
//   const lightenColor = (color) => {
//     return `${color}99`; // Add alpha (transparency) for lighter shade (hex format)
//   };

//   return (
//     <Box sx={{ p: 2, width: '80%', maxWidth: '600px', mx: 'auto' }}>
//       <Box display="flex" justifyContent="space-around" alignItems="center">
//         <Typography variant="h6">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           {/* All Courses Dropdown */}
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select value={selectedCourse} onChange={(e) => {
//               setSelectedCourse(e.target.value);
//               setSortBy("Months");
//             }} label="All Courses">
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* Sort By Dropdown */}
//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} label="Sort By">
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Box>
//       </Box>

//       {/* Area Chart with Light Fill Colors */}
//       <ResponsiveContainer width="100%" height={400}>
//         <AreaChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }} cursor="default">
//           <CartesianGrid stroke="none" />
//           <XAxis dataKey={sortBy === "Months" ? "month" : "week"} type="category" allowDuplicatedCategory={false} />
//           <YAxis />
//           <Tooltip content={<CustomTooltip selectedMonth={sortBy} />} cursor={false} />
//           {selectedCourse === "All Courses"
//             ? getData("All Courses").map((courseData) => (
//                 <Area
//                   key={courseData.name}
//                   type="monotone"
//                   dataKey="progress"
//                   data={prepareDataForChart(courseData.data, sortBy)}
//                   name={courseData.name}
//                   stroke={courseColors[courseData.name]}
//                   fill={lightenColor(courseColors[courseData.name])} // Lighter color fill
//                 />
//               ))
//             : <Area 
//                 type="monotone" 
//                 dataKey="progress" 
//                 data={data} 
//                 stroke={courseColors[selectedCourse]} 
//                 fill={lightenColor(courseColors[selectedCourse])} // Lighter color fill 
//               />
//           }
//         </AreaChart>
//       </ResponsiveContainer>
//     </Box>
//   );
// };

// export default AnalyticsGraph;




// import React, { useState } from 'react';
// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
// import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

// const CustomTooltip = ({ active, payload, label, selectedSort, courseName }) => {
//   if (active && payload && payload.length) {
//     const courseTitle = courseName !== "All Courses" ? courseName : payload[0].name;

//     return (
//       <Box sx={{ p: 1, backgroundColor: "white", border: "1px solid #ddd" }}>
//         <Typography variant="body1"><strong>{courseTitle}</strong></Typography>
//         <Typography variant="body1"><strong>{label}</strong></Typography>
//         {selectedSort === "Days"
//           ? <Typography variant="body2">{`Hours Worked: ${payload[0].value} hours`}</Typography>
//           : <Typography variant="body2">Progress: {payload[0].value}%</Typography>
//         }
//       </Box>
//     );
//   }
//   return null;
// };

// const AnalyticsGraph = () => {
//   const [selectedCourse, setSelectedCourse] = useState("All Courses");
//   const [sortBy, setSortBy] = useState("Months");
//   const [selectedWeek, setSelectedWeek] = useState(null);

//   const courseColors = {
//     "React Basics": "#3f51b5",
//     "Advanced JavaScript": "#ff5722",
//     "Python for Beginners": "#4caf50",
//     "Data Science Basics": "#9c27b0", // Added new course color
//     "Machine Learning": "#00bcd4",    // Added new course color
//     "Web Development": "#ff9800",    // Added new course color
//   };

//   const courseData = {
//     "React Basics": [
//       { month: 'Jan', progress: 75, weeks: [
//         { week: 'Week 1', progress: 70, hoursWorked: 20, days: [
//           { day: 'Monday', progress: 68, hours: 1 }, { day: 'Tuesday', progress: 69, hours: 4 }, { day: 'Wednesday', progress: 70, hours: 2 },
//           { day: 'Thursday', progress: 71, hours: 1 }, { day: 'Friday', progress: 72, hours: 3 }, { day: 'Saturday', progress: 73, hours: 5 }, { day: 'Sunday', progress: 74, hours: 4 }
//         ]},
//         { week: 'Week 2', progress: 75, hoursWorked: 22, days: [
//           { day: 'Monday', progress: 72, hours: 4 }, { day: 'Tuesday', progress: 73, hours: 4 }, { day: 'Wednesday', progress: 74, hours: 4 },
//           { day: 'Thursday', progress: 75, hours: 4 }, { day: 'Friday', progress: 76, hours: 4 }, { day: 'Saturday', progress: 77, hours: 4 }, { day: 'Sunday', progress: 78, hours: 4 }
//         ]},
//       ]},
//     ],
//     "Advanced JavaScript": [
//       { month: 'Apr', progress: 60, weeks: [
//         { week: 'Week 1', progress: 55, hoursWorked: 18, days: [
//           { day: 'Monday', progress: 52, hours: 3 }, { day: 'Tuesday', progress: 53, hours: 3 }, { day: 'Wednesday', progress: 54, hours: 3 },
//           { day: 'Thursday', progress: 55, hours: 3 }, { day: 'Friday', progress: 56, hours: 3 }, { day: 'Saturday', progress: 57, hours: 3 }, { day: 'Sunday', progress: 58, hours: 3 }
//         ]},
//         { week: 'Week 2', progress: 60, hoursWorked: 20, days: [
//           { day: 'Monday', progress: 58, hours: 3 }, { day: 'Tuesday', progress: 59, hours: 3 }, { day: 'Wednesday', progress: 60, hours: 3 },
//           { day: 'Thursday', progress: 61, hours: 3 }, { day: 'Friday', progress: 62, hours: 3 }, { day: 'Saturday', progress: 63, hours: 3 }, { day: 'Sunday', progress: 64, hours: 3 }
//         ]},
//       ]},
//     ],
//     "Python for Beginners": [
//       { month: 'Mar', progress: 80, weeks: [
//         { week: 'Week 1', progress: 78, hoursWorked: 25, days: [
//           { day: 'Monday', progress: 75, hours: 4 }, { day: 'Tuesday', progress: 77, hours: 5 }, { day: 'Wednesday', progress: 79, hours: 4 },
//           { day: 'Thursday', progress: 80, hours: 3 }, { day: 'Friday', progress: 81, hours: 5 }, { day: 'Saturday', progress: 82, hours: 6 }, { day: 'Sunday', progress: 83, hours: 4 }
//         ]},
//       ]},
//     ],
//     "Data Science Basics": [
//       { month: 'Jun', progress: 70, weeks: [
//         { week: 'Week 1', progress: 68, hoursWorked: 30, days: [
//           { day: 'Monday', progress: 67, hours: 6 }, { day: 'Tuesday', progress: 68, hours: 6 }, { day: 'Wednesday', progress: 69, hours: 6 },
//           { day: 'Thursday', progress: 70, hours: 6 }, { day: 'Friday', progress: 71, hours: 6 }, { day: 'Saturday', progress: 72, hours: 6 }, { day: 'Sunday', progress: 73, hours: 6 }
//         ]},
//       ]},
//     ],
//     "Machine Learning": [
//       { month: 'Jul', progress: 85, weeks: [
//         { week: 'Week 1', progress: 80, hoursWorked: 35, days: [
//           { day: 'Monday', progress: 78, hours: 7 }, { day: 'Tuesday', progress: 79, hours: 7 }, { day: 'Wednesday', progress: 80, hours: 7 },
//           { day: 'Thursday', progress: 81, hours: 7 }, { day: 'Friday', progress: 82, hours: 7 }, { day: 'Saturday', progress: 83, hours: 7 }, { day: 'Sunday', progress: 84, hours: 7 }
//         ]},
//       ]},
//     ],
//     "Web Development": [
//       { month: 'Sep', progress: 90, weeks: [
//         { week: 'Week 1', progress: 85, hoursWorked: 40, days: [
//           { day: 'Monday', progress: 83, hours: 8 }, { day: 'Tuesday', progress: 84, hours: 8 }, { day: 'Wednesday', progress: 85, hours: 8 },
//           { day: 'Thursday', progress: 86, hours: 8 }, { day: 'Friday', progress: 87, hours: 8 }, { day: 'Saturday', progress: 88, hours: 8 }, { day: 'Sunday', progress: 89, hours: 8 }
//         ]},
//       ]},
//     ],
//   };

//   const getCourseMonths = (course) => {
//     if (course === "All Courses") {
//       return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     }
//     return courseData[course].map((data) => data.month);
//   };

//   const getWeeksForMonth = (course, selectedMonth) => {
//     if (course === "All Courses") return [];
//     const monthData = courseData[course].find((data) => data.month === selectedMonth);
//     return monthData ? monthData.weeks.map((week) => week.week) : [];
//   };

//   const getData = (course) => {
//     if (course === "All Courses") {
//       return Object.keys(courseData).map((courseName) => ({
//         name: courseName,
//         data: courseData[courseName],
//       }));
//     }
//     return courseData[course];
//   };

//   const prepareDataForChart = (data, selectedSort) => {
//     if (selectedSort === "Months") return data;
//     if (selectedWeek) {
//       return Array.isArray(data)
//         ? data.find((item) => item.month === selectedSort)?.weeks.find(w => w.week === selectedWeek)?.days || []
//         : data.map((course) => ({
//             ...course,
//             data: course.data.find((item) => item.month === selectedSort)?.weeks.find(w => w.week === selectedWeek)?.days || []
//           }));
//     }
//     return Array.isArray(data)
//       ? data.find((item) => item.month === selectedSort)?.weeks || []
//       : data.map((course) => ({
//           ...course,
//           data: course.data.find((item) => item.month === selectedSort)?.weeks || []
//         }));
//   };

//   const data = prepareDataForChart(getData(selectedCourse), sortBy);
//   const courseMonths = getCourseMonths(selectedCourse);

//   return (
//     <Box sx={{ p: 2, width: '96%', mx: 'auto', boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)', borderRadius: '15px' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant="h6" fontWeight="600" fontFamily="poppins" fontSize="20px">User Progress Analytics</Typography>

//         <Box display="flex" gap={2}>
//           <FormControl variant="outlined" size="small">
//             <InputLabel>All Courses</InputLabel>
//             <Select value={selectedCourse} onChange={(e) => {
//               setSelectedCourse(e.target.value);
//               setSortBy("Months");
//               setSelectedWeek(null);
//             }} label="All Courses">
//               <MenuItem value="All Courses">All Courses</MenuItem>
//               {Object.keys(courseData).map((course) => (
//                 <MenuItem key={course} value={course}>{course}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <FormControl variant="outlined" size="small">
//             <InputLabel>Sort By</InputLabel>
//             <Select value={sortBy} onChange={(e) => {
//               setSortBy(e.target.value);
//               setSelectedWeek(null);
//             }} label="Sort By">
//               <MenuItem value="Months">Months</MenuItem>
//               {courseMonths.map((month) => (
//                 <MenuItem key={month} value={month}>{month}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {sortBy !== "Months" && selectedCourse !== "All Courses" && (
//   <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
//     <InputLabel>Weeks</InputLabel>
//     <Select value={selectedWeek} onChange={(e) => setSelectedWeek(e.target.value)} label="Weeks">
//       {getWeeksForMonth(selectedCourse, sortBy).map((week) => (
//         <MenuItem key={week} value={week}>{week}</MenuItem>
//       ))}
//     </Select>
//   </FormControl>
// )}

//         </Box>
//       </Box>

//       <ResponsiveContainer width="100%" height={350}>
//   <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }} cursor="default">
//     <CartesianGrid stroke="none" />
//     <XAxis dataKey={selectedWeek ? "day" : (sortBy === "Months" ? "month" : "week")} type="category" allowDuplicatedCategory={false} />
//     <YAxis label={{ value: "Progress / Hours", angle: -90, position: 'insideLeft' }} />
//     <Tooltip content={<CustomTooltip selectedSort={selectedWeek ? "Days" : sortBy} courseName={selectedCourse} />} />
    
//     {/* If "All Courses" is selected, render a line for each course */}
//     {selectedCourse === "All Courses"
//       ? Object.keys(courseData).map((course) => (
//         <Line
//           key={course}
//           type="monotone"
//           dataKey={selectedWeek ? "hours" : "progress"}  // Display hours or progress depending on sortBy
//           name={course}
//           data={prepareDataForChart(getData(course), sortBy)}
//           stroke={courseColors[course]}  // Apply course-specific color
//           dot={{ r: 3 }}
//         />
//       ))
//       : (
//         // If a single course is selected, render a single line with that course's color
//         <Line
//           type="monotone"
//           dataKey={selectedWeek ? "hours" : "progress"}
//           stroke={courseColors[selectedCourse] || "#3f51b5"}  // Use the color from courseColors, fallback to a default color
//           dot={{ r: 3 }}
//         />
//       )
//     }
//   </LineChart>
// </ResponsiveContainer>

//     </Box>
//   );
// };

// export default AnalyticsGraph;


