import React, { useState } from 'react';
import { useNavigate , useLocation} from 'react-router-dom';
import '../assets/Css/OtpConfirm.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import OtpConfirmImg from '../assets/Images/OtpConfirmImg.png';
import { CircularProgress } from '@mui/material';
import { loginaxios } from '../axios';
import skill_synq_logo from '../assets/Images/Skillsynqlogo.png';
 
const Popup = ({ message, onClose }) => (
  <div className="popup-overlay">
    <div className="popup-content">
      <p>{message}</p>
      <button className='popup-okay-button'onClick={onClose}>OK</button>
    </div>
  </div>
);
 
const OtpConfirm = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpError, setOtpError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
 
  const navigate = useNavigate();
  const userID = localStorage.getItem('user_id_registration');
  const location = useLocation();
const flowType = location.state?.flowType || 'forgot-password'; // Default to 'forgot-password' if not passed

 
  const handleOtpRequest = async (actionType) => {
    setLoading(true);
    setOtpError(''); // Clear any previous errors
 
    const payload = {
      user_id: userID,
      otp: otp.join(''),
      action: actionType, // 'verify' or 'resend'
     
    };
 
    try {
      const response = await loginaxios.post('/verify-otp/', payload);
      console.log(`${actionType} OTP success:`, response.data);
     
      if (actionType === 'verify' && (response.status === 200 || response.status === 201)) {
        if (flowType === 'signup') {
          // Navigate to login after signup
          navigate('/');
        } else if (flowType === 'forgot-password') {
          // Navigate to set-new-password after forgot password
          navigate('/set-new-password');
        }

      } else if (actionType === 'resend') {
        // Reset OTP fields for the new code and show success message
        setOtp(new Array(6).fill(''));
        setIsPopupVisible(true);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.error(`${actionType} OTP error:`, err.response.data);
        setOtpError(err.response.data.message);
        if (actionType === 'verify') setOtp(new Array(6).fill('')); // Reset OTP for verification error
        const { data } = err.response;
        if (data.otp) {
          setOtpError(data.otp[0]);
        }
      } else {
        console.error(`${actionType} OTP error:`, err);
        setOtpError('Something went wrong. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };
 
  const handleNewPassword = (event) => {
    event.preventDefault();
    handleOtpRequest('verify'); // Call with 'verify' action type
  };
 
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
 
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };
 
  const handleKeyDown = (element, index) => {
    if (element.key === 'Backspace') {
      if (otp[index] === '') {
        if (element.target.previousSibling) {
          element.target.previousSibling.focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };
 
  const handleResend = () => {
    handleOtpRequest('resend'); // Call with 'resend' action type
  };
 
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };
 
  return (
    <div className="OtpConfirm-container">
      <div className="OtpConfirm-left">
        <img
          src={OtpConfirmImg}
          alt="OtpConfirmImg"
          className="OtpConfirm-left-img-setNewPassword"
        />
      </div>
 
      <div className="OtpConfirm-right">
        <div className="OtpConfirm-circles">
          <img
            src={Circle1}
            alt="Circle 1"
            className="OtpConfirm-circle OtpConfirm-circle1"
          />
          <img
            src={Circle2}
            alt="Circle 2"
            className="OtpConfirm-circle OtpConfirm-circle2"
          />
        </div>
        <div className="OtpConfirm-box">
          <h2>Enter verification code</h2>
          <p className="OtpConfirm-para-otp-2">
            We have sent 6 digits of OTP to your registered email address
          </p>
          <form onSubmit={handleNewPassword}>
            <div className="OtpConfirm-form-group-otpverify">
              {otp.map((data, index) => (
                <input
                  className="OtpConfirm-otp-field"
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={(e) => e.target.select()}
                  autoComplete="off"
                />
              ))}
            </div>
            {otpError && <p style={{ color: 'red',fontSize:'12px'  }}>{otpError}</p>}
            <p className="OtpConfirm-para-otp">
              If you haven't received a code,
              <button
                type="button"
                className="OtpConfirm-otp-button-resend"
                onClick={handleResend}
              >
                Resend
              </button>
            </p>
            <button className="OtpConfirm-button-email-forget" type="submit">
              Continue
              {loading && (
                <CircularProgress
                  size={8}
                  color="inherit"
                  style={{ marginLeft: '10px', marginTop: '3px' }}
                />
              )}
            </button>
          </form>
        </div>
        {/* <div className="powered-by_4">
          Powered by <nbsp></nbsp>
          <img
            src={skill_synq_logo}
            alt="Skillsynq Logo"
          />
        </div> */}
      </div>
 
      {isPopupVisible && (
        <Popup
          message="A new OTP has been sent to your email."
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};
 
export default OtpConfirm;
