import React from 'react';
import '../assets/Css/SuccessMessage.css';
import DW from '../assets/Images/Successfull_image.png';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import Success_icon from '../assets/Images/Success_tick.png';
import skill_synq_logo from '../assets/Images/Skillsynqlogo.png';
import { Navigate, useNavigate } from 'react-router-dom';
const ConfirmationPage = () => {
  const navigate = useNavigate();
  const handlesuccess = () => {
    navigate('/');
  };
  return (
    <div className="SuccessMessage-main-container">
    <div className="SuccessMessage-container">
      <img src={DW} alt="dw" className="SuccessMessage-image" />
      <div>
        <div className="SuccessMessage-circles">
          <img src={Circle1} alt="Circle 1" className="SuccessMessage-circle SuccessMessage-circle1" />
          <img src={Circle2} alt="Circle 2" className="SuccessMessage-circle SuccessMessage-circle2" />
        </div>
      </div>
      <div className='successmessage-form'>
      <div className="SuccessMessage-message">
        <h2>Updated Successfully</h2>
          <div className="SuccessMessage-image-text-container">
            <img src={Success_icon} alt="Success" className="SuccessMessage-success-image" />
            <p>Success! <br></br>Your password has been changed.</p>
          </div>
        <button type="button" className='SuccessMessage-button' onClick={handlesuccess}>Sign in</button>
      </div>
      <div>
      {/* <div className="powered-by">
                    Powered by <nbsp></nbsp>
                    <img 
                        src={skill_synq_logo}
                        alt="Skillsynq Logo"  
                    />
                </div> */}
      </div>
      </div>
    </div>
    </div>
  );
};
 
export default ConfirmationPage;

