import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const isAuthenticated = localStorage.getItem('tokens');

    useEffect(() => {
      // Redirect to login and clear invalid tokens
      if (!isAuthenticated || isAuthenticated === 'null' || isAuthenticated === 'undefined') {
        localStorage.removeItem('tokens'); // Clear invalid tokens
        navigate('/'); // Redirect to login page
      }
    }, [isAuthenticated, navigate]);

    // If not authenticated, show redirect message
    if (!isAuthenticated || isAuthenticated === 'null' || isAuthenticated === 'undefined') {
      return <div>Redirecting to login...</div>;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
