import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { adminaxios } from '../axios';
import { useParams, useNavigate } from 'react-router-dom';
import { setTopicId } from '../actions/apisIdsActions';
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css'; // Choose any theme of your choice
import 'prismjs/plugins/autoloader/prism-autoloader'; // Autoloader for dynamic languages
import '../assets/Css/notes.css';
import filledbubble from '../assets/Images/Ellipse 648.png';
import unfilledbubble from '../assets/Images/Ellipse 651.png';
import copyicon from '../assets/Images/document-copy.png';
import { Search } from 'react-feather';
import Sidebar2 from '../Sidebar/Sidebar2';
import Animations from '../CourseList/RolebasedDetails/RolebasedModuleSkeleton'; // Import the Animations component

// Configure the autoloader to load languages dynamically
Prism.plugins.autoloader.languages_path = 'https://cdnjs.cloudflare.com/ajax/libs/prism/1.28.0/components/';

function Notes() {
  const [activeTab, setActiveTab] = useState(null);
  const [notesTitle, setNotesTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [headings, setHeadings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [scrollProgress, setScrollProgress] = useState(0);
  const [timeSpent, setTimeSpent] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [lastProgressSent, setLastProgressSent] = useState(0); // To track when the last API call was made
  const [hasReached100, setHasReached100] = useState(false); // Track if 100% has been reached
  const notesContainerRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  const scrollStartTime = useRef(null);
  const navigate = useNavigate();


  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const selectedTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
  const dispatch = useDispatch();
  const { id: courseId } = useParams();

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      element.style.paddingTop = '20px';
      element.style.transition = 'padding 0.3s ease-in-out';
      setActiveTab(id);
    } else {
      console.error(`No element found with ID ${id}`);
    }
  };

  const filteredHeadings = headings.filter(heading =>
    heading.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (notes) {
      const preElements = document.querySelectorAll('.notes pre');
      preElements.forEach((pre) => {
        const codeBlock = pre.querySelector('code');
        // Add default language class if none exists
        if (!codeBlock.classList.contains('language-')) {
          codeBlock.classList.add('language-javascript'); // Default to JavaScript
        }

        if (!pre.parentNode.classList.contains('pre-wrapper')) {
          const wrapper = document.createElement('div');
          wrapper.classList.add('pre-wrapper');
          pre.parentNode.insertBefore(wrapper, pre);
          wrapper.appendChild(pre);

          // Create and insert the "Copy Code" button
          const copyButton = document.createElement('button');
          copyButton.classList.add('copy-code-btn');
          copyButton.innerHTML = `<img src="${copyicon}" alt="Copy Icon" width="16" height="16" /> Copy Code`;

          copyButton.onclick = () => {
            const code = pre.innerText;
            navigator.clipboard.writeText(code)
              .then(() => {
                copyButton.innerText = 'Copied!';
                setTimeout(() => {
                  copyButton.innerHTML = `<img src="${copyicon}" alt="Copy Icon" width="16" height="16" /> Copy Code`;
                }, 3000); // 3 seconds delay
              })
              .catch(err => console.error('Failed to copy code: ', err));
          };

          wrapper.appendChild(copyButton);
        }
      });

      // Apply Prism.js syntax highlighting
      Prism.highlightAll();
    }
  }, [notes]); // Re-run when 'notes' content changes

  useEffect(() => {
    setLoading(true);
    adminaxios
      .get(`/modules/${selectedModuleId}/`)
      .then((response) => {
        const moduleDetails = response.data?.data?.module_details;
        setLoading(false);
        if (moduleDetails && Array.isArray(moduleDetails) && moduleDetails.length > 0) {
          const topics = moduleDetails[0].topics;
          if (Array.isArray(topics) && topics.length > 0) {
            const initialTopicId = topics[0]._id;
            dispatch(setTopicId(initialTopicId));
          } else {
            console.error('No topics found in module details.');
            setLoading(false);
          }
        } else {
          console.error('No module details found or module details is empty.');
          setLoading(false);
        }

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [selectedModuleId, dispatch]);

  useEffect(() => {
    if (storeNotesId !== null) {
      setLoading(true);
      adminaxios
        .get(`/contents/${storeNotesId}/`)
        .then((response) => {
          const data = response.data;
          setNotesTitle(data.versions[0]?.title || '');
          const contentHtml = data.versions[0]?.data || '';

          const parser = new DOMParser();
          const doc = parser.parseFromString(contentHtml, 'text/html');
          const headingsList = [];
          const contentWithoutFirstH1 = [];
          let firstH1Removed = false;

          doc.querySelectorAll('h1, h2, h3, h4').forEach((heading, index) => {
            const id = `heading-${index}`;
            heading.setAttribute('id', id);

            if (heading.tagName === 'H1' && !firstH1Removed) {
              firstH1Removed = true;
            } else {
              headingsList.push({
                id,
                text: heading.innerText.replace(/:/g, ''),
                tag: heading.tagName,
              });
              contentWithoutFirstH1.push(heading.outerHTML);
            }

            let nextElement = heading.nextElementSibling;
            while (nextElement && !['H1', 'H2', 'H3'].includes(nextElement.tagName)) {
              contentWithoutFirstH1.push(nextElement.outerHTML);
              nextElement = nextElement.nextElementSibling;
            }
          });

          setHeadings(headingsList);
          setNotes(contentWithoutFirstH1.join(''));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error fetching the data:', error);
        });
    }
  }, [storeNotesId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (activeTab) {
        handleScrollTo(activeTab);
      }
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [notes, activeTab]);

  const handleNotesSkip = () => {
    // Handle skip logic here
  };

  // Calculate reading time and scroll progress
  useEffect(() => {
    const handleScroll = () => {
      if (!notesContainerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = notesContainerRef.current;
      const currentScroll = (scrollTop / (scrollHeight - clientHeight)) * 100;
      setScrollProgress(currentScroll);
    };

    const notesContainer = notesContainerRef.current;
    if (notesContainer) {
      notesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (notesContainer) {
        notesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const readingSpeed = 200;
    const words = notes.split(/\s+/).length;
    const estimatedReadingTime = Math.ceil(words / readingSpeed);
    setTotalTime(estimatedReadingTime * 60);

    const interval = setInterval(() => {
      setTimeSpent((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [notes]);

  // useEffect(() => {
  //   const postProgress = async (progress) => {
  //     const data = { progress };
  //     try {
  //         await adminaxios.post(`/update_user_progress/${courseId}/${selectedModuleId}/${selectedTopicId}/${storeNotesId}/`, {progress}, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //       console.log(`Notes progress updated: ${progress}%`);
  //     } catch (error) {
  //       console.error('Error posting notes progress:', error);
  //     }
  //   };

  //   const scrollWeight = 0.7;
  //   const timeWeight = 0.3;

  //   const timeProgress = Math.min(timeSpent / totalTime, 1) * 100;
  //   const overallProgress = scrollWeight * scrollProgress + timeWeight * timeProgress;

  //   if (overallProgress >= 100) {
  //     postProgress(100);
  //   } else {
  //     postProgress(overallProgress);
  //   }
  // }, [scrollProgress, timeSpent, totalTime]);


  useEffect(() => {
    const handlePageUnload = () => {
      const overallProgress = calculateProgress();
      if (overallProgress > lastProgressSent.current + 20 || overallProgress >= 100) {
        postProgress.current(overallProgress);
        setLastProgressSent(overallProgress); // Save the last sent progress
      }
    };

    window.addEventListener('beforeunload', handlePageUnload);
    return () => {
      window.removeEventListener('beforeunload', handlePageUnload);
    };
  }, [scrollProgress, timeSpent, totalTime]);

  const postProgress = async (progress) => {
    try {
      await adminaxios.post(`/update_user_progress/${courseId}/${selectedModuleId}/${selectedTopicId}/${storeNotesId}/`, { progress }, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log(`Progress updated: ${progress}%`);
    } catch (error) {
      console.error('Error posting notes progress:', error);
    }
  };

  const calculateProgress = () => {
    const scrollWeight = 0.7;
    const timeWeight = 0.3;
    const timeProgress = Math.min(timeSpent / totalTime, 1) * 100;
    const overallProgress = scrollWeight * scrollProgress + timeWeight * timeProgress;
    return Math.round(overallProgress);
  };

  // Only send progress if there's a significant change (20%) or if the user scrolls a lot
  useEffect(() => {
    if (hasReached100) return; // Stop API calls if 100% has already been reached

    const overallProgress = calculateProgress();
    if (overallProgress >= 100) {
      postProgress(100);
      setHasReached100(true); // Stop further updates
    } else if (overallProgress > lastProgressSent + 20) {
      postProgress(overallProgress);
      setLastProgressSent(overallProgress); // Update the last sent progress
    }
  }, [scrollProgress, timeSpent]);

   // Stop API calls on page navigation
   useEffect(() => {
    return () => {
      if (!hasReached100) {
        const finalProgress = calculateProgress();
        postProgress(finalProgress);
      }
    };
  }, [navigate, hasReached100]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {/* <Sidebar2 courseId={courseId} /> */}

      <div className="notes-app">
        <div className="notes-content-wrapper">
          {/* Sidebar and Search Container */}
          <div className="notes-sidebar-container">
            <div className="notes-sidebar">
              <div className="notes-search-bar">
                <div className="search-container">
                  <input
                    type="text"
                    placeholder="Search topics..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search className="basic-header-search-icon" />
                </div>
              </div>
              
              <ul>
                {filteredHeadings.map((heading, index) => (
                  <li
                    key={heading.id}
                    className={`notes-topic-link ${activeTab === heading.id ? 'active' : ''} ${index === 0 ? 'first-heading' : ''}`}
                    onClick={() => handleScrollTo(heading.id)}
                  >
                    <img
                      src={filledbubble}
                      alt="Unfilled Bubble"
                      className="unfilledbubble"
                    />
                    <img
                      src={unfilledbubble}
                      alt="Filled Bubble"
                      className="filledbubble"
                    />
                    {heading.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Main Content */}
          <div className="notes-content" ref={notesContainerRef}>
            {loading ? (
              <Animations /> // Skeleton loader will display if data is loading
            ) : (
              <>
                <div className="notes-header">
                  <h1>{notesTitle || 'Loading...'}</h1>
                  <div className="notes-tags">
                    <span># lorem</span>
                    <span># ipsum</span>
                    <span># simply</span>
                    <span># dummy</span>
                  </div>
                </div>
                <div className="notes-title-btn-container">
                  <h2 className="notes-main-title"></h2>
                </div>
                <div
                  className="notes"
                  dangerouslySetInnerHTML={{ __html: notes }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;



