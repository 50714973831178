// import React, { useState, useEffect,  useRef} from 'react';
// import profileAvatar from '../../assets/Images/profileAvatar.png';
// import rankingpic from '../../assets/Images/ranking.png';
// import rankingstarpic from '../../assets/Images/ranking-star.png';
// import lines from '../../assets/Images/lines.png';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Button from '@mui/material/Button';
// import { useParams } from 'react-router-dom';
// import '../../assets/Css/CourseDetails.css';
// import { adminaxios } from '../../axios';
// import TestimonialSkeleton from './CourseDetailsTestimonialsSkeleton';
 
// const ReviewCard = ({ review }) => {
//   const { comment, rating, date, posted_by, user_role } = review;
//   const [showFullText, setShowFullText] = useState(true);
//     const [showSeeMoreButton, setShowSeeMoreButton] = useState(false); // To show button conditionally
//   const commentRef = useRef(null); // To get reference to the comment text div
//   // Function to dynamically generate star ratings
//   const renderStars = (rating) => {
//     const filledStars = Math.floor(rating);
//     const emptyStars = 5 - filledStars;
//     return (
//       <>
//         {Array(filledStars).fill().map((_, index) => (
//           <img key={index} src={rankingpic} alt="Star" className="course-testimonial-star-img" />
//         ))}
//         {Array(emptyStars).fill().map((_, index) => (
//           <img key={index} src={rankingstarpic} alt="Star" className="course-testimonial-star-img" />
//         ))}
//       </>
//     );
//   };
 
//     // useEffect to check if the comment exceeds two lines
//     useEffect(() => {
//       const commentElement = commentRef.current;
//       if (commentElement) {
//         // Assuming 'line-height' is 1.2em, and two lines = 2.4em
//         const maxTwoLineHeight = parseFloat(getComputedStyle(commentElement).lineHeight)*2;
//          // Only show "See More" if the content overflows, meaning not fully visible within 2 lines
//     if (commentElement.scrollHeight > commentElement.clientHeight && commentElement.clientHeight >= maxTwoLineHeight) {
//       setShowSeeMoreButton(true); // Show See More button if content exceeds two fully visible lines
//         }
//       }
//     }, []);
 
//   return (
//     <div className="course-testimonials-section">
//       <div className="course-testimonial-card">
//         <div className="course-testimonial-header">
//           <img src={profileAvatar} alt="Profile" className="course-testimonial-profile-img" />
//           <div className="course-testimonial-header-info">
//             <div className="course-testimonial-name">{posted_by || "Anonymous"}</div>
//             <div className="course-testimonial-designation">{user_role || "User"}</div>
//           </div>
//           <div className='course-testimonial-lines'>
//             <img src={lines} alt="Icon" />
//           </div>
//         </div>
//         <div
//           className={`course-testimonial-review-text ${showFullText ? 'expanded' : 'collapsed'}`}
//           ref={commentRef}
//         >
//           {comment}
//         </div>
//         <div className="course-testimonial-footer">
//           <div className="course-testimonial-stars">
//             {renderStars(rating)}
//           </div>
//           <div className="course-testimonial-rating">{rating.toFixed(1)}</div>
//           <div className="course-testimonial-review-date">
//             Reviewed on {new Date(date).toLocaleDateString()}
//           </div>
//         </div>
//         {showSeeMoreButton && (
//         <button className="course-testimonial-see-more-button" onClick={() => setShowFullText(!showFullText)}>
//           {showFullText ? 'Read more' : 'Read less'}
//         </button>
//           )}
//       </div>
//     </div>
//   );
// };
 
// const ReviewCardRow = () => {
//   const [showMore, setShowMore] = useState(false);
//   const [reviews, setReviews] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { id: courseId } = useParams();
 
 
//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await adminaxios.get(`/testimonials/${courseId}/`);
//         console.log('API Response:', response.data);
 
//         // Extracting the reviews data from the response
//         const reviewsData = Array.isArray(response.data) ? response.data : [];
//         setReviews(reviewsData); // Set the reviews state with the fetched testimonials
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching reviews:', error);
//         setLoading(false);
//       }
//     };
 
//     fetchReviews();
//   }, [courseId]);
 
 
//   return (
//     <div>
//       <h4 className="testimonials-heading">What Our Students Say</h4>
 
//       <div className="course-testimonial-row" style={{ display: 'flex', gap: '50px', flexWrap: 'nowrap' }}>
//         {/* Show skeleton while reviews are loading */}
//         {loading
//           ? // Display 3 skeletons when loading
//             Array(3)
//               .fill(0)
//               .map((_, index) => (
//                 <div key={index} className="testimonial-card" style={{ flexWrap:'1', minWidth: '100%' }}>
//                   <TestimonialSkeleton />
//                 </div>
//               ))
//           : // Display actual reviews when loaded
//             reviews.slice(0, 3).map((review, index) => (
//               <div key={index} className="testimonial-card" style={{ flexWrap:'1', minWidth: '300px' }}>
//                 <ReviewCard review={review} />
//               </div>
//             ))}
//       </div>
 
//       {showMore && (
//         <div className="course-testimonial-row" style={{ display: 'flex', gap: '50px', flexWrap: 'wrap' }}>
//           {reviews.slice(3).map((review, index) => (
//             <div key={index + 3} className="testimonial-card" style={{ flexWrap:'1', minWidth: '300px' }}>
//               <ReviewCard review={review} />
//             </div>
//           ))}
//         </div>
//       )}
 
//       {/* See More/Less Button */}
//       {reviews.length > 3 && (
//         <div className="course-details-see-more-container">
//           <Button onClick={() => setShowMore(!showMore)} className="course-details-see-more-button">
//             {showMore ? 'Less' : 'More'}
//             <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
//           </Button>
//         </div>
//       )}
//     </div>
//   );
// };
 
// export default ReviewCardRow;



// import React, { useState } from 'react';
// import { Grid, Typography, Card, Box, Button } from '@mui/material';
// import content_moderation_ProfilePic from '../../assets/Images/testimonialspicture.jpg';
// import Testimonial_quotes from '../../assets/Images/testimoinialcodes.jpg'
// import Testimonial_left from '../../assets/Images/testimonialsleftarrow.jpg'
// import Testimonial_right from '../../assets/Images/testimoinialsrightarrow.jpg'
// import '../../assets/Css/CourseDetails.css';

// const testimonials = [
//     {
//         text: "SkillSynQ has transformed our approach to online learning! The platform is incredibly user-friendly, allowing both instructors and students to navigate with ease. Since implementing SkillSynQ, we've seen a remarkable increase in engagement and course completion rates. The ability to track progress and gather feedback has elevated our educational experience. We highly recommend SkillSynQ for anyone looking to enhance their learning management capabilities!",
//         author: "Jayanth Mishra",
//         role: "UI/UX Designer",
//         profilePic: content_moderation_ProfilePic
//     },
//     {
//         text: "Our LMS has transformed our training programs, making them more interactive and effective.",
//         author: "Anjali Verma",
//         role: "Software Engineer",
//         profilePic: content_moderation_ProfilePic
//     },
//     {
//         text: "Since implementing the LMS, we've experienced a significant boost in learner engagement and completion rates!",
//         author: "Rahul Singh",
//         role: "Project Manager",
//         profilePic: content_moderation_ProfilePic
//     }
// ];

// const Testimonials = () => {
//     const [currentTestimonial, setCurrentTestimonial] = useState(0);

//     const handleNext = () => {
//         if (currentTestimonial < testimonials.length - 1) {
//             setCurrentTestimonial((prev) => prev + 1);
//         }
//     };

//     const handlePrevious = () => {
//         if (currentTestimonial > 0) {
//             setCurrentTestimonial((prev) => prev - 1);
//         }
//     };

//     return (
//         <Box sx={{ py: 4, px: 2 }}>
//             <Typography  variant='h3'sx={{fontSize:'18px',fontWeight:"800", marginTop:'4%'}}>Testimonials</Typography>
//             <Grid container spacing={4} maxWidth="lg" mx="auto">
//                 <Grid item xs={12} md={6} sx={{ marginTop: '2%',textAlign:'justify' }}>
//                     <Typography variant="h5" fontWeight="600" gutterBottom>
//                         Who we are<br />and what we do
//                     </Typography>
//                     <Typography variant="body1"sx={{fontSize:'14px', lineHeight:'1.8'}} paragraph>
//                         "Our LMS has transformed our training programs, making them more interactive and effective."
//                     </Typography>
//                     <Typography variant="body1"sx={{fontSize:'14px', lineHeight:'1.8'}}  paragraph>
//                         "The intuitive design of the LMS has made it easy for both students and instructors to navigate and utilize."
//                     </Typography>
//                     <Typography variant="body1"sx={{fontSize:'14px', lineHeight:'1.8'}}  paragraph>
//                         "Since implementing the LMS, we've experienced a significant boost in learner engagement and completion rates!"
//                     </Typography>
//                 </Grid>

//                 {/* Testimonial Card */}
//                 <Grid item xs={12} md={6}>
//                     <Card
//                         className='Testimonial_card'
//                         sx={{
//                             p: 3,
//                             borderRadius: 2,
//                             boxShadow: 3,
//                             height: '80%', // Fixed height for the card
//                             display: 'flex',
//                             flexDirection: 'column',
//                             justifyContent: 'space-between',
//                         }}
//                     >
//                         <Box
//                             alignItems="flex-start"
//                             sx={{
//                               paddingRight:'15px',
//                               fontSize:'12px',
//                                 textAlign: 'justify',
//                                 overflowY: 'auto',
//                                 scrollbarWidth:'thin',
//                                 flexGrow: 1,
//                                 marginBottom: '16px', // Space above footer
//                                height:'90%', // Set max height for content to enable scrolling within the fixed card height
//                             }}
//                         >
//                             <img
//                                 src={Testimonial_quotes}
//                                 alt="Quote Icon"
//                                 style={{ height: 40, width: 60, marginRight: 8 }}
//                             />
//                             <Typography
//                                 variant="body1"
//                                 color="textSecondary"
//                                 sx={{  display: 'inline' , fontSize:'14px', lineHeight:'1.8'}}
//                             >
//                                 {testimonials[currentTestimonial].text}
//                             </Typography>
//                         </Box>

//                         {/* Testimonial author details */}
//                         <footer>
//                             <Box display="flex" alignItems="center" mt={2}>
//                                 <img
//                                     src={testimonials[currentTestimonial].profilePic}
//                                     alt="Profile"
//                                     style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 8 }}
//                                 />
//                                 <Box>
//                                     <Typography variant="subtitle1" fontWeight="600">
//                                         {testimonials[currentTestimonial].author}
//                                     </Typography>
//                                     <Typography variant="body2" color="textSecondary">
//                                         {testimonials[currentTestimonial].role}
//                                     </Typography>
//                                 </Box>
//                             </Box>
//                         </footer>
//                     </Card>
//                   </Grid>

//             </Grid>

//             {/* Navigation Arrows */}
//             <Box display="flex" justifyContent="flex-end" mt={4} sx={{ marginRight: '11%' }}>
//                 <Button onClick={handlePrevious} disabled={currentTestimonial === 0}>
//                     <img
//                         src={Testimonial_left}
//                         alt="Previous Testimonial"
//                         style={{ height: 40, width: 40, borderRadius: '50%', opacity: currentTestimonial === 0 ? 0.5 : 1 }}
//                     />
//                 </Button>
//                 <Button onClick={handleNext} disabled={currentTestimonial === testimonials.length - 1}>
//                     <img
//                         src={Testimonial_right}
//                         alt="Next Testimonial"
//                         style={{ height: 40, width: 40, borderRadius: '50%', opacity: currentTestimonial === testimonials.length - 1 ? 0.5 : 1 }}
//                     />
//                 </Button>
//             </Box>
//         </Box>
//     );
// };

// export default Testimonials;


// import React, { useState, useEffect ,useRef} from 'react';
// import { Grid, Typography, Card, Box, Button } from '@mui/material';
// import axios from 'axios';
// import profileAvatar from '../../assets/Images/profileAvatar.png';
// import rankingpic from '../../assets/Images/ranking.png';
// import rankingstarpic from '../../assets/Images/ranking-star.png';
// import Testimonial_quotes from '../../assets/Images/testimoinialcodes.jpg';
// import Testimonial_left from '../../assets/Images/testimonialsleftarrow.jpg';
// import Testimonial_right from '../../assets/Images/testimoinialsrightarrow.jpg';
// import '../../assets/Css/CourseDetails.css';

// const Testimonials = ({review}) => {
//   const { comment, rating, date, posted_by, user_role } = review;
//     const [currentTestimonial, setCurrentTestimonial] = useState(0);
//     const [testimonials, setTestimonials] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const commentRef = useRef(null);

//     useEffect(() => {
//         const fetchTestimonials = async () => {
//             try {
//                 const response = await axios.get('/api/testimonials'); // Replace with your API endpoint
//                 setTestimonials(response.data); // Ensure the API returns an array of testimonials
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching testimonials:', error);
//                 setLoading(false);
//             }
//         };
//         fetchTestimonials();
//     }, []);
  

//     const handleNext = () => {
//         if (currentTestimonial < testimonials.length - 1) {
//             setCurrentTestimonial((prev) => prev + 1);
//         }
//     };

//     const handlePrevious = () => {
//         if (currentTestimonial > 0) {
//             setCurrentTestimonial((prev) => prev - 1);
//         }
//     };

//     const renderStars = (rating) => {
//         const filledStars = Math.floor(rating);
//         const emptyStars = 5 - filledStars;
//         return (
//             <>
//                 {Array(filledStars)
//                     .fill()
//                     .map((_, index) => (
//                         <img key={index} src={rankingpic} alt="Star" style={{ height: '20px', marginRight: '2px' }} />
//                     ))}
//                 {Array(emptyStars)
//                     .fill()
//                     .map((_, index) => (
//                         <img key={index} src={rankingstarpic} alt="Star" style={{ height: '20px', marginRight: '2px' }} />
//                     ))}
//             </>
//         );
//     };

//     if (loading) {
//         return <Typography>Loading testimonials...</Typography>;
//     }

//     return (
//         <Box sx={{ py: 4, px: 2 }}>
//             <Typography variant="h3" sx={{ fontSize: '18px', fontWeight: "800", marginBottom: '16px' }}>
//                 Testimonials
//             </Typography>
//             <Grid container spacing={4} maxWidth="lg" mx="auto">
//                 {/* Static "Who We Are" Section */}
//                 <Grid item xs={12} md={6} sx={{ textAlign: 'justify' }}>
//                     <Typography variant="h4" fontWeight="600" gutterBottom>
//                         Who we are<br />and what we do
//                     </Typography>
//                     <Typography variant="body1" paragraph>
//                         "Our LMS has transformed our training programs, making them more interactive and effective."
//                     </Typography>
//                     <Typography variant="body1" paragraph>
//                         "The intuitive design of the LMS has made it easy for both students and instructors to navigate and utilize."
//                     </Typography>
//                     <Typography variant="body1" paragraph>
//                         "Since implementing the LMS, we've experienced a significant boost in learner engagement and completion rates!"
//                     </Typography>
//                 </Grid>

//                 {/* Dynamic Testimonial Section */}
//                 <Grid item xs={12} md={6}>
//                     <Card
//                         className='Testimonial_card'
//                         sx={{
//                             p: 3,
//                             borderRadius: 2,
//                             boxShadow: 3,
//                             height: '90%',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             justifyContent: 'space-between',
//                         }}
//                     >
//                         <Box
//                             alignItems="flex-start"
//                             sx={{
//                                 paddingRight: '15px',
//                                 textAlign: 'justify',
//                                 overflowY: 'auto',
//                                 scrollbarWidth: 'thin',
//                                 flexGrow: 1,
//                                 marginBottom: '16px',
//                                 maxHeight: '290px',
//                             }}
//                         >
//                             <img
//                                 src={Testimonial_quotes}
//                                 alt="Quote Icon"
//                                 style={{ height: 40, width: 60, marginRight: 8 }}
//                             />
//                             <Typography
//                                 variant="body1"
//                                 color="textSecondary"
//                                 sx={{ wordWrap: 'break-word', display: 'inline' }}
//                                 ref={commentRef}
//                             >
//                                 {comment}
//                             </Typography>
//                         </Box>

//                         {/* Author Details with Ratings */}
//                         <footer>
//                             <Box display="flex" alignItems="center" mt={2}>
//                                 <img
//                                     src={profileAvatar}
//                                     alt="Profile"
//                                     style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 8 }}
//                                 />
//                                 <Box>
//                                     <Typography variant="subtitle1" fontWeight="600">
//                                         {testimonials[currentTestimonial].posted_by || "Anonymous"}
//                                     </Typography>
//                                     <Typography variant="body2" color="textSecondary">
//                                         {testimonials[currentTestimonial].user_role || "User"}
//                                     </Typography>
//                                 </Box>
//                             </Box>
//                             <Box mt={1}>{renderStars(testimonials[currentTestimonial].rating)}</Box>
//                             <Typography variant="body2" color="textSecondary" mt={1}>
//                                 Reviewed on {new Date(testimonials[currentTestimonial].date).toLocaleDateString()}
//                             </Typography>
//                         </footer>
//                     </Card>
//                 </Grid>
//             </Grid>

//             {/* Navigation Arrows */}
//             <Box display="flex" justifyContent="flex-end" mt={4} sx={{ marginRight: '11%' }}>
//                 <Button onClick={handlePrevious} disabled={currentTestimonial === 0}>
//                     <img
//                         src={Testimonial_left}
//                         alt="Previous Testimonial"
//                         style={{ height: 40, width: 40, borderRadius: '50%', opacity: currentTestimonial === 0 ? 0.5 : 1 }}
//                     />
//                 </Button>
//                 <Button onClick={handleNext} disabled={currentTestimonial === testimonials.length - 1}>
//                     <img
//                         src={Testimonial_right}
//                         alt="Next Testimonial"
//                         style={{ height: 40, width: 40, borderRadius: '50%', opacity: currentTestimonial === testimonials.length - 1 ? 0.5 : 1 }}
//                     />
//                 </Button>
//             </Box>
//         </Box>
//     );
// };

// export default Testimonials;



import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, Box, Button } from '@mui/material';
import { adminaxios } from '../../axios';
// import profileAvatar from '../../assets/Images/profileAvatar.png';
import content_moderation_ProfilePic from '../../assets/Images/testimonialspicture.jpg';
import rankingpic from '../../assets/Images/ranking.png';
import rankingstarpic from '../../assets/Images/ranking-star.png';
import Testimonial_quotes from '../../assets/Images/image 39.png';
import Testimonial_left from '../../assets/Images/testimonialsleftarrow.jpg';
import Testimonial_right from '../../assets/Images/testimoinialsrightarrow.jpg';
import { useParams } from 'react-router-dom';
import '../../assets/Css/CourseDetails.css';


const Testimonials = () => {
  const { id: courseId } = useParams();
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const fetchTestimonials = async () => {
    //         try {
    //             const response = await axios.get('/api/testimonials'); // Replace with your API endpoint
    //             setTestimonials(response.data); // Ensure the API returns an array of testimonials
    //             setLoading(false);
    //         } catch (error) {
    //             console.error('Error fetching testimonials:', error);
    //             setLoading(false);
    //         }
    //     };
    //     fetchTestimonials();
    // }, []);

    useEffect(() => {
      const fetchTestimonials = async () => {
          try {
            const response = await adminaxios.get(`/testimonials/${courseId}/`);
              console.log('API Response:', response.data); // Log data to debug
              setTestimonials(Array.isArray(response.data) ? response.data : [response.data]);
              setLoading(false);
          } catch (error) {
              console.error('Error fetching testimonials:', error);
              setLoading(false);
          }
      };
      fetchTestimonials();
  }, []);
    const handleNext = () => {
        if (currentTestimonial < testimonials.length - 1) {
            setCurrentTestimonial((prev) => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentTestimonial > 0) {
            setCurrentTestimonial((prev) => prev - 1);
        }
    };

    const renderStars = (rating) => {
        const filledStars = Math.floor(rating);
        const emptyStars = 5 - filledStars;
        return (
            <>
                {Array(filledStars)
                    .fill()
                    .map((_, index) => (
                        <img key={index} src={rankingpic} alt="Star" style={{ height: '20px', marginRight: '2px' }} />
                    ))}
                {Array(emptyStars)
                    .fill()
                    .map((_, index) => (
                        <img key={index} src={rankingstarpic} alt="Star" style={{ height: '20px', marginRight: '2px' }} />
                    ))}
            </>
        );
    };

    if (loading) {
        return <Typography>Loading testimonials...</Typography>;
    }

    const currentReview = testimonials[currentTestimonial];

    return (
        <Box sx={{ py: 4, px: 2 }}>
            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: "800", marginBottom: '2%' , marginTop:'3%'}}>
                Testimonials
            </Typography>
            <Grid container spacing={4} maxWidth="lg" mx="auto">
                {/* Static "Who We Are" Section */}
                <Grid item xs={12} md={6} sx={{ textAlign: 'justify' }}>
                    <Typography variant="h5" sx={{ fontSize: '18px'}}fontWeight="600" gutterBottom>
                        Who we are<br />and what we do
                    </Typography>
                    <Typography variant="body1"sx={{fontSize:'13px', lineHeight:'1.8',color: '#666666',fontFamily:'Poppins ,sans-serif'}} paragraph>
                        "Our LMS has transformed our training programs, making them more interactive and effective."
                    </Typography>
                    <Typography variant="body1" sx={{fontSize:'13px', lineHeight:'1.8',color: '#666666',fontFamily:'Poppins ,sans-serif'}} paragraph>
                        "The intuitive design of the LMS has made it easy for both students and instructors to navigate and utilize."
                    </Typography>
                    <Typography variant="body1" sx={{fontSize:'13px', lineHeight:'1.8',color: '#666666',fontFamily:'Poppins ,sans-serif'}} paragraph>
                        "Since implementing the LMS, we've experienced a significant boost in learner engagement and completion rates!"
                    </Typography>
                </Grid>

                {/* Dynamic Testimonial Section */}
                <Grid item xs={12} md={6}>
                    {currentReview && (
                        <Card
                            className="Testimonial_card"
                            sx={{
                                p: 3,
                                borderRadius: 2,
                                boxShadow: 3,
                                height: '90%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                marginBottom:'10%',
                                marginLeft: '10%',
                      
                            }}
                        >
                            <Box
                                alignItems="flex-start"
                                sx={{
                                    paddingRight: '15px',
                                    textAlign: 'justify',
                                    overflowY: 'auto',
                                    scrollbarWidth: 'thin',
                                    flexGrow: 1,
                                    marginBottom: '16px',
                                    maxHeight: '290px',
                                }}
                            >
                                <img
                                    src={Testimonial_quotes}
                                    alt="Quote Icon"
                                    style={{ height: 30, width: 50, marginRight: '10%', marginBottom:'4%'  }}
                                />
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ wordWrap: 'break-word', display: 'inline',fontSize:'13px', lineHeight:'1.8',fontFamily:'Poppins ,sans-serif' }}
                                    
                                >
                                    {currentReview.comment}
                                </Typography>
                            </Box>

                            {/* Author Details with Ratings */}
                            <footer>
                            
                                <Box display="flex" alignItems="center" >
                                    <img
                                        src={content_moderation_ProfilePic}
                                        alt="Profile"
                                        style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 8 }}
                                    />
                                    
                                    <Box>
                                        <Typography variant="subtitle1" fontWeight="600">
                                            {currentReview.posted_by || "Anonymous"}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" fontSize={'12px'} fontFamily='Poppins ,sans-serif'>
                                            {currentReview.user_role || "User"}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box mt={1}>{renderStars(currentReview.rating)}</Box>
                                <Typography variant="body2" color="textSecondary" mt={1} fontSize={'13px'} fontFamily='Poppins ,sans-serif'>
                                    Reviewed on {new Date(currentReview.date).toLocaleDateString()}
                                </Typography>
                            </footer>
                        </Card>
                    )}
                </Grid>
            </Grid>

            {/* Navigation Arrows */}
            <Box display="flex" justifyContent="flex-end" mt={4} sx={{ marginRight: '11%' }}>
                <Button onClick={handlePrevious} disabled={currentTestimonial === 0}>
                    <img
                        src={Testimonial_left}
                        alt="Previous Testimonial"
                        style={{ height: 40, width: 40, borderRadius: '50%', opacity: currentTestimonial === 0 ? 0.5 : 1 }}
                    />
                </Button>
                <Button onClick={handleNext} disabled={currentTestimonial === testimonials.length - 1}>
                    <img
                        src={Testimonial_right}
                        alt="Next Testimonial"
                        style={{ height: 40, width: 40, borderRadius: '50%', opacity: currentTestimonial === testimonials.length - 1 ? 0.5 : 1 }}
                    />
                </Button>
            </Box>
        </Box>
    );
};

export default Testimonials;
