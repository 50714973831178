import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Typography, Box, Chip, Stack, Avatar } from '@mui/material'; import "../assets/Css/CourseList.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Oval } from 'react-loader-spinner';
import gridIcon from '../assets/Images/dashboard.png';
import rowIcon from '../assets/Images/list.svg';
import filter from '../assets/Images/filter-search-one.png';
import order from '../assets/Images/courseListOrderOne.png';
import course_main_img from '../assets/Images/lms_coursecard_main.png'
import course_company_img from '../assets/Images/lms_coursecard_company.png'
import course_type_img from '../assets/Images/lms_coursecard_type.png'
import { adminaxios } from "../axios";
import Tooltip from '@mui/material/Tooltip';
import CourseSkeletonLoader from "../CourseList/CourseSkeletonLoader";
import { setCourseTitle, setCourseId } from "../actions/apisIdsActions";
import LocalStorageCourseDetails from "./LocalStorageCourseDetails";
import Pagination from '@mui/material/Pagination';
import { toggleFooterRedux } from "../actions/footerActions";
import Footer from '../Footer/footer';
import { courseType } from "../actions/typeOfCourseActions";
import CertificateImg from '../assets/Images/CertificateIcon.png';
import RowDurationIcon from'../assets/Images/RowDurationIcon.png';
import RowLearnersIcon from '../assets/Images/RowLearnersIcon.png';
import dataworks_icon from '../assets/Images/Dataworks_logo_2.png';
import AnimationRowSkeleton from './AnimationRowSkeleton'
import certificate_icon from "../assets/Images/CertificateIcon.png";
import medalicon from '../assets/Images/solar_medal-ribbons-star-linear.png';


// Search query selector
const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState("grid");
  const [activeTab, setActiveTab] = useState("skillbased");
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [progressData, setProgressData] = useState({});
  const [roleBasedProgress, setRoleBasedProgress] = useState({});
  const dynamicCourseId = localStorage.getItem('localStorageCourseId');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicSidebarToggle = useSelector(state => state.basicSidebar.basicSideToggle);
  const courseTypeToggle = useSelector((state)=>state.CourseType.courseType);

  const coursesPerPage = 2;
  
  // Get search query from Redux store
  const searchQuery = useSelector((state) => state.HeaderSearchbar.query);
  
   // Fetch courses and progress data for all courses
   useEffect(() => {
    const fetchCoursesAndProgress = async () => {
      setLoading(true);
      try {
        const endpoint = activeTab === "skillbased" ? '/skill_based_courses/' : '/role_based_courses/';
        const response = await adminaxios.get(endpoint);
        
        // Extract course list
        const courseList = response.data.map(course => ({
          _id: course._id,
          course_title: course.course_title,
          difficulty_level: course.difficulty_level,
          module_ids:course.module_ids,
        }));
        setCourses(courseList);

        // Fetch progress for each course
        const progressPromises = courseList.map(async (course) => {
          try {
            const progressEndpoint = activeTab === 'skillbased' 
              ? `/skill_based_progress/${course._id}/` 
              : `/role_based_progress/${course._id}/`;

            const progressResponse = await adminaxios.get(progressEndpoint);
            return {
              courseId: course._id,
              progress: activeTab === 'skillbased' 
                ? progressResponse.data.skill_progress_percentage || 0
                : progressResponse.data.role_progress_percentage || 0
            };
          } catch (error) {
            console.error(`Error fetching progress for course ${course._id}:`, error);
            return { courseId: course._id, progress: 0 };
          }
        });

        const progressDataArray = await Promise.all(progressPromises);
        
        // Store progress data
        const newProgressData = {};
        progressDataArray.forEach(item => {
          newProgressData[item.courseId] = item.progress;
        });
        setProgressData(newProgressData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching courses and progress:', error);
        setLoading(false);
      }
    };

    fetchCoursesAndProgress();
  }, [activeTab]);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleToCourseRender =async (courseId, courseTitle) => {
    localStorage.setItem('localStorageCourseId', courseId);
    localStorage.setItem('localStorageCourseTitle', courseTitle);
    dispatch(setCourseId(courseId));
    setCurrentCourseId(courseId);
    dispatch(setCourseTitle(courseTitle));
    console.log('CourseId', courseId);
    
// Get the token or user_id from localStorage or other source
    const token = localStorage.getItem('token'); // Replace 'token' with the key where user_id/token is stored


// Start learner progress by posting to the API with the token in headers
try {
  const progressEndpoint = `/user_progress/${courseId}/`; // Replace with your actual endpoint
  console.log('courseIdsss',courseId)

  const postResponse = await adminaxios.post(progressEndpoint, {}, {
    headers: {
      Authorization: `Bearer ${token}`  // Sending token/user_id in the request headers
    }
  });

  console.log('Progress started:', postResponse.data);

} catch (error) {
  console.error('Error starting learner progress:', error);
}

// Navigate to the appropriate course details page after 500ms
setTimeout(() => {
  navigate(activeTab === 'skillbased' ? `/course-details/${courseId}` : `/rolebased-details/${dynamicCourseId}`);
},0);
};
  


  const CourseCard = ({ course }) => {
    const progress = progressData[course._id] || 0;

    return (

          <div className="lms-courseList-card" onClick={() => handleToCourseRender(course._id, course.course_title)}>
            <div className="lms-courseList-image">
                <img src={course_main_img} alt="Course Preview" />
            </div>           
            <div><img src={dataworks_icon} alt="dataworks-icon" className="Dataworks_icon_2"/></div>
            <div className="lms-courseList-content">
                <h2>{course.course_title}</h2>
                <p className="lms-courseList-description">
                    Turn Learning into Adventure – Unlock Skills, Earn Points, and Rise to the Top!
                </p>
                {/* <p className="lms-courseList-certification">Get certification course 
                  <span role="img" aria-label="key">🔑</span></p> */}
                  <div class="lms-course-certification-container">
                      <p class="lms-course-certification-text">Get your certification course</p>
                      <img src={medalicon} alt="certificate_icon" class="lms-course-certification-icon"/>
                  </div>
                <div className="lms-courseList-details">
                    {/* <div className="lms-courseList-detail"><span>{course.module_ids && course.module_ids.length > 0 ? ` ${course.module_ids.length} Modules `: `${0} Modules`}</span></div> */}
                    {/* <div className="lms-courseList-detail">
                      <span>{course.difficulty_level ? `${course.difficulty_level}` : "Difficulty: N/A"}</span>
                    </div> */}
                    <div className="lms-courseList-detail">
                      <span>
                        {course.difficulty_level
                          ? `${course.difficulty_level.charAt(0).toUpperCase()}${course.difficulty_level.slice(1)}`
                          : "Difficulty: N/A"}
                      </span>
                    </div>
                    <div className="lms-courseList-detail"><span>2 Months</span></div>
                    <div className="lms-courseList-detail"><span>389 Learners</span></div>
                </div>
            </div>
        </div>
    );
  };

  const CourseRow = ({ course }) => {
    const progress = progressData[course._id] || 0;
    
    return (
              <Card sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' }, // Stack in column for small screens, row for medium and above
                  borderRadius: 4,
                  boxShadow: 'none',
                  cursor: 'pointer',
                  border: '1px solid #e0e0e0',
                  padding: 2,
                  alignItems: 'center',
                  maxWidth: '92%',
                  marginLeft: '15px',
                  marginBottom: '16px', // Add bottom margin for spacing between cards
                  transition: 'transform 0.3s ease', // Smooth transition for zoom effect
                  '&:hover': {
                    transform: 'scale(1.03)'
                   } // Zoom effect
              }}  onClick={() => handleToCourseRender(course._id, course.course_title)}>
                  {/* Course Image Wrapper */}
                  <Box sx={{
                      position: 'relative',
                      width: { xs: '100%', md: 342 }, // Full width on small screens, fixed size on medium and above
                      height: { xs: 200, md: 150},
                      borderRadius: 2,
                      marginBottom: { xs: 2, md: 0 } // Add bottom margin for small screens when in column layout
                  }}>
                      {/* New Image Positioned at Top-Left */}
                      {/* <img
                          src={course_type_img}
                          alt="New Icon"
                          style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: 46,
                              height: 46,
                              borderRadius: '0%',
                              background: 'white',
                              padding: '4px',
                          }}
                      /> */}
      
                      {/* Main Course Image */}
                 <CardMedia
                    component="img"
                    sx={{
                        width: '100%',
                        height: '150px',
                        borderRadius: 4,
                        // marginTop: '-5px'
                    }}
                     image={course_main_img}
                     alt="Course Image"
                   />
                  </Box>
      
                  {/* Course Content */}
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: { xs: 0, md: 2 }, // Adjust left margin for small screens
                      flex: 1
                  }}>
                      <CardContent sx={{ padding: '0px' }}>
      
                          {/* Heading and Company Logo */}
                          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'space-between', mb: 1 ,paddingBottom:'0px'}}>
                              <Typography variant="h6" sx={{ fontWeight: '600', color: "#2A285A", fontSize: '27px', fontFamily: 'poppins', lineHeight: 1.4 }}>
                                 {`${course.course_title}`}
                              </Typography>
      
                              {/* Company Logo and Content */}
                              <div ><img src={dataworks_icon} alt="dataworks-icon" className="rowview-Dataworks_icon_2"/></div>
                          </Box>
      
                          {/* Description with Border */}
                          <Box sx={{
                              border: '2px solid #7B7A93',
                              borderRadius: 3,
                              display: 'inline-block',
                              padding: '4px 8px',
                              mb: '5px',
                              backgroundColor: '#F3F3FF'
                          }}>
                              <Typography variant="" sx={{ fontFamily: 'poppins', fontWeight: '400', fontSize: '12px', color: "#2A285A", lineHeight: '14.4px' }}>
                                  Turn Learning into Adventure – Unlock Skills, Earn Points, and Rise to the Top!
                              </Typography>
                          </Box>
      
                          {/* Certification and Details */}
                          <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: '400', fontSize: '14px', color: "#E4A700", display: 'flex', lineHeight: '25.2px', alignItems: 'center', mb: '5px' }}>
                              Get certification course
                              <img src={medalicon} alt="Certificate Icon" style={{ marginLeft: '8px', width: '17px', height: '17px' }} />
                          </Typography>
      
                          {/* Module, Duration, Learners Chips */}
                          <Stack direction="row" spacing={1} sx={{ mt: 0 }}>
                              <Chip sx={{ height:'28px',  borderRadius: '10px' }} label={`${course.module_ids?.length} Modules`} variant="outlined" />
                              <Chip sx={{ height:'28px', alignItems: 'center', borderRadius: '10px' }} label="2 Months" variant="outlined" />
                              <Chip sx={{ height:'28px', alignItems: 'center', borderRadius: '10px' }} label="389 Learners" variant="outlined" />
                          </Stack>
                      </CardContent>
                  </Box>
              </Card>
  
    );
  };

  const toggleView = (newView) => {
    setView(newView);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setLoading(true);  // Set loading to true when switching tabs

      // Check the tab and dispatch the action accordingly
      if (tab === 'skillbased') {
        dispatch(courseType(false)); // If the tab is 'skillbased', set courseType to false
      } else if (tab === 'rolebased') {
        dispatch(courseType(true));  // If the tab is 'rolebased', set courseType to true
      }

      console.log(tab, 'tab');
      localStorage.setItem('whichTab',tab)
    }
  };

 
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  
  // Filter the courses based on the search query before applying pagination
  const filteredCourses = courses.filter((course) =>
    course.course_title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  
  // Apply pagination after filtering the courses
  const currentCourses = filteredCourses.slice(indexOfFirstCourse, indexOfLastCourse);

  return (
    <>
      {currentCourseId && (
        <LocalStorageCourseDetails
          courseId={currentCourseId}
          onCompleted={() => setCurrentCourseId(null)}
        />
      )}
      <div style={{ display: "flex", justifyContent: "flex-start", width: "100%", height: '90vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'thin' }}>
        {/* <Sidebar /> */}
        <div id="course-List-main-container" style={{ width: "100%", margin: "10px 0px 0px 0px", display: "flex", flexDirection: "column", padding: '0px 20px' }}>
          <div id="course-list-container-id">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px", marginTop: "10px", marginLeft: '30px' }}>
              <div className="toggle-btn-course-list-container">
                <button className={`toggle-btn-course-list-component ${activeTab === "skillbased" ? "active" : ""}`} onClick={() => toggle("skillbased")}>Skill based</button>
                <button className={`toggle-btn-course-list-component ${activeTab === "rolebased" ? "active" : ""}`} onClick={() => toggle("rolebased")}>Role based</button>
              </div>
              <div style={{ display: "flex", marginRight: "7px", alignItems: "center" }}>
                <div style={{ display: "flex", marginLeft: "26px", padding: "2px 3px", borderRadius: "90px", backgroundColor: "#F6F6FB", border: "solid 1px #EEEEEE" }}>
                  <button onClick={() => toggleView("grid")} className={`toggle-view-btn ${view === "grid" ? "active" : ""}`}><img src={gridIcon} alt="Grid View" /></button>
                  <button onClick={() => toggleView("row")} className={`toggle-view-btn ${view === "row" ? "active" : ""}`}><img src={rowIcon} alt="Row View" /></button>
                </div>
                <div className="course-list-icons">
                  <Tooltip title="Filter" placement="top" arrow>
                    <img src={filter} alt="icon" className="course-list-icon" />
                  </Tooltip>
                  <Tooltip title="Order" placement="top" arrow>
                    <img src={order} alt="icon" className="course-list-icon" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div>
              {loading ? (
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", flexDirection: view === "grid" ? "row" : "column", marginLeft: '30px'}}>
                  {[...Array(coursesPerPage)].map((_, index) =>
        view === "grid" ? (
          // Render skeleton for grid view
          <CourseSkeletonLoader key={index} />
        ) : (
          // Render skeleton for row view
          <AnimationRowSkeleton key={index} />
        )
      )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    flexDirection: view === "grid" ? "row" : "column",
                    marginLeft: "30px",
                  }}
                >
                  {currentCourses.map((course) =>
                    view === "grid" ? <CourseCard key={course._id} course={course} /> : <CourseRow key={course._id} course={course} />
                  )}
                </div>
              )}
            </div>

            {filteredCourses.length > coursesPerPage && (
              <div className="pagination-container">
                <Pagination
                  count={Math.ceil(filteredCourses.length / coursesPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{ marginTop: '10px', marginBottom: '40px', alignSelf: 'flex-end' }}
                />
              </div>
            )}
          </div>
          <div className="Course-list-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseList;