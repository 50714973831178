// import React, { useEffect, useRef, useState } from 'react';
// import Hls from 'hls.js';
// import ReactPlayer from 'react-player';
// import Modal from 'react-modal';
// import '../assets/Css/CourseRendering.css';

// const VideoPlayer = ({ videoUrl, sidebarToggle, contentToggle, setIsModalOpen, isModalOpen, handleToQuiz, handleVideoProgress }) => {
//   const videoRef = useRef(null);
//   const [isHlsSupported, setIsHlsSupported] = useState(false);
//   const [hlsInstance, setHlsInstance] = useState(null);
  
//   console.log('videoUrl', videoUrl);

//   useEffect(() => {
//     const video = videoRef.current;

//     if (!video || !videoUrl) {
//       return;
//     }

//     if (hlsInstance) {
//       hlsInstance.destroy();
//       setHlsInstance(null);
//     }

//     if (Hls.isSupported() && videoUrl.includes('.m3u8')) {
//       const hls = new Hls();
//       hls.loadSource(videoUrl);
//       hls.attachMedia(video);

//       hls.on(Hls.Events.MANIFEST_PARSED, () => {
//         video.play().catch((error) => {
//           console.warn('Play request interrupted:', error);
//         });
//       });

//       hls.on(Hls.Events.ERROR, (event, data) => {
//         if (data.fatal) {
//           switch (data.type) {
//             case Hls.ErrorTypes.NETWORK_ERROR:
//               console.error('Network error: ', data);
//               hls.startLoad();
//               break;
//             case Hls.ErrorTypes.MEDIA_ERROR:
//               console.error('Media error: ', data);
//               hls.recoverMediaError();
//               break;
//             default:
//               hls.destroy();
//               break;
//           }
//         }
//       });

//       setIsHlsSupported(true);
//       setHlsInstance(hls);
//     } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
//       video.src = videoUrl;
//       video.addEventListener('loadedmetadata', () => {
//         video.play().catch((error) => {
//           console.warn('Play request interrupted:', error);
//         });
//       });
//       setIsHlsSupported(true);
//     } else {
//       setIsHlsSupported(false);
//     }

//     return () => {
//       if (hlsInstance) {
//         hlsInstance.destroy();
//       }
//     };
//   }, [videoUrl, hlsInstance]);

//   // Adding an effect to ensure video plays if ready on the first load
//   useEffect(() => {
//     const video = videoRef.current;
//     if (video && video.readyState >= 3) {
//       video.play().catch((error) => {
//         console.warn('Play request interrupted:', error);
//       });
//     }
//   }, [isHlsSupported]);

//   return (
//     <div
//       style={{
//         marginLeft: '0px',
//         height: '100%',
//         width: sidebarToggle ? '100%' : '100%',
//         position: 'relative',
//         zIndex: contentToggle ? '-1' : '0',
//       }}
//     >
//       {isHlsSupported ? (
//         <video
//           ref={videoRef}
//           width="100%"
//           height="100%"
//           controls
//           style={{ backgroundColor: '#000' }}
//           onEnded={() => setIsModalOpen(true)}
//           onProgress={handleVideoProgress}
//         />
//       ) : (
//         <ReactPlayer
//           url={videoUrl}
//           style={{ marginTop: '0px', backgroundColor: '#000' }}
//           width="100%"
//           height="100%"
//           controls
//           onEnded={() => setIsModalOpen(true)}
//           onProgress={handleVideoProgress}
//         />
//       )}

//       {/* Modal for post-video actions */}
//       <div className="course-render-modal-cont">
//         <Modal
//           isOpen={isModalOpen}
//           onRequestClose={() => setIsModalOpen(false)}
//           contentLabel="Video Ended"
//           style={{
//             overlay: {
//               backgroundColor: 'transparent',
//               width: sidebarToggle ? '100%' : '100%',
//               height: '100%',
//               marginLeft: sidebarToggle ? '22%' : '1%',
//               transition: 'all 0.5s ease-in-out 0s',
//               marginTop: '-33px',
//             },
//             content: {
//               top: '50%',
//               left: '50%',
//               right: 'auto',
//               bottom: 'auto',
//               marginRight: '-50%',
//               transform: 'translate(-50%, -50%)',
//               width: '100%',
//               height: '70%',
//               background: 'linear-gradient(145deg, #060655, #0C0C1A)',
//               opacity: '0.6',
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               transition: 'width 2s ease 0s',
//               justifyContent: 'center',
//               color: 'white',
//               marginTop: '22px',
//             },
//           }}
//         >
//           <h5 className="course-render-moadal-heading">
//             Next, let's assess your learning with a quiz. Head over to the quiz section to continue.
//           </h5>

//           <button
//             onClick={handleToQuiz}
//             className="course-render-moadal-continue-btn"
//           >
//             Continue
//           </button>
//           <button
//             onClick={() => setIsModalOpen(false)}
//             className="course-render-moadal-skip-btn"
//           >
//             Close
//           </button>
//         </Modal>
//       </div>
//     </div>
//   );
// };

// export default VideoPlayer;




import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import '../assets/Css/CourseRendering.css';

const VideoPlayer = ({
  videoUrl,
  sidebarToggle,
  contentToggle,
  setIsModalOpen,
  isModalOpen,
  handleToQuiz,
  handleVideoProgress,
  handlePause,
  handlePlay,
  storedProgress, // Pass the stored progress from the parent
  postProgressUpdate, // Function to send progress to the backend
}) => {
  const videoRef = useRef(null);
  const [isHlsSupported, setIsHlsSupported] = useState(false);
  const [hlsInstance, setHlsInstance] = useState(null);
  const intervalRef = useRef(null); // Reference for interval for periodic progress updates

  console.log('videoUrl', videoUrl);

  useEffect(() => {
    const video = videoRef.current;

    if (!video || !videoUrl) {
      return;
    }

    if (hlsInstance) {
      hlsInstance.destroy();
      setHlsInstance(null);
    }

    if (Hls.isSupported() && videoUrl.includes('.m3u8')) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play().catch((error) => {
          console.warn('Play request interrupted:', error);
        });
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error('Network error: ', data);
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error('Media error: ', data);
              hls.recoverMediaError();
              break;
            default:
              hls.destroy();
              break;
          }
        }
      });

      setIsHlsSupported(true);
      setHlsInstance(hls);
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoUrl;
      video.addEventListener('loadedmetadata', () => {
        video.play().catch((error) => {
          console.warn('Play request interrupted:', error);
        });
      });
      setIsHlsSupported(true);
    } else {
      setIsHlsSupported(false);
    }

    return () => {
      if (hlsInstance) {
        hlsInstance.destroy();
      }
    };
  }, [videoUrl, hlsInstance]);

  // Adding an effect to ensure video plays if ready on the first load
  useEffect(() => {
    const video = videoRef.current;
    if (video && video.readyState >= 3) {
      video.play().catch((error) => {
        console.warn('Play request interrupted:', error);
      });
    }
  }, [isHlsSupported]);

  // Start periodic API calls when the video is playing
  const startPeriodicUpdates = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        console.log('Periodic progress update:', storedProgress);
        postProgressUpdate(storedProgress);
      }, 3 * 60 * 1000); // Every 3 minutes
    }
  };

  // Stop periodic API calls
  const stopPeriodicUpdates = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    // Cleanup interval on component unmount
    return () => stopPeriodicUpdates();
  }, []);

  return (
    <div
      style={{
        marginLeft: '0px',
        height: '100%',
        width: sidebarToggle ? '100%' : '100%',
        position: 'relative',
        zIndex: contentToggle ? '-1' : '0',
      }}
    >
      {isHlsSupported ? (
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          controls
          style={{ backgroundColor: '#000' }}
          onEnded={() => {
            setIsModalOpen(true);
            stopPeriodicUpdates(); // Stop periodic updates
            postProgressUpdate(100); // Send final progress
          }}
          onPlay={() => {
            handlePlay();
            startPeriodicUpdates();
          }}
          onPause={() => {
            handlePause();
            stopPeriodicUpdates();
          }}
          onTimeUpdate={(e) => {
            const video = e.target;
            const progress = Math.floor((video.currentTime / video.duration) * 100);
            console.log("Calculated progress for HLS:", progress);
            postProgressUpdate(progress); // Send progress
          }}
          onProgress={handleVideoProgress}
        />
      ) : (
        <ReactPlayer
          url={videoUrl}
          style={{ marginTop: '0px', backgroundColor: '#000' }}
          width="100%"
          height="100%"
          controls
          onEnded={() => {
            setIsModalOpen(true);
            stopPeriodicUpdates(); // Stop periodic updates
            postProgressUpdate(100); // Send final progress
          }}
          onPlay={() => {
            handlePlay();
            startPeriodicUpdates();
          }}
          onPause={() => {
            handlePause();
            stopPeriodicUpdates();
          }}
          onProgress={(progressState) => {
            const progress = Math.floor(progressState.played * 100);
            console.log("Calculated progress for MP4:", progress);
            postProgressUpdate(progress); // Send progress
          }}
        />
      )}

      {/* Modal for post-video actions */}
      <div className="course-render-modal-cont">
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Video Ended"
          style={{
            overlay: {
              backgroundColor: 'transparent',
              width: sidebarToggle ? '100%' : '100%',
              height: '100%',
              marginLeft: sidebarToggle ? '22%' : '1%',
              transition: 'all 0.5s ease-in-out 0s',
              marginTop: '-33px',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '70%',
              background: 'linear-gradient(145deg, #060655, #0C0C1A)',
              opacity: '0.6',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              transition: 'width 2s ease 0s',
              justifyContent: 'center',
              color: 'white',
              marginTop: '22px',
            },
          }}
        >
          <h5 className="course-render-moadal-heading">
            Next, let's assess your learning with a quiz. Head over to the quiz section to continue.
          </h5>

          <button
            onClick={handleToQuiz}
            className="course-render-moadal-continue-btn"
          >
            Continue
          </button>
          <button
            onClick={() => setIsModalOpen(false)}
            className="course-render-moadal-skip-btn"
          >
            Close
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default VideoPlayer;
