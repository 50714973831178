import React from 'react'
import Footer from '../Footer/footer';
import DashboardTwo from '../DashBoard/mergegraph'

const Dashboard = () => {

  return (
    <div style={{height:'90vh',overflowY:'auto'}}>
        <DashboardTwo />
        {/* <div>
          <Footer />
        </div> */}
    </div>
  )
}

export default Dashboard

