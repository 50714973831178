import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lamp_img from '../assets/Images/lamp.png';
import task_square_image from '../assets/Images/task-square.png';
import axios from 'axios';
import '../assets/Css/TimedLab.css';
import solar_menu_dots from '../assets/Images/solar_menu-dots.png';
import theme_imag from '../assets/Images/sun.png';
import maximize_image from '../assets/Images/maximize-4.png';
import Download_imag from '../assets/Images/Download_button.png';
import line_image from '../assets/Images/Line.png';
import achive_image from '../assets/Images/Archive-tick.png';
import video_circle_image from '../assets/Images/video-circle.png';
import Double_arrow_image from '../assets/Images/DoubleArrow.png';
import closeQuestion from '../assets/Images/close_question.png';
import openQuestion from '../assets/Images/open_question.png';
import LoaderImg from "../assets/Images/Loding_image.png";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/ext-language_tools';
import play_icon from "../assets/Images/play.png";
import { Tooltip } from 'react-tooltip';
import Redo_image from '../assets/Images/redo.png';
import Undo_image from '../assets/Images/undo.png';
import { useNavigate } from 'react-router-dom';
import home_breadcrumb_icon from '../assets/Images/Home_Breadcrumb.png';
import cross_line_icon from '../assets/Images/Cross_line.png';
import Colour_loader from '../assets/Images/Colour_loader.png';
import Close_quesion2 from '../assets/Images/Close_quesion2.png';
import cup_image from '../assets/Images/Cup_icon.png';
import { Oval } from 'react-loader-spinner';
import { adminaxios } from '../axios/index';
import { useParams } from 'react-router-dom';


const TimedLab = () => {

    const initialCode = `print("Try dataworks.pro")${Array(21).join('\n')}`;
    const [code, setCode] = useState(initialCode);
    const [isOpen, setIsOpen] = useState(true);
    const [output, setOutput] = useState("");
    const [data, setData] = useState([]);
    const [instruction, setInstruction] = useState(true);
    const [hint, setHint] = useState(false);
    const [solution, setSolution] = useState(false);
    const [maximized, setMaximized] = useState(false);
    const [editorTheme, setEditorTheme] = useState("monokai");   
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const currentModuleId = useSelector((state) => state.dynamicApiId.moduleId);
    const currentLabId = useSelector((state) => state.dynamicApiId.labConfigId);
    const currentTopicId = useSelector((state) => state.dynamicApiId.topicId);
    const [loading, setLoading] = useState(true,)
    const hours = 0; // hours
    const minutes = 30; // minutes
    const initialDuration = (hours * 60 * 60) + (minutes * 60); // Convert to seconds
    const [timer, setTimer] = useState(
      // initialDuration
    );
    const [isActive, setIsActive] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const quizData = data[0];
    const navigate = useNavigate();
    const { id: courseId } = useParams();
    
  
    const handleInstruction = () => {
      setInstruction(true);
      setHint(false);
      setSolution(false);
    };
  
      useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);
  
    const handleMaximize = () => {
      setMaximized(!maximized);
    };
 
    const handleRunCode = async () => {
    const button = document.querySelector('.timedlab_run-code-btn'); 
        if (button) {
            button.classList.add('clicked');
        
            setTimeout(() => {
                button.classList.remove('clicked');
            }, 0);
            
            setIsActive(true);   // Timer stop
            
            try {
                const payload = {
                    problem_id: '667bb72cea0cf957c88bf2c8',
                    code: code,
                    language: "python",
                };
        
                const response = await fetch('http://192.168.0.148:8001/api/submissions/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
        
                const data = await response.json();
                console.log('Run code response:', data); // Debug log
                if (data.expected_output.error) {
                    setOutput(data.expected_output.error);
                } else {
                    setOutput(data.expected_output.output);
                }
            } catch (error) {
                setOutput('Error running code');
                console.error('Error:', error);
            }
           };
    };

  
    const togglePanel = () => setIsOpen(!isOpen);

      
// Function to call progress API with storeVideoId as contentId
useEffect(() => {
  const postProgressUpdate = async () => {
    try {
        const response = await adminaxios.post(`/update_user_progress/${courseId}/${currentModuleId}/${currentTopicId}/${currentLabId}/`, {

          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(`Progress updated:`, response.data);
      }
      
    catch (error) {
      console.error(`Error posting progress:`, error);
    }
  };
    postProgressUpdate();
  }, [courseId, currentModuleId, currentTopicId]);
  
  
    useEffect(() => {
      axios.get('http://192.168.0.148:8001/api/problems/')
        .then(response => {
          console.log('Fetched data:', response);
          setData(response.data);
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);
  
    const clearCode = () => {
      setUndoStack([...undoStack, code]);
      setCode('');
      setRedoStack([]);
    };
  
    const toggleTheme = () => {
      const newTheme = editorTheme === "monokai" ? "github" : "monokai";
      console.log("Switching theme to: ", newTheme);
      setEditorTheme(newTheme);
      setLoading(false);
    };
  
    const handleDownload = () => {
      const blob = new Blob([code], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'main.py';
      link.href = url;
      link.click();
      URL.revokeObjectURL(url);
    };

    useEffect(() => {
        // Start the timer when the component mounts
        setIsActive(true);
      }, []);
    
      useEffect(() => {
        let interval = null;
    
        if (isActive && timer > 0) {
          interval = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
          }, 1000);
        } else if (!isActive && timer !== 0) {
          clearInterval(interval);
        }
    
        return () => clearInterval(interval);
      }, [isActive, timer]);
    
      function formatTime(time) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return [hours, minutes, seconds]
          .map(unit => (unit < 10 ? `0${unit}` : unit))
          .join(':');
      }        
      
      const onChange = (newCode) => {
        setUndoStack([...undoStack, code]);
        setCode(newCode);
        if (!isActive) {
          setIsActive(true);
        }
      };

    const handleUndo = () => {
      if (undoStack.length > 0) {
        const lastCode = undoStack.pop();
        setRedoStack([code, ...redoStack]);
        setCode(lastCode);
        setUndoStack([...undoStack]);
      }
    };
  
    const handleRedo = () => {
      if (redoStack.length > 0) {
        const nextCode = redoStack.shift();
        setUndoStack([...undoStack, code]);
        setCode(nextCode);
        setRedoStack([...redoStack]);
      }
    };
    const [editorMaximized, setEditorMaximized] = useState(false);
  
    const handleEditorMaximize = () => {
      setEditorMaximized(!editorMaximized);
    };
  
    const handleClick = () => {
      navigate('/course-list');
    };
  
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
          <Oval
            height={50}
            width={50}
            color="#000080"
            ariaLabel="loading"
          />
        </div>
      );
    }
    const handleSubmit = () => {
      setShowPopup(true);
    };
  
    const handleClosePopup = () => {
      setShowPopup(false);
    };

  
    return (
      <div className="timedlab_editor-container">
        <div className="timedlab_editor-header">
          <div className="timedlab_header_first_icons">
            <img src={home_breadcrumb_icon} alt="home_breadcrumb_icon" className="timedlab_home_breadcrumb_icon" onClick={handleClick} />
            <img src={cross_line_icon} alt="cross_line_icon" className="timedlab_cross_line_icon" />
            <p className="timedlab_course_breadcrumb">course</p>
            <img src={cross_line_icon} alt="cross_line_icon" className="timedlab_cross_line_icon" />
            <p className="timedlab_course_breadcrumb">intro to python</p>
          </div>
          <div className="timedlab_editor-header-icons">
            <img src={Double_arrow_image} alt="Double Arrow" className="timedlab_Double_arrow_image" data-tooltip-id="tooltip" data-tooltip-content="previous & Next" />
            <img src={line_image} alt="Line" className="timedlab_line-img" />
            <img src={video_circle_image} alt="Video Circle" className="timedlab_header-icon" data-tooltip-id="tooltip" data-tooltip-content="Video" />
            <img src={line_image} alt="Line" className="timedlab_line-img" />
            <img src={achive_image} alt="Archive" className="timedlab_header-icon" data-tooltip-id="tooltip" data-tooltip-content="Save" />
            <img src={line_image} alt="Line" className="timedlab_line-img" />
            <img src={Download_imag} alt="Download" className="timedlab_download-img" onClick={handleDownload} />
            <img src={line_image} alt="Line" className="timedlab_line-img" />
            <p className='timedlab_skip_ps_button' onClick={handleClick}>Skip</p> 
          </div>
        </div>
        <div className="timedlab_editor-main">
          <div className={`timedlab_panel ${isOpen ? 'open' : 'closed'}`} >
            <div className='timedlab_exercise-background'>
              <div><b>1. Exercise</b></div>
            </div>
            <div className='timedlab_exercise-data'>
              {quizData && (
                <>
                  <h3>{quizData.title}</h3>
                  <p>{quizData.description}</p>
                </>
              )}
              <p>Widespread availability of sophisticated computing technology and,AI enables virtually anyone to create highly realistic fake content.</p>
              <p>Widespread availability of sophisticated computing technology and AI enables virtually anyone to create highly realistic fake content.</p>
            </div>
            <div className="timedlab_instruction-bar">
              <div className="timedlab_instruction-bar-buttons">
                <div className='timedlab_instruction-quiz' onClick={handleInstruction} >
                  <b className={instruction ? 'active' : ''}>Instructions</b>
                </div>
              </div>
            </div>
            <div className="timedlab_instruction-content">
              {instruction && (
                <div>
                  <p>This is instruction page</p>
                  <p>This is instruction page</p>
                </div>
              )}
            </div>
          </div>
          <div className='timedlab_editor-code-section'>
            <div className={`timedlab_code-header ${editorTheme === "github" ? "white-theme" : ""}`}>
              <div></div>
              <div className='timedlab_code-header-icons'>
                <div>
                  <img src={maximize_image} alt="Maximize" className={`timedlab_DownwardMaximize ${editorTheme}`} data-tooltip-id="tooltip" data-tooltip-content="Maximize" onClick={handleEditorMaximize} />
                  <img src={theme_imag} alt="Change theme" className={`timedlab_theme_icon ${editorTheme}`} onClick={toggleTheme} data-tooltip-id="tooltip" data-tooltip-content="Theme" />
                  <span className="timedlab_timer-display" >{formatTime(timer)} </span>
                  <img src={Undo_image} alt="Undo" className={`timedlab_undo_icon ${editorTheme}`} onClick={handleUndo} data-tooltip-id="tooltip" data-tooltip-content="Undo" />
                  <img src={Redo_image} alt="Redo" className={`timedlab_redo_icon ${editorTheme}`} onClick={handleRedo} data-tooltip-id="tooltip" data-tooltip-content="Redo" />
                  <img src={solar_menu_dots} alt="Menu" className="timedlab_solar_menu_dotIon" data-tooltip-id="tooltip" data-tooltip-content="Menu" />
                </div>
              </div>
            </div>
            {!maximized && (
              <div className="timedlab_code-editor" >
                <AceEditor
                  mode="python"
                  theme={editorTheme}
                  value={code}
                  editorProps={{ $blockScrolling: true }}
                  name="UNIQUE_ID_OF_DIV"
                  fontSize={14}
                  showGutter={true}
                  highlightActiveLine={true}
                  showPrintMargin={false}
                  onChange={onChange}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{
                    width: '100%',
                    height: editorMaximized ? '100vh' : '20%',
                  }}
                />
                <img src={editorTheme === "monokai" ? LoaderImg : Colour_loader} alt="Loading" className="timedlab_loader-icon" onClick={clearCode} data-tooltip-id="tooltip" data-tooltip-content="Clear Code" />
                <button onClick={handleRunCode} className="timedlab_run-code-btn">
                  <img src={play_icon} alt="Play Icon" className='timedlab_button_play_icon' />Run Code
                </button>
                <button className="timedlab_submit_button" onClick={handleSubmit}>
                  Submit
                </button>
                {showPopup && (
                  <div className="timedlab_popup">
                    <div className="timedlab_popup-content">
                      <h2>Great work! Another step towards mastery!<img src={cup_image} alt="Play Icon" className='timedlab_cup_icon' /></h2>
                        {/* <p>You have completed.let's move another quesion</p> */}
                        <div className='timedlab_P2'>
                           You're doing an amazing job, and each step brings you closer to mastering the <br/>material. Keep pushing forward!
                        </div>
                    </div>
                    <button onClick={handleClosePopup} className='timedlab_popup-content_button'>Continue</button>
                  </div>
                )}
              </div>
            )}
            {!editorMaximized &&
              <div className="timedlab_task-output" style={{ height: maximized ? '100%' : 'auto' }} >
                <div className="timedlab_task-output-header">
                  <div>Task Output</div>
                  <div className="timedlab_task-output-header-icons">
                    <img src={maximize_image} alt="Maximize" className="timedlab_maximize_icon" onClick={handleMaximize} data-tooltip-id="tooltip" data-tooltip-content="Maximize" />
                  </div>
                </div>
                <div className="timedlab_task-output-content">
                  <pre>{output}</pre>
                </div>
              </div>
            }
            <div onClick={togglePanel} className="timedlab_toggle-panel-icon">
              {isOpen ? (<img src={editorTheme === "monokai" ? closeQuestion : Close_quesion2} alt="Close Panel" className="timedlab_code_lab_toggle_img" />) : (<img src={openQuestion} alt="Open Panel" className="timedlab_code_lab_toggle_img" />)}
            </div>
          </div>
        </div>  
        <Tooltip id="tooltip" />
      </div>
    );
  };
  
  export default TimedLab;
  
