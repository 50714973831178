// import React from "react";
// import { Grid, Box, Typography } from "@mui/material";
// import HeaderCard from "./HeaderCard";
// import RecommendationCard from "./RecommendationCard";
// import GroupedBarChart from "./graph"; // Assuming the path to graph.jsx
// import AnalyticsGraph from "./card"; // Assuming the path to card.jsx
// import QuizAssessment from "./QuizAssessment";
// import LabAssessment from "./LabAssessment";
// import Footer from "../Footer/footer";


// const DashboardTwo = () => {
//   return (
//     <Box padding={"20px"}>
//       <div
//         style={{
//           display: "flex",
//           gap: "20px",
//           justifyContent: "center",
//           alignItems: "stretch",
//           padding: 0,
//         }}
//       >
//         <Box flex={2}>
//           <HeaderCard />
//         </Box>
//         <Box flex={1}>
//           <RecommendationCard />
//         </Box>
//       </div>
//       <Box display="flex" justifyContent="space-around" gap={1} sx={{ p: 3 }}>
//         {/* Left Component: GroupedBarChart */}
//         <Box flex={1}>
//           <GroupedBarChart />
//         </Box>

//         {/* Right Component: AnalyticsGraph */}
//         <Box flex={1}>
//           <AnalyticsGraph />
//         </Box>
//       </Box>
//       <Box sx={{ width: "100%", padding: "0px" }}>
//         <Grid container spacing={2}  >
//           <Grid item xs={12} sm={12}>
//             <QuizAssessment />
//           </Grid>
          
//           {/* <Grid item xs={12} sm={6}>
//             <LabAssessment />
//           </Grid> */}
//         </Grid>
//         </Box>
//         {/* <Box sx={{ marginTop: '40px', color: '#34495e', display: 'flex', justifyContent: 'center' }}>
//   <Typography
//     variant="body2"
//     sx={{
//       fontStyle: 'bold',
//       border: '1px solid #b0bec5',  // Softer border color
//       borderRadius: '8px',
//       fontsize:'24px',         // Rounded corners
//       padding: '10px 20px',        // Padding inside the box
//       textAlign: 'center',
//       boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Light shadow
//       backgroundColor: '#f9fafb'   // Light background color for contrast
//     }}
//   >
//     THE PROJECTS WILL BE ADDED SOON
//   </Typography>
// </Box> */}
//         <Box
//           sx={{
//             marginTop: "40px",
//             color: "#34495e",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Typography
//             variant="body1"
//             sx={{
//               fontWeight: "600", // Updated from fontStyle for bold text
//               fontSize: "20px", // Corrected to `fontSize` instead of `fontsize`
//               color: "#2c3e50", // Darker text color for better contrast
//               border: "1px solid #ff9800", // Brighter border color to draw attention
//               borderRadius: "8px",
//               padding: "12px 24px", // Slightly larger padding
//               textAlign: "center",
//               boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)", // Slightly stronger shadow
//               backgroundColor: "white", // Soft pink background for a warm tone
//               animation: "pulse 2s infinite", // Adding pulse animation
//               "@keyframes pulse": {
//                 // Defining pulse animation
//                 "0%": { transform: "scale(1)" },
//                 "50%": { transform: "scale(1.05)" },
//                 "100%": { transform: "scale(1)" },
//               },
//             }}
//           >
//             THE PROJECTS WILL BE ADDED SOON
//           </Typography>
//         </Box>
    
//       <Footer />
//     </Box>
//   );
// };

// export default DashboardTwo;
import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import HeaderCard from "./HeaderCard";
import RecommendationCard from "./RecommendationCard";
import GroupedBarChart from "./graph"; // Assuming the path to graph.jsx
import AnalyticsGraph from "./card"; // Assuming the path to card.jsx
import QuizAssessment from "./QuizAssessment";
import LabAssessment from "./LabAssessment";
import Footer from "../Footer/footer";
import { adminaxios } from "../axios";
import { useSelector } from "react-redux";

const DashboardTwo = () => {
  const [quizData, setQuizData] = useState([]);
  const [labData, setLabData] = useState([]);
  const [loading, setLoading] = useState(true);

  const currentcourseId = useSelector((state) => state.dynamicApiId.courseId);

  useEffect(() => {
    const fetchAssessmentData = async () => {
      setLoading(true);
      try {
        if (!currentcourseId) {
          console.error("No courseId found. Please ensure the route includes :id");
          return;
        }
        const response = await adminaxios.get(
          `/courses/${currentcourseId}/assessment-topics/`
        );
        const data = response.data?.assessments || [];

        // Separate data for quizzes and labs
        const quizzes = data
          .filter((item) => item.type === "Quiz")
          .map((item) => ({
            Title: item.topic_title,
            UnlockedDate: "N/A",
            Status: item.completed ? "Completed" : "Not Started",
            Score: item.score || "N/A",
            AttemptedDate: item.completion_date || "N/A",
          }));

        const labs = data
          .filter((item) => item.type === "Lab")
          .map((item) => ({
            Title: item.topic_title,
            UnlockedDate: "N/A",
            Status: item.completed ? "Completed" : "Not Started",
            Score: item.score || "N/A",
            AttemptedDate: item.completion_date || "N/A",
          }));

        setQuizData(quizzes);
        setLabData(labs);
      } catch (error) {
        console.error("Error fetching assessment data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessmentData();
  }, [currentcourseId]);

  return (
    <Box padding={"20px"}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          alignItems: "stretch",
        }}
      >
        <Box flex={2}>
          <HeaderCard />
        </Box>
        <Box flex={1}>
          <RecommendationCard />
        </Box>
      </Box>

      {/* Chart Section */}
      <Box display="flex" justifyContent="space-around" gap={1} sx={{ p: 3 }}>
        <Box flex={1}>
          <GroupedBarChart />
        </Box>
        <Box flex={1}>
          <AnalyticsGraph />
        </Box>
      </Box>

      {/* Assessment Tables */}
      <Box sx={{ width: "100%", padding: "0px", display: "flex", gap: "20px" }}>
        {/* Quiz Table */}
        <Box flex={1}>
          <QuizAssessment quizData={quizData} loading={loading} />
        </Box>

        {/* Lab Table */}
        <Box flex={1}>
          <LabAssessment labData={labData} loading={loading} />
        </Box>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          marginTop: "40px",
          color: "#34495e",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "600",
            fontSize: "20px",
            color: "#2c3e50",
            border: "1px solid #ff9800",
            borderRadius: "8px",
            padding: "12px 24px",
            textAlign: "center",
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
            backgroundColor: "white",
            animation: "pulse 2s infinite",
            "@keyframes pulse": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.05)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        >
          THE PROJECTS WILL BE ADDED SOON
        </Typography>
      </Box>
      <Footer />
    </Box>
  );
};

export default DashboardTwo;


// import React from 'react';
// import { Grid, Box, Typography } from '@mui/material';
// import HeaderCard from './HeaderCard';
// import RecommendationCard from './RecommendationCard';
// import GroupedBarChart from './graph';  // Assuming the path to graph.jsx
// import AnalyticsGraph from './card';    // Assuming the path to card.jsx
// import QuizAssessment from './QuizAssessment';
// import LabAssessment from './LabAssessment';

// const Dashboard = () => {
//   return (
//     <Box padding={{ xs: '10px', sm: '20px' }}>
//       {/* Header and Recommendation Section */}
//       <Grid container spacing={2} justifyContent="center" alignItems="stretch" sx={{ mb: 3 }}>
//         <Grid item xs={12} md={8}>
//           <HeaderCard />
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <RecommendationCard />
//         </Grid>
//       </Grid>

//       {/* Graphs Section */}
//       <Grid container spacing={1} sx={{ mb: 3,marginLeft:1 }}>
//         <Grid item xs={12} md={6}>
//           <GroupedBarChart />
//         </Grid>
//         <Grid item xs={12} md={6} sx={{marginRight:0}}>
//           <AnalyticsGraph />
//         </Grid>
//       </Grid>

//       {/* Assessments Section */}
//       <Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
//         <Grid item xs={12} sm={6}>
//           <QuizAssessment />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <LabAssessment />
//         </Grid>
//       </Grid>

//       {/* Projects Notice */}
//       <Box
//         sx={{
//           marginTop: '40px',
//           color: '#34495e',
//           display: 'flex',
//           justifyContent: 'center',
//           textAlign: 'center',
//         }}
//       >
//         <Typography
//           variant="body1"
//           sx={{
//             fontWeight: '600',
//             fontSize: { xs: '16px', sm: '18px', md: '20px' }, // Responsive font size
//             color: '#2c3e50',
//             border: '1px solid #ff9800',
//             borderRadius: '8px',
//             padding: { xs: '8px 16px', sm: '10px 20px', md: '12px 24px' }, // Responsive padding
//             boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.15)',
//             backgroundColor: 'white',
//             animation: 'pulse 2s infinite',
//             '@keyframes pulse': {
//               '0%': { transform: 'scale(1)' },
//               '50%': { transform: 'scale(1.05)' },
//               '100%': { transform: 'scale(1)' },
//             },
//           }}
//         >
//           THE PROJECTS WILL BE ADDED SOON
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default Dashboard;



// import React, { useEffect, useState } from "react";
// import {
//   Grid,
//   Box,
//   Typography,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
// } from "@mui/material";
// import HeaderCard from "./HeaderCard";
// import RecommendationCard from "./RecommendationCard";
// import GroupedBarChart from "./graph";
// import AnalyticsGraph from "./card";
// import QuizAssessment from "./QuizAssessment";
// import LabAssessment from "./LabAssessment";
// import Footer from "../Footer/footer";
// import axios from "axios";

// const DashboardTwo = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState("");

//   const token =
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMyMTIyMjU3LCJpYXQiOjE3MzIwNzkwNTcsImp0aSI6ImViYTk2ODcyNzFhMTQ5NWFhMGUyMzg1OWY3YTExNWYwIiwidXNlcl9pZCI6NywidXNlcm5hbWUiOiJNZWdoYW5hIiwicm9sZSI6IkxlYXJuZXIifQ.Ts7RSrI79bTU-ZbIPPkQuDgtO8-_RDJ_LJvAE5kkVuI";

//   // Fetch course titles from the API
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           "https://lmsbackend.nquantum.in/cms/courses/view/",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         console.log("Fetched Courses:", response.data);

//         if (Array.isArray(response.data)) {
//           setCourses(response.data);
//           setSelectedCourse(response.data[0]?._id || ""); // Default to the first course
//         } else {
//           console.error("Unexpected API response structure:", response.data);
//           setCourses([]);
//         }
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };

//     fetchCourses();
//   }, []);

//   const handleCourseChange = (event) => {
//     setSelectedCourse(event.target.value);
//     console.log("Selected course:", event.target.value);
//   };

//   return (
//     <Box padding={"20px"}>
//       {/* Dropdown for Course Selection */}
//       <Box
//         sx={{
//           mb: 3,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <FormControl fullWidth sx={{ maxWidth: 400 }}>
//           <InputLabel id="course-select-label">Select Course</InputLabel>
//           <Select
//             labelId="course-select-label"
//             value={selectedCourse}
//             onChange={handleCourseChange}
//             variant="outlined"
//           >
//             {courses.length > 0 ? (
//               courses.map((course) => (
//                 <MenuItem key={course._id} value={course._id}>
//                   {course.course_title}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled>Loading...</MenuItem>
//             )}
//           </Select>
//         </FormControl>
//       </Box>

//       <div
//         style={{
//           display: "flex",
//           gap: "0px",
//           justifyContent: "center",
//           alignItems: "stretch",
//           padding: 0,
//         }}
//       >
//         <Box flex={2}>
//           <HeaderCard selectedCourse={selectedCourse} />
//         </Box>
//         <Box flex={1}>
//           <RecommendationCard selectedCourse={selectedCourse} />
//         </Box>
//       </div>

//       <Box display="flex" justifyContent="space-around" gap={1} sx={{ p: 3 }}>
//         <Box flex={1}>
//           <GroupedBarChart selectedCourse={selectedCourse} />
//         </Box>

//         <Box flex={1}>
//           <AnalyticsGraph selectedCourse={selectedCourse} />
//         </Box>
//       </Box>

//       <Box sx={{ width: "98%", padding: "0px" }}>
//         <Grid container spacing={2} justifyContent="center">
//           <Grid item xs={12} sm={6}>
//             <QuizAssessment selectedCourse={selectedCourse} />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <LabAssessment selectedCourse={selectedCourse} />
//           </Grid>
//         </Grid>
//         <Box
//           sx={{
//             marginTop: "40px",
//             color: "#34495e",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Typography
//             variant="body1"
//             sx={{
//               fontWeight: "600",
//               fontSize: "20px",
//               color: "#2c3e50",
//               border: "1px solid #ff9800",
//               borderRadius: "8px",
//               padding: "12px 24px",
//               textAlign: "center",
//               boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
//               backgroundColor: "white",
//               animation: "pulse 2s infinite",
//               "@keyframes pulse": {
//                 "0%": { transform: "scale(1)" },
//                 "50%": { transform: "scale(1.05)" },
//                 "100%": { transform: "scale(1)" },
//               },
//             }}
//           >
//             THE PROJECTS WILL BE ADDED SOON
//           </Typography>
//         </Box>
//       </Box>
//       <Footer />
//     </Box>
//   );
// };

// export default DashboardTwo;




// import React, { useEffect, useState } from "react";
// import {
//   Grid,
//   Box,
//   Typography,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
// } from "@mui/material";
// import HeaderCard from "./HeaderCard";
// import RecommendationCard from "./RecommendationCard";
// import GroupedBarChart from "./graph";
// import AnalyticsGraph from "./card";
// import QuizAssessment from "./QuizAssessment";
// import LabAssessment from "./LabAssessment";
// import Footer from "../Footer/footer";
// import { adminaxios } from "../axios";

// const DashboardTwo = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState("");


//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await adminaxios.get('/courses/view/');

//         console.log("Fetched Courses:", response.data);

//         if (Array.isArray(response.data)) {
//           setCourses(response.data);
//           setSelectedCourse(response.data[0]?._id || ""); // Default to the first course
//         } else {
//           console.error("Unexpected API response structure:", response.data);
//           setCourses([]);
//         }
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };

//     fetchCourses();
//   }, []);

//   const handleCourseChange = (event) => {
//     setSelectedCourse(event.target.value);
//     console.log("Selected course:", event.target.value);
//   };

//   return (
//     <Box padding={"20px"}>
//       {/* Dropdown for Course Selection */}
//       <Box
//         sx={{
//           mb: 3,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <FormControl fullWidth sx={{ maxWidth: 400 }}>
//           <InputLabel id="course-select-label">Select Course</InputLabel>
//           <Select
//             labelId="course-select-label"
//             value={selectedCourse}
//             onChange={handleCourseChange}
//             variant="outlined"
//           >
//             {courses.length > 0 ? (
//               courses.map((course) => (
//                 <MenuItem key={course._id} value={course._id}>
//                   {course.course_title}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled>Loading...</MenuItem>
//             )}
//           </Select>
//         </FormControl>
//       </Box>

//       <div
//         style={{
//           display: "flex",
//           gap: "0px",
//           justifyContent: "center",
//           alignItems: "stretch",
//           padding: 0,
//         }}
//       >
//         <Box flex={2}>
//           <HeaderCard selectedCourse={selectedCourse} />
//         </Box>
//         <Box flex={1}>
//           <RecommendationCard selectedCourse={selectedCourse} />
//         </Box>
//       </div>

//       <Box display="flex" justifyContent="space-around" gap={1} sx={{ p: 3 }}>
//         <Box flex={1}>
//           <GroupedBarChart selectedCourse={selectedCourse} />
//         </Box>

//         <Box flex={1}>
//           <AnalyticsGraph selectedCourse={selectedCourse} />
//         </Box>
//       </Box>

//       <Box sx={{ width: "98%", padding: "0px" }}>
//         <Grid container spacing={2} justifyContent="center">
//           <Grid item xs={12} sm={6}>
//             <QuizAssessment selectedCourse={selectedCourse} />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <LabAssessment selectedCourse={selectedCourse} />
//           </Grid>
//         </Grid>
//         <Box
//           sx={{
//             marginTop: "40px",
//             color: "#34495e",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Typography
//             variant="body1"
//             sx={{
//               fontWeight: "600",
//               fontSize: "20px",
//               color: "#2c3e50",
//               border: "1px solid #ff9800",
//               borderRadius: "8px",
//               padding: "12px 24px",
//               textAlign: "center",
//               boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
//               backgroundColor: "white",
//               animation: "pulse 2s infinite",
//               "@keyframes pulse": {
//                 "0%": { transform: "scale(1)" },
//                 "50%": { transform: "scale(1.05)" },
//                 "100%": { transform: "scale(1)" },
//               },
//             }}
//           >
//             THE PROJECTS WILL BE ADDED SOON
//           </Typography>
//         </Box>
//       </Box>
//       <Footer />
//     </Box>
//   );
// };

// export default DashboardTwo;



// import React, { useEffect, useState } from "react";
// import {
//   Grid,
//   Box,
//   Typography,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
// } from "@mui/material";
// import HeaderCard from "./HeaderCard";
// import RecommendationCard from "./RecommendationCard";
// import GroupedBarChart from "./graph";
// import AnalyticsGraph from "./card";
// import QuizAssessment from "./QuizAssessment";
// import LabAssessment from "./LabAssessment";
// import Footer from "../Footer/footer";
// import { adminaxios } from "../axios";

// const DashboardTwo = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState("");

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await adminaxios.get("/courses/view/");

//         console.log("Fetched Courses:", response.data);

//         if (Array.isArray(response.data)) {
//           setCourses(response.data);
//           setSelectedCourse(response.data[0]?._id || ""); // Default to the first course
//         } else {
//           console.error("Unexpected API response structure:", response.data);
//           setCourses([]);
//         }
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };

//     fetchCourses();
//   }, []);

//   const handleCourseChange = (event) => {
//     setSelectedCourse(event.target.value);
//     console.log("Selected course:", event.target.value);
//   };

//   return (
//     <Box padding={"20px"}>
//       {/* Dropdown with Instruction Text */}
//       <Box
//         sx={{
//           mb: 3,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           gap: 10, // Spacing between text and dropdown
//           padding:'30px',
//           borderRadius:'20px',
//           margin: '10px 20px 30px 20px',
//           boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
//         }}
//       >
//         <Typography
//           variant="body1"
//           sx={{
//             fontWeight: "600",
//             fontSize: "20px",
//             color: "#2c3e50",
//             // border: "1px solid #ff9800",
//             // borderRadius: "8px",
//             padding: "12px 24px",
//             textAlign: "center",
//             // boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
//             backgroundColor: "white",
//             animation: "pulse 2s infinite",
//             "@keyframes pulse": {
//               "0%": { transform: "scale(1)" },
//               "50%": { transform: "scale(1.05)" },
//               "100%": { transform: "scale(1)" },
//             },
//           }}
//         >
//           Select a course from the dropdown to view your progress and insights!
//         </Typography>

//         <FormControl fullWidth sx={{ maxWidth: 400 }}>
//           <InputLabel id="course-select-label">Select Course</InputLabel>
//           <Select
//             labelId="course-select-label"
//             value={selectedCourse}
//             onChange={handleCourseChange}
//             variant="outlined"
//           >
//             {courses.length > 0 ? (
//               courses.map((course) => (
//                 <MenuItem key={course._id} value={course._id}>
//                   {course.course_title}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled>Loading...</MenuItem>
//             )}
//           </Select>
//         </FormControl>
//       </Box>

//       <div
//         style={{
//           display: "flex",
//           gap: "20px",
//           justifyContent: "center",
//           alignItems: "stretch",
//           padding: 0,
//         }}
//       >
//         <Box flex={2}>
//           <HeaderCard selectedCourse={selectedCourse} />
//         </Box>
//         <Box flex={1}>
//           <RecommendationCard selectedCourse={selectedCourse} />
//         </Box>
//       </div>

//       <Box display="flex" justifyContent="space-around" gap={1} sx={{ p: 3 }}>
//         <Box flex={1}>
//           <GroupedBarChart selectedCourse={selectedCourse} />
//         </Box>

//         <Box flex={1}>
//           <AnalyticsGraph selectedCourse={selectedCourse} />
//         </Box>
//       </Box>

//       <Box sx={{ width: "98%", padding: "0px" }}>
//         <Grid container spacing={2} justifyContent="center">
//           <Grid item xs={12} sm={6}>
//             <QuizAssessment selectedCourse={selectedCourse} />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <LabAssessment selectedCourse={selectedCourse} />
//           </Grid>
//         </Grid>
//         <Box
//           sx={{
//             marginTop: "40px",
//             color: "#34495e",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Typography
//             variant="body1"
//             sx={{
//               fontWeight: "600",
//               fontSize: "20px",
//               color: "#2c3e50",
//               border: "1px solid #ff9800",
//               borderRadius: "8px",
//               padding: "12px 24px",
//               textAlign: "center",
//               boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
//               backgroundColor: "white",
//               animation: "pulse 2s infinite",
//               "@keyframes pulse": {
//                 "0%": { transform: "scale(1)" },
//                 "50%": { transform: "scale(1.05)" },
//                 "100%": { transform: "scale(1)" },
//               },
//             }}
//           >
//             THE PROJECTS WILL BE ADDED SOON
//           </Typography>
//         </Box>
//       </Box>
//       <Footer />
//     </Box>
//   );
// };

// export default DashboardTwo;
