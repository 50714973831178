
import React from 'react';
import '../assets/Css/VerifyEmail.css';
import VE from '../assets/Images/Verifyemail_image.png';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import skill_synq_logo from '../assets/Images/Skillsynqlogo.png';
import Success_icon from '../assets/Images/Success_tick.png';
 
const VerifyEmail = () => {
  return (
    <div className="VerifyeEmail-main-container">
    <div className="VerifyeEmail-container">
      <img src={VE} alt="dw" className="VerifyeEmail-image" />
      <div>
        <div className="VerifyeEmail-circles">
          <img src={Circle1} alt="Circle 1" className="VerifyeEmail-circle VerifyeEmail-circle1" />
          <img src={Circle2} alt="Circle 2" className="VerifyeEmail-circle VerifyeEmail-circle2" />
        </div>
      </div>
      <div></div>
      <div className='VerifyEmail-form'>
      <div className="VerifyeEmail-message">
        <h2>Please verify your email</h2>
          <div className="VerifyeEmail-image-text-container">
              <img src={Success_icon} alt="Success" className="VerifyeEmail-success-image" />
              <p>
                We have sent an email to <br />
                <span className="verifyEmail_emailbold">Synchroniai@gmail.com</span>
              </p>            
          </div>
          <p className='verifyemail_belowPragraph'>If the email doesn't show up soon, check your spam folder. We sent it from <b>Synchroniai@gmail.com</b></p>
        <button type="button" className='VerifyeEmail-button'>Resent link</button>
      </div>
      {/* <div className="powered-by">
                    Powered by <nbsp></nbsp>
                    <img 
                        src={skill_synq_logo}
                        alt="Skillsynq Logo"  
                    />
                </div> */}
    </div>
    </div>
    </div>
  );
};
 
export default VerifyEmail;