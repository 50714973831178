
// import React from 'react';
// import { Card, CardContent, Typography, Box } from '@mui/material';
// import { styled } from '@mui/system';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const moduleData = [
//   {
//     name: 'Module 1',
//     Video: 200,
//     Notes: 150,
//     Quiz: 100,
//     Lab: 50,
//   },
//   {
//     name: 'Module 2',
//     Video: 250,
//     Notes: 180,
//     Quiz: 120,
//     Lab: 70,
//   },
//   {
//     name: 'Module 3',
//     Video: 150,
//     Notes: 120,
//     Quiz: 80,
//     Lab: 60,
//   },
//   {
//     name: 'Module 4',
//     Video: 300,
//     Notes: 220,
//     Quiz: 180,
//     Lab: 100,
//   },
// ];

// // Styled Card with light gradient background and soft shadow
// const StyledCard = styled(Card)({
//   borderRadius: '15px',
//   maxWidth: '98%',
//   height:'100%',
//   boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)',
//   padding: '0px',
//   textAlign: 'center',
//   overflow: 'hidden',
// });

// // Custom Tooltip component
// const CustomTooltip = ({ payload, label }) => {
//   if (!payload || payload.length === 0) return null;

//   // Extract data from payload
//   const { Video, Notes, Quiz, Lab } = payload[0].payload;

//   // Define the colors that correspond to each data type
//   const colorMap = {
//     Video: '#8884d8',
//     Notes: '#82ca9d',
//     Quiz: '#ffc658',
//     Lab: '#f08080',
//   };

//   return (
//     <div style={{
//       backgroundColor: '#fff',
//       borderRadius: '5px',
//       boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//       width: '120px',
//       padding: '5px',
//       fontSize: '14px',
//       lineHeight: '1.5',
//     }}>

//       <Typography variant="body2" style={{ fontWeight: 'bold' }}>{label}</Typography><hr/>
//       <div style={{ alignItems: 'initial' }}>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <div style={{ width: '10px', height: '10px', backgroundColor: colorMap.Video,borderRadius: '50%', marginRight: '5px' }}></div>
//           <div>Video:<span style={{ fontWeight: 'bold' }}> {Video}</span></div>
//         </div>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <div style={{ width: '10px', height: '10px', backgroundColor: colorMap.Notes,borderRadius: '50%', marginRight: '5px' }}></div>
//           <div>Notes:<span style={{ fontWeight: 'bold' }}>{Notes}</span></div>
//         </div>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <div style={{ width: '10px', height: '10px', backgroundColor: colorMap.Quiz,borderRadius: '50%', marginRight: '5px' }}></div>
//           <div>Quiz:<span style={{ fontWeight: 'bold' }}> {Quiz} </span></div>
//         </div>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <div style={{ width: '10px', height: '10px', backgroundColor: colorMap.Lab,borderRadius: '50%', marginRight: '5px' }}></div>
//           <div>Lab:<span style={{ fontWeight: 'bold' }}> {Lab}</span></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// // Custom Legend component with circular indicators
// const CustomLegend = (props) => {
//   const { payload } = props;

//   return (
//     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
//       {payload.map((entry, index) => (
//         <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
//           <div style={{
//             width: '10px',
//             height: '10px',
//             backgroundColor: entry.color,
//             borderRadius: '50%',
//             marginRight: '5px',
//           }}></div>
//           <span>{entry.value}</span>
//         </div>
//       ))}
//     </div>
//   );
// };

// const GroupedBarChart = () => (
//   <StyledCard>
//     <CardContent >
//       <Typography variant="h6" fontWeight="600" color="black"  fontFamily="poppins" fontSize="20px" textAlign= 'left'>
//         Module Performance (Video, Notes, Quiz, Lab) - Grouped
//       </Typography><br/>
//       <Box mt={2} >
//         <ResponsiveContainer width="100%" height={300} >
//           <BarChart data={moduleData}>
//             {/* Remove the background grid lines */}
//             <CartesianGrid stroke="none" />
//             <XAxis dataKey="name" />
//             <YAxis />
//             <Tooltip content={<CustomTooltip />} wrapperStyle={{ backgroundColor: 'transparent', border: 'none', textAlign: 'left' }} />
//             <Legend content={<CustomLegend />}/>


//             {/* Custom Tooltip with transparent background */}
//             <Tooltip
//               content={<CustomTooltip />}
//               wrapperStyle={{ backgroundColor: 'transparent', border: 'none',textAlign: 'left', cursor:'default'}}
//             />
//             <Legend />
         

//             <Bar dataKey="Video" fill="#63ADF2" barSize={10} animationDuration={3000} animationEasing='ease-out' />
//             <Bar dataKey="Notes" fill="#A7CCED" barSize={10} animationDuration={3000} animationEasing='ease-out' />
//             <Bar dataKey="Quiz" fill="#304D6D" barSize={10} animationDuration={3000} animationEasing='ease-out' />
//             <Bar dataKey="Lab" fill="#82A0BC" barSize={10} animationDuration={3000} animationEasing='ease-out' />
            
//           </BarChart>
//         </ResponsiveContainer>
//       </Box>
//     </CardContent>
//   </StyledCard>
// );

// export default GroupedBarChart;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Card, CardContent, Typography, Box } from '@mui/material';
// import { styled } from '@mui/system';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const colorMap = {
//   Video: '#63ADF2',
//   Notes: '#A7CCED',
//   Quiz: '#304D6D',
//   Lab: '#82A0BC',
// };
// // Styled Card
// const StyledCard = styled(Card)({
//   borderRadius: '15px',
//   maxWidth: '98%',
//   height: '100%',
//   boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)',
//   padding: '0px',
//   textAlign: 'center',
//   overflow: 'hidden',
// });



// // Tooltip
// const CustomTooltip = ({ payload, label }) => {
//   if (!payload || payload.length === 0) return null;

//   const { Video, Notes, Quiz, Lab } = payload[0].payload;

//   return (
//     <div
//       style={{
//         backgroundColor: '#fff',
//         borderRadius: '5px',
//         boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//         width: '120px',
//         padding: '5px',
//         fontSize: '14px',
//         lineHeight: '1.5',
//       }}
//     >
//       <Typography variant="body2" style={{ fontWeight: 'bold' }}>
//         {label}
//       </Typography>
//       <hr />
//       {Object.entries({ Video, Notes, Quiz, Lab }).map(([key, value]) => (
//         <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
//           <div
//             style={{
//               width: '10px',
//               height: '10px',
//               backgroundColor: '#000',
//               borderRadius: '50%',
//               marginRight: '5px',
//             }}
//           ></div>
//           <div>
//             {key}:<span style={{ fontWeight: 'bold' }}> {value}%</span>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// const GroupedBarChart = () => {
//   const [moduleData, setModuleData] = useState([]);

//   useEffect(() => {
//     const token =
//       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMxOTY2NDI0LCJpYXQiOjE3MzE5MjMyMjQsImp0aSI6IjA2M2RmNjIzZDRmYTRlNzdiNGZkMzA1YTk4MjVjY2NlIiwidXNlcl9pZCI6NywidXNlcm5hbWUiOiJNZWdoYW5hIiwicm9sZSI6IkxlYXJuZXIifQ.SUWOysymoVGKOROsFmYefhB5BjlqN0r1h4AQbjiK4pc';

//     const fetchData = async () => {
//       try {
//         const response = await axios.get('https://lmsbackend.nquantum.in/cms/user_progress/6710b231521ddfd3226a62ec', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         const transformedData = transformData(response.data);
//         setModuleData(transformedData);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

 
//   // const transformData = (data) => {
//   //   // Ensure data structure exists
//   //   if (!data || !data[0]?.courses?.length) return [];
  
//   //   // Extract and transform data
//   //   return data[0].courses[0].modules.map((module) => {
//   //     let videoProgress = 0;
//   //     let notesProgress = 0;
//   //     let quizProgress = 0;
//   //     let labProgress = 0;
  
//   //     // Aggregate progress for each content type
//   //     module.topics.forEach((topic) => {
//   //       topic.contents.forEach((content) => {
//   //         if (content.content_type === 'media' && content.content_id?.format === '.mp4') {
//   //           videoProgress += content.progress_percentage || 0;
//   //         } else if (content.content_type === 'media' && content.content_id?.format === 'HTML') {
//   //           notesProgress += content.progress_percentage || 0;
//   //         } else if (content.content_type === 'quiz') {
//   //           quizProgress += content.progress_percentage || 0;
//   //         } else if (content.content_type === 'lab') {
//   //           labProgress += content.progress_percentage || 0;
//   //         }
//   //       });
//   //     });
  
//   //     return {
//   //       name: module.module_name,
//   //       Video: Math.round(videoProgress),
//   //       Notes: Math.round(notesProgress),
//   //       Quiz: Math.round(quizProgress),
//   //       Lab: Math.round(labProgress),
//   //     };
//   //   });
//   // };

//   const transformData = (data) => {
//     if (!data || !data[0]?.courses?.length) return [];
  
//     return data[0].courses[0].modules.map((module) => {
//       let totalVideoProgress = 0;
//       let totalNotesProgress = 0;
//       let totalQuizProgress = 0;
//       let totalLabProgress = 0;
  
//       let videoCount = 0;
//       let notesCount = 0;
//       let quizCount = 0;
//       let labCount = 0;
  
//       // Loop through topics and contents
//       module.topics.forEach((topic) => {
//         topic.contents.forEach((content) => {
//           if (content.content_type === 'media' && content.content_id?.format === '.mp4') {
//             totalVideoProgress += content.progress_percentage || 0;
//             videoCount++;
//           } else if (content.content_type === 'media' && content.content_id?.format === 'HTML') {
//             totalNotesProgress += content.progress_percentage || 0;
//             notesCount++;
//           } else if (content.content_type === 'quiz') {
//             totalQuizProgress += content.progress_percentage || 0;
//             quizCount++;
//           } else if (content.content_type === 'lab') {
//             totalLabProgress += content.progress_percentage || 0;
//             labCount++;
//           }
//         });
//       });
  
//       // Calculate average progress for each content type
//       const videoProgress = videoCount > 0 ? totalVideoProgress / videoCount : 0;
//       const notesProgress = notesCount > 0 ? totalNotesProgress / notesCount : 0;
//       const quizProgress = quizCount > 0 ? totalQuizProgress / quizCount : 0;
//       const labProgress = labCount > 0 ? totalLabProgress / labCount : 0;
  
//       // Normalize to 100%
//       const overallProgress = videoProgress + notesProgress + quizProgress + labProgress;
  
//       return {
//         name: module.module_name,
//         Video: Math.round(videoProgress),
//         Notes: Math.round(notesProgress),
//         Quiz: Math.round(quizProgress),
//         Lab: Math.round(labProgress),
//         Overall: Math.round(overallProgress / 4), // Dividing by 4 to get an overall percentage
//       };
//     });
//   };
  
  

//   return (
//     <StyledCard>
//       <CardContent>
//         <Typography
//           variant="h6"
//           fontWeight="600"
//           color="black"
//           fontFamily="poppins"
//           fontSize="20px"
//           textAlign="left"
//         >
//           Module Performance (Video, Notes, Quiz, Lab) - Grouped
//         </Typography>
//         <br />
//         <Box mt={2}>
//           <ResponsiveContainer width="100%" height={300}>
//             <BarChart data={moduleData}>
//               <CartesianGrid stroke="none" />
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip content={<CustomTooltip />} />
//               <Legend />
//               <Bar
//                 dataKey="Video"
//                 fill={colorMap.Video}
//                 barSize={10}
//                 animationDuration={3000}
//                 animationEasing="ease-out"
//               />
//               <Bar
//                 dataKey="Notes"
//                 fill={colorMap.Notes}
//                 barSize={10}
//                 animationDuration={3000}
//                 animationEasing="ease-out"
//               />
//               <Bar
//                 dataKey="Quiz"
//                 fill={colorMap.Quiz}
//                 barSize={10}
//                 animationDuration={3000}
//                 animationEasing="ease-out"
//               />
//               <Bar
//                 dataKey="Lab"
//                 fill={colorMap.Lab}
//                 barSize={10}
//                 animationDuration={3000}
//                 animationEasing="ease-out"
//               />
//             </BarChart>
//           </ResponsiveContainer>
//         </Box>
//       </CardContent>
//     </StyledCard>
//   );
// };

// export default GroupedBarChart;

import React, { useEffect, useState } from 'react';
import { adminaxios } from '../axios';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Color Map
const colorMap = {
  Video: '#63ADF2',
  Notes: '#A7CCED',
  Quiz: '#304D6D',
  Lab: '#82A0BC',
};

// Styled Card
const StyledCard = styled(Card)({
  borderRadius: '15px',
  maxWidth: '98%',
  height: '100%',
  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)',
  padding: '0px',
  textAlign: 'center',
  overflow: 'hidden',
});

// Custom Tooltip
const CustomTooltip = ({ payload, label }) => {
  if (!payload || payload.length === 0) return null;

  const data = payload[0]?.payload || {};

  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        fontSize: '14px',
        lineHeight: '1.5',
      }}
    >
      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
      {data.tooltipName}
      </Typography>
      <hr />
      {Object.entries(colorMap).map(([key, color]) => (
        <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <div
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: color, // Use colorMap or fallback to black
              borderRadius: '50%',
              marginRight: '5px',
            }}
          ></div>
          <div>
          {key}: <span style={{ fontWeight: 'bold' }}>{data[key] !== undefined ? `${data[key]}%` : 'N/A'}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

const GroupedBarChart = () => {
  const [moduleData, setModuleData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const { id: courseId } = useParams();
  const currentcourseId = useSelector((state) => state.dynamicApiId.courseId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Dynamically get courseId or other required parameters
        const response = await adminaxios.get(`/user_progress/${currentcourseId}/`);
        const data = response.data; // Axios stores the response data in `response.data`
        const transformedData = transformData(data); // Transform data if required
        setModuleData(transformedData); // Update state with the transformed data
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    };
  
    fetchData();
  }, [currentcourseId]); // Add `courseId` as a dependency to re-fetch data when it changes
  

  const transformData = (data) => {
    if (!data || !data.courses?.length) return []; // Safeguard against missing or invalid data
  
    const course = data.courses.find((c) => c.course_id === currentcourseId); // Find the relevant course
    if (!course || !course.modules?.length) return []; // Ensure modules exist
  
    return course.modules.map((module, index) => {
      let totalVideoProgress = 0;
      let totalNotesProgress = 0;
      let totalQuizProgress = 0;
      let totalLabProgress = 0;
  
      let videoCount = 0;
      let notesCount = 0;
      let quizCount = 0;
      let labCount = 0;
  
      // Loop through topics and contents
      module.topics.forEach((topic) => {
        topic.contents.forEach((content) => {
          if (content.content_type === 'media' && content.content_id?.format === '.mp4') {
            totalVideoProgress += content.progress_percentage || 0;
            videoCount++;
          } else if (content.content_type === 'media' && content.content_id?.format === 'HTML') {
            totalNotesProgress += content.progress_percentage || 0;
            notesCount++;
          } else if (content.content_type === 'quiz') {
            totalQuizProgress += content.progress_percentage || 0;
            quizCount++;
          } else if (content.content_type === 'lab') {
            totalLabProgress += content.progress_percentage || 0;
            labCount++;
          }
        });
      });
  
      // Calculate average progress for each content type
      const videoProgress = videoCount > 0 ? totalVideoProgress / videoCount : 0;
      const notesProgress = notesCount > 0 ? totalNotesProgress / notesCount : 0;
      const quizProgress = quizCount > 0 ? totalQuizProgress / quizCount : 0;
      const labProgress = labCount > 0 ? totalLabProgress / labCount : 0;
  
      return {
        name: `Module ${index + 1}`,
        tooltipName: module.module_name || `Module ${index + 1}`,
        Video: Math.round(videoProgress),
        Notes: Math.round(notesProgress),
        Quiz: Math.round(quizProgress),
        Lab: Math.round(labProgress),
      };
    });
  };
  
  return (
    <StyledCard>
      <CardContent>
        <Typography
          variant="h6"
          fontWeight="600"
          color="black"
          fontFamily="poppins"
          fontSize="18px"
          textAlign="left"
        >
          Module Performance (Video, Notes, Quiz, Lab) - Grouped
        </Typography>
        <br />
        <Box mt={2}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={moduleData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="Video" fill={colorMap.Video} barSize={10} />
              <Bar dataKey="Notes" fill={colorMap.Notes} barSize={10} />
              <Bar dataKey="Quiz" fill={colorMap.Quiz} barSize={10} />
              <Bar dataKey="Lab" fill={colorMap.Lab} barSize={10} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default GroupedBarChart;



// import React, { useEffect, useState } from 'react';
// import { adminaxios } from '../axios';
// import { Card, CardContent, Typography, Box } from '@mui/material';
// import { styled } from '@mui/system';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// // Color Map
// const colorMap = {
//   Video: '#63ADF2',
//   Notes: '#A7CCED',
//   Quiz: '#304D6D',
//   Lab: '#82A0BC',
// };

// // Styled Card
// const StyledCard = styled(Card)(() => ({
//   borderRadius: '15px',
//   maxWidth: '98%',
//   height: '100%',
//   boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.08)',
//   padding: '0px',
//   textAlign: 'center',
//   overflow: 'hidden',
// }));

// // Custom Tooltip
// const CustomTooltip = ({ payload }) => {
//   if (!payload || payload.length === 0) return null;

//   const data = payload[0]?.payload || {};

//   return (
//     <div
//       style={{
//         backgroundColor: '#fff',
//         borderRadius: '5px',
//         boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//         padding: '10px',
//         fontSize: '14px',
//         lineHeight: '1.5',
//       }}
//     >
//       <Typography variant="body2" style={{ fontWeight: 'bold' }}>
//         {data.tooltipName}
//       </Typography>
//       <hr />
//       {Object.entries(colorMap).map(([key, color]) => (
//         <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
//           <div
//             style={{
//               width: '10px',
//               height: '10px',
//               backgroundColor: color,
//               borderRadius: '50%',
//               marginRight: '5px',
//             }}
//           ></div>
//           <div>
//             {key}: <span style={{ fontWeight: 'bold' }}>{data[key] !== undefined ? `${data[key]}%` : 'N/A'}</span>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// const GroupedBarChart = ({ selectedCourse }) => {
//   const [moduleData, setModuleData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!selectedCourse) return;

//       setLoading(true);
//       try {
//         const response = await adminaxios.get(`/user_progress/${selectedCourse}/`);
//         const data = response.data;
//         const transformedData = transformData(data);
//         setModuleData(transformedData);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [selectedCourse]); // Re-fetch when `selectedCourse` changes

//   const transformData = (data) => {
//     if (!data || !data[0]?.courses?.length) return [];

//     return data[0].courses[0].modules.map((module, index) => {
//       let totalVideoProgress = 0;
//       let totalNotesProgress = 0;
//       let totalQuizProgress = 0;
//       let totalLabProgress = 0;

//       let videoCount = 0;
//       let notesCount = 0;
//       let quizCount = 0;
//       let labCount = 0;

//       // Loop through topics and contents
//       module.topics.forEach((topic) => {
//         topic.contents.forEach((content) => {
//           if (content.content_type === 'media' && content.content_id?.format === '.mp4') {
//             totalVideoProgress += content.progress_percentage || 0;
//             videoCount++;
//           } else if (content.content_type === 'media' && content.content_id?.format === 'HTML') {
//             totalNotesProgress += content.progress_percentage || 0;
//             notesCount++;
//           } else if (content.content_type === 'quiz') {
//             totalQuizProgress += content.progress_percentage || 0;
//             quizCount++;
//           } else if (content.content_type === 'lab') {
//             totalLabProgress += content.progress_percentage || 0;
//             labCount++;
//           }
//         });
//       });

//       // Calculate average progress for each content type
//       const videoProgress = videoCount > 0 ? totalVideoProgress / videoCount : 0;
//       const notesProgress = notesCount > 0 ? totalNotesProgress / notesCount : 0;
//       const quizProgress = quizCount > 0 ? totalQuizProgress / quizCount : 0;
//       const labProgress = labCount > 0 ? totalLabProgress / labCount : 0;

//       return {
//         name: `Module ${index + 1}`,
//         tooltipName: module.module_name,
//         Video: Math.round(videoProgress),
//         Notes: Math.round(notesProgress),
//         Quiz: Math.round(quizProgress),
//         Lab: Math.round(labProgress),
//       };
//     });
//   };

//   return (
//     <StyledCard>
//       <CardContent>
//         <Typography
//           variant="h6"
//           fontWeight="600"
//           color="black"
//           fontFamily="poppins"
//           fontSize="20px"
//           textAlign="left"
//         >
//           Module Performance (Video, Notes, Quiz, Lab) - Grouped
//         </Typography>
//         <br />
//         <Box mt={2}>
//           <ResponsiveContainer width="100%" height={300}>
//             <BarChart data={moduleData}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip content={<CustomTooltip />} />
//               <Legend />
//               <Bar dataKey="Video" fill={colorMap.Video} barSize={10} />
//               <Bar dataKey="Notes" fill={colorMap.Notes} barSize={10} />
//               <Bar dataKey="Quiz" fill={colorMap.Quiz} barSize={10} />
//               <Bar dataKey="Lab" fill={colorMap.Lab} barSize={10} />
//             </BarChart>
//           </ResponsiveContainer>
//         </Box>
//       </CardContent>
//     </StyledCard>
//   );
// };

// export default GroupedBarChart;


