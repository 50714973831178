
// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';

// export default function Animations() {
//   return (
//     <Box sx={{ width: '100%', marginBottom: '20px', marginTop:'5%' }}>
//       {/* Skeleton with custom height */}
//       <Skeleton variant="rectangular" width="100%" height={45} sx={{ marginBottom: '20px' , borderRadius:'15px'}} />
      
//       {/* Skeleton with wave animation */}
//       <Skeleton variant="rectangular" width="100%" height={45} animation="wave" sx={{ marginBottom: '20px', borderRadius:'15px'}} />
      
//       {/* Static Skeleton with animation */}
//       <Skeleton variant="rectangular" width="100%" height={45} animation="wave" sx={{ marginBottom: '20px', borderRadius:'15px'}}  />
//     </Box>
//   );
// }

import React from 'react'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const AnimationRowSkeleton = () => {
  return (
    <Box sx={{ width: '100%', marginTop:'2%', marginLeft:'2%' }}>
          {/* Skeleton with custom height */}
           <Skeleton variant="rectangular" width="92%" height={'200px'} sx={{ marginBottom: '20px' , borderRadius:'15px'}} />
          
           {/* Skeleton with wave animation */}
           <Skeleton variant="rectangular" width="92%" height={'200px'} animation="wave" sx={{ marginBottom: '20px', borderRadius:'15px'}} />  
         </Box>
  )
}

export default AnimationRowSkeleton
