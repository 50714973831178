

// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';
// import axios from 'axios';

// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' };
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' };
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' };
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' };
//   }
// };

// const QuizAssessment = () => {
//   const [quizData, setQuizData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchQuizData = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.get(
//           'https://lmsbackend.nquantum.in/cms/courses/66f69adac2bde2e5874729bd/assessment-topics/'
//         );
//         const data = response.data?.assessments || [];
        
//         // Transform data to fit table requirements
//         const transformedData = data
//         .filter((item) => item.type === 'Quiz')
//         .map((item) => ({
//           Title: item.topic_title,
//           UnlockedDate: 'N/A', // If no unlocked date provided
//           Status: item.completed ? 'Completed' : 'Not Started',
//           Score: item.score || 'N/A',
//           AttemptedDate: item.completion_date || 'N/A',
//         }));

//         setQuizData(transformedData);
//       } catch (error) {
//         console.error('Error fetching quiz data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchQuizData();
//   }, []);

//   return (
//     <TableContainer
//       component={Paper}
//       elevation={3}
//       sx={{
//         flex: 1,
//         height: 'auto',
//         margin: '20px',
//         maxHeight: 300, // Set max height for scrollable area
//         overflowY: 'auto', // Enable vertical scroll
//         boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//         borderRadius: '15px',
//         scrollbarWidth: 'thin',
//       }}
//     >
//       <Table aria-label="Quiz Assessment Table" stickyHeader>
//         <TableHead sx={{ backgroundColor: 'white' }}>
//           <TableRow>
//             <TableCell
//               colSpan={5}
//               align="start"
//               style={{
//                 fontSize: '21px',
//                 fontWeight: '600',
//                 padding: '15px',
//                 color: '#2c3e50',
//                 fontFamily: 'poppins',
//                 backgroundColor: 'white',
//               }}
//             >
//               Quiz Assessment
//             </TableCell>
//           </TableRow>
//           <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
//             {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//               <TableCell
//                 key={index}
//                 style={{
//                   border: '1px solid #dfe6e9',
//                   fontWeight: 'bold',
//                   padding: '12px',
//                   color: '#34495e',
//                   backgroundColor: 'whitesmoke',
//                 }}
//               >
//                 {col}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {loading ? (
//             <TableRow>
//               <TableCell colSpan={5} align="center">
//                 Loading...
//               </TableCell>
//             </TableRow>
//           ) : quizData.length === 0 ? (
//             <TableRow>
//               <TableCell colSpan={5} align="center">
//                 No data available.
//               </TableCell>
//             </TableRow>
//           ) : (
//             quizData.map((row, index) => (
//               <TableRow key={index}>
//                 {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//                   <TableCell
//                     key={i}
//                     style={{
//                       border: '1px solid #dfe6e9',
//                       fontSize: '13px',
//                       padding: '12px',
//                       color: '#34495e',
//                       maxWidth: '100px',
//                       textOverflow: 'ellipsis',
                   
//                       // whiteSpace: 'nowrap',
//                     }}
//                   >
//                     {col === 'Title' ? (
//                       <Tooltip title={`Details about ${row[col]}`} arrow>
//                         <span>{row[col]}</span>
//                       </Tooltip>
//                     ) : col === 'Status' ? (
//                       <Chip
//                         label={getStatusBadge(row[col]).label}
//                         size="small"
//                         style={{
//                           fontWeight: 'bold',
//                           color: getStatusBadge(row[col]).color,
//                           backgroundColor: getStatusBadge(row[col]).backgroundColor,
//                           borderRadius: '8px',
//                         }}
//                       />
//                     ) : row[col] === 'N/A' ? (
//                       'N/A'
//                     ) : (
//                       row[col]
//                     )}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))
//           )}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default QuizAssessment;



// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';
// import { adminaxios } from '../axios';
// import { useSelector } from 'react-redux';

// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' };
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' };
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' };
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' };
//   }
// };

// const QuizAssessment = () => {
//   const [quizData, setQuizData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const currentcourseId = useSelector((state) => state.dynamicApiId.courseId);
  

//   useEffect(() => {
//     const fetchQuizData = async () => {
//       setLoading(true);
//       try {
//         if (!currentcourseId) {
//           console.error('No courseId found. Please ensure the route includes :id');
//           return;
//         }
//         const response = await adminaxios.get(
//           `/courses/${currentcourseId}/assessment-topics/`
//         );
//         const data = response.data?.assessments || [];

//         // Transform data to fit table requirements
//         const transformedData = data
//           .filter((item) => item.type === 'Quiz')
//           .map((item) => ({
//             Title: item.topic_title,
//             UnlockedDate: 'N/A', // If no unlocked date provided
//             Status: item.completed ? 'Completed' : 'Not Started',
//             Score: item.score || 'N/A',
//             AttemptedDate: item.completion_date || 'N/A',
//           }));

//         setQuizData(transformedData);
//       } catch (error) {
//         console.error('Error fetching quiz data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchQuizData();
//   }, [currentcourseId]); // Dependency on courseId to re-fetch if it changes

//   return (
//     <TableContainer
//       component={Paper}
//       elevation={3}
//       sx={{
//         flex: 1,
//         height: 'auto',
//         margin: '20px',
//         maxHeight: 300, // Set max height for scrollable area
//         scrollbarWidth:'thin',
//         boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//         borderRadius: '15px',
        
//       }}
//     >
//       <Table aria-label="Quiz Assessment Table" stickyHeader>
//         <TableHead sx={{ backgroundColor: 'white' }}>
//           <TableRow>
//             <TableCell
//               colSpan={5}
//               align="start"
//               style={{
//                 fontSize: '21px',
//                 fontWeight: '600',
//                 padding: '15px',
//                 color: '#2c3e50',
//                 fontFamily: 'poppins',
//                 backgroundColor: 'white',
//               }}
//             >
//               Quiz Assessment
//             </TableCell>
//           </TableRow>
//           <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
//             {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//               <TableCell
//                 key={index}
//                 style={{
//                   border: '1px solid #dfe6e9',
//                   fontWeight: 'bold',
//                   padding: '12px',
//                   color: '#34495e',
//                   backgroundColor: 'whitesmoke',
//                 }}
//               >
//                 {col}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {loading ? (
//             <TableRow>
//               <TableCell colSpan={5} align="center">
//                 Loading...
//               </TableCell>
//             </TableRow>
//           ) : quizData.length === 0 ? (
//             <TableRow>
//               <TableCell colSpan={5} align="center">
//                 No data available.
//               </TableCell>
//             </TableRow>
//           ) : (
//             quizData.map((row, index) => (
//               <TableRow key={index}>
//                 {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//                   <TableCell
//                     key={i}
//                     style={{
//                       border: '1px solid #dfe6e9',
//                       fontSize: '13px',
//                       padding: '12px',
//                       color: '#34495e',
//                       maxWidth: '100px',
//                       textOverflow: 'ellipsis',
//                     }}
//                   >
//                     {col === 'Title' ? (
//                       <Tooltip title={`Details about ${row[col]}`} arrow>
//                         <span>{row[col]}</span>
//                       </Tooltip>
//                     ) : col === 'Status' ? (
//                       <Chip
//                         label={getStatusBadge(row[col]).label}
//                         size="small"
//                         style={{
//                           fontWeight: 'bold',
//                           color: getStatusBadge(row[col]).color,
//                           backgroundColor: getStatusBadge(row[col]).backgroundColor,
//                           borderRadius: '8px',
//                         }}
//                       />
//                     ) : row[col] === 'N/A' ? (
//                       'N/A'
//                     ) : (
//                       row[col]
//                     )}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))
//           )}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default QuizAssessment;




// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';
// import { adminaxios } from '../axios';
// import { useSelector } from 'react-redux';
// import LabAssessment from './LabAssessment';

// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' };
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' };
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' };
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' };
//   }
// };

// const QuizAssessment = () => {
//   const [quizData, setQuizData] = useState([]);
//   const [labData, setLabData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const currentcourseId = useSelector((state) => state.dynamicApiId.courseId);

//   useEffect(() => {
//     const fetchAssessmentData = async () => {
//       setLoading(true);
//       try {
//         if (!currentcourseId) {
//           console.error('No courseId found. Please ensure the route includes :id');
//           return;
//         }
//         const response = await adminaxios.get(
//           `/courses/${currentcourseId}/assessment-topics/`
//         );
//         const data = response.data?.assessments || [];

//         // Separate data for quizzes and labs
//         const quizData = data
//           .filter((item) => item.type === 'Quiz')
//           .map((item) => ({
//             Title: item.topic_title,
//             UnlockedDate: 'N/A',
//             Status: item.completed ? 'Completed' : 'Not Started',
//             Score: item.score || 'N/A',
//             AttemptedDate: item.completion_date || 'N/A',
//           }));

//         const labData = data
//           .filter((item) => item.type === 'Lab')
//           .map((item) => ({
//             Title: item.topic_title,
//             UnlockedDate: 'N/A',
//             Status: item.completed ? 'Completed' : 'Not Started',
//             Score: item.score || 'N/A',
//             AttemptedDate: item.completion_date || 'N/A',
//           }));

//         setQuizData(quizData);
//         setLabData(labData);
//       } catch (error) {
//         console.error('Error fetching assessment data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAssessmentData();
//   }, [currentcourseId]);

//   return (
//     <div style={{display:'flex', width:'100%'}}>
//       <TableContainer
//         component={Paper}
//         elevation={3}
//         sx={{
//           flex: 1,
//           height: 'auto',
//           marginLeft: '20px',
//           maxHeight: 300,
//           scrollbarWidth: 'thin',
//           boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//           borderRadius: '15px',
//         }}
//       >
//         <Table aria-label="Quiz Assessment Table" stickyHeader>
//           <TableHead sx={{ backgroundColor: 'white' }}>
//             <TableRow>
//               <TableCell
//                 colSpan={5}
//                 align="start"
//                 style={{
//                   fontSize: '21px',
//                   fontWeight: '600',
//                   padding: '15px',
//                   color: '#2c3e50',
//                   fontFamily: 'poppins',
//                   backgroundColor: 'white',
//                 }}
//               >
//                 Quiz Assessment
//               </TableCell>
//             </TableRow>
//             <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
//               {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//                 <TableCell
//                   key={index}
//                   style={{
//                     border: '1px solid #dfe6e9',
//                     fontWeight: 'bold',
//                     padding: '12px',
//                     color: '#34495e',
//                     backgroundColor: 'whitesmoke',
//                   }}
//                 >
//                   {col}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {loading ? (
//               <TableRow>
//                 <TableCell colSpan={5} align="center">
//                   Loading...
//                 </TableCell>
//               </TableRow>
//             ) : quizData.length === 0 ? (
//               <TableRow>
//                 <TableCell colSpan={5} align="center">
//                   No data available.
//                 </TableCell>
//               </TableRow>
//             ) : (
//               quizData.map((row, index) => (
//                 <TableRow key={index} >
//                   {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//                     <TableCell
//                       key={i}
//                       style={{
//                         border: '1px solid #dfe6e9',
//                         fontSize: '13px',
//                         padding: '12px',
//                         color: '#34495e',
//                         maxWidth: '100px',
//                         textOverflow: 'ellipsis',
//                       }}
//                     >
//                       {col === 'Title' ? (
//                         <Tooltip title={`Details about ${row[col]}`} arrow>
//                           <span>{row[col]}</span>
//                         </Tooltip>
//                       ) : col === 'Status' ? (
//                         <Chip
//                           label={getStatusBadge(row[col]).label}
//                           size="small"
//                           style={{
//                             fontWeight: 'bold',
//                             color: getStatusBadge(row[col]).color,
//                             backgroundColor: getStatusBadge(row[col]).backgroundColor,
//                             borderRadius: '8px',
//                           }}
//                         />
//                       ) : row[col] === 'N/A' ? (
//                         'N/A'
//                       ) : (
//                         row[col]
//                       )}
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               ))
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       {/* Pass labData as prop */}
//       {loading ? null : <LabAssessment labData={labData} loading={loading} />}
//     </div>
//   );
// };

// export default QuizAssessment;


import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip, Skeleton } from "@mui/material";


const getStatusBadge = (status) => {
  switch (status) {
    case "Completed":
      return { label: "Completed", color: "rgba(19,197,107,1)", backgroundColor: "#dcf6e9" };
    case "In Progress":
      return { label: "In Progress", color: "rgba(232, 188, 82, 1)", backgroundColor: "#fcf5ef" };
    case "Not Started":
      return { label: "Not Started", color: "#ff4500", backgroundColor: "#fce7e7" };
    default:
      return { label: "Unknown", color: "#546e7a", backgroundColor: "#f0f8ff" };
  }
};

const QuizAssessment = ({ quizData, loading }) => {
  return (
    <div style={{ display: "flex", gap: "20px", width: "97%", marginLeft:'20px' }}>
      {/* Quiz Assessment Table */}
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          flex: 1,
          height: "auto",
          maxHeight: 300,
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
        }}
      >
        <Table aria-label="Quiz Assessment Table" stickyHeader>
          <TableHead sx={{ backgroundColor: "white" }}>
            <TableRow>
              <TableCell
                colSpan={5}
                align="start"
                style={{
                  fontSize: "21px",
                  fontWeight: "600",
                  padding: "15px",
                  color: "#2c3e50",
                  fontFamily: "poppins",
                  backgroundColor: "white",
                }}
              >
                Quiz Assessment
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: "whitesmoke" }}>
              {["Title", "UnlockedDate", "Status", "Score", "AttemptedDate"].map((col, index) => (
                <TableCell
                  key={index}
                  style={{
                    border: "1px solid #dfe6e9",
                    fontWeight: "bold",
                    padding: "12px",
                    color: "#34495e",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Skeleton variant="text" width="100%" />
                </TableCell>
              </TableRow>
            ) : quizData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Quiz Data Available
                </TableCell>
              </TableRow>
            ) : (
              quizData.map((row, index) => (
                <TableRow key={index}>
                  {["Title", "UnlockedDate", "Status", "Score", "AttemptedDate"].map((col, i) => (
                    <TableCell
                      key={i}
                      style={{
                        border: "1px solid #dfe6e9",
                        fontSize: "13px",
                        padding: "12px",
                        color: "#34495e",
                        maxWidth: "100px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {col === "Title" ? (
                        <Tooltip title={`Details about ${row[col]}`} arrow>
                          <span>{row[col]}</span>
                        </Tooltip>
                      ) : col === "Status" ? (
                        <Chip
                          label={getStatusBadge(row[col]).label}
                          size="small"
                          style={{
                            fontWeight: "bold",
                            color: getStatusBadge(row[col]).color,
                            backgroundColor: getStatusBadge(row[col]).backgroundColor,
                            borderRadius: "8px",
                          }}
                        />
                      ) : row[col] === "N/A" ? (
                        "N/A"
                      ) : (
                        row[col]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Lab Assessment Table */}
    
    </div>
  );
};

export default QuizAssessment;
