// src/components/skeletons/CourseCardSkeleton.js

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS

const CourseCardSkeleton = () => {
  return (
    <div className="course-card" style={{ marginTop: '0' }}> {/* Removed margin-top for the card */}
      <div className="course-first-half">
        <header className="course-header">
          <div className='course-details-page-back-button'>
            <Skeleton height={20} width={80} />
            <Skeleton height={20} width={80} style={{ marginLeft: '10px' }} />
          </div>
        </header>

        <div className="course-content">
          <div className="course-main">
            <div className="course-title-section">
              <div className='course-title-logo'>
                <Skeleton circle={true} height={45} width={45} /> {/* Decreased circle size */}
                <Skeleton height={15} width={160} style={{ marginLeft: '10px'}} /> {/* Reduced width */}
              </div>
              <div className='Course-details-paragraph'>
                <Skeleton height={10} count={2} />
              </div>
              <div className="course-tagss">
                <Skeleton height={32} width={100} style={{ marginRight: '15px', border: "none", borderRadius: "5px" }} />
                <Skeleton height={32} width={100} style={{ marginRight: '15px', border: "none", borderRadius: "5px" }} />
                <Skeleton height={32} width={100} style={{ marginRight: '15px', border: "none", borderRadius: "5px" }} />
              </div>
            </div>
          </div>

          <div className="course-details">
            <h3>
              <Skeleton height={18} width={100} />
            </h3>
            <div className="details">
              <Skeleton height={14} width={110} style={{ marginRight: '8px' }} />
              <Skeleton height={14} width={110} style={{ marginRight: '8px' }} />
              <Skeleton height={14} width={110} style={{ marginRight: '8px' }} />
            </div>
            <div className='resume-button-progress'>
              <div className="course-progress">
                <label>
                  <Skeleton height={14} width={90} /> {/* Reduced height */}
                </label>
                <Skeleton height={14} width={'100%'} />
                <Skeleton height={18} width={60} /> {/* Reduced height */}
              </div>
              <Skeleton height={28} width={100} /> {/* Reduced height */}
            </div>
          </div>
        </div>
      </div>

      {/* Right-side card (course-management) */}
      <div className="course-management" style={{ marginTop: '0' }}> {/* Removed margin-top */}
        <Skeleton height={18} width={'100%'} /> {/* Reduced height */}
        <Skeleton height={18} width={'100%'} style={{ marginTop: '2px' }} /> {/* Reduced height */}
        <Skeleton height={18} width={'100%'} style={{ marginTop: '2px' }} /> {/* Reduced height */}
      </div>
    </div>
  );
};

export default CourseCardSkeleton;
