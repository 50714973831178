// import React, { useState, useEffect } from 'react';
// import '../assets/Css/HeaderAssessment.css';
// import { useNavigate, useLocation} from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { adminaxios } from '../axios/index';
 
// const HeaderAssessment = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [timeLeft, setTimeLeft] = useState(10);
//   const storeCourseId = useSelector((state)=>state.dynamicApiId.courseId);
//   console.log('localStorageCourseId',storeCourseId);
//   const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
//   const quizDataLists = location.state?.quizData?.question_bank_ids || [];
//   console.log(quizDataLists, "quizDataLists")
//   const [selectedAnswers, setSelectedAnswers] = useState(
//     quizDataLists.map(questionId => ({
//       question_id: questionId,
//       selected_option: null // Start with no answer selected
//     }))
//   );
//   const [loading, setLoading] = useState(false);
//   const [unattemptedQuestionNumbers, setUnattemptedQuestionNumbers] = useState([]);
//   const [showUnattemptedPopup, setShowUnattemptedPopup] = useState(false);
 
//   const moduleId = "66ed3f2efa095c767878c349";// Example module ID
//   const topicId = "66ed3f2efa095c767878c347"; // Example topic ID
//   const quizId = "66ed3f2efa095c767878c348"; // Example quiz ID
 
//   const storedAnswers = JSON.parse(localStorage.getItem("selectedAnswers")) || [];
 
//   console.log(storedAnswers,"storedAnswers")
 
//   // Timer to track remaining time
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft((prevTime) => {
//         if (prevTime <= 1) {
//           clearInterval(timer);
//           setShowTimeoutPopup(true);
//           return 0;
//         }
//         return prevTime - 1;
//       });
//     }, 1000);
 
//     return () => clearInterval(timer);
//   }, []);
 
//   // Handle answer selection
//   const handleAnswerSelect = (selectedAnswer, questionIndex) => {
//     const updatedAnswers = [...selectedAnswers];
 
//     updatedAnswers[questionIndex] = {
//       question_id: quizDataLists[questionIndex],
//       selected_option: selectedAnswer || null // Store selected option
//     };
 
//     setSelectedAnswers(updatedAnswers);
//     console.log("Selected Answers: ", updatedAnswers);
//   };
 
//   // Submit the quiz results to the API
//   const handleQuizSubmit = async () => {
//     setLoading(true);
 
 
//     const payload = {
//       quiz_id: quizId,
//       module_id: moduleId,
//       topic_id: topicId,
//       total_questions: quizDataLists.length,
//       submitted_answers: storedAnswers // Only submit answered questions
//     };
 
//     console.log(payload, "payload")
 
//     // const getToken = () => {
//     //   return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI2NzY0NjY1LCJpYXQiOjE3MjY3MjE0NjUsImp0aSI6IjViYjg1ZGYxZTdmMzQwNjY4NzNiZGM4ZWZjM2IwNjMxIiwidXNlcl9pZCI6MSwidXNlcm5hbWUiOiJsbXMxIiwicm9sZSI6IkFkbWluIn0.VDFy8OvEfFa1OPNNOgHqVqjvWtjHaOUZYLQz1jwp5gA'; // Replace with your actual token logic
//     // };
 
//     try {
//       const response = await adminaxios.post('/submit_quiz/', payload, {
//         // headers: {
//         //   Authorization: `Bearer ${getToken()}`,
//         // },
//       });
 
//       const quizResults = response.data;
//       console.log('Submission successful', quizResults);
 
//       // Navigate to the results page with quiz results
//       navigate(`/timedquiz-results/${storeCourseId}`, { state: { quizResults, quizDataLists } });
 
//     } catch (error) {
//       console.error("Error submitting quiz", error);
//     } finally {
//       setLoading(false);
//     }
//   };
 
//   // Handle quiz submission logic with unattempted questions check
//   const handleSubmit = () => {
//     setLoading(true);
//     const unattemptedQuestions = selectedAnswers
//       .map((answer, index) => (answer.selectedAnswer === null || answer.selectedAnswer === "") ? index + 1 : null)
//       .filter(index => index !== null); // Get unattempted question numbers
 
//     setUnattemptedQuestionNumbers(unattemptedQuestions);
 
//     if (unattemptedQuestions.length > 0) {
//       // Show unattempted questions popup if any questions are not answered
//       setShowUnattemptedPopup(true);
//       setLoading(false);
//     } else {
//       // If all questions are attempted, proceed to submit the quiz
//       handleQuizSubmit();
//     }
//   };
//   const ProgressCircle = ({ progress }) => {
//     const radius = 20;
//     const circumference = 2 * Math.PI * radius;
//     const offset = circumference - ((100 - progress) / 100) * circumference;
 
//     return (
//       <svg className="assessmentheader-progress-circle" width="50" height="50">
//         {/* Background circle */}
//         <circle
//           className="assessmentheader-progress-background"
//           cx="25"
//           cy="25"
//           r={radius}
//           strokeWidth="5"
//           stroke="#f0f0f0"
//           fill="none"
//         />
//         {/* Progress value circle */}
//         <circle
//           className="assessmentheader-progress-value"
//           cx="25"
//           cy="25"
//           r={radius}
//           strokeWidth="6"
//           stroke={timeLeft > 0 ? "#4caf50" : "#0000FF"}
//           fill="none"
//           strokeDasharray={circumference}
//           strokeDashoffset={offset}
//         />
//         {/* Timer text */}
//         <text x="50%" y="40%" textAnchor="middle" dy=".3em" className="assessmentheader-progress-text">
//           {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`}
//         </text>
//         {/* 'left' text */}
//         <text x="50%" y="60%" textAnchor="middle" dy=".3em" className="assessmentheader-progress-text" fontSize="2">
//           left
//         </text>
//       </svg>
//     );
//   };
 
 
 
//   const progress = (timeLeft / 10) * 100; // Calculate progress based on time left
 
//   return (
//     <div className="assessmentheader-container">
//       <div className="assessmentheader-left">
//         <h1 className="assessmentheader-title">Assessment: Module 1</h1>
//       </div>
//       <div className="assessmentheader-right">
//         <ProgressCircle progress={progress} />
//       </div>
//       {/* Timeout Popup */}
//       {showTimeoutPopup && (
//         <div className="assessmentheader-popup-container">
//           <div className="assessmentheadertimeout-popup">
//             <div className="assessmentheadertimeout-heading">
//               <p>Assessment Time Limit Reached!</p>
//             </div>
//             <div className="assessmentheadertimeout-message">
//               <p>Your responses have been recorded, and the quiz will now be submitted automatically.</p>
//             </div>
//             <div className="assessmentheadertimeout-buttons">
//               <button className="assessmentheadertimeout-submit-button" onClick={handleSubmit}>
//                 Submit & View Results
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
 
//       {/* Unattempted Questions Popup */}
//       {showUnattemptedPopup && (
//         <div className="assessmentheader-popup-container">
//           <div className="assessmentheader-unattempted-popup">
//             <p>The following questions are unattempted: {unattemptedQuestionNumbers.join(', ')}</p>
//             <button onClick={handleQuizSubmit}>Continue & Submit</button>
//           </div>
//         </div>
//       )}
 
//       {/* Rest of your quiz UI */}
//     </div>
//   );
// };
 
// export default HeaderAssessment;
 

import React, { useState, useEffect, useRef } from 'react';
import '../assets/Css/HeaderAssessment.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { adminaxios } from '../axios/index';

const HeaderAssessment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
  const [timeLeft, setTimeLeft] = useState(0); // Set time initially to 0
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
  const quizDataLists = location.state?.quizData?.question_bank_ids || [];
  const [selectedAnswers, setSelectedAnswers] = useState(
    quizDataLists.map((questionId) => ({
      question_id: questionId,
      selected_option: null, // Start with no answer selected
    }))
  );
  const [loading, setLoading] = useState(true); // Set loading initially to true
  const [unattemptedQuestionNumbers, setUnattemptedQuestionNumbers] = useState([]);
  const [showUnattemptedPopup, setShowUnattemptedPopup] = useState(false);

  const timerRef = useRef(null); // useRef to store timer ID
  const moduleId = "66ed3f2efa095c767878c349"; // Example module ID
  const topicId = "66ed3f2efa095c767878c347"; // Example topic ID
  const quizId = "66ed3f2efa095c767878c348"; // Example quiz ID

  const storedAnswers = JSON.parse(localStorage.getItem("selectedAnswers")) || [];

  // Fetch quiz data from backend to get time limit
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        // Fetch the quiz data from backend, including time limit
        const response = await adminaxios.get('/modules/66ed3f2efa095c767878c349/topics/66ed3f2efa095c767878c347/question_bank_config/66ed3f2efa095c767878c348/');
        const quizData = response.data;

        // Check if backend provided time limit, else use fallback (1 minute per question)
        const timeLimit = quizData.time_limit_min || quizDataLists.length; // 1 min per question if no time_limit_min is provided
        setTimeLeft(timeLimit * 60); // Set timeLeft in seconds

        setLoading(false); // Stop loading after fetching the data
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        // Fallback if API fails
        const fallbackTime = quizDataLists.length; // 1 minute per question
        setTimeLeft(fallbackTime * 60); // Set timeLeft in seconds based on questions count
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [quizId, quizDataLists.length]);

  // Timer to track remaining time (starts after data is loaded)
  useEffect(() => {
    if (loading || timeLeft === 0) return; // Don't start timer until loading is done

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current); // Stop timer when time reaches 0
          setShowTimeoutPopup(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current); // Clear timer on unmount
  }, [loading, timeLeft]);

  // Handle answer selection
  const handleAnswerSelect = (selectedAnswer, questionIndex) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[questionIndex] = {
      question_id: quizDataLists[questionIndex],
      selected_option: selectedAnswer || null, // Store selected option
    };
    setSelectedAnswers(updatedAnswers);
  };

  // Submit the quiz results to the API and stop the timer
  const handleQuizSubmit = async () => {
    clearInterval(timerRef.current); // Stop the timer when submitting
    setLoading(true);

    const payload = {
      quiz_id: quizId,
      module_id: moduleId,
      topic_id: topicId,
      total_questions: quizDataLists.length,
      submitted_answers: storedAnswers, // Only submit answered questions
    };

    try {
      const response = await adminaxios.post('/submit_quiz/', payload);
      const quizResults = response.data;

      // Navigate to the results page with quiz results
      navigate(`/timedquiz-results/${storeCourseId}`, { state: { quizResults, quizDataLists } });
    } catch (error) {
      console.error("Error submitting quiz", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const unattemptedQuestions = selectedAnswers
      .map((answer, index) => (answer.selectedAnswer === null || answer.selectedAnswer === "") ? index + 1 : null)
      .filter((index) => index !== null); // Get unattempted question numbers

    setUnattemptedQuestionNumbers(unattemptedQuestions);

    if (unattemptedQuestions.length > 0) {
      setShowUnattemptedPopup(true);
    } else {
      handleQuizSubmit(); // Stop timer and submit quiz
    }
  };

  const ProgressCircle = ({ progress }) => {
    const radius = 20;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - ((100 - progress) / 100) * circumference;

    return (
      <svg className="assessmentheader-progress-circle" width="50" height="50">
        <circle
          className="assessmentheader-progress-background"
          cx="25"
          cy="25"
          r={radius}
          strokeWidth="5"
          stroke="#f0f0f0"
          fill="none"
        />
        <circle
          className="assessmentheader-progress-value"
          cx="25"
          cy="25"
          r={radius}
          strokeWidth="6"
          stroke={timeLeft > 0 ? "#4caf50" : "#0000FF"}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text x="50%" y="40%" textAnchor="middle" dy=".3em" className="assessmentheader-progress-text">
          {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`}
        </text>
        <text x="50%" y="60%" textAnchor="middle" dy=".3em" className="assessmentheader-progress-text" fontSize="2">
          left
        </text>
      </svg>
    );
  };

  const progress = (timeLeft / (quizDataLists.length * 60)) * 100;

  return (
    <div className="assessmentheader-container">
      <div className="assessmentheader-left">
        <h1 className="assessmentheader-title">Assessment: Module 1</h1>
      </div>
      <div className="assessmentheader-right">
        <ProgressCircle progress={progress} />
      </div>
      {showTimeoutPopup && (
        <div className="assessmentheader-popup-container">
          <div className="assessmentheadertimeout-popup">
            <div className="assessmentheadertimeout-heading">
              <p>Assessment Time Limit Reached!</p>
            </div>
            <div className="assessmentheadertimeout-message">
              <p>Your responses have been recorded, and the quiz will now be submitted automatically.</p>
            </div>
            <div className="assessmentheadertimeout-buttons">
              <button className="assessmentheadertimeout-submit-button" onClick={handleSubmit}>
                Submit & View Results
              </button>
            </div>
          </div>
        </div>
      )}
      {showUnattemptedPopup && (
        <div className="assessmentheader-popup-container">
          <div className="assessmentheader-unattempted-popup">
            <p>The following questions are unattempted: {unattemptedQuestionNumbers.join(', ')}</p>
            <button onClick={handleQuizSubmit}>Continue & Submit</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderAssessment;
