// import React from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';

// // Lab data specific to the LabAssessment component
// const labData = [
//   { Title: "Chemistry Lab - Organic Compounds", UnlockedDate: "01/01/2024", Status: "Completed", Score: "95%", AttemptedDate: "01/10/2024" },
//   { Title: "Physics Lab - Mechanics", UnlockedDate: "01/02/2024", Status: "In Progress", Score: "85%", AttemptedDate: "02/10/2024" },
//   { Title: "Biology Lab - Cell Structure", UnlockedDate: "01/03/2024", Status: "Not Started", Score: "N/A", AttemptedDate: "N/A" }
// ];

// // const getStatusBadge = (status) => {
// //   switch (status) {
// //     case 'Completed':
// //       return { label: 'Completed', color: 'success' };
// //     case 'In Progress':
// //       return { label: 'In Progress', color: 'warning' };
// //     case 'Not Started':
// //       return { label: 'Not Started', color: 'error' };
// //     default:
// //       return { label: 'Unknown', color: 'default' };
// //   }
// // };

// // const getStatusBadge = (status) => {
// //     switch (status) {
// //       case 'Completed':
// //         return { label: 'Completed', color: '#388e3c', backgroundColor: 'rgb(224 240 225)' }; // Green text, light green background
// //       case 'In Progress':
// //         return { label: 'In Progress', color: '#f57c00', backgroundColor: 'rgb(247 238 228)' }; // Orange text, light orange background
// //       case 'Not Started':
// //         return { label: 'Not Started', color: '#d32f2f', backgroundColor: 'rgb(251 237 237)' }; // Red text, light red background
// //       default:
// //         return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' }; // Grey text, light grey background
// //     }
// //   };
// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' }; // Green text, light green background
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' }; // Orange text, light orange background
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' }; // Red text, light red background
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' }; // Grey text, light grey background
//   }
// };


// const LabAssessment = () => (
//   <TableContainer component={Paper} sx={{
//     flex: 1, height: 'auto', margin: '20px', boxShadow: '0px 6px 15px rgba(0,0,0,0.1)', borderRadius: '0px', overflow: 'hidden'
//   }}>
//     <Table aria-label="Lab Assessment Table">
//       <TableHead sx={{ backgroundColor: 'whitesmoke' }}>
//         <TableRow>
//           <TableCell colSpan={5} align="start" style={{
//             fontSize: '24px', fontWeight: 'bold', padding: '15px', color: '#2c3e50'
//           }}>
//             Lab Assessment
//           </TableCell>
//         </TableRow>
//         <TableRow sx={{ backgroundColor: '#b0c4de' }}>
//           {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//             <TableCell key={index} style={{
//               border: '1px solid #dfe6e9', fontWeight: 'bold', padding: '12px', color: '#34495e'
//             }}>
//               {col}
//             </TableCell>
//           ))}
//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {labData.map((row, index) => (
//           <TableRow key={index}>
//             {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//               <TableCell
//                 key={i}
//                 style={{
//                   border: '1px solid #dfe6e9',
//                   padding: '12px',
//                   color: '#34495e',
//                   maxWidth: '100px',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 {col === 'Title' ? (
//                   <Tooltip title={`Details about ${row[col]}`} arrow>
//                     <span>{row[col]}</span>
//                   </Tooltip>
//                 ) : col === 'Status' ? (
//                   <Chip
//                     label={getStatusBadge(row[col]).label}
//                     size="small"
//                     style={{
//                       fontWeight: 'bold',
//                       color: getStatusBadge(row[col]).color, // Text color
//                       backgroundColor: getStatusBadge(row[col]).backgroundColor, // Lighter background color
//                       borderRadius: '8px' // Remove border radius
//                     }}
//                   />
//                 ) : row[col] === 'N/A' ? (
//                   'N/A'
//                 ) : (
//                   row[col]
//                 )}
//               </TableCell>
//             ))}
//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//   </TableContainer>
// );

// export default LabAssessment;




// import React from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';

// const labData = [
//   { Title: "Chemistry Lab - Organic Compounds", UnlockedDate: "01/01/2024", Status: "Completed", Score: "95%", AttemptedDate: "01/10/2024" },
//   { Title: "Physics Lab - Mechanics", UnlockedDate: "01/02/2024", Status: "In Progress", Score: "85%", AttemptedDate: "02/10/2024" },
//   { Title: "Biology Lab - Cell Structure", UnlockedDate: "01/03/2024", Status: "Not Started", Score: "N/A", AttemptedDate: "N/A" }
// ];

// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' };
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' };
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' };
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' };
//   }
// };

// const LabAssessment = () => (
//   <TableContainer
//     component={Paper}
//     elevation={4} // Increase elevation for shadow effect
//     sx={{
//       flex: 1,
//       height: 'auto',
//       margin: '20px',
//       // boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)', // Custom shadow for deeper effect
//       boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',

//       borderRadius: '15px', // Add rounded corners for a softer look
//       overflow: 'hidden',
//     }}
//   >
//     <Table aria-label="Lab Assessment Table">
//       <TableHead sx={{ backgroundColor: 'white' }}>
//         <TableRow>
//           <TableCell colSpan={5} align="start" style={{
//             fontSize: '21px', fontWeight: '600', padding: '15px', color: '#2c3e50',fontFamily:'poppins'
//           }}>
//             Lab Assessment
//           </TableCell>
//         </TableRow>
//         <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
//           {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//             <TableCell key={index} style={{
//               border: '1px solid #dfe6e9', fontWeight: 'bold', padding: '12px', color: '#34495e'
//             }}>
//               {col}
//             </TableCell>
//           ))}
//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {labData.map((row, index) => (
//           <TableRow key={index}>
//             {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//               <TableCell
//                 key={i}
//                 style={{
//                   border: '1px solid #dfe6e9',
//                   fontSize:'13px',
//                   padding: '12px',
//                   color: '#34495e',
//                   maxWidth: '100px',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 {col === 'Title' ? (
//                   <Tooltip title={`Details about ${row[col]}`} arrow>
//                     <span>{row[col]}</span>
//                   </Tooltip>
//                 ) : col === 'Status' ? (
//                   <Chip
//                     label={getStatusBadge(row[col]).label}
//                     size="small"
//                     style={{
//                       fontWeight: 'bold',
//                       color: getStatusBadge(row[col]).color,
//                       backgroundColor: getStatusBadge(row[col]).backgroundColor,
//                       borderRadius: '8px'
//                     }}
//                   />
//                 ) : row[col] === 'N/A' ? (
//                   'N/A'
//                 ) : (
//                   row[col]
//                 )}
//               </TableCell>
//             ))}
//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//   </TableContainer>
// );

// export default LabAssessment;



// import React from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';

// const labData = [
//   { Title: "Chemistry Lab - Organic Compounds", UnlockedDate: "01/01/2024", Status: "Completed", Score: "95%", AttemptedDate: "01/10/2024" },
//   { Title: "Physics Lab - Mechanics", UnlockedDate: "01/02/2024", Status: "In Progress", Score: "85%", AttemptedDate: "02/10/2024" },
//   { Title: "Biology Lab - Cell Structure", UnlockedDate: "01/03/2024", Status: "Not Started", Score: "N/A", AttemptedDate: "N/A" },
//   { Title: "Chemistry Lab - Organic Compounds", UnlockedDate: "01/01/2024", Status: "Completed", Score: "95%", AttemptedDate: "01/10/2024" },
//   { Title: "Physics Lab - Mechanics", UnlockedDate: "01/02/2024", Status: "In Progress", Score: "85%", AttemptedDate: "02/10/2024" },
//   { Title: "Biology Lab - Cell Structure", UnlockedDate: "01/03/2024", Status: "Not Started", Score: "N/A", AttemptedDate: "N/A" },
// ];

// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' };
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' };
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' };
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' };
//   }
// };

// const LabAssessment = () => (
//   <TableContainer
//     component={Paper}
//     elevation={4}
//     sx={{
//       flex: 1,
//       margin: '20px',
//       maxHeight: 300,  // Set max height to enable scrolling
//       overflowY: 'auto',  // Enable vertical scroll
//       boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//       borderRadius: '15px',
//       scrollbarWidth:'thin',
//     }}
//   >
//     <Table aria-label="Lab Assessment Table" stickyHeader> {/* stickyHeader to keep headers visible during scroll */}
//       <TableHead sx={{ backgroundColor: 'white' }}>
//         <TableRow>
//           <TableCell colSpan={5} align="start" style={{
//             fontSize: '21px', fontWeight: '600', padding: '15px', color: '#2c3e50', fontFamily: 'poppins',backgroundColor: 'white'
//           }}>
//             Lab Assessment
//           </TableCell>
//         </TableRow>
//         <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
//           {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//             <TableCell key={index} style={{
//               border: '1px solid #dfe6e9', fontWeight: 'bold', padding: '12px', color: '#34495e',backgroundColor: 'whitesmoke'
//             }}>
//               {col}
//             </TableCell>
//           ))}
//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {labData.map((row, index) => (
//           <TableRow key={index}>
//             {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//               <TableCell
//                 key={i}
//                 style={{
//                   border: '1px solid #dfe6e9',
//                   fontSize: '13px',
//                   padding: '12px',
//                   color: '#34495e',
//                   maxWidth: '100px',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   whiteSpace: 'nowrap',
//                 }}
//               >
//                 {col === 'Title' ? (
//                   <Tooltip title={`Details about ${row[col]}`} arrow>
//                     <span>{row[col]}</span>
//                   </Tooltip>
//                 ) : col === 'Status' ? (
//                   <Chip
//                     label={getStatusBadge(row[col]).label}
//                     size="small"
//                     style={{
//                       fontWeight: 'bold',
//                       color: getStatusBadge(row[col]).color,
//                       backgroundColor: getStatusBadge(row[col]).backgroundColor,
//                       borderRadius: '8px'
//                     }}
//                   />
//                 ) : row[col] === 'N/A' ? (
//                   'N/A'
//                 ) : (
//                   row[col]
//                 )}
//               </TableCell>
//             ))}
//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//   </TableContainer>
// );

// export default LabAssessment;



// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';
// import { adminaxios } from '../axios';
// import { useSelector } from 'react-redux';

// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' };
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' };
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' };
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' };
//   }
// };

// const LabAssessment = () => {
//   const [labData, setLabData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const currentcourseId = useSelector((state) => state.dynamicApiId.courseId);


//   useEffect(() => {
//     const fetchLabData = async () => {
//       setLoading(true);
//       try {
//         if (!currentcourseId) {
//           console.error('No courseId found. Please check the route configuration.');
//           return;
//         }
  
//         const response = await adminaxios.get(
//           `/courses/${currentcourseId}/assessment-topics/`
//         );
//         const data = response.data?.assessments || [];

//         // Transform data to fit table requirements
//         const transformData = data
//           .filter((item) => item.type === 'Lab')
//           .map((item) => ({
//             Title: item.topic_title,
//             UnlockedDate: 'N/A', // If no unlocked date provided
//             Status: item.completed ? 'Completed' : 'Not Started',
//             Score: item.score || 'N/A',
//             AttemptedDate: item.completion_date || 'N/A',
//           }));
//         setLabData(transformData);
//       } catch (error) {
//         console.error('Error fetching lab data:', error);
//       }finally {
//         setLoading(false);
//       }
//     };
  
//     fetchLabData();
//   }, [currentcourseId]);
  


//   return (
//     <TableContainer
//       component={Paper}
//       elevation={4}
//       sx={{
//         flex: 1,
//         margin: '20px',
//         maxHeight: 300, // Enable scrolling
//         overflowY: 'auto',
//         boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//         borderRadius: '15px',
//         scrollbarWidth: 'thin',
//       }}
//     >
//       <Table aria-label="Lab Assessment Table" stickyHeader>
//         <TableHead sx={{ backgroundColor: 'white' }}>
//           <TableRow>
//             <TableCell
//               colSpan={5}
//               align="start"
//               style={{
//                 fontSize: '21px',
//                 fontWeight: '600',
//                 padding: '15px',
//                 color: '#2c3e50',
//                 fontFamily: 'poppins',
//                 backgroundColor: 'white',
//               }}
//             >
//               Lab Assessment
//             </TableCell>
//           </TableRow>
//           <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
//             {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//               <TableCell
//                 key={index}
//                 style={{
//                   border: '1px solid #dfe6e9',
//                   fontWeight: 'bold',
//                   padding: '12px',
//                   color: '#34495e',
//                   backgroundColor: 'whitesmoke',
//                 }}
//               >
//                 {col}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {labData.length === 0 ? (
//             <TableRow>
//               <TableCell colSpan={5} align="center">
//                 No Lab Assessments Available.
//               </TableCell>
//             </TableRow>
//           ) : (
//             labData.map((row, index) => (
//               <TableRow key={index}>
//                 {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//                   <TableCell
//                     key={i}
//                     style={{
//                       border: '1px solid #dfe6e9',
//                       fontSize: '13px',
//                       padding: '12px',
//                       color: '#34495e',
//                       maxWidth: '100px',
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
                      
//                     }}
//                   >
//                     {col === 'Title' ? (
//                       <Tooltip title={`Details about ${row[col]}`} arrow>
//                         <span>{row[col]}</span>
//                       </Tooltip>
//                     ) : col === 'Status' ? (
//                       <Chip
//                         label={getStatusBadge(row[col]).label}
//                         size="small"
//                         style={{
//                           fontWeight: 'bold',
//                           color: getStatusBadge(row[col]).color,
//                           backgroundColor: getStatusBadge(row[col]).backgroundColor,
//                           borderRadius: '8px',
//                         }}
//                       />
//                     ) : row[col] === 'N/A' ? (
//                       'N/A'
//                     ) : (
//                       row[col]
//                     )}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))
//           )}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default LabAssessment;

// import React from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@mui/material';

// const getStatusBadge = (status) => {
//   switch (status) {
//     case 'Completed':
//       return { label: 'Completed', color: 'rgba(19,197,107,1)', backgroundColor: '#dcf6e9' };
//     case 'In Progress':
//       return { label: 'In Progress', color: 'rgba(232, 188, 82, 1)', backgroundColor: '#fcf5ef' };
//     case 'Not Started':
//       return { label: 'Not Started', color: '#ff4500', backgroundColor: '#fce7e7' };
//     default:
//       return { label: 'Unknown', color: '#546e7a', backgroundColor: '#f0f8ff' };
//   }
// };

// const LabAssessment = ({ labData = [], loading }) => (
//   <TableContainer
//     component={Paper}
//     elevation={4}
//     sx={{
//       flex: 1,
//       marginLeft: '20px',
//       marginRight: '15px',
//       maxHeight: 300,
//       overflowY: 'auto',
//       boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//       borderRadius: '15px',
//       scrollbarWidth: 'thin',
//     }}
//   >
//     <Table aria-label="Lab Assessment Table" stickyHeader>
//       <TableHead sx={{ backgroundColor: 'white' }}>
//         <TableRow>
//           <TableCell
//             colSpan={5}
//             align="start"
//             style={{
//               fontSize: '21px',
//               fontWeight: '600',
//               padding: '15px',
//               color: '#2c3e50',
//               fontFamily: 'poppins',
//               backgroundColor: 'white',
//             }}
//           >
//             Lab Assessment
//           </TableCell>
//         </TableRow>
//         <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
//           {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, index) => (
//             <TableCell
//               key={index}
//               style={{
//                 border: '1px solid #dfe6e9',
//                 fontWeight: 'bold',
//                 padding: '12px',
//                 color: '#34495e',
//                 backgroundColor: 'whitesmoke',
//               }}
//             >
//               {col}
//             </TableCell>
//           ))}
//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {labData.length === 0 ? (
//           <TableRow>
//             <TableCell colSpan={5} align="center">
//               No Lab Assessments Available.
//             </TableCell>
//           </TableRow>
//         ) : (
//           labData.map((row, index) => (
//             <TableRow key={index}>
//               {['Title', 'UnlockedDate', 'Status', 'Score', 'AttemptedDate'].map((col, i) => (
//                 <TableCell
//                   key={i}
//                   style={{
//                     border: '1px solid #dfe6e9',
//                     fontSize: '13px',
//                     padding: '12px',
//                     color: '#34495e',
//                     maxWidth: '100px',
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                   }}
//                 >
//                   {col === 'Title' ? (
//                     <Tooltip title={`Details about ${row[col]}`} arrow>
//                       <span>{row[col]}</span>
//                     </Tooltip>
//                   ) : col === 'Status' ? (
//                     <Chip
//                       label={getStatusBadge(row[col]).label}
//                       size="small"
//                       style={{
//                         fontWeight: 'bold',
//                         color: getStatusBadge(row[col]).color,
//                         backgroundColor: getStatusBadge(row[col]).backgroundColor,
//                         borderRadius: '8px',
//                       }}
//                     />
//                   ) : row[col] === 'N/A' ? (
//                     'N/A'
//                   ) : (
//                     row[col]
//                   )}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))
//         )}
//       </TableBody>
//     </Table>
//   </TableContainer>
// );

// export default LabAssessment;

import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton,Chip, Tooltip } from "@mui/material";

const getStatusBadge = (status) => {
  switch (status) {
    case "Completed":
      return { label: "Completed", color: "rgba(19,197,107,1)", backgroundColor: "#dcf6e9" };
    case "In Progress":
      return { label: "In Progress", color: "rgba(232, 188, 82, 1)", backgroundColor: "#fcf5ef" };
    case "Not Started":
      return { label: "Not Started", color: "#ff4500", backgroundColor: "#fce7e7" };
    default:
      return { label: "Unknown", color: "#546e7a", backgroundColor: "#f0f8ff" };
  }
};

const LabAssessment = ({ labData = [], loading }) => {
  return (
    <div style={{ display: "flex", gap: "20px", width: "98%"}}>
    <TableContainer
      component={Paper}
      elevation={3}
      sx={{
        flex: 1,
        height: "auto",
        maxHeight: 300,
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "15px",
      }}
    >
      <Table aria-label="Lab Assessment Table" stickyHeader>
        <TableHead sx={{ backgroundColor: "white" }}>
          <TableRow>
            <TableCell
              colSpan={5}
              align="start"
              style={{
                fontSize: "21px",
                fontWeight: "600",
                padding: "15px",
                color: "#2c3e50",
                fontFamily: "poppins",
                backgroundColor: "white",
              }}
            >
              Lab Assessment
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: "whitesmoke" }}>
            {["Title", "UnlockedDate", "Status", "Score", "AttemptedDate"].map((col, index) => (
              <TableCell
                key={index}
                style={{
                  border: "1px solid #dfe6e9",
                  fontWeight: "bold",
                  padding: "12px",
                  color: "#34495e",
                  backgroundColor: "whitesmoke",
                }}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Skeleton variant="text" width="100%" />
              </TableCell>
            </TableRow>
          ) : labData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No Lab Data Available
              </TableCell>
            </TableRow>
          ) : (
            labData.map((row, index) => (
              <TableRow key={index}>
                {["Title", "UnlockedDate", "Status", "Score", "AttemptedDate"].map((col, i) => (
                  <TableCell
                    key={i}
                    style={{
                      border: "1px solid #dfe6e9",
                      fontSize: "13px",
                      padding: "12px",
                      color: "#34495e",
                      maxWidth: "100px",
                      textOverflow: "ellipsis",
                    }}
                  >
                      {col === "Title" ? (
                        <Tooltip title={`Details about ${row[col]}`} arrow>
                          <span>{row[col]}</span>
                        </Tooltip>
                      ) : col === "Status" ? (
                        <Chip
                          label={getStatusBadge(row[col]).label}
                          size="small"
                          style={{
                            fontWeight: "bold",
                            color: getStatusBadge(row[col]).color,
                            backgroundColor: getStatusBadge(row[col]).backgroundColor,
                            borderRadius: "8px",
                          }}
                        />
                      ) : row[col] === "N/A" ? (
                        "N/A"
                      ) : (
                        row[col]
                      )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default LabAssessment;
