import React from 'react'
import Footer from '../Footer/footer';



const Challenges = () => {
  return (
    <div>
     <h4 > Challenges</h4>
    
     <div className="Course-list-footer">
     <Footer />
   </div>
   </div>
  )
}

export default Challenges
