// import React, { useRef } from "react";
// import { Grid, Typography, Box } from "@mui/material";
// import { toPng } from "html-to-image";
// import Certificate_dlogo from "../assets/Images/Certificate_dlogo.png";
// import Certificate_slogo from "../assets/Images/Certificate_slogo.png";
// import Certificate_signature from "../assets/Images/Certificate_signature.png";
// import Certificate_medal from "../assets/Images/Certificate_medal.png";
// import Certificate_bg_img from "../assets/Images/Certificate_bg_img.png";

// const Certificate = () => {
//   const certificateRef = useRef(null); // Create a ref for the certificate

//   // Function to download the certificate as an image
//   const handleDownload = () => {
//     if (certificateRef.current) {
//       toPng(certificateRef.current, { cacheBust: true })
//         .then((dataUrl) => {
//           const link = document.createElement("a");
//           link.href = dataUrl;
//           link.download = "certificate.png"; // Specify the filename
//           link.click();
//         })
//         .catch((error) => {
//           console.error("Error generating image", error);
//         });
//     }
//   };

//   return (
//     <div style={{height:'90vh',overflowY:'scroll',scrollbarWidth:'thin'}}>
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection:'row',
//         justifyContent: "center",
//         alignItems: "center",
//         padding:'10px',
//          // Optional: To give a nice background to the page
//       }}
//     >
//       {/* Wrapping the certificate in a ref */}
//       <Box
//         ref={certificateRef}
//         sx={{
//           padding: "13px",
//           width: "800px",
//           border: "2px solid #eee",
//           position: "relative",
//         }}
//       >
//         <Box
//           sx={{
//             backgroundColor: "white",
//             border: "5px solid #eee",
//             padding: "10px",
//             maxWidth: "800px",
//             margin: "auto",
//             fontFamily: "'Arial', sans-serif",
//           }}
//         >
//           {/* Header Section */}
//           <Grid
//             xs={12}
//             container
//             display="flex"
//             justifyContent="space-between"
//             alignItems="center"
//           >
//             <Grid
//               item
//               xs={6}
//               display="flex"
//               alignItems="center"
//               marginTop="-4.2%"
//               gap={2}
//             >
//               <img
//                 src={Certificate_slogo}
//                 alt="DataWorks Logo"
//                 style={{ maxWidth: "120px", marginLeft: "3%" }}
//               />
//               <img
//                 src={Certificate_medal}
//                 alt="Certificate Medal"
//                 style={{
//                   maxWidth: "120px",
//                   marginLeft: "5%",
//                   height: "130px",
//                   marginTop: "5%",
//                 }}
//               />
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sx={{
//                 display: { xs: "flex", sm: "flex", md: "flex" },
//                 justifyContent: {
//                   xs: "flex-start",
//                   sm: "flex-end",
//                   md: "flex-end",
//                 },
//                 marginTop: { xs: "10%", sm: "0px" },
//               }}
//               display="flex"
//               justifyContent="flex-end"
//               alignItems="center"
//             >
//               <img
//                 src={Certificate_dlogo}
//                 alt="Google Logo"
//                 style={{
//                   height: "35px",
//                   width: "180px",
//                   marginTop: "-19%",
//                   marginRight: "3%",
//                 }}
//               />
//             </Grid>
//           </Grid>

//           {/* Certificate Body */}
//           <Box
//             sx={{
//               position: "relative",
//               textAlign: "center",
//               padding: "40px",
//               overflow: "hidden",
//             }}
//           >
//             <img
//               src={Certificate_bg_img}
//               alt="Certificate Background"
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 width: "40%",
//                 height: "100%",
//                 opacity: 1,
//                 zIndex: 0,
//               }}
//             />
//             <Typography
//               variant="h4"
//               sx={{
//                 fontWeight: "600",
//                 fontSize: "24px",
//                 marginBottom: 2,
//                 zIndex: 1,
//                 position: "relative",
//                 color: "#393939",
//               }}
//             >
//               CERTIFICATE OF COMPLETION
//             </Typography>
//             <Typography
//               variant="body1"
//               sx={{
//                 marginBottom: 2,
//                 zIndex: 1,
//                 position: "relative",
//                 fontWeight: "600",
//                 color: "#656565",
//               }}
//             >
//               This acknowledges that
//             </Typography>
//             <Typography
//               variant="h3"
//               sx={{
//                 margin: "10px 0",
//                 fontSize: "32px",
//                 zIndex: 1,
//                 position: "relative",
//                 fontWeight: "600",
//                 color: "#393939",
//               }}
//             >
//               Anuhya
//             </Typography>
//             <Typography
//               variant="body1"
//               sx={{
//                 marginBottom: 2,
//                 zIndex: 1,
//                 position: "relative",
//                 fontWeight: "600",
//                 color: "#656565",
//               }}
//             >
//               has successfully completed all the requirements to be recognized
//               as a
//             </Typography>
//             <Typography
//               variant="body1"
//               sx={{
//                 fontWeight: "400",
//                 zIndex: 1,
//                 position: "relative",
//                 color: "#656565",
//               }}
//             >
//               GOOGLE CERTIFIED
//             </Typography>
//             <Typography
//               variant="h5"
//               sx={{
//                 fontWeight: "600",
//                 marginTop: 2,
//                 fontSize: "28px",
//                 zIndex: 1,
//                 position: "relative",
//                 color: "#393939",
//               }}
//             >
//               Professional UI/UX Designer
//             </Typography>
//           </Box>

//           {/* Footer Section */}
//           <Grid container justifyContent="space-between" alignItems="center">
//             <Grid item xs={6} textAlign="left">
//               {<img src={Certificate_signature} alt="Signature" style={{ maxHeight: "70px" }} />}
//             </Grid>
//             <Grid item xs={6} textAlign="right">
//               <Typography
//                 variant="body2"
//                 sx={{
//                   marginBottom: 1,
//                   marginRight: "25%",
//                   color: "#252525",
//                 }}
//               >
//                 Verify at:
//               </Typography>
//               <Box
//                 component="a"
//                 href="https://www.google.com/search?q=dataworks"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{
//                   color: "#0073e6",
//                   textDecoration: "none",
//                   fontSize: "12px",
//                   wordBreak: "break-word",
//                   whiteSpace: "normal",
//                   display: "block",
//                   maxWidth: {
//                     xs: "250px",
//                     sm: "none",
//                   },
//                   marginLeft: { xs: "0px", sm: "0px" },
//                 }}
//               >
//                 https://www.google.com/search?q=dataworks
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>

//       {/* Download Button */}
//       <button
//         onClick={handleDownload}
//         style={{
//           marginTop: "20px",
//           padding: "10px 20px",
//           backgroundColor: "#007BFF",
//           color: "#FFF",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//           position: 'absolute',
//         top:'60px',
//        right:'120px',
          
//         }}
//       >
//         Download
//       </button>
//     </Box>
//     </div>
//   );
// };

// export default Certificate;



import React, { useRef, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { toPng } from "html-to-image";
import Certificate_dlogo from "../assets/Images/Certificate_dlogo.png";
import Certificate_slogo from "../assets/Images/Certificate_slogo.png";
import Certificate_signature from "../assets/Images/Certificate_signature.png";
import Certificate_medal from "../assets/Images/Certificate_medal.png";
import Certificate_bg_img from "../assets/Images/Certificate_bg_img.png";

const Certificate = () => {
  const certificateRef = useRef(null); // Create a ref for the certificate
  const [isPreview, setIsPreview] = useState(false); // State to toggle preview

  // Function to download the certificate as an image
  const handleDownload = () => {
    if (certificateRef.current) {
      toPng(certificateRef.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "certificate.png"; // Specify the filename
          link.click();
        })
        .catch((error) => {
          console.error("Error generating image", error);
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: isPreview ? "98vh" : "100vh", // Adjust height dynamically
        background: isPreview
          ? "#f4f4f4"
          : "linear-gradient(135deg, #ece9e6, #ffffff)", // Classy gradient background
        overflowY: "scroll",
        gap: 2,
        transition: "background 0.5s ease-in-out", // Smooth background transition
      }}
    >
      {/* Wrapping the certificate in a ref */}
      <Box
        ref={certificateRef}
        className={isPreview ? "certificate-preview" : "certificate-compressed"}
        sx={{
          padding: "13px",
          width: "800px",
          border: "2px solid #eee",
          position: "relative",
          filter: isPreview ? "none" : "blur(4px)", // Enhanced blur effect by default
          transform: isPreview ? "scale(1)" : "scale(0.9)", // Gentle scaling effect
          transition: "all 0.7s ease", // Smooth animation for transition
          cursor: isPreview ? "default" : "pointer",
          overflow: "visible", // Ensure content is not clipped
          marginTop: isPreview ? "0px" : "-15px", // Adjust margin for compressed state
          background: "#fff",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)", // Classy shadow
          borderRadius: "8px", // Subtle rounding
          marginTop: "-5%",
          
        }}
      >
        {/* Certificate content */}
        <Box
          sx={{
            backgroundColor: "white",
            border: "5px solid #eee",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "10px",
            maxWidth: "800px",
            margin: "auto",
            fontFamily: "'Arial', sans-serif",
          }}
        >
          {/* Header Section */}
          <Box
            xs={12}
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              marginTop="-4.2%"
              gap={2}
            >
              <img
                src={Certificate_slogo}
                alt="Google_logo"
                style={{ maxWidth: "120px", marginLeft: "3%" }}
              />
              <img
                src={Certificate_medal}
                alt="Certificate Medal"
                style={{
                  maxWidth: "120px",
                  marginLeft: "5%",
                  height: "100px",
                  marginTop: "8%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "flex", sm: "flex", md: "flex" },
                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-end",
                  md: "flex-end",
                },
                marginTop: { xs: "10%", sm: "0px" },
              }}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <img
                src={Certificate_dlogo}
                alt="Dataworks Logo"
                style={{
                  height: "35px",
                  width: "180px",
                  marginTop: "-19%",
                  marginRight: "3%",
                }}
              />
            </Grid>
          </Box>

          {/* Certificate Body */}
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
              padding: "20px",
              overflow: "hidden",
            }}
          >
            <img
              src={Certificate_bg_img}
              alt="Certificate Background"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "40%",
                height: "100%",
                opacity: 1,
                zIndex: 0,
              }}
            />
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "24px",
                marginBottom: 1,
                zIndex: 1,
                position: "relative",
                color: "#393939",
              }}
            >
              CERTIFICATE OF COMPLETION
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: 0 ,
                zIndex: 1,
                position: "relative",
                fontWeight: "600",
                color: "#656565",
              }}
            >
              This acknowledges that
            </Typography>
            <Typography
              sx={{
                margin: "10px 0",
                fontSize: "24px",
                zIndex: 1,
                position: "relative",
                fontWeight: "600",
                color: "#393939",
              }}
            >
              Anuhya
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '8px',
                zIndex: 1,
                position: "relative",
                fontWeight: "600",
                color: "#656565",
              }}
            >
              has successfully completed all the requirements to be recognized
              as a
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "400",
                zIndex: 1,
                position: "relative",
                color: "#656565",
              }}
            >
              GOOGLE CERTIFIED
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                marginTop: '8px',
                fontSize: "24px",
                zIndex: 1,
                position: "relative",
                color: "#393939",
              }}
            >
              Professional UI/UX Designer
            </Typography>
          </Box>

          {/* Footer Section */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} textAlign="left">
              {
                <img
                  src={Certificate_signature}
                  alt="Signature"
                  style={{ maxHeight: "70px" }}
                />
              }
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 1,
                  marginRight: "25%",
                  color: "#252525",
                }}
              >
                Verify at:
              </Typography>
              <Box
                component="a"
                href="https://www.google.com/search?q=dataworks"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: "#0073e6",
                  textDecoration: "none",
                  fontSize: "12px",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  display: "block",
                  maxWidth: {
                    xs: "250px",
                    sm: "none",
                  },
                  marginLeft: { xs: "0px", sm: "0px" },
                }}
              >
                https://www.google.com/search?q=dataworks
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Preview and Download Buttons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 2,
          position: "relative",
          marginTop: "20px",
          zIndex: 2, // Ensure visibility
        }}
      >
        {/* Preview Button */}
        {!isPreview && (
          <button
            onClick={() => setIsPreview(!isPreview)}
            style={{
              position: "absolute", // Position the button in the middle
              right:'300px',
              top:'-40px',
              transform: "translate(-50%, -50%)", // Center the button
              padding: "10px 20px",
              backgroundColor: "GrayText",
              color: "#FFF",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              zIndex: 3, // Ensure it appears above the certificate
            }}
          >
            Preview
          </button>
        )}

        {/* Hide Preview and Download Buttons */}
        {isPreview && (
          <>
            {/* <button
              onClick={() => setIsPreview(!isPreview)}
              style={{
                padding: "10px 20px",
                backgroundColor: "#dc3545",
                color: "#FFF",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Hide Preview
            </button> */}
            <button
              onClick={handleDownload}
              style={{
                padding: "10px 20px",
                backgroundColor: "#007BFF",
                color: "#FFF",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                position: 'absolute',
                top: '-280px'
              }}
            >
              Download
            </button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Certificate;
