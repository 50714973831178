import React, { useState, useEffect, useRef, us } from 'react';
import MonacoEditor from '@monaco-editor/react';
import '../assets/Css/TimedAssessment.css';
import { useNavigate } from 'react-router-dom';
import instruction_icon2 from '../assets/Images/Instruction2.png';
import Hint2_icon from '../assets/Images/Hint2.png';
import output_code_icon from '../assets/Images/output_code.png';
import Theme_icon from '../assets/Images/sun.png';
import Play_icon from '../assets/Images/play.png';
import Backward_icon from '../assets/Images/Backward_icon_3.png';
import Fordward_icon from '../assets/Images/Fordward_icon_3.png';
import output_maximize_icon from '../assets/Images/Output_maximize_icon_2.png';
import { adminaxios } from '../axios/index';
import { useParams } from 'react-router-dom';
import Header1 from '../Header/Header1';
import light_theme_instuction_icon from '../assets/Images/Light_Theme_Instuction_icon.png';
import light_theme_hint_icon from "../assets/Images/Light_Theme_Hint_Icon.png";
import Closed_block_icon from "../assets/Images/Closed_left_block_icon.png";
import openLeftWindow_icon from "../assets/Images/OpenLeftWindow_icon.png";
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { setParticularLabId } from '../actions/apisIdsActions';
import download_icon from '../assets/Images/download_icon1.png';
import reset_icon from "../assets/Images/Loding_image.png";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import light_theme_reset_icon from '../assets/Images/Light_Theme_reset_icon.png';
import light_theme_play_icon from '../assets/Images/Light_Theme_Play_icon.png';
import output_maximize_light_icon from '../assets/Images/Output_Maximize_Light_Icon.png';
import Close_Outputsection_dark_theme_icon from '../assets/Images/close_outputsection_dark.png';
import Close_Outputsection_light_theme_icon from '../assets/Images/close_outputsection_light.png';
import Closed_LeftSide_icon_light_4 from "../assets/Images/Closed_LeftSide_icon_Light_4.png";
import closed_editor_section_4 from "../assets/Images/Closed_editor_section_4.png";
import Closed_block_lcon_light_theme_3 from "../assets/Images/closed_block_lcon_light_theme_3.png";





const TimedAssessment_lab = () => {

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('challenge');
  const [content, setContent] = useState({ challenge: '', instructions: '', hints: '', solution: '' });
  const [leftWidth, setLeftWidth] = useState(40);
  const [editorWidth, setEditorWidth] = useState(60);
  const [outputWidth, setOutputWidth] = useState(0);
  const [showOutput, setShowOutput] = useState(false);
  const [outputExpanded, setOutputExpanded] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isHovering_2, setIsHovering_2] = useState(false);
  const [isHovering_3, setIsHovering_3] = useState(false);
  const [isHovering_4, setIsHovering_4] = useState(false);
  const [isHovering_5, setIsHovering_5] = useState(false);
  const defaultContent = `print("Try Dataworks.pro")` + `\n`.repeat(22);
  const [showSolution, setShowSolution] = useState(false);
  const [outputs, setOutputs] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const challengeRef = useRef(null);
  const instructionsRef = useRef(null);
  const hintsRef = useRef(null);
  const solutionRef = useRef(null);
  const containerRef = useRef(null);
  const editorRef = useRef(null);
  const [isDraggingLeft, setIsDraggingLeft] = useState(false);
  const [isDraggingEditorOutput, setIsDraggingEditorOutput] = useState(false);
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const selectedLabId = useSelector((state) => state.dynamicApiId.particularLabId);
  const current_Instruction_icon = isDarkTheme ? instruction_icon2 : light_theme_instuction_icon;
  const current_Hint_icon = isDarkTheme ? Hint2_icon : light_theme_hint_icon;
  const [isActive, setIsActive] = useState(false);
  const [isLeftCollapsed, setIsLeftCollapsed] = useState(false);
  const [isOutputExpanded, setIsOutputExpanded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const webSocketRef = useRef(null);
  const [userInput, setUserInput] = useState('');
  const [waitingForInput, setWaitingForInput] = useState(false);
  const currentModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const currentLabId = useSelector((state) => state.dynamicApiId.labConfigId);
  const currentTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const [questionArray, setQuestionArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log(currentIndex, 'currentIndex')
  console.log(selectedLabId, 'each_lab_id')
  const [terminalContent, setTerminalContent] = useState('');
  const terminalRef = useRef(null);
  const [input_Submitting, setInput_Submitting] = useState(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1800);
  const [showInitialPopup, setShowInitialPopup] = useState(true);
  const [countdown, setCountdown] = useState(null);
  const [labStarted, setLabStarted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('');
  const [popupCountdown, setPopupCountdown] = useState(3);
  const [showCountdownPopup, setShowCountdownPopup] = useState(false);
  const timerIdRef = useRef(null);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [showTimerOverPopup, setShowTimerOverPopup] = useState(false);
  const [showEmptyCodePopup, setShowEmptyCodePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  





  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await adminaxios.get(
          `/modules/${currentModuleId}/topics/${currentTopicId}/question_bank_config/${currentLabId}/`
        );

        // Assuming the response contains the 'questions' array like in your provided data structure
        const questions = response.data.question_bank_configs?.questions || [];

        // Map through the questions and extract the `_id` into a new array
        const extractedTopicsArray = questions.map((question) => question._id);

        // Store the extracted question IDs in state
        setQuestionArray(extractedTopicsArray);
        const selectedLabId = localStorage.getItem('each_lab_id');

        // Find the index of the selectedLabId in the topicsArray
        const initialIndex = extractedTopicsArray.indexOf(selectedLabId);

        // If the ID is found, set the currentIndex to it, otherwise default to 0
        setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  const handleBackward = () => {
    if (!isSubmitted && currentIndex > 0) {
      stopTimer();
      setPopupCountdown(3);
      setPopupType('previous');
      setShowPopup(true);
    }
  };

  const handleForward = () => {
    if (currentIndex < questionArray.length - 1) {
      // setIsLoadingProgress(true);
      stopTimer();
      setPopupCountdown(3);
      setPopupType('next');
      setShowPopup(true);
    }
  };

  const handleContinue = () => {
    setShowPopup(false); // Hide the first popup
    setShowCountdownPopup(true);  // Show the countdown popup
    const timer = setInterval(() => {
      setPopupCountdown((prevCount) => {
        if (prevCount === 1) {
          clearInterval(timer);  // Stop the timer after countdown reaches 0
          setShowCountdownPopup(false); // Hide countdown popup after it ends
          setLabStarted(true); // Start the lab
          setTimeLeft(1800);
          // Logic to navigate to next or previous question
          if (popupType === 'next' && currentIndex < questionArray.length - 1) {
            setCurrentIndex(currentIndex + 1);
            dispatch(setParticularLabId(questionArray[currentIndex + 1]));
          } else if (popupType === 'previous' && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            dispatch(setParticularLabId(questionArray[currentIndex - 1]));
          }
        }
        return prevCount - 1;
      });
    }, 1000);  // Decrease the countdown every 1 second
  };



  useEffect(() => {
    console.log(selectedLabId, "selectedLabId")
    // API call to fetch content
    adminaxios.get(`question_bank/${selectedLabId}/`)
      .then(res => {
        const projectLabContent = res.data.project_lab_content;
        setContent({
          title: projectLabContent.title || 'No title available.',
          challenge: projectLabContent.lab_question || 'No challenge available.',
          instructions: projectLabContent.lab_instructions || 'No instructions available.',
          hints: projectLabContent.lab_hints || 'No hints available.',
          solution: projectLabContent.lab_solution || 'No solution available.',
          testCases: projectLabContent.lab_test_cases || []
        });
      })
      .catch(error => console.error('Error fetching content:', error));
  }, [selectedLabId]);

  const handleExitLabClick = () => {
    navigate(`/lab-list/${courseId}`);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case 'challenge':
        if (challengeRef.current) {
          challengeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'instructions':
        if (instructionsRef.current) {
          instructionsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'hints':
        if (hintsRef.current) {
          hintsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        break;
    }
  };

  const handleSolutionClick = () => {
    setShowSolution(true);
  };

  const handleCloseSolution = () => {
    setShowSolution(false);
  };


  useEffect(() => {
    const ws = new WebSocket('wss://lmsbackend.nquantum.in/ws/execute/');
    webSocketRef.current = ws;

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.output) {
        appendToTerminal(response.output + '\n'); // Append output to terminal
      } else if (response.prompt) {
        appendToTerminal(response.prompt);  // Append prompt for user input
      } else if (response.error) {
        appendToTerminal('Error: ' + response.error + '\n');
      }
      setIsLoading(false);
    };

    return () => {
      ws.close();
    };
  }, []);


  const appendToTerminal = (data) => {
    setTerminalContent(prev => prev + data + '\n');
    setTimeout(() => {
      if (terminalRef.current) {
        terminalRef.current.scrollTop = terminalRef.current.scrollHeight; // Auto-scroll to the bottom
      }
    }, 100);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      const trimmedInput = userInput.trim(); // Capture the complete input

      if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
        // Send full user input to the WebSocket in the required format
        const payload = {
          action: 'input',  // Action to indicate input
          input: trimmedInput,  // The actual user input
        };
        webSocketRef.current.send(JSON.stringify(payload)); // Send input as JSON

        appendToTerminal(`"${trimmedInput}"\n`); // Append the input to terminal for display
        setUserInput(''); // Clear the input field after sending the input
        setWaitingForInput(false); // Stop waiting for input after sending
      } else {
        appendToTerminal('Error: WebSocket is not open\n'); // Handle WebSocket not open
      }
    } else {
      setUserInput(e.target.value);
    }
  };



  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [terminalContent]);



  const handleSubmitInput = () => {
    const trimmedInput = userInput.trim(); // Ensure input is clean
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      const payload = {
        action: 'input',
        input: trimmedInput,
      };

      console.log(payload, "payload");
      setInput_Submitting(true); // Set submitting to true to show loader
      webSocketRef.current.send(JSON.stringify(payload)); // Send input as JSON

      appendToTerminal(`"${trimmedInput}"\n`); // Append the input to terminal for display
      setUserInput(''); // Clear input field
      setWaitingForInput(false); // Stop waiting for input

      // Simulate loading complete after the input is processed (optional)
      setTimeout(() => {
        setInput_Submitting(false); // Hide loader after submission is processed
      }, 2000); // Adjust delay based on your actual processing time
    } else {
      appendToTerminal('Error: WebSocket is not open\n'); // Handle WebSocket not open
    }
  };


  const runCode = () => {
    const codeToExecute = editorRef.current.getValue();

    // Check if no code is present
    if (!codeToExecute || codeToExecute.trim() === '') {
      // Show popup with the message
      setPopupMessage('Write the code to proceed.');
      setShowEmptyCodePopup(true);

      // Auto-close the popup after 3 seconds
      setTimeout(() => {
        setShowEmptyCodePopup(false);
      }, 2000);

      return;
    }
    setIsLoading(true);
    setOutputs([]); // Clear previous outputs
    setTerminalContent('');
  
    // Ensure output section opens with full width in mobile/tablet view
    if (window.innerWidth <= 1024) {
      // For mobile and tablet views, show only the output section at full width
      setShowOutput(true);
      setLeftWidth(0); // Hide the left side section
      setEditorWidth(0); // Hide the editor section
      setOutputWidth(100); // Make the output section take full width
    } else {
      // For larger screens, split into three sections
      setShowOutput(true);
      setLeftWidth(33.33);
      setEditorWidth(33.33);
      setOutputWidth(33.33);
    }



    // Send code via WebSocket
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      const payload = {
        action: 'execute',
        code: codeToExecute, // Send the code entered in MonacoEditor
        language: 'python', // Assuming Python is the language
      };

      // Send the code to the WebSocket server
      webSocketRef.current.send(JSON.stringify(payload));

      // Handle WebSocket message (output from the server)
      webSocketRef.current.onmessage = (event) => {
        try {
          // Parse the incoming data from the WebSocket
          const response = JSON.parse(event.data);

          if (response.output) {
            // If 'output' is present, append the output to the terminalContent
            const cleanedOutput = response.output.replace(/[\[\]"]/g, '');
            appendToTerminal(cleanedOutput);
            setIsError(false);
          } else if (response.prompt) {
            // If 'prompt' is present (e.g., asking for input), append the prompt to the terminalContent
            appendToTerminal(response.prompt);
            setWaitingForInput(true); // Flag to indicate waiting for user input
            setIsError(false);
          } else if (response.error) {
            // If 'error' is present, append the error to the terminalContent
            appendToTerminal(`Error: ${response.error}`);
            setIsError(true);
          } else {
            // If no known fields (output/prompt/error) are present, append a generic message
            appendToTerminal('Unknown response received');
            setIsError(true);
          }

          setIsLoading(false); // Stop loading
        } catch (error) {
          // Handle any errors parsing the WebSocket response
          console.error("Error parsing WebSocket response:", error);
          appendToTerminal('An error occurred while processing the response');
          setIsError(true);
          setIsLoading(false);
        }
      };

    } else {
      console.error('WebSocket is not open');
      appendToTerminal('Error: WebSocket is not open');
      setIsLoading(false);
    }
  };


  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    setIsActive(!isActive);

  };

  const toggleOutput = () => {
    // For mobile and tablet view
    if (window.innerWidth <= 1024) {
      setShowOutput(!showOutput);
  
      if (!showOutput) {
        setLeftWidth(0); // Hide the left side section
        setEditorWidth(0); // Hide the editor section
        setOutputWidth(100); // Output section takes full width
      } else {
        // Output section is closing, show either left side or editor based on collapsed state
        if (isLeftCollapsed) {
          setEditorWidth(100); // Show only editor section if left side is collapsed
        } else {
          setLeftWidth(100); // Show only left side section if it's not collapsed
        }
        setOutputWidth(0); // Hide the output section
      }
    } else {
      // For larger screens (desktop/laptop)
      setShowOutput(!showOutput);
      if (!showOutput) {
        // Output section is opening, split into equal parts
        setLeftWidth(33.33);
        setEditorWidth(33.33);
        setOutputWidth(33.33);
      } else {
        // Output section is closing, return to default layout
        setLeftWidth(40);
        setEditorWidth(60);
        setOutputWidth(0);
      }
    }
  };

  const expandOutput = () => {
    // Always expand the output section when clicked
    setOutputExpanded(true);
    setIsOutputExpanded(true);

    setLeftWidth(0);
    setEditorWidth(50);
    setOutputWidth(50);
  };


  /* reset daat  */
  const resetEditor = () => {
    if (editorRef.current) {
      const emptyContent = `\n`.repeat(22);
      editorRef.current.setValue(emptyContent);
      editorRef.current.setPosition({ lineNumber: 1, column: 1 });
      editorRef.current.focus();
    }
  };

  // Draggable divider event handlers for left-right division
const startDraggingLeft = (e) => {
  setIsDraggingLeft(true);
  containerRef.current.classList.add('is-dragging');  // Add class to disable transition
  e.preventDefault();
};

const stopDraggingLeft = () => {
  if (isDraggingLeft) {
    setIsDraggingLeft(false);
    containerRef.current.classList.remove('is-dragging');  // Remove class after dragging
  }
};

  const onDragLeft = (e) => {
    if (isDraggingLeft && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const newLeftWidth = (e.clientX / containerWidth) * 100;
  
      // Set width constraints to avoid resizing too much or too little
      if (newLeftWidth > 24 && newLeftWidth < 90) {
        setLeftWidth(newLeftWidth);
        setEditorWidth(showOutput ? 100 - newLeftWidth - outputWidth : 100 - newLeftWidth);
      }
    }
  };

  // Draggable divider event handlers for editor-output division
const startDraggingEditorOutput = (e) => {
  setIsDraggingEditorOutput(true);
  containerRef.current.classList.add('is-dragging');  // Add class to disable transition
  e.preventDefault();
};

const stopDraggingEditorOutput = () => {
  if (isDraggingEditorOutput) {
    setIsDraggingEditorOutput(false);
    containerRef.current.classList.remove('is-dragging');  // Remove class after dragging
  }
};

  const onDragEditorOutput = (e) => {
    if (isDraggingEditorOutput && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const newEditorWidth = (e.clientX / containerWidth) * 100 - leftWidth;
      if (newEditorWidth > 10 && newEditorWidth < 90) {
        setEditorWidth(newEditorWidth);
        setOutputWidth(100 - leftWidth - newEditorWidth);
      }
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const closeOutput = () => {
    setShowOutput(false);
    setIsOutputExpanded(false);
    
    // For mobile/tablet view
    if (window.innerWidth <= 1024) {
      // Check if left section is collapsed, if so, show the editor section
      if (isLeftCollapsed) {
        setEditorWidth(100); // Show only editor section in mobile view
        setLeftWidth(0); // Hide the left side
      } else {
        // Otherwise, show the left side section
        setLeftWidth(100); // Show only left side section in mobile view
        setEditorWidth(0); // Hide the editor section
      }
    } else {
      // For larger screens, reset to the default layout
      setLeftWidth(40);
      setEditorWidth(60);
      setOutputWidth(0);
    }
  };

  const toggleLeftSide = () => {
    setIsLeftCollapsed(!isLeftCollapsed);
    if (!isLeftCollapsed) {
        setLeftWidth(0);
        setEditorWidth(100); // Editor takes full width when left side is collapsed
    } else {
        if (window.innerWidth <= 1024) {
            // In mobile/tablet view, reset widths based on layout requirements
            setLeftWidth(100);
            setEditorWidth(0);
        } else {
            setLeftWidth(40); // Reset to default width for larger screens
            setEditorWidth(60);
        }
    }
};

  const handleSubmitClick = () => {
    setIsPopupOpen(true);
  };


  const handleConfirmSubmit = () => {
    setIsPopupOpen(false); // Close the confirmation popup
  
    // Show a popup that the code has been submitted
    setPopupMessage("Your code has been submitted!");
    setShowEmptyCodePopup(true);
    setIsSubmitted(true);
  
    setTimeout(() => {
      setShowEmptyCodePopup(false);
  
      // Check if there are more questions
      if (currentIndex < questionArray.length - 1) {
        // Move to the next question
        setCurrentIndex(currentIndex + 1);
        dispatch(setParticularLabId(questionArray[currentIndex + 1]));
      } else {
        // If no more questions, show the final completion message and navigate to the lab-list page
        setPopupMessage("The assigned tasks have been successfully completed. Please proceed to the lab-list page for further tasks.");
        setShowEmptyCodePopup(true);
        setTimeout(() => {
          setShowEmptyCodePopup(false);
          navigate(`/lab-list/${courseId}`);
        }, 3000);
      }
    }, 2000); // Delay after showing "Your code has been submitted!"
  };
  
 


  // Close the popup if the user clicks "Cancel"
  const handleCancelSubmit = () => {
    setIsPopupOpen(false);
  };


  // Function to handle sending user input via WebSocket
  const handleWebSocketInput = (input) => {
    // Ensure input is not empty
    if (input.length === 0) return;

    // Ensure WebSocket is open and ready to send data
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      // Send input as a JSON object
      const payload = {
        action: 'input',
        input,
      };

      webSocketRef.current.send(JSON.stringify(payload)); // Send to WebSocket

      appendToTerminal(`You entered: ${input}\n`); // Show the input in terminal
      setUserInput(''); // Clear the input field
      setWaitingForInput(false); // Stop waiting for input
    } else {
      appendToTerminal('Error: WebSocket is not open\n');
    }
  };
  console.log(leftWidth);

  /* Download */

  const downloadOutput = () => {
    const outputData = terminalContent;
    const blob = new Blob([outputData], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.txt';
    link.click();
    URL.revokeObjectURL(link.href);
  };



  /* popup  */

  const handleContinueClick = () => {
    setTimeLeft(1800);
    setCountdown(3); // Start countdown from 3
    setShowInitialPopup(false);
  };

  // Handle the "Cancel" button click
  const handleCancelClick = () => {
    setShowInitialPopup(false); // Close the popup
    navigate(`/lab-list/${courseId}`)
  };

  // Countdown logic
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setLabStarted(true);
      // setShowInitialPopup(false);
    }
  }, [countdown]);





  useEffect(() => {
    if (labStarted && timeLeft > 0) {
      const timerId = setInterval(() => setTimeLeft((prevTime) => prevTime - 1), 1000);
      return () => clearInterval(timerId); // Clear the interval on unmount or timeLeft reaches 0
    } else if (timeLeft <= 0) {
      // Add logic to handle what happens when time is over (e.g., auto-submit, disable lab, etc.)
      setLabStarted(false);
    }
  }, [labStarted, timeLeft]);

  // Stop the timer
  const stopTimer = () => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }
  };

  // Format the time in HH:MM:SS
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  /* popup - number count */
  useEffect(() => {
    setIsCountdownActive(true);
    const timer = setTimeout(() => {
      setIsCountdownActive(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [popupCountdown]);



  /* if timmer is stop! Auto popup  */
  useEffect(() => {
    if (labStarted && timeLeft > 0) {
      const timerId = setInterval(() => setTimeLeft((prevTime) => prevTime - 1), 1000);
      return () => clearInterval(timerId);
    } else if (timeLeft <= 0) {

      setShowTimerOverPopup(true);
      setLabStarted(false);
    }
  }, [labStarted, timeLeft]);

  // Handle next question when the popup is shown after timer ends
  const handleNextAfterSubmit = () => {
    setShowTimerOverPopup(false);
    if (currentIndex < questionArray.length - 1) {
      setCurrentIndex(currentIndex + 1);
      dispatch(setParticularLabId(questionArray[currentIndex + 1]));
      setTimeLeft(1800);
      setLabStarted(true);
    }
  };

  // Exit the lab and navigate to the lab list
  const handleExitAfterSubmit = () => {
    navigate(`/lab-list/${courseId}`);
  };


  useEffect(() => {    
    const handleResize = () => {
        if (window.innerWidth <= 1024) {
            setLeftWidth(100); // Set full width for the left section by default
            setEditorWidth(0); // Hide editor initially on mobile/tablet
            setOutputWidth(0); // Hide output initially on mobile/tablet
            setIsMobileView(true);
        } else {
            setLeftWidth(40); // Set default widths for desktop view
            setEditorWidth(60);
            setOutputWidth(0);
            setIsMobileView(false);
        }
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the layout
    handleResize();

    // Cleanup the event listener
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);


  return (

    <div
      className={`timedassessment-app-container ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}
      ref={containerRef}
      onMouseMove={(e) => {
        if (isDraggingLeft) onDragLeft(e);
        if (isDraggingEditorOutput) onDragEditorOutput(e);
      }}
      onMouseLeave={() => { stopDraggingLeft(); stopDraggingEditorOutput(); }}
      onMouseUp={() => { stopDraggingLeft(); stopDraggingEditorOutput(); }}
    >
      {showInitialPopup && (
        <div className="timedassessment-popup-overlay">
          <div className="timedassessment-popup">
            <p>Your timed lab assessment starts now—rise to the challenge and prove your skills!</p>
            <div className="timedassessment-popup-buttons">
              <button className="timedassessment-cancel-button" onClick={handleCancelClick}>
                Cancel
              </button>
              <button className="timedassessment-confirm-button" onClick={handleContinueClick}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {countdown !== null && countdown > 0 && (
        <div className="popup-overlay_1">
          <div className="popup_1">
            <p className='.countdown'>{countdown}</p>
          </div>
        </div>
      )}
      
      {/* Previous and next question popup*/}
      {showPopup && (
        <div className="timedassessment-popup-overlay">
          <div className="timedassessment-popup_2">
            <img
              src={isHovering_4 ? Close_Outputsection_light_theme_icon : Close_Outputsection_dark_theme_icon}
              alt="Close"
              className="timedassessment-cancel-icon"
              onMouseEnter={() => setIsHovering_4(true)}
              onMouseLeave={() => setIsHovering_4(false)}
              onClick={() => setShowPopup(false)}
            />
            <p>{popupType === 'next' ? "You've selected the next question. Would you like to proceed?" : "You've selected the previous question. Would you like to proceed? "}</p>
            <div className="timedassessment-popup-buttons">
              <button className="timedassessment-confirm-button" onClick={handleContinue}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {showCountdownPopup && (
        <div className="timedassessment-popup-overlay">
          <div className="timedassessment-countdown-popup">
            <p className= "countdown ">
              {popupCountdown}
            </p>
          </div>
        </div>
      )}

      {/* Time stop - popup*/}
      {showTimerOverPopup && (
        <div className="timedassessment-popup-overlay">
          <div className="timedassessment-popup">
            <p>Your code has been submitted. Proceed to the next question!</p>
            <div className="timedassessment-popup-buttons">
              <button className="timedassessment-cancel-button" onClick={handleExitAfterSubmit}>
                Exit
              </button>
              <button className="timedassessment-confirm-button" onClick={handleNextAfterSubmit}>
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {showEmptyCodePopup && (
        <div className="timedassessment-popup-overlay">
          <div className="timedassessment-popup">
            <p>{popupMessage}</p>
          </div>
        </div>
      )}

      {/* Replace new_labpage with timedassessment */}
      <div className="timedassessment-header-container">
        <div className="timedassessment-left-side-header">
          <button className="timedassessment-exit-button" onClick={handleExitLabClick}>Exit</button>
          <h3 className="timedassessment_Technology_name">Python</h3>
        </div>
        <div className="timedassessment-right-side-header">

          <span className="timedassessment-timer" >
            {timeLeft > 0 ? formatTime(timeLeft) : 'Time Over'}
          </span>

          <div className="timedassessment-right-header-actions">
            <Tooltip title="Output" arrow>
              <img
                src={output_code_icon}
                alt="OutPut-Code-Icon"
                className={`timedassessment-output-icon ${showOutput ? 'active' : ''}`}
                onClick={toggleOutput}
              />
            </Tooltip>
            <Tooltip title="Theme" arrow>
              <img src={Theme_icon} alt="OutPut-Code-Icon" className={`timedassessment-theme-icon ${isActive ? 'active' : ''}`} onClick={toggleTheme} />
            </Tooltip>
            <Tooltip title="Download" arrow>
              <img src={download_icon} alt="dowmload_icon" className={`timedassessment-download-icon ${isActive ? 'active' : ''}`} onClick={downloadOutput} />
            </Tooltip>
            <button className="timedassessment-submit-button" onClick={handleSubmitClick}>Submit</button>
          </div>
          {isPopupOpen && (
            <div className="timedassessment_popup-overlay">
              <div className="timedassessment_popup">
                <p>Would you like to submit the code?</p>
                <div className="timedassessment_popup-buttons">
                  <button className="timedassessment_cancel-button" onClick={handleCancelSubmit}>No</button>
                  <button className="timedassessment_confirm-button" onClick={handleConfirmSubmit}>Yes</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Your loading bar */}
      {isLoadingProgress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            sx={{
              height: 2,
              backgroundColor: '#f0f0f0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#56965c',
              },
            }}
          />
        </Box>
      )}

      <div className="timedassessment-app-content">
        {!showSolution ? (
          <div className="timedassessment-left-side" style={{ width: `${leftWidth}%` }}>

              {isMobileView && !showOutput &&  (
              <img 
                src={isHovering_5 
                  ? (isLeftCollapsed ? closed_editor_section_4  : Closed_LeftSide_icon_light_4 )
                  : (isLeftCollapsed ? Closed_block_lcon_light_theme_3 : Closed_block_icon)
                }
                alt= "OPen and closed icon"
                className="timedassessment-header-toggle-icon"
                onClick={toggleLeftSide}
                onMouseEnter={() => setIsHovering_5(true)}  
                onMouseLeave={() => setIsHovering_5(false)}
                style={{
                  position: 'fixed',
                  top: '60px',
                  cursor: 'pointer',   
                  zIndex: '10' ,
                  marginBottom: '10px',
                }}
              />
            )}
            <div className="timedassessment-header" style={{ flexDirection: leftWidth >= 33.33 ? 'row' : 'column' }}>
              <div className="timedassessment-tab-buttons" style={{ alignSelf: leftWidth >= 33.33 ? '' : 'flex-start', marginLeft: leftWidth >= 33.33 ? '' : '32px' }}>
                <button
                  onClick={() => handleTabClick('challenge')}
                  className={activeTab === 'challenge' ? 'active' : ''}
                >
                  Challenge
                </button>
                <button
                  onClick={() => handleTabClick('instructions')}
                  className={activeTab === 'instructions' ? 'active' : ''}
                >
                  Instructions
                </button>
              </div>

              {/* Your pagination */}
              <div className="timedassessment-pagination" style={{ order: leftWidth >= 33.33 ? '0' : '-1', marginLeft: leftWidth >= 33.33 ? '' : '32px', alignSelf: leftWidth >= 33.33 ? '' : 'flex-start', marginBottom: leftWidth >= 33.33 ? '' : '20px' }}>
                <Tooltip title="Previous Question" arrow>
                  {currentIndex > 0 && (
                    <img
                      src={Backward_icon}
                      alt="Previous"
                      className="timedassessment-Backward-icon"
                      onClick={handleBackward}
                    />
                  )}
                </Tooltip>
                <span>{currentIndex + 1}/{questionArray.length}</span>
                {currentIndex < questionArray.length - 1 && (
                  <Tooltip title="Next Question" arrow>
                    <img
                      src={Fordward_icon}
                      alt="Next"
                      className="timedassessment-forward-icon"
                      onClick={handleForward}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="timedassessment-content-container">
              <div ref={challengeRef} className="timedassessment-content-section">
                <h2 className="timedassessment_challenge_title">Challenge</h2>
                <div className="timedassessment_challenge_pera" dangerouslySetInnerHTML={{ __html: content.challenge }} />
                {/* Test Cases Section */}
                <div className="timedassessment_challenge_pera">
                  {content.testCases && content.testCases.length > 0 && (
                    <div>
                      <h4 className="timedassessment_test_cases_title">Test Cases:</h4>
                      <ul>
                        {content.testCases.map((testCase, index) => (
                          <li key={index}>
                            <div dangerouslySetInnerHTML={{ __html: testCase.input }} />
                            <div dangerouslySetInnerHTML={{ __html: testCase.expected_result }} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div ref={instructionsRef} className="timedassessment-content-section">
                <div className="timedassessment-instructions-header">
                  <img src={current_Instruction_icon} alt="Instructions Icon" className="timedassessment-instruction-icon" />
                  <h2 className="timedassessment_challenge_title">Instructions</h2>
                </div>
                <div className="timedassessment_challenge_pera" dangerouslySetInnerHTML={{ __html: content.instructions }} />
              </div>
            </div>
          </div>
        ) : (
          <div className="timedassessment-left-side" style={{ width: `${leftWidth}%` }}>
            <div className="timedassessment-header">
              <div className="timedassessment-tab-back_buttons">
                <button onClick={handleCloseSolution}>Back</button>
              </div>
            </div>
            <div ref={solutionRef} className="timedassessment-content-section_solution">
              <h2 className="timedassessment_challenge_title">Solution</h2>
              <div className="timedassessment_challenge_pera" dangerouslySetInnerHTML={{ __html: content.solution }} />
            </div>
          </div>
        )}

        {/* Draggable divider */}
        <div className="timedassessment-divider" onMouseDown={startDraggingLeft}>
          <div
            className={`timedassessment_OpenClose_icon ${isLeftCollapsed ? 'collapsed' : ''}`}
            onClick={toggleLeftSide}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {!isOutputExpanded && (
              isLeftCollapsed ? (
                <img src={openLeftWindow_icon} alt="Expand" className="timedassessment_openLeftWindow_icon" />
              ) : (
                <img src={isHovering ? isDarkTheme ? Closed_LeftSide_icon_light_4 : Closed_block_icon :
                  isDarkTheme ? Closed_block_icon : Closed_LeftSide_icon_light_4}
                  alt="Collapse"
                  className="timedassessment_closed_block_icon"
                />
              )
            )}
          </div>
        </div>
        {/* Editor Section */}
        <div className="timedassessment-editor-section" style={{ width: `${editorWidth}%` }}>
          <MonacoEditor
            height="90.4%"
            defaultLanguage="python"
            defaultValue={defaultContent}
            options={{
              lineNumbers: 'on',
              wordWrap: 'off',
              scrollBeyondLastLine: false,
              selectOnLineNumbers: true,
              lineNumbersMinChars: 6,
              fontSize: 14,
              lineHeight: 1.8,
              minimap: {
                enabled: true,
                size: 'fit',
                scale: 1,
                side: 'right',
                showSlider: 'always',
                renderCharacters: true,
                maxColumn: 160
              }
            }}
            theme={isDarkTheme ? 'vs-dark' : 'light'}
            onMount={(editor) => {
              editorRef.current = editor;
              editorRef.current.setPosition({ lineNumber: 1, column: 1 });
              editorRef.current.focus();
            }}
          />
          <div className="timedassessment-editor-footer">
            <div className="timedassessment-reset-container" onClick={resetEditor}>
              <img src={isDarkTheme ? reset_icon : light_theme_reset_icon} alt="Reset Icon" className="timedassessment-reset-button" />
              <p className='timedassessment_reset_text'>Reset</p>
            </div>
            <div className="timedassessment-run-container">
              <button className="timedassessment-run-button" onClick={runCode} disabled={isLoading}>
                {isLoading ? (
                  <div className="timedassessment-loader"></div>
                ) : (
                  <>
                    <img src={isDarkTheme ? Play_icon : light_theme_play_icon} alt="Play Icon" className="timedassessment_run-code-icon" />
                    Run code
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {showOutput && (
          <div className="timedassessment-editor-output-divider" onMouseDown={startDraggingEditorOutput}>
          </div>
        )}

        {showOutput && (
          <div className="timedassessment-output-section" style={{ width: `${outputWidth}%` }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="timedassessment-output-header">
              <span className="timedassessment-output-title">Output</span>
              <Tooltip title="Maximize" arrow>
                <img
                  src={isHovering_2 ? isDarkTheme ? output_maximize_light_icon : output_maximize_icon :
                    isDarkTheme ? output_maximize_icon : output_maximize_light_icon}
                  alt="Expand Icon"
                  className="timedassessment-expand-icon"
                  onClick={expandOutput}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setIsHovering_2(true)}
                  onMouseLeave={() => setIsHovering_2(false)}
                />
              </Tooltip>
              <img
                src={isHovering_3 ? isDarkTheme ? Close_Outputsection_dark_theme_icon : Close_Outputsection_light_theme_icon :
                  isDarkTheme ? Close_Outputsection_light_theme_icon : Close_Outputsection_dark_theme_icon}
                alt="Close Icon"
                className="timedassessment-close-icon"
                onClick={closeOutput}
                onMouseEnter={() => setIsHovering_3(true)}
                onMouseLeave={() => setIsHovering_3(false)}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              />
            </div>
            <div className="timedassessment_textarea-container">
              {(isLoading || input_Submitting) && (
                <div className="timedassessment_textarea-loader">
                  <div className="timedassessment_loader-2"></div>
                </div>
              )}
              <textarea
                ref={terminalRef}
                value={terminalContent + (waitingForInput ? userInput : '')}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleWebSocketInput(userInput.trim());
                  }
                }}
                style={{
                  width: '100%',
                  height: '70vh',
                  color: isError ? 'red' : '#56965c',
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  padding: '10px',
                  border: 'none',
                  resize: 'none',
                  outline: 'none',
                }}
                className='timedassessment-terminal-textarea'
              />
            </div>
            <div className={`timedassessment-output-console ${isError ? 'error' : ''}`}>
              {outputs.map((output, index) => (
                <div key={index} style={{ whiteSpace: 'pre-wrap' }}>{output}</div>
              ))}

              {waitingForInput && (
                <div className="timedassessment-input-prompt">
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Enter your input here"
                    className="timedassessment_custom-input-field"
                  />
                  <button onClick={handleSubmitInput} className="timedassessment_custom-submit-button_2">
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}


export default TimedAssessment_lab

