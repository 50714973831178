import React, { useState, useEffect } from 'react';
import '../assets/Css/LabLists.css';
import list_author_icon from '../assets/Images/hugeicons_mentor (1).png';
import lB_image from '../assets/Images/L_L_B.png';
import { useNavigate } from 'react-router-dom';
import { adminaxios } from '../axios';
import { useSelector, useDispatch } from 'react-redux';
import { setParticularLabId } from '../actions/apisIdsActions';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Sidebar2 from '../Sidebar/Sidebar2';

const LabLists = () => {
  const { id: courseId } = useParams();
  const [cardlist, setCardlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authorname = useSelector((state) => state.auth.username);
  const dynamicModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  const dynamicTopicId = useSelector((state) => state.dynamicApiId.topicId);
  const dynamicLabId = useSelector((state) => state.dynamicApiId.labConfigId);
  const isCheckingLabAssesmentTrue = useSelector((state)=>state.dynamicApiId.assesmentLabCheck)

  useEffect(() => {
    if (dynamicLabId) {
      adminaxios.get(`modules/${dynamicModuleId}/topics/${dynamicTopicId}/question_bank_config/${dynamicLabId}/`)
        .then(response => {
          setCardlist(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [dynamicModuleId, dynamicTopicId, dynamicLabId]);

  const handleClick = (labId) => {
    localStorage.setItem('each_lab_id', labId);
    dispatch(setParticularLabId(labId));
    navigate(isCheckingLabAssesmentTrue ?`/timed-assessment-lab/${courseId}`  :`/lab/${courseId}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); 
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const difficultyLevels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];

  return (
    <div style={{display:'flex',justifyContent:'flex-start'}}>
    {/* <Sidebar2 courseId={courseId} /> */}
    <div className="List_App">
      <h1 style={{alignSelf:'center'}}>{isCheckingLabAssesmentTrue ? 'Timed Lab' : ''}</h1>
      <h2 className='list_h_2'>Instructions:</h2>
      <ul className='list_ul'>
        <li>FEC officials are divided over whether to require disclosure of AI-generated content in political ads.</li>
        <li>FCC Chair Rosenworcel supports the proposal, citing concerns about misleading deep fakes.</li>
        <li>Vice Chair Weintraub agrees.</li>
      </ul>
      <div className="List_CardContainer">
        {loading ? (
          <Box sx={{ width: '100%' }}>
            <Skeleton variant="rectangular" width="100%" height={160} sx={{ mb: 2, mt: 2, borderRadius: 10 }} />
            <Skeleton variant="rectangular" width="100%" height={160} sx={{ mb: 2, mt: 2, borderRadius: 10 }} />
            <Skeleton variant="rectangular" width="100%" height={160} sx={{ mb: 2, mt: 2, borderRadius: 10 }} />
          </Box>
        ) : (
          cardlist?.question_bank_configs?.questions?.map(item => (
            <div className="List_Card" key={item._id}>
              <img src={lB_image} alt="author_icon" className="Lab_list_image" />
              <div className='List_card_containar2'>
                <div className="List_CardHeader">
                  <div 
                  className="List_CardTitle" 
                  dangerouslySetInnerHTML={{ __html: item?.title }}
                  />
                  <span><button className='status-changes'>Status</button></span>
                </div>
                <div 
                  className="List_CardContent" 
                  dangerouslySetInnerHTML={{ __html: item?.description }} 
                />
                
                <div className="List_CardAuthor">
                  <img src={list_author_icon} alt="author_icon" className="List_Author_icon" />
                  <span>{authorname}Dataworks</span>
                </div>
                <div className="List_CardContent difficulty-container">
                  {difficultyLevels.map(level => (
                    <span 
                    key={level} 
                    className={`difficulty-level ${level.toLowerCase()}`}
                    style={{
                      fontWeight: item?.difficulty?.toLowerCase() === level.toLowerCase() ? 'bold' : 'normal'
                    }}
                  >
                    {capitalizeFirstLetter(level)}
                  </span>
                  ))}
                </div>
              </div>
              <button className="List_CardButton" onClick={() => handleClick(item._id)}>Start</button>
            </div>
          ))
        )}
      </div>
    </div>
    </div>
  );
};

export default LabLists;
