
// import React from 'react';
// import { Box, Grid, Typography } from '@mui/material';
// import tick from "../../assets/Images/correcttick.jpg";

// const Whatiwilllearn = () => {
   
//   const content = (
//     <Box display="flex" alignItems="center">
//         <img
//         src={tick} alt='img'
//         style={{height:'25px',
//                 width:'25px',
//             marginRight:'12px'}}
//         />
      
//       <Typography variant="body2">
//         Meanwhile, UX design addresses the overall experience, emphasizing usability, accessibility, and the flow of interactions. By conducting user research, wireframing, prototyping.
//       </Typography>
//     </Box>
//   );

//   return (
//     <Box>
//       <Typography sx={{fontSize:'25px',paddingLeft:'20px',paddingBottom:'20px'}}>What will you learn</Typography>
//     <Box
//       sx={{
//         padding: 5, // Reduced padding for a smaller box size
//         borderRadius: 3, // Slightly reduced border radius
//         boxShadow: 1, // Reduced shadow for a less prominent effect
//         backgroundColor: '#f8f8f8',
//         maxWidth: '90%', // Set a maximum width to limit box size
//         margin: '0 auto', // Center the box
//       }}
      
//     >
        
//       <Grid container spacing={3}> {/* Reduced spacing between items */}
//         {Array.from({ length: 8 }).map((_, index) => (
//           <Grid item xs={12} sm={6} md={6} key={index}>
//             {content}
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//     </Box>
//   );
// };

// export default Whatiwilllearn;



import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import tick from '../../assets/Images/hugeicons_tick-double-02.png';

const Whatiwilllearn = () => {
  const points = [
    "Learn how to navigate through a modern LMS interface.",
    "Understand how to create and manage courses effectively.",
    "Explore techniques for monitoring and tracking student progress.",
    "Gain insights into creating interactive quizzes and assignments.",
    "Master the use of analytics tools to enhance learning outcomes.",
    "Understand the integration of multimedia content for engaging learning.",
    "Learn to implement role-based access for students, instructors, and admins.",
    "Discover the best practices for managing certifications and course completion reports."
  ];

  return (
    <Box>
      <Typography variant="h6" sx={{ fontSize: '16px', fontWeight:"800",marginBottom:'3%',boxShadow:'none' }}>
        What will you learn ?
      </Typography>
      <Box
        sx={{
          padding: 5, // Reduced padding for a smaller box size
          borderRadius: 3, // Slightly reduced border radius
          boxShadow: 1, // Reduced shadow for a less prominent effect
          backgroundColor: '#f8f8f8',
          maxWidth: '90%', // Set a maximum width to limit box size
          margin: '0 auto', // Center the box
        }}
      >
        <Grid container spacing={3}> {/* Reduced spacing between items */}
          {points.map((point, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Box display="flex" alignItems="center" sx={{color: '#666666'}}>
                <img
                  src={tick}
                  alt="img"
                  style={{
                    height: '25px',
                    width: '25px',
                    marginRight: '12px',
                  }}
                />
                <Typography sx={{fontSize:'13px',fontFamily:'Poppins ,sans-serif'}}variant="body2">{point}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Whatiwilllearn;
