import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { adminaxios } from '../axios';
import { toggleContent } from '../actions/contentActions';
import Like_icon_1 from '../assets/Images/like_icon_1.png';
import dislike_icon_1 from '../assets/Images/Dislike_icon_1.jpeg';
import reply_icon_1 from '../assets/Images/Reply_icon_1.png';
import send_icon_1 from '../assets/Images/icon_comment_ask_post.png'
import gallery_icon_1 from '../assets/Images/gallery_comment_ask_post.png';
import close_comments_icon from "../assets/Images/Close_Comments_icon.png";
import Profile_icon_1 from "../assets/Images/profile_icon_1.png";
import likethin from '../assets/Images/like_askme_thin.png';
import dislike_thick from '../assets/Images/dislike_askme_thick.png';
import closeCross from '../assets/Images/askme_close_Cross.png'
import './CommentSection.css'
 
const CommentSection = ({ handleAskContModal, handleToggleNotes }) => {
    const [postData, setPostData] = useState([]);
    const [textData, setTextData] = useState('');
    const [fileData, setFileData] = useState(null);
    const [replyTextData, setReplyTextData] = useState('');
    const [editTextData, setEditTextData] = useState('');
    const [replyEditTextData, setReplyEditTextData] = useState('');
    const [commentQustionId, setCommentQustionId] = useState(null);
    const [commentEditQustionId, setCommentEditQustionId] = useState(null);
    const [replyEditId, setReplyEditId] = useState(null);
    const [likeTrigger, setLikeTrigger] = useState(0);
    const [disLikeTrigger, setDisLikeTrigger] = useState(0);
    const [replyLikeTrigger, setReplyLikeTrigger] = useState(0);
    const [replyDisLikeTrigger, setReplyDisLikeTrigger] = useState(0);
    const [showExitPopup, setShowExitPopup] = useState(false);
    const [showEditLimitPopup, setShowEditLimitPopup] = useState(false);
    const [editLimitResponse, setEditLimitResponse] = useState('');
    const [showReplyEditLimitPopup, setShowReplyEditLimitPopup] = useState(false);
    const [replyEditLimitResponse, setReplyEditLimitResponse] = useState('');
    const [replyLikeActive,setReplyLikeActive] = useState(false);
    const [replyDisLikeActive,setReplyDisLikeActive] = useState(false);
    const [likesStatus, setLikesStatus] = useState({});
    const [dislikesStatus, setDislikesStatus] = useState({});
    const [replyLikesStatus, setReplyLikesStatus] = useState({});
    const [replyDislikesStatus, setReplyDislikesStatus] = useState({});


    const currentVideoId = useSelector((state) => state.dynamicApiId.videoId);
    const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
    const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
    const username = localStorage.getItem('user_name');
    const firstName = username.split(' ')[0]; 
    const FinalName =  firstName.charAt(0).toUpperCase() + firstName.slice(1)
    const dispatch = useDispatch();
 
    const handleContinueCourse = () => {
        setShowExitPopup(false);
        dispatch(toggleContent());
    };
 
    const handleEditLimitClose = () => {
        setShowEditLimitPopup(false);
        setCommentEditQustionId(null);
    };
 
    const handleReplyEditLimitClose = () => {
        setShowReplyEditLimitPopup(false);
        setReplyEditId(null);
    };
 
    const handleTextChange = (e) => {
        setTextData(e.target.value);
    };
 
    const handleReplyTextChange = (e) => {
        setReplyTextData(e.target.value);
    };
 
    const handleEditTextChange = (e) => {
        setEditTextData(e.target.value);
    };
 
    const handleReplyEditTextChange = (e) => {
        setReplyEditTextData(e.target.value);
    };
 
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'application/msword')) {
            setFileData(file);
        } else {
            alert('Please upload a file in PDF, PNG, or DOC format');
        }
    };
 
    const handlePostClick = async () => {
        const formData = new FormData();
        formData.append('question', textData);
        if (fileData) {
            formData.append('file', fileData);
        }
 
        handleAskContModal(formData);
        setTextData('');
        setFileData(null);
    };
 
    useEffect(() => {
        adminaxios
            .get(`ask_me/course/${storeCourseId}/module/${selectedModuleId}/content/${currentVideoId}/`)
            .then((response) => {
                setPostData(response?.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [storeCourseId, selectedModuleId, currentVideoId, likeTrigger, disLikeTrigger, replyLikeTrigger, replyDisLikeTrigger]);
 
    const handleReplyPost = (questionId) => {
        setCommentQustionId(commentQustionId === questionId ? null : questionId);
    };
 
    const handleReplyPostClick = async () => {
        const data = { comment: replyTextData };
        try {
            const response = await adminaxios.post(`ask_me/reply/questions/${commentQustionId}/`, data, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200 || response.status === 201) {
                setReplyTextData('');
                setCommentQustionId(null);
                setShowExitPopup(true);
            }
        } catch (err) {
            console.error('Error:', err);
        }
    };
 
    const handleLikeQuestion = async (questionId) => {
        try {
            const response = await adminaxios.post(`ask_me/questions/${questionId}/like/`, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200 || response.status === 201) {
                setLikesStatus((prev) => ({
                    ...prev,
                    [questionId]: true,
                }));
                setDislikesStatus((prev) => ({
                    ...prev,
                    [questionId]: false, // Reset dislike if it was active
                }));
                setLikeTrigger((prev) => prev + 1); // Optional, if fetching data
            }
        } catch (err) {
            console.error('Error liking question:', err);
        }
    };
    
    const handleDisLikeQuestion = async (questionId) => {
        try {
            const response = await adminaxios.post(`ask_me/questions/${questionId}/dislike/`, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200 || response.status === 201) {
                setDislikesStatus((prev) => ({
                    ...prev,
                    [questionId]: true,
                }));
                setLikesStatus((prev) => ({
                    ...prev,
                    [questionId]: false, // Reset like if it was active
                }));
                setDisLikeTrigger((prev) => prev + 1); // Optional, if fetching data
            }
        } catch (err) {
            console.error('Error disliking question:', err);
        }
    };
    
 
    const handleReplyLike = async (questionId, replyId) => {
        try {
            const response = await adminaxios.post(`ask_me/questions/${questionId}/reply/${replyId}/like/`, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200 || response.status === 201) {
                setReplyLikesStatus((prev) => ({
                    ...prev,
                    [replyId]: true,
                }));
                setReplyDislikesStatus((prev) => ({
                    ...prev,
                    [replyId]: false, // Reset dislike if it was active
                }));
                setReplyLikeTrigger((prev) => prev + 1); // Optional, if fetching data
            }
        } catch (err) {
            console.error('Error liking reply:', err);
        }
    };
    
    const handleReplyDisLike = async (questionId, replyId) => {
        try {
            const response = await adminaxios.post(`ask_me/questions/${questionId}/reply/${replyId}/dislike/`, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200 || response.status === 201) {
                setReplyDislikesStatus((prev) => ({
                    ...prev,
                    [replyId]: true,
                }));
                setReplyLikesStatus((prev) => ({
                    ...prev,
                    [replyId]: false, // Reset like if it was active
                }));
                setReplyDisLikeTrigger((prev) => prev + 1); // Optional, if fetching data
            }
        } catch (err) {
            console.error('Error disliking reply:', err);
        }
    };
    
    
 
    const handleQuestionEdit = (questionId, commentText) => {
        setCommentEditQustionId(commentEditQustionId === questionId ? null : questionId);
        setEditTextData(commentText);
    };
 
    const handleUpdateComment = async (questionId) => {
        try {
            const formData = new FormData();
            formData.append('comment', editTextData);
 
            const response = await adminaxios.post(`ask_me/questions/${questionId}/edit/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.status === 200 || response.status === 201) {
                setCommentEditQustionId(null);
                setEditTextData('');
                setLikeTrigger((prev) => prev + 1);
            }
        } catch (err) {
            console.error('Error updating comment:', err);
            if (err.response.status === 403 || err.response.statusText === 'Forbidden') {
                setShowEditLimitPopup(true);
                setEditLimitResponse('You crossed the edit limit!');
            }
        }
    };
 
    const handleReplyEdit = (replyId, replyText) => {
       
        if (replyEditId === replyId) {
            setReplyEditId(null);
            setReplyEditTextData('');
        } else {
            setReplyEditId(replyId);
            setReplyEditTextData(replyText);
        }
    };
   
 
    const handleReplyUpdate = async (questionId,replyId) => {
        try {
            const formData = new FormData();
            formData.append('comment', replyEditTextData);
 
            const response = await adminaxios.post(`ask_me/questions/${questionId}/reply/${replyId}/edit/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.status === 200 || response.status === 201) {
                setReplyEditId(null);
                setReplyEditTextData('');
                setReplyLikeTrigger((prev) => prev + 1);
            }
        } catch (err) {
            console.error('Error updating reply:', err);
            if (err.response.status === 403 || err.response.statusText === 'Forbidden') {
                setShowReplyEditLimitPopup(true);
                setReplyEditLimitResponse('You have exceeded the reply edit limit!');
            }
        }
    };
 
   
 

    return (    
        <div className="comment-section">
            <div className='ask-me-comment-cls-btn-container'>
                <button className='ask-me-comment-cls-btn'>
                <img src={closeCross} alt="Post" className="close_comments_icon" onClick={handleToggleNotes}/>  
                </button>
            </div>
            <div className="header-comment-section-ask-me">
                {/* <button onClick={handleToggleNotes} className="ask-me-close-lms">Close</button> */}
                <h1>Hi {FinalName},</h1>
                <h2>What would you like to know</h2>
            </div>
            <div className="input-area">
                <textarea
                    placeholder="Ask whatever you want..."
                    onChange={handleTextChange}
                    value={textData}
                ></textarea>
                <div className="input-icons">  
                    <label htmlFor="file-upload" className="file-icon-1" title="Upload File">
                    <img src={gallery_icon_1} alt="Post" className="file-icon-1" />
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        accept=".pdf,.png,.doc"
                        onChange={handleFileChange}
                        className="file-upload"
                    />
                    <span className="icon post-icon" onClick={handlePostClick} title="Post">
                        <img src={send_icon_1} alt="Post" className="send_icon_1" />
                    </span>
                </div>
            </div>
            <div className="comments-header">
                <h3>Comments</h3>
                    <select className="sort-options">
                        <option>Most recent</option>
                        <option>Oldest</option>
                    </select>
            </div>
            <div className="comment-cont">
                {postData?.map((comment) => (
                    <div key={comment?._id} className="comment">
                        <div className="comment-header">
                            <div className="comment-info">
                                <img src={Profile_icon_1} alt="Profile" className="profile-icon" />
                                <span className="author">{comment?.user_name}</span>
                            </div>
                            <span className="time">{comment?.date}</span>
                        </div>
                        <p className={`comment-text ${commentQustionId === comment?._id ? 'reply-active' : ''}`}>
                            {comment?.comment}
                        </p>
                           {commentEditQustionId === comment?._id ? (
                            <div className="edit-area">
                                <textarea
                                    className="edit-textarea"
                                    value={editTextData}
                                    onChange={handleEditTextChange}
                                />
                                <div className="icon-container">
                                    <img src={send_icon_1} alt="Send" className="send-icon-image" onClick={() => handleUpdateComment(comment?._id)} />
                                </div>
                            </div>
                        ) : (
                            // <p className="comment-text">{comment?.comment}</p>
                            ''
                        )}
                        <div className="comment-actions">
                        <span className="like" onClick={() => handleLikeQuestion(comment?._id)}>
                                <img
                                    src={likesStatus[comment?._id] ? Like_icon_1 : likethin}
                                    alt="Like"
                                    className="Like-icon_1"
                                />
                                {comment?.likes}
                            </span>
                            <span className="dislike" onClick={() => handleDisLikeQuestion(comment?._id)}>
                                <img
                                    src={dislikesStatus[comment?._id] ? dislike_thick : dislike_icon_1}
                                    alt="Dislike"
                                    className="Like-icon_1"
                                />
                                {comment?.dislikes}
                            </span>
                            <span className={`reply ${commentQustionId === comment?._id ? 'reply-active' : ''}`} onClick={() => handleReplyPost(comment?._id)}>
                                <img src={reply_icon_1} alt="Reply" className="reply_icon_1" />
                                {comment?.replies?.length > 1 &&  `${comment?.replies?.length} Replies` || `${comment?.replies?.length} Reply` }
                            </span>
                            <span className={`options ${commentEditQustionId === comment?._id ? 'reply-active' : ''}`} onClick={() => handleQuestionEdit(comment?._id, comment?.comment)}>
                                Edit
                            </span>
                        </div>

                        {commentQustionId === comment?._id && (
                            <div className="input-area reply-input-area">
                                <textarea
                                    placeholder="Write your reply..."
                                    onChange={handleReplyTextChange}
                                    value={replyTextData}
                                ></textarea>
                                <span className="send-icon" onClick={handleReplyPostClick}>
                                    <img src={send_icon_1} alt="Send" className="send-icon-image" />
                                </span>
                            </div>
                        )}
                        {commentQustionId === comment?._id  && 
                        <div className="replies">
                            {comment?.replies?.map((reply) => (
                                <div key={reply?._id} className="comment reply-comment">
                                    <div className="comment-header">
                                        <span className="author">{reply?.user_name}</span>
                                        <span className="time">{reply?.date}</span>
                                    </div>
                                    {replyEditId === reply?._id ? (
                                        <div className="edit-area">
                                            <textarea
                                                className="edit-textarea"
                                                value={replyEditTextData}
                                                onChange={handleReplyEditTextChange}
                                            />
                                            <div className="icon-container">
                                                <img
                                                    src={send_icon_1}
                                                    alt="Send"
                                                    className="send-icon-image"
                                                    onClick={() => handleReplyUpdate(comment?._id, reply?._id)}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="comment-text">{reply?.comment}</p>
                                    )}
                                    <div className="comment-actions">
                                        <span
                                            className="like"
                                            onClick={() => handleReplyLike(comment?._id, reply?._id)}
                                        >
                                            <img
                                                src={replyLikesStatus[reply?._id] ? Like_icon_1 : likethin}
                                                alt="Like"
                                                className="Like-icon_1"
                                            />
                                            {reply?.likes}
                                        </span>
                                        <span
                                            className="dislike"
                                            onClick={() => handleReplyDisLike(comment?._id, reply?._id)}
                                        >
                                            <img
                                                src={replyDislikesStatus[reply?._id] ? dislike_thick : dislike_icon_1}
                                                alt="Dislike"
                                                className="Like-icon_1"
                                            />
                                            {reply?.dislikes}
                                        </span>
                                        <span
                                            className={`options ${replyEditId === reply?._id ? 'reply-active' : ''}`}
                                            onClick={() => handleReplyEdit(reply?._id, reply?.comment)}
                                        >
                                            Edit
                                        </span>
                                        
                                    </div>
                                </div>
                            ))}

                        </div>
                        }       
                    </div>
                ))}
            </div>
            {showExitPopup && (
                <div className="course-exit-popup-container">
                    <div className="course-exit-popup">
                        <p>Successfully Your Reply Is Posted</p>
                        <button onClick={handleContinueCourse}>Exit</button>
                    </div>
                </div>
            )}
            {showEditLimitPopup && (
                <div className="course-exit-popup-container">
                    <div className="course-exit-popup">
                        <p>{editLimitResponse}</p>
                        <button onClick={handleEditLimitClose}>Close</button>
                    </div>
                </div>
            )}
            {showReplyEditLimitPopup && (
                <div className="course-exit-popup-container">
                    <div className="course-exit-popup">
                        <p>{replyEditLimitResponse}</p>
                        <button onClick={handleReplyEditLimitClose}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};
 
export default CommentSection;