const initialState = {
    query: ''  // Ensure there is a default value for `query`
  };
  
  const searchReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SEARCH_QUERY':
        return {
          ...state,
          query: action.payload
        };
      default:
        return state;
    }
  };
  
  export default searchReducer;
