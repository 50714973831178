import React, { useState, useEffect, useRef } from 'react';  // Make sure to import useEffect and useRef
import { useNavigate } from 'react-router-dom';
import '../assets/Css/Header2.css';
import image2 from '../assets/Images/cloud_sync_company_logo.png';
import profilePic from '../assets/Images/profile.png';
import notificationIcon from '../assets/Images/notification.png';
import { Search } from 'react-feather';
import settingsIcon1 from '../assets/Images/headerSettinfgs.png';
import enrollmentIcon from '../assets/Images/Enrollments.png';
import achievementIcon from '../assets/Images/Achievements.png'; 
import supportIcon from '../assets/Images/message-question.png';
import logoutIcon from '../assets/Images/headerLogout.png';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { logoutaxios } from '../axios';
import { clearCredentials } from '../reducers/authSlice'; // assuming you have an action to clear credentials in your authSlice
import { courseType } from '../actions/typeOfCourseActions';
import skill_synq_logo from '../assets/Images/Skillsynqlogo.png';

const Header2 = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const progress = 80;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const popupRef = useRef(null); // Use ref to keep track of the popup

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };



  // Function to toggle the popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside of the popup
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible]);

  const navigateToSettings = () => {
    navigate('/profile-settings');
  };

  // const username = useSelector((state) => state.auth.username) || 'CharanTeja';
  // // const refreshToken = useSelector((state) => state.auth.refreshToken);
  // const role = useSelector((state) => state.auth.role) || 'Learner';
  const localRefreshToken = localStorage.getItem("refresh_token")
    const username =  localStorage.getItem('user_name');
    const role =   localStorage.getItem('user_role');
 
  const handleToCourseListPage = () => {
    navigate('/course-list')
    dispatch(courseType());
  }

  // const logoutWithJwt = async () => {
  //   const data = { refresh_token: localRefreshToken };
  //   setLoading(true);
    

  //   try {
  //     const response = await logoutaxios.post('/logout/', data);

  //     if (response.data.message === "Successfully logged out.") {
  //       console.log('Logout successful:', response.data.message);
  //       localStorage.removeItem('tokens');
  //       //localStorage.removeItem('refresh_token');
  //       dispatch(clearCredentials());
  //       navigate('/');
  //     } else {
  //       console.error('Unexpected logout response:', response);
  //     }
  //   } catch (err) {
  //     console.error('Logout error:', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const logoutWithJwt = async () => {
    const data = { refresh_token: localRefreshToken };
    setLoading(true);
  
    try {
      const response = await logoutaxios.post('/logout/', data);
  
      if (response.status === 401) {
        console.log('Unauthorized - Logging out');
        localStorage.removeItem('tokens');
        dispatch(clearCredentials());
        navigate('/');
      } else if (response.data.message === "Successfully logged out.") {
        console.log('Logout successful:', response.data.message);
        localStorage.removeItem('tokens');
        dispatch(clearCredentials());
        navigate('/');
      } else {
        console.error('Unexpected logout response:', response);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.log('Logout error - Unauthorized:', err.response.status);
        localStorage.removeItem('tokens');
        dispatch(clearCredentials());
        navigate('/');
      } else {
        console.error('Logout error:', err);
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleAchievements = () => {
    navigate('/certificate');
  };

  return (
    <header className="basic-header-header-container">
      <div className="basic-header-logo" onClick={handleToCourseListPage}>
        <img src={skill_synq_logo} alt="DataWorks Logo" />
      </div>
      <div className="basic-header-search-bar">
        <input type="text" placeholder="Search " className="basic-header-search-bar-input" />
        <Search className="basic-header-search-icon" />
      </div>
      <div className="basic-header-user-info">
        <img src={notificationIcon} alt="Settings" className="basic-header-settings-icon" />
        <div className="basic-header-divider"></div>
        <div className="basic-header-profile-container" onClick={toggleDropdown}>
          <img src={profilePic} alt="User Profile" className="basic-header-profile-img" />
          <div className='popup-details'>
            <p className="popup-username">{username.split(' ')[0]}</p>
            <div className='popup-role-arrow' onClick={togglePopup} >
    
            <p className="popup-role">{role}</p>
            <span className={`expand-arrow-functionality ${isPopupVisible ? "up" : "down"}`}></span>
            <div className={`expand-arrow-show ${isPopupVisible  ? 'show' : ''}`}>
              </div>
          </div>
          </div>
        </div>
        {dropdownVisible && (
          <div className="basic-header-popup-menu" ref={popupRef}>
           <div className="popup-actions">
              <div className="popup-action-item" >
                <img src={enrollmentIcon} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text">Enrollments</span>
              </div> 
              <div className="popup-action-item" >
                <img src={achievementIcon} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text" onClick={handleAchievements}>Achievements</span>
              </div>
              <div className="popup-action-item" onClick={navigateToSettings}>
                <img src={settingsIcon1} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text">Settings</span>
              </div>
              <div className="popup-action-item" >
                <img src={supportIcon} alt="Settings Icon" className="popup-action-icon" />
                <span className="popup-action-text">Support</span>
              </div>
              <div className="popup-action-item" onClick={logoutWithJwt}>
                <img src={logoutIcon} alt="Logout Icon" className="popup-action-icon" />
                <span className="popup-action-text">Logout</span>
                {loading && (
                  <CircularProgress size={8} color="inherit" style={{ marginLeft: '10px', marginTop: '3px' }} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header2;








