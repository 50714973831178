// import React, { useState,useEffect } from 'react';
// import { Card, CardContent, Typography, LinearProgress, Box, Button } from '@mui/material';
// import { styled } from '@mui/system';
// import {useSelector } from 'react-redux';
// import { adminaxios } from '../axios';
// import coinImage from '../assets/Images/lms_dashboard_coins.png';
// import tortoise from '../assets/Images/lms_dashboard_tortoise.png';
// import rocketImage from '../assets/Images/lms_dashboard_rocket.png';


// const StyledHeaderCard = styled(Card)(() => ({
//   borderRadius: '20px',
//   Width: '100%',
//   marginLeft: '20px',
//   position: 'relative',
//   boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
//   overflow: 'hidden',
//   padding: '1px',
// }));

// const ProgressBox = styled(Box)(() => ({
//   display: 'flex',
//   alignItems: 'center',
//   gap: '8px',
// }));

// const ContinueButton = styled(Button)(() => ({
//   backgroundColor: '#000080',
//   color: 'white',
//   borderRadius: '24px',
//   padding: '8px 0px',
//   fontWeight: 'bold',
//   marginTop: '16px',
//   textTransform: 'none',
// }));

// const CoinIcon = styled('img')(() => ({
//   width: '38px',
//   height: '36px',
//   marginLeft: '4px',
// }));

// const BackgroundImageBox = styled(Box)(() => ({
//   position: 'absolute',
//   bottom: 0,
//   top: 30,
//   right: 0,
//   width: '40%',
//   height: '100%',
//   backgroundImage: `url(${rocketImage})`,
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'right bottom',
//   opacity: 1,
//   zIndex: 0,
// }));

// const HeaderCard = () => {

//   const [progressData, setProgressData] = useState('');
//   const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
//   const roleBasedProgressValue = localStorage.getItem('roleBasedProgress');
//   const TabName = localStorage.getItem('whichTab');

//   // console.log(roleBasedProgressValue.role_progress_percentage)
//   console.log(TabName)

//   useEffect(() => {
  
//     const fetchProgressData = async () => {
//       try {
//         const progressResponse = await adminaxios.get(`/skill_based_progress/${storeCourseId}/`);
//         setProgressData(progressResponse.data); // Store the skill-based progress data
//       } catch (error) {
//         console.error("Error fetching skill-based progress:", error);
//       }
//     };

   
//     fetchProgressData(); // Fetch progress data when component mounts
//   }, [storeCourseId]);

//   return (
 
//   <StyledHeaderCard>
//     <BackgroundImageBox />
//     <CardContent sx={{ position: 'relative', zIndex: 1 }}>
//       <Typography variant="h4" fontWeight="600" fontFamily="poppins" lineHeight="42px" textAlign="justified" color="#985DF6" gutterBottom>
//         "Hurry up! Don't miss out on your 50
//         <CoinIcon src={coinImage} alt="coin icon" /> coins."
//       </Typography>

//       <Typography variant="" fontWeight="500" color="#000080">
//         Methods and Techniques for Empathy<br />
//       </Typography>

//       <Typography variant="" fontWeight="300" color="#252525">
//         Complete the Module on Time! <strong fontWeight="bold" color="black"> End 27 OCT 2024</strong>
//       </Typography>

//       <Box mt={1}>
//         <ProgressBox>
//           <LinearProgress
//             variant="determinate"
//             value={10}
//             sx={{
//               width: '45%',
//               backgroundColor: '#e0e0e0',
//               '& .MuiLinearProgress-bar': {
//                 backgroundColor: '#000080',
//               },
//             }}
//           />
//           <Box display="flex" alignItems="center" gap="4px">
//             <img src={tortoise} alt="Tortoise" style={{ width: '16px', height: '16px' }} />
//             <Typography variant="body2" color="error">{TabName === 'skillbased' ? `${(progressData?.skill_progress_percentage || 0).toFixed(2)}%` : `${(roleBasedProgressValue?.role_progress_percentage || 0).toFixed(2)}%` }
//             </Typography>
//           </Box>
//         </ProgressBox>
//       </Box>

//       <ContinueButton variant="contained" sx={{ width: '15%' }}>
//         Continue
//       </ContinueButton>
//     </CardContent>
//   </StyledHeaderCard>
  
// )

// }

// export default HeaderCard;


// import React, { useState, useEffect } from 'react';
// import { Card, CardContent, Typography, LinearProgress, Box, Button } from '@mui/material';
// import { styled } from '@mui/system';
// import { useSelector } from 'react-redux';
// import { adminaxios } from '../axios';
// import coinImage from '../assets/Images/lms_dashboard_coins.png';
// import tortoise from '../assets/Images/lms_dashboard_tortoise.png';
// import rocketImage from '../assets/Images/lms_dashboard_rocket.png';

// const StyledHeaderCard = styled(Card)(() => ({
//   borderRadius: '20px',
//   width: '100%',
//   marginLeft: '20px',
//   position: 'relative',
//   boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
//   overflow: 'hidden',
//   padding: '1px',
// }));

// const ProgressBox = styled(Box)(() => ({
//   display: 'flex',
//   alignItems: 'center',
//   gap: '8px',
// }));

// const ContinueButton = styled(Button)(() => ({
//   backgroundColor: '#000080',
//   color: 'white',
//   borderRadius: '24px',
//   padding: '8px 0px',
//   fontWeight: 'bold',
//   marginTop: '16px',
//   textTransform: 'none',
// }));

// const CoinIcon = styled('img')(() => ({
//   width: '38px',
//   height: '36px',
//   marginLeft: '4px',
// }));

// const BackgroundImageBox = styled(Box)(() => ({
//   position: 'absolute',
//   bottom: 0,
//   top: 30,
//   right: 0,
//   width: '40%',
//   height: '100%',
//   backgroundImage: `url(${rocketImage})`,
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'right bottom',
//   opacity: 1,
//   zIndex: 0,
// }));

// const HeaderCard = () => {
//   const [progressData, setProgressData] = useState(null);
//   const currentcourseId = useSelector((state) => state.dynamicApiId.courseId);
//   const TabName = localStorage.getItem('whichTab');

//   useEffect(() => {
//     const fetchProgressData = async () => {
//       try {
//         if (currentcourseId) {
//           const response = await adminaxios.get(`/user_progress/${currentcourseId}/`);
//           const courseData = response.data?.courses?.find(
//             (course) => course.course_id === currentcourseId
//           );
//           if (courseData) {
//             setProgressData(courseData.course_progress_percentage);
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching course progress data:', error);
//       }
//     };

//     fetchProgressData();
//   }, [currentcourseId]);

//   return (
//     <StyledHeaderCard>
//       <BackgroundImageBox />
//       <CardContent sx={{ position: 'relative', zIndex: 1 }}>
//         <Typography
//           variant="h4"
//           fontWeight="600"
//           fontFamily="poppins"
//           lineHeight="42px"
//           textAlign="justified"
//           color="#985DF6"
//           gutterBottom
//         >
//           "Hurry up! Don't miss out on your 50
//           <CoinIcon src={coinImage} alt="coin icon" /> coins."
//         </Typography>

//         <Typography variant="body1" fontWeight="500" color="#000080">
//           Methods and Techniques for Empathy
//           <br />
//         </Typography>

//         <Typography variant="body2" fontWeight="300" color="#252525">
//           Complete the Module on Time!{' '}
//           <strong style={{ fontWeight: 'bold', color: 'black' }}>End 27 OCT 2024</strong>
//         </Typography>

//         <Box mt={1}>
//           <ProgressBox>
//             <LinearProgress
//               variant="determinate"
//               value={progressData || 0}
//               sx={{
//                 width: '45%',
//                 backgroundColor: '#e0e0e0',
//                 '& .MuiLinearProgress-bar': {
//                   backgroundColor: '#000080',
//                 },
//               }}
//             />
//             <Box display="flex" alignItems="center" gap="4px">
//               <img src={tortoise} alt="Tortoise" style={{ width: '16px', height: '16px' }} />
//               <Typography variant="body2" color="error">
//                 {progressData !== null ? `${progressData.toFixed(2)}%` : '0.00%'}
//               </Typography>
//             </Box>
//           </ProgressBox>
//         </Box>

//         <ContinueButton variant="contained" sx={{ width: '15%' }}>
//           Continue
//         </ContinueButton>
//       </CardContent>
//     </StyledHeaderCard>
//   );
// };

// export default HeaderCard;


import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, LinearProgress, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { adminaxios } from '../axios';
import coinImage from '../assets/Images/lms_dashboard_coins.png';
import tortoise from '../assets/Images/lms_dashboard_tortoise.png';
import rocketImage from '../assets/Images/lms_dashboard_rocket.png';

const StyledHeaderCard = styled(Card)(() => ({
  borderRadius: '20px',
  width: '100%',
  marginLeft: '20px',
  position: 'relative',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  padding: '1px',
}));

const ProgressBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

const ContinueButton = styled(Button)(() => ({
  backgroundColor: '#000080',
  color: 'white',
  borderRadius: '24px',
  padding: '4px 0px',
  marginTop: '16px',
  textTransform: 'none',
}));

const CoinIcon = styled('img')(() => ({
  width: '38px',
  height: '36px',
  marginLeft: '4px',
}));

const BackgroundImageBox = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  top: 30,
  right: 0,
  width: '40%',
  height: '100%',
  backgroundImage: `url(${rocketImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right bottom',
  opacity: 1,
  zIndex: 0,
}));

const HeaderCard = () => {
  const [progressData, setProgressData] = useState(null); // Initialize state as null
  const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
  const TabName = localStorage.getItem('whichTab');

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        if (storeCourseId) {
          const response = await adminaxios.get(`/skill_based_progress/${storeCourseId}/`);
          console.log('API Response:', response.data); // Debugging log
          setProgressData(response.data); // Store progress data
        }
      } catch (error) {
        console.error('Error fetching skill-based progress:', error);
      }
    };

    fetchProgressData();
  }, [storeCourseId]);

  // Log the state to ensure it's being updated
  console.log('Progress Data State:', progressData);

  return (
    <StyledHeaderCard>
      <BackgroundImageBox />
      <CardContent sx={{ position: 'relative', zIndex: 1 }}>
        <Typography
          variant="h5"
          fontWeight="600"
          fontFamily="poppins"
          lineHeight="42px"
          textAlign="justified"
          color="#985DF6"
          gutterBottom
        >
          "Hurry up! Don't miss out on your 50
          <CoinIcon src={coinImage} alt="coin icon" /> coins."
        </Typography>
{/* 
        <Typography variant="" fontWeight="500" color="#000080">
          Methods and Techniques for Empathy
          <br />
        </Typography> */}

        <Typography variant="" fontWeight="300" color="#252525">
          Complete the Module on Time!{' '}
          <strong fontWeight="bold" color="black">
            End 27 OCT 2024
          </strong>
        </Typography>

        <Box mt={1}>
          <ProgressBox>
            <LinearProgress
              variant="determinate"
              value={progressData?.skill_progress_percentage || 0} // Dynamically set progress
              sx={{
                width: '45%',
                height: '6px',
                borderRadius:'25px',
                backgroundColor: '#e0e0e0',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#000080',
                },
              }}
            />
            <Box display="flex" alignItems="center" gap="4px" >
              <img src={tortoise} alt="Tortoise" style={{ width: '16px', height: '16px' }} />
              <Typography variant="body2" color="error" >
                {progressData
                  ? `${progressData.skill_progress_percentage.toFixed(2)}%`
                  : '0.00%'}
              </Typography>
            </Box>
          </ProgressBox>
        </Box>

        <ContinueButton variant="contained" sx={{ width: '25%', }}>
          Continue
        </ContinueButton>
      </CardContent>
    </StyledHeaderCard>
  );
};

export default HeaderCard;
