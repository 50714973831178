import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import Testimonial_bg from '../../assets/Images/backgroundimg.jpg'

function Message() {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundColor: '#F3F3FF ', // Light purple background color
                padding: '20px',
                borderRadius: '20px',
                width: '93%',
                marginLeft: '40px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                position: 'relative',
                overflow: 'hidden',
            }}
        >

            <Box
                sx={{
                    position: 'relative',
                    textAlign: 'center',
                    padding: '30px',
                }}
            >
                <img
                    src={Testimonial_bg}
                    alt="Testimonial_bg"
                    style={{
                        position: 'absolute',
                        top: '-18%',
                        left: '-10%',
                        width: '120%',
                        height: '120%',
                        zIndex: 0, // Ensures the image stays behind the text
                        objectFit: 'cover', // Ensures the image covers the background properly
                    }}
                />
                <Typography
                    variant="h6"
                    align="center"
                    sx={{
                        fontWeight: 600,
                        color: '#343434',
                        lineHeight: '42px',
                        fontSize: '27px',
                        position: 'relative', // Ensures the text remains in normal flow but above the image
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'start',
                        gap: '5px',
                        fontFamily:'Poppins ,sans-serif'
                    }}
                >
                    On a UI/UX binge? 😜 Make it official! Enroll now and let’s create <br />
                    something so awesome, even your mouse will be impressed
                </Typography>
            </Box>
        </Grid>
    );
}

export default Message;


