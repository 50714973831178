import React, { useState } from 'react';
import '../assets/Css/SetNewPassword.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
import passwordCardImg from '../assets/Images/setNewPAsswordImg.png'
import eyeClose from '../assets/Images/eye-slash.png'
import eyeOpen from '../assets/Images/eye.png'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import { loginaxios } from '../axios';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import skill_synq_logo from '../assets/Images/Skillsynqlogo.png';
 
const SetNewPassword = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePasswordTwo, setTogglePasswordTwo] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
 
  const navigate = useNavigate()
  const userID = localStorage.getItem('user_id_registration');
 
  const handleSetPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
 
    const payload = {
      "user_id": userID,
      "new_password": newPassword,
      "confirm_password": confirmPassword
    }
 
    try {
      const response = await loginaxios.post('/password-reset/reset-password/', payload);
      console.log('new_password_success:', response.data);
     
      navigate('/success-message')
     
    } catch (err) {
      console.error('Signup error:', err);
      const { data } = err.response;
        if (data.error) {
          setError(data.error);
        }
    }
 
    setLoading(false);
  };
 
  return (
    <div className="setPassword-login-container">
      <div className="setPassword-login-left">
        <img src={passwordCardImg} alt='notificationImg' className='setPassword-left-img-setNewPassword' />
      </div>
      <div className="setPassword-login-right">
        <div className="setPassword-circles">
          <img src={Circle1} alt="Circle 1" className="setPassword-circle setPassword-circle1" />
          <img src={Circle2} alt="Circle 2" className="setPassword-circle setPassword-circle2" />
        </div>
        <div className="setPassword-login-box">
          <h2>Create a new password</h2>
          <form onSubmit={handleSetPassword}>
          <div className="setPassword-form-group">
  <label htmlFor="emailLabel" className="setPassword-label_feild_newPassword">
    Enter new password
  </label>
  <div className="setPassword-input-wrapper">
    <input
      className="setPassword-form-control setPassword-newPassword"
      type={togglePasswordTwo ? "text" : "password"}
      id="emailLabel"
      placeholder="At least 6 digits"
      required
      onChange={(e) => setNewPassword(e.target.value)}
      value={newPassword}
    />
    <span
      className="setPassword-newPassword-eye-icon"
      onClick={() => setTogglePasswordTwo(!togglePasswordTwo)}
    >
      {togglePasswordTwo ? <FaEye /> : <FaEyeSlash />}
    </span>
  </div>
</div>
<label htmlFor="emailLabel" className="setPassword-label_feild_newPassword">
Conform password
  </label>

            <div className="setPassword-form-group">
  <input
    className="setPassword-form-control setPassword-newPassword"
    type={togglePassword ? "text" : "password"}
    id="emailLabel2"
    placeholder="Confirm Password"
    required
    onChange={(e) => setConfirmPassword(e.target.value)}
    value={confirmPassword}
  />
  <span
    className="setPassword-newPassword-eye-icon"
    onClick={() => setTogglePassword(!togglePassword)}
  >
    {togglePassword ? <FaEye /> : <FaEyeSlash />}
  </span>
</div>

            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button className='setPassword-button-nnewPassword'>
            Confirm
            {loading ? <CircularProgress size={8} color="inherit" style={{ marginLeft: "10px", marginTop: "3px" }} /> : ''}
            </button>
          </form>          
        </div>
        {/* <div className="powered-by_5">
              Powered by <nbsp></nbsp>
              <img
                  src={skill_synq_logo}
                  alt="Skillsynq Logo"  
              />
        </div> */}
      </div>
    </div>
  );
};
 
export default SetNewPassword;
