import React from 'react'
import Footer from '../Footer/footer'

const Learning = () => {
  return (
    <div>
      <h4> My learning</h4>
      <div className="Course-list-footer">
            <Footer />
      </div>
    </div>
  )
}

export default Learning
