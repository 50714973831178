// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { adminaxios } from "../axios";

// const LabViewer = ({updateURL, onCancel}) => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const labUrl = updateURL // Extract the lab URL passed via state
//   const [loading, setLoading] = useState(false); // Track loading state for cancel action


//   const handleCancel = async () => {
//     setLoading(true);
//     try {
//       const token = localStorage.getItem("token"); // Get the token from local storage

//       // Make the API call to delete the container
//       const response = await adminaxios.post(
//         "/start-novnc/", // Replace with the actual endpoint  
//         {}, // Add payload if required
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       // Check if the response status is 200
//       if (response.status === 200) {
//         console.log("Container deleted successfully");
//         // navigate(-1); // Navigate back to the previous page
//         onCancel();
//       } else {
//         console.warn(`Unexpected status code: ${response.status}`);
//         // navigate(-1); // Navigate back even for non-200 responses
//         onCancel();
//       }
//     } catch (err) {
//       console.error("Error occurred during the API call:", err);
//       // navigate(-1); // Navigate back for any errors
//       onCancel();
//     } finally {
//       setLoading(false); // Stop loading
//     }
//   };

//   if (!labUrl) {
//     return (
//       <div style={{ textAlign: "center", marginTop: "50px" }}>
//         <p style={{ color: "red" }}>Lab URL not provided or invalid.</p>
//         <button
//           onClick={() => navigate(-1)}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "#007bff",
//             color: "#fff",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//         >
//           Go Back
//         </button>
//       </div>
//     );
//   }

//   return (
//     <div style={{ height: "100vh", width: "60%", display: "flex", flexDirection: "column" }}>
//       {/* Top section with Cancel button */}
//       <div
//         style={{
//           height: "50px",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           background: "#f8f9fa",
//           borderBottom: "1px solid #ddd",
//           padding: "0 15px",
//         }}
//       >
//         <h3 style={{ margin: 0, fontSize: "18px", color: "#333" }}>Lab Viewer</h3>
//         <button
//           onClick={handleCancel}
//           style={{
//             padding: "8px 16px",
//             background: loading ? "#ddd" : "#ff4d4f",
//             color: "#fff",
//             border: "none",
//             borderRadius: "4px",
//             cursor: loading ? "not-allowed" : "pointer",
//           }}
//           disabled={loading}
//         >
//           {loading ? "Cancelling..." : "Cancel"}
//         </button>
//       </div>

//       {/* iframe displaying the lab URL */}
//       <iframe
//         src={labUrl}
//         style={{
//           flex: 1,
//           border: "none",
//           width: "100%",
//           height: "calc(100% - 50px)",
//         }}
//         title="Lab Viewer"
//       ></iframe>
//     </div>
//   );
// };

// export default LabViewer;








import React, { useState ,useEffect} from "react";
import { adminaxios } from "../axios";

const LabViewer = ({ updateURL, onCancel }) => {

  const labUrl = updateURL;
  const [loading, setLoading] = useState(false);
console.log(labUrl,"labUrl") 
  const handleCancel = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      // Make the API call to delete the container
      const response = await adminaxios.post(
        "/start-novnc/", // Replace with the actual endpoint  
        {}, // Add payload if required
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if the response status is 200
      if (response.status === 200) {
        console.log("Container deleted successfully");
        onCancel();
      } else {
        console.warn(`Unexpected status code: ${response.status}`);
        onCancel();
      }
    } catch (err) {
      console.error("Error occurred during the API call:", err);
      onCancel();
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const [iframeUrl, setIframeUrl] = useState(null);

  useEffect(() => {
    if (labUrl) {
      // Add a delay before setting the iframe URL
      const timer = setTimeout(() => {
        const labUrlWithTimestamp = `${labUrl}?t=${Date.now()}`;
        setIframeUrl(labUrlWithTimestamp);
      }, 3000);

      // Cleanup the timer on unmount or if labUrl changes
      return () => clearTimeout(timer);
    }
  }, [labUrl]);

  if (!labUrl) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <p style={{ color: "red" }}>Lab URL not provided or invalid.</p>
        <button
          // onClick={() => navigate(-1)}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Go Back
        </button>
      </div>
    );
  }


  return (

    // <div style={{ height: "91.8vh", width: "100%", display: "flex", flexDirection: "column" }}>
    //   {/* Mock Browser Header */}
    //   <div
    //     style={{
    //       height: "50px",
    //       display: "flex",
    //       alignItems: "center",
    //       background: "#f8f9fa",
    //       borderBottom: "1px solid #ddd",
    //       padding: "0 15px",
    //       justifyContent: "space-between",
    //     }}
    //   >
    //     <input
    //       type="text"
    //       value={labUrl}
    //       readOnly
    //       style={{
    //         width: "80%",
    //         padding: "5px 10px",
    //         border: "1px solid #ccc",
    //         borderRadius: "4px",
    //         backgroundColor: "#f1f1f1",
    //         fontSize: "14px",
    //         color: "#555",
    //       }}
    //     />
    //     <button
    //       onClick={handleCancel}
    //       style={{
    //         padding: "8px 16px",
    //         background: loading ? "#ddd" : "#ff4d4f",
    //         color: "#fff",
    //         border: "none",
    //         borderRadius: "4px",
    //         cursor: loading ? "not-allowed" : "pointer",
    //       }}
    //       disabled={loading} 
    //     >
    //       {loading ? "Cancelling..." : "Cancel"}
    //     </button>
    //   </div>

    //   {/* iframe displaying the lab URL */}
    //   {iframeUrl ? (
    //     <iframe
    //       src={iframeUrl}
    //       style={{
    //         flex: 1,
    //         border: "none",
    //         width: "100%",
    //         height: "calc(100% - 50px)",
    //       }}
    //       title="Lab Viewer"
    //     ></iframe>
    //   ) : (
    //     <p >Loading Lab Viewer...</p>
    //   )}

    // </div>





    <div style={{ height: "91.2vh", width: "100%", display: "flex", flexDirection: "column" }}>
  {/* Mock Browser Header */}
  <div
    style={{
      height: "50px",
      display: "flex",
      alignItems: "center",
      background: "#f8f9fa",
      padding: "0 15px",
      justifyContent: "space-between",
    }}
  >
    <span
      style={{
        marginLeft: "14px",
        fontSize: "18px",
        // color: "#555",
        display: "inline-block",
      }}
    >
      NO-VNC
    </span>
    <button
      onClick={handleCancel}
      style={{
        padding: "8px 16px",
        background: loading ? "#ddd" : "#ff4d4f",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        cursor: loading ? "not-allowed" : "pointer",
      }}
      disabled={loading}
    >
      {loading ? "Cancelling..." : "Cancel"}
    </button>
  </div>

  {/* iframe displaying the lab URL */}
  {iframeUrl ? (
    <iframe
      src={iframeUrl}
      style={{
        flex: 1,
        border: "none",
        width: "100%",
        height: "calc(100% - 50px)",
      }}
      title="Lab Viewer"
    ></iframe>
  ) : (
    <p>Loading Lab Viewer...</p>
  )}
</div>
  );
};

export default LabViewer;









