import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleContent } from "../actions/contentActions";
import { useNavigate } from "react-router-dom";
import Sidebar2 from "../Sidebar/Sidebar2";
import "../assets/Css/CourseRendering.css";
import right_arrow from "../assets/Images/arrow-right.png";
import down from "../assets/Images/arrow-down.png";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { adminaxios } from "../axios";
import { useParams} from 'react-router-dom';
import SkeletonLoader from "./SkeletonLoader";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { setTopicId, setMediaContentId,setLabConfigId,setQuizConfigId ,setNotesId,setVideoId,setPowerPointId,setTopicTitle} from "../actions/apisIdsActions"; // Ensure this import
import { borderRadius } from "@mui/system";
import Ppt from './Ppt'
import  CommentSection from '../CourseRendering/CommentSection'
import "./../assets/Css/CourseRendering.css"
import VideoChunk from "../CourseRendering/VideoChunk";



const Combine = () => {
  const contentToggle = useSelector((state) => state.content.contentToggle);
  console.log('contentToggle' , contentToggle)
  const sidebarToggle = useSelector((state) => state.sidebar.sideToggle);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [acticeAskCont,setActiceAskCont] = useState(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editorData, setEditorData] = useState('');
  const [loading, setLoading] = useState(true);
  const [mediaData, setMediaData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [pptUrl,setPptUrl] = useState('');
  const [notesUrl, setNotesUrl] = useState("");
  const [topicsArrayData, setTopicsArrayData] = useState([]);
  const [updatedNextTopicId, setUpdatedNextTopicId] = useState(null);
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0); // State to track video progress
  const [storedProgress, setStoredProgress] = useState(0); // Store the progress locally
  const [isPaused, setIsPaused] = useState(false); // Track if the video is paused
  const playerRef = useRef(null); // ReactPlayer reference
  const [isPlaying, setIsPlaying] = useState(false); // Track if the video is playing
  const intervalRef = useRef(null); // Reference for the interval
 
  const { id: courseId } = useParams();
  const selectedModuleId = useSelector((state) => state.dynamicApiId.moduleId);
  console.log(selectedModuleId);
  const selectedTopicId = useSelector((state) => state.dynamicApiId.topicId);
  console.log(selectedTopicId,'selectedTopicId');
 const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
 console.log(storeCourseId,'storeCourseID');
 const courseTypeToggle = useSelector((state)=>state.CourseType.courseType);
 
 console.log(courseTypeToggle,' courseType');
console.log(storeCourseId,"storeCourseId");
console.log(courseId,'courseId');
  const selectedMediaId = useSelector(
    (state) => state.dynamicApiId.mediaContentId
  );
 
  const storeQuizConfigId = useSelector(
    (state) => state.dynamicApiId.quizConfigId
  );
 
  const storeLabConfigId = useSelector(
    (state) => state.dynamicApiId.labConfigId
  );
 
  const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
  const storeVideoId = useSelector((state)=> state.dynamicApiId. videoId);
  console.log(storeVideoId,'storeVideoId');  
  const storePptId = useSelector((state) => state.dynamicApiId.PptId)
  const contentToggleId = useSelector((state) => state.content.contentToggle);
  console.log(storeVideoId);
 
  console.log('lab',storeLabConfigId)
  console.log('quiz',storeQuizConfigId)
 
  const handleAskContModal = async (content) => {
    setShowExitPopup(true);
   
    console.log('content',content);
   
 
    try {
      const response = await adminaxios.post(`ask_me/course/${storeCourseId}/module/${selectedModuleId}/content/${storeVideoId}/`, content, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
    } catch (err) {
      console.error('error:', err);
    }
  };
 
 
// Function to call progress API with storeVideoId as contentId
const postProgressUpdate = async (progress) => {
  console.log("Progress being sent:", progress); // Debugging
  const data = { progress };
 
  try {
    if (storeVideoId && progress >= 0) {
      console.log("Updating progress for videoId:", storeVideoId);
      const response = await adminaxios.post(
        `/update_user_progress/${courseId}/${selectedModuleId}/${selectedTopicId}/${storeVideoId}/`,
        data,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Progress updated successfully:", response.data);
    } else {
      console.error("Invalid progress or missing storeVideoId:", progress, storeVideoId);
    }
  } catch (error) {
    console.error("Error posting progress:", error);
  }
};
 
 
 
 
 
 
// Trigger progress API call when video is completed
const handleVideoEnd = () => {
  if (storeVideoId) {
    postProgressUpdate(100); // 100% progress when video is fully completed
    setStoredProgress(100); // Save 100% progress locally
  } else {
    console.error("Video ID is missing. Cannot update progress.");
  }
};
  /// Function to handle video progress
const handleVideoProgress = (progressState) => {
  const percentage = Math.floor(progressState.played * 100);
  console.log("Calculated progress:", percentage);
  setStoredProgress(percentage); // Update local progress
};
 // Start API calls when the video starts playing
const handlePlay = () => {
  setIsPlaying(true);

  if (!intervalRef.current) {
    intervalRef.current = setInterval(() => {
      console.log("Sending periodic progress update:", storedProgress);
      postProgressUpdate(storedProgress); // Call API every 3 minutes
    }, 3 * 60 * 1000); // 3 minutes interval
  }
};


// Handle video pause
const handlePause = () => {
  setIsPlaying(false);

  if (intervalRef.current) {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }

  console.log("Pausing video. Sending final progress:", storedProgress);
  postProgressUpdate(storedProgress); // Send progress on pause
};
 
  // Handle user navigation
const handleNavigation = () => {
  if (intervalRef.current) {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }

  console.log("Navigating away. Sending final progress:", storedProgress);
  postProgressUpdate(storedProgress); // Send progress on navigation
};

// Cleanup interval on component unmount
useEffect(() => {
  return () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    console.log("Component unmounting. Sending final progress:", storedProgress);
    postProgressUpdate(storedProgress); // Send progress on unmount
  };
}, [storedProgress]);
   
 
   
 
  useEffect(() => {
    setLoading(true); // Set loading state true when fetching new data
    adminaxios
      .get(`/modules/${selectedModuleId}/`)
      .then((response) => {
        setTopicsArrayData(response.data.topics);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [selectedModuleId]);
 
  useEffect(() =>{
    if(storeVideoId !== null){
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await adminaxios.get(`/contents/${storeVideoId}/`);
          const data = response.data;
          const videoUrlFromApi = data.versions[0]?.url;
          setVideoUrl(videoUrlFromApi);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching the data:', error);
        }
      };
 
      fetchData();
    }
  },[storeVideoId])
 
  useEffect(() =>{
    if(storePptId !== null){
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await adminaxios.get(`/contents/${storePptId}/`);
          const data = response.data;
          const PptUrlFromApi = data.versions[0]?.url;
          setPptUrl(PptUrlFromApi);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching the data:', error);
        }
      };
 
      fetchData();
    }
  },[storePptId])
 
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
 
 
  const handleToggleNotes = () => {
    dispatch(toggleContent());
  };
 
 
 
  const topicsIndex = topicsArrayData?.map(topic => topic._id);
  const currentTopicIndex = topicsIndex?.indexOf(selectedTopicId);
 
 
  const handleToQuiz = () => {
    if (storeNotesId === null && storeQuizConfigId === null && storeLabConfigId === null) {
      if (currentTopicIndex < topicsIndex.length - 1) {
        const nextTopic = topicsArrayData[currentTopicIndex + 1];
        const nextTopicId = nextTopic._id;
        const nextTopicTitle = nextTopic.title
        const nextMediaContentId = nextTopic.media_content_ids[0]?._id;
        const nextMediaContent = nextTopic.media_content_ids[0];
        const nextVideoId = nextMediaContent?.content_ids.find(content => content.format === ".mp4")?.content_id;
        const nextNotesId = nextMediaContent?.content_ids.find(content => content.format === "HTML")?.content_id;
        const nextPptId = nextMediaContent?.content_ids.find(content => content.format === ".pptx")?.content_id;
       
        dispatch(setNotesId(nextNotesId));
        dispatch(setVideoId(nextVideoId));
        dispatch(setTopicId(nextTopicId));
        dispatch(setMediaContentId(nextMediaContentId));
        dispatch(setPowerPointId(nextPptId));
        dispatch(setTopicTitle(nextTopicTitle));
        console.log("Next Topic ID:", nextTopicId);
        let labId = '', quizId = '';
        nextTopic.question_bank_configs.forEach(config => {
            if (config.type === 'Lab') {
                labId = config._id;
            } else if (config.type === 'Quiz') {
                quizId = config._id;
            }
        });
        console.log('labId',labId)
        console.log('quizId',quizId)
        if (labId) {
            dispatch(setLabConfigId(labId));
        } else {
            dispatch(setLabConfigId(null));
        }
 
        if (quizId) {
            dispatch(setQuizConfigId(quizId));
        } else {
            dispatch(setQuizConfigId(null));
        }
        navigate(`/combine/${courseId}`)
      }
    } else if (storeNotesId === null && storeQuizConfigId === null) {
      navigate(`/lab-list/${courseId}`);
    }
    else if(storeNotesId === null) {
      navigate(`/quiz/${courseId}`);
    }else{
      navigate(`/notes/${courseId}`)
    }
  };
 
 
 
  const handleContinueCourse = () => {
    setShowExitPopup(false);
    dispatch(toggleContent());
  };
 
  if (loading) {
    return <SkeletonLoader />;
  }
 
const roleEachCourseId = localStorage.getItem('role_based_courseId');
console.log('roleEachCourseId',roleEachCourseId);
console.log('combine,videoUrl',videoUrl);
  return (
    <div style={{height:'85%', display:'flex'}}>
      {/* <Sidebar2 courseId={courseId} /> */}
      <VideoChunk
          videoUrl={videoUrl}
          sidebarToggle={sidebarToggle}
          contentToggle={contentToggle}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          handleToQuiz={handleToQuiz}
          handleVideoProgress={handleVideoProgress}
          handleVideoEnd={handleVideoEnd}
          handleNavigation={handleNavigation}
          handlePause={handlePause}
          isPlaying={isPlaying}
          storedProgress={storedProgress}
          handlePlay={handlePlay}
          postProgressUpdate={postProgressUpdate}
 
        />
      {contentToggleId ?  "" :
       
         <div
                className='askme-cont'
                style={{
                  width:"0%",
                  display:'flex',
                  alignItems:'center',
                  flexDirection:'column',
                  // justifyContent:'space-around',
                  zIndex:'2000',
                }}
               
          >
         
                    <button
                      onClick={handleToggleNotes}
                      className={
                        // contentToggle ? "notes-direction-button" : "notes-normal"
                        'show-ask-me-container'
                      }
                   
                    >
                    Ask ?
                    </button>
                    {/* <button className="next-topic-combine-button-navigate-text"  onClick={handleSkipVideo}>
                      Skip
                    </button>       */}
            </div>
 
      }
     
      {contentToggle ?
        //  <div className="combine-askme-full-matter-container">
        //     <div className="combine-askme-full-matter-sub-container"
        //     >
        //       <div style={{display:'flex',alignItems:'center'}} >
        //         <button onClick={handleToggleNotes} className="ask_cont_right_arrow_btn"><img src={right_arrow} alt='back' className="ask_cont_right_arrow" /></button>
        //         <h4 className="combine-askme-heading-cont">Ask Me</h4>
        //       </div>
             
        //       <CKEditor
        //         editor={ClassicEditor}
        //         data={editorData}
        //         onChange={(event, editor) => {
        //           const data = editor.getData();
        //           setEditorData(data);
        //           console.log({ event, editor, data });
        //         }}
        //         className='custom-editor'
        //       />
        //      <button className="ask-me-post-button" onClick={() =>handleAskContModal(editorData)}>Post</button>
        //     </div>
 
        //  </div>
        <CommentSection  
          handleAskContModal={handleAskContModal}
          handleToggleNotes={handleToggleNotes}
        />
       :''
      }
 
      {showExitPopup && (
        <div className="course-exit-popup-container">
          <div className="course-exit-popup">
            <p>Your comment has been posted successfully!</p>
            <div className="course-exit-buttons">
              <button className='course-exit-continue-button' onClick={handleContinueCourse}>Exit</button>
            </div>
          </div>
        </div>
      )}
     
    </div>
   
  );
};
 
export default Combine;