import React, { useState, useEffect } from 'react';
import { adminaxios } from '../../axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import folder from '../../assets/Images/folder-2.png';
import videoplay from '../../assets/Images/videoplay.png';
import unlock from '../../assets/Images/unlock.png';
import { useParams } from 'react-router-dom';
import Animations from '../RolebasedDetails/RolebasedModuleSkeleton';
import { Grid } from '@mui/material';
import Testimonial_Group from '../../assets/Images/Group 1000002927.png'; // Adjust the path as per your project structure
import Testimonial_book from '../../assets/Images/book@2x.png';
import Testimonial_lampon from '../../assets/Images/lamp-on.png';
import Testimonial_Property from '../../assets/Images/Property 34.png';


const Modules = ({ onModulesLoad }) => {
  const [modules, setModules] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const { id: courseId } = useParams();

  useEffect(() => {
    const fetchModules = async () => {
      setLoading(true);
      try {
        const response = await adminaxios.get(`/course_details_page/${courseId}/`);
        const data = response.data; // Axios stores the response data in `response.data`
        setLoading(false); 

        if (data && data.curriculum) {
          const transformedModules = data.curriculum.map((module, index) => ({
            id: index + 1,
            title: `Module ${index + 1} - ${module.title}`,
            content: { text: module.title, time: '04:30' },
            details: module.topics.map(topic => ({
              text: topic.title,
              time: '04:30',
            })),
          }));

          setModules(transformedModules);
          if (onModulesLoad) {
            onModulesLoad(transformedModules);
          }
        } else {
          console.error('API response does not contain expected curriculum data.');
          setLoading(false); 
        }
      } catch (error) {
        console.error('Error fetching modules:', error);
        setLoading(false); 
      }
    };

    fetchModules();
  }, [courseId, onModulesLoad]);

  const handleAccordionChange = (id) => (event, isExpanded) => {
    setExpandedPanels((prevExpanded) =>
      isExpanded ? [...prevExpanded, id] : prevExpanded.filter((panelId) => panelId !== id)
    );
  };



  return (
    <div className="course-details-second">
      <div className="course-modules">
        <div className="course-single-module">
          <h4>Course Modules</h4>
          
          {/* Add Animation Component here to show loading below the heading */}
          {loading && <Animations />} 

          {/* Render the modules only after loading is complete */}
          {!loading && modules.slice(0, showMore ? modules.length : 4).map((module) => (
            <Accordion 
              key={module.id}
              expanded={expandedPanels.includes(module.id)}
              onChange={handleAccordionChange(module.id)}
              TransitionProps={{ unmountOnExit: true }} // Ensure smooth mounting/unmounting transitions
              className={expandedPanels.includes(module.id) ? 'accordion-expanded' : ''}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${module.id}-content`}
                id={`panel${module.id}-header`}
              >
                <div className="accordion-summary-content">
                  <span className="accordion-summary-title">{module.title}</span>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <p className="course-module-divider"></p>
                <div className="course-module-container">
                  {/* <div className="course-module-content">
                    <img src={folder} alt="Document Icon" className="course-content-icon" />
                    <span className="course-content-text">{module.content.text}</span>

                    <div className="course-detail-time">
                      <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                      <span>{module.content.time}</span>
                    </div>
                  </div> */}
                  <Grid container spacing={1} sx={{ marginBottom: '0px', gap: 1 }}>
  <Grid item sx={{ border: '1px solid', borderColor: '#494949', borderRadius: '10px',     display: 'flex',
    alignItems: 'center',padding: '0px 15px', padding:'0px 8px'}}>
    <img src={Testimonial_Group} alt="Testimonial_Group" style={{ height: '18px', width: '18px', marginRight: '3px' }} />
    8 Videos
  </Grid>
  <Grid item sx={{ border: '1px solid', borderColor: '#494949', borderRadius: '10px',     display: 'flex',
    alignItems: 'center',padding: '0px 15px', padding:'0px 8px'}}>
    <img src={Testimonial_book} alt="Testimonial_book" style={{ height: '15px', width: '15px', marginRight: '5px' }} />
    2 Notes
  </Grid>
  <Grid item sx={{ border: '1px solid', borderColor: '#494949', borderRadius: '10px',     display: 'flex',
    alignItems: 'center',padding: '0px 15px', padding:'0px 8px'}}>
    <img src={Testimonial_lampon} alt="Testimonial_lampon" style={{ height: '14px', width: '14px', marginRight: '3px', marginTop:'-2px'}} />
    7 Quiz
  </Grid>
  <Grid item sx={{ border: '1px solid', borderColor: '#494949', borderRadius: '10px',     display: 'flex',
    alignItems: 'center',padding: '0px 15px', padding:'0px 8px'}}>
    <img src={Testimonial_Property} alt="Testimonial_Property" style={{ height: '14px', width: '14px', marginRight: '3px' }} />
    6 Lab
  </Grid>
</Grid>

                </div>
                <div className="course-module-subcontent">
                  <img src={unlock} alt="Lock Icon" className="course-subcontent-icon" />
                  <span>Get the materials</span>
                </div>
                {module.details.map((detail, index) => (
                  <div key={index} className="course-module-detail">
                    <div className="course-detail-text">
                      <span>{detail.text}</span>
                    </div>
                    <div className="course-detail-time">
                      <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                      <span>{detail.time}</span>
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
            {/* See More/Less Button - only visible if modules.length > 4 and after loading */}
            {!loading && modules.length > 4 && (
            <Button onClick={() => setShowMore(!showMore)} className="course-see-more-button">
              {showMore ? 'Show less' : 'See more'}
              <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modules;




