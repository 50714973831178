 
import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight } from 'react-feather';
import '../assets/Css/Sidebar2.css';
import topicsIcon from '../assets/Images/topics-icon.png';
import notesIcon from '../assets/Images/sidebar_notes_icon.png'
import durationIcon from '../assets/Images/duration-icon.png';
import quizIcon from '../assets/Images/flask.png';
//import nitesIcon from '../assets/Images/sidebar_book.png';
import labIcon from '../assets/Images/lightbulb.png';
import lockIcon from '../assets/Images/lock_lms_sidebar.png';
import unlockIcon from '../assets/Images/unlock_lms_sidebar.png'
import { toggleSidebarRedux } from '../actions/sidebarActions';
import { adminaxios } from '../axios';
import { useDispatch, useSelector } from "react-redux";
import ClaimRewardModule from './ClaimRewardModule';
import Modal from '@mui/material/Modal';
import { Tooltip } from 'react-tooltip';
import { useNavigate, useLocation } from 'react-router-dom';
import { setModuleId, setTopicId, setMediaContentId, setLabConfigId, setQuizConfigId, setModuleTitle, setTopicTitle, setNotesId, setVideoId, setPowerPointId, isAssesmentLabCondition, isAssesmentQuizCondition } from '../actions/apisIdsActions';
 
 
const Sidebar2 = ({ courseId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [expandedModuleId, setExpandedModuleId] = useState(null);
    const [courseDetails, setCourseDetails] = useState([]);
    const [activeTopicId, setActiveTopicId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModuleId, setSelectedModuleId] = useState(null);
    const [modulesFromProgress, setModulesFromProgress] = useState([]);
    console.log(modulesFromProgress, 'modulesFromProgress')
    const [userProgress, setUserProgress] = useState(null);  
    const [progressMainId, setProgressMAinId] = useState(null);
    const storeCourseId = useSelector((state) => state.dynamicApiId.courseId);
    const openDefaultModuleId = useSelector((state) => state.dynamicApiId.moduleId);
    const openDefaultTopicId = useSelector((state) => state.dynamicApiId.topicId);
    //console.log(openDefaultModuleId,"openDefaultModuleId")
    //console.log(openDefaultTopicId,"openDefaultTopicId")
    const currentTopicMediaId = useSelector((state) => state.dynamicApiId.mediaContentId);
    const currentNotesId = useSelector((state) => state.dynamicApiId.notesId);
    const currentVideoId = useSelector((state) => state.dynamicApiId.videoId);
    const currentQuizId = useSelector((state) => state.dynamicApiId.quizId);
    const currentLabId = useSelector((state) => state.dynamicApiId.labId);
    const sidebarAction = useSelector((state) => state.sidebar.sideToggle);
    //const assessmentCheckingQuiz = useSelector((state) => state.dynamicApiId.assesmentQuizCheck);
    //const assessmentCheckingLab = useSelector((state) => state.dynamicApiId.assesmentLabCheck);
    const courseTypeToggle = useSelector((state) => state.CourseType.courseType);
    console.log(courseTypeToggle, 'courseTypeToggle');
    const dynamicCourseId = useSelector((state) => state.dynamicApiId.courseId);
    const roleBasedCourseID = localStorage.getItem('role_based_courseId');
    console.log('roleCourse', roleBasedCourseID);
    console.log('courseIdddd', courseId);
    const isAssesmentQuiz = useSelector((state) => state.dynamicApiId.assesmentQuizCheck);
    const isAssesmentLab = useSelector((state) => state.dynamicApiId.assesmentLabCheck);
    const isCombinePage = location.pathname.includes('/combine/');
    const isNotesPage = location.pathname.includes('/notes/');
    const isQuizPage = location.pathname.includes('/quiz/');
    const isLabPage = location.pathname.includes('/lab-list/');
 
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        dispatch(toggleSidebarRedux());
    };
 
    const toggleModule = (moduleId) => {
        if (expandedModuleId === moduleId) {
            setExpandedModuleId(null);
            localStorage.removeItem('expandedModuleId');
        } else {
            setExpandedModuleId(moduleId);
            const selectedModule = courseDetails.data.module_details.find(module => module._id === moduleId);
            if (selectedModule && selectedModule.topics.length > 0) {
                const firstTopic = selectedModule.topics[0];
                handleToTopicRender(firstTopic._id, firstTopic.title, firstTopic.media_content_ids[0]?._id, firstTopic.question_bank_configs, firstTopic.media_content_ids);
            }
            localStorage.setItem('expandedModuleId', moduleId);
            dispatch(setModuleId(moduleId));
        }
    };
 
    const activeCourseId = courseTypeToggle ? roleBasedCourseID : courseId;
 
    console.log('sidebar_courseId', activeCourseId);
    console.log('sidebar_rolecourseId', roleBasedCourseID);
    console.log('sidebar_norcourseId', courseId);
    // const handleLockClick = (moduleId) => {
    //     setSelectedModuleId(moduleId);
    //     setIsModalOpen(true);
    // };
 
    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    //     setSelectedModuleId(null);
    // };
 
    // const handleUnlock = () => {
    //     console.log(`Unlocking module with ID: ${selectedModuleId}`);
    //     // Add your unlock logic here
    //     setIsModalOpen(false);
    // };
 
    useEffect(() => {
        // Fetch course details and user progress simultaneously
        const fetchCourseDetails = async () => {
            try {
                // Fetch course details
                const courseResponse = await adminaxios.get(`/courses/${activeCourseId}/`);
                setCourseDetails(courseResponse.data);
                console.log("Course API Response:", courseResponse.data);
                const savedModuleId = localStorage.getItem('expandedModuleId');
                const savedTopicId = localStorage.getItem('activeTopicId');
                const roleFirstModuleId = localStorage.getItem('LocalModuleId');
                const roleFirstTopicId = localStorage.getItem('LocalTopicId');
 
                // Check for openDefaultModuleId to auto-expand the module
                const moduleIdToExpand = openDefaultModuleId || savedModuleId;
 
                if (moduleIdToExpand) {
                    setExpandedModuleId(moduleIdToExpand);
                    const moduleToExpand = courseResponse.data?.module_details?.find(module => module._id === moduleIdToExpand);
                    if (moduleToExpand && moduleToExpand.topics.length > 0) {
                        if (savedTopicId) {
                            const savedTopic = moduleToExpand.topics?.find(topic => topic._id === savedTopicId);
                            if (savedTopic) {
                                handleToTopicRender(
                                    savedTopic._id,
                                    savedTopic.title,
                                    savedTopic.media_content_ids[0]?._id,
                                    savedTopic.question_bank_configs,
                                    savedTopic.media_content_ids
                                );
                            }
                        } else {
                            const firstTopic = moduleToExpand.topics[0];
                            handleToTopicRender(
                                firstTopic._id,
                                firstTopic.title,
                                firstTopic.media_content_ids[0]?._id,
                                firstTopic.question_bank_configs,
                                firstTopic.media_content_ids
                            );
                        }
                    }
                } else {
                    if (courseResponse.data?.module_details?.length > 0) {
                        const firstModule = courseResponse.data.module_details[0];
                        setExpandedModuleId(firstModule._id);
                        if (firstModule.topics.length > 0) {
                            const firstTopic = firstModule.topics[0];
                            handleToTopicRender(
                                firstTopic._id,
                                firstTopic.title,
                                firstTopic.media_content_ids[0]?._id,
                                firstTopic.question_bank_configs,
                                firstTopic.media_content_ids
                            );
                        }
                    }
                }
 
                if (openDefaultTopicId) {
                    setActiveTopicId(openDefaultTopicId);
                }
 
                // Fetch user progress
                const progressResponse = await adminaxios.get(`/user_progress/${activeCourseId}/`);
                setUserProgress(progressResponse.data); // Set user progress data
                console.log("Progress API Response:", progressResponse.data);
                console.log("Progress API Response:_id", progressResponse.data._id);
                setProgressMAinId(progressResponse.data._id);
                // console.log("Progress API Response modules", progressResponse.data.courses.modules);
                // setProgressObjectId(progressResponse.data._id);
                // Store modules array from user progress in state
                const modules = progressResponse.data?.courses[0]?.modules || [];
                setModulesFromProgress(modules);
 
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
 
        fetchCourseDetails(); // Call the async function to fetch data
    }, [courseId, openDefaultModuleId, openDefaultTopicId, activeCourseId]);
 
    console.log(modulesFromProgress, 'jwhcvquwhjwkbujb');
    console.log(openDefaultModuleId, 'defalt module id')
    const handleToModuleRender = (moduleId, moduleTitle) => {
        dispatch(setModuleId(moduleId));
        dispatch(setModuleTitle(moduleTitle));
    };
 
    const handleToTopicRender = (topicId, topicTitle, contentId, questionBankConfigs, mediaContentIds) => {
        setActiveTopicId(topicId);
        localStorage.setItem('activeTopicId', topicId);
        dispatch(setTopicId(topicId));
        dispatch(setTopicTitle(topicTitle));
        dispatch(setMediaContentId(contentId));
 
        let labId = '', quizId = '', notesId = '', videoId = '', Pptid = '';
        let assesmentQuizCheck = '', assesmentLabCheck = '';
 
        // Extracting quiz and lab configs first
        questionBankConfigs.forEach(config => {
            if (config.type === 'Lab') {
                labId = config._id;
                assesmentLabCheck = config.is_assessment;
                dispatch(isAssesmentLabCondition(assesmentLabCheck || null));
            } else if (config.type === 'Quiz') {
                quizId = config._id;
                assesmentQuizCheck = config.is_assessment;
 
            }
        });
 
 
        // Extracting media content (video, notes, ppt) and prioritizing navigation in sequence
        mediaContentIds.forEach(mediaContent => {
            mediaContent.content_ids.forEach(content => {
                if (content.format === '.mp4') {
                    videoId = content.content_id;
                } else if (content.format === 'HTML') {
                    notesId = content.content_id;
                } else if (content.format === '.pptx') {
                    Pptid = content.content_id;
                }
            });
        });
 
        // Dispatch the extracted IDs
        dispatch(setLabConfigId(labId || null));
        dispatch(setQuizConfigId(quizId || null));
        dispatch(setNotesId(notesId || null));
        dispatch(setVideoId(videoId || null));
        dispatch(setPowerPointId(Pptid || null));
        dispatch(isAssesmentQuizCondition(assesmentQuizCheck || null));
        dispatch(isAssesmentLabCondition(assesmentLabCheck || null));
 
        // Navigate in the specific order: first video, then notes, then quiz, then lab
        if (videoId) {
            navigate(`/combine/${courseId}`);
        } else if (notesId) {
            navigate(`/notes/${courseId}`);
        } else if (quizId) {
            navigate(assesmentQuizCheck ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
        } else if (labId) {
            navigate(`/lab-list/${courseId}`);
        }
    };
 
 
    const getModuleProgress = (moduleId) => {
        const module = modulesFromProgress.find(m => m.module_id === moduleId);
        return module ? module.progress_percentage || 0 : 0;
    };
 
    // Helper function to get topic progress within a module
    const getTopicProgress = (moduleId, topicId) => {
        const module = modulesFromProgress.find(m => m.module_id === moduleId);
        if (module) {
            const topic = module.topics.find(t => t.topic_id === topicId);
            return topic ? topic.topic_percentage || 0 : 0;
        }
        return 0;
    };
 
    return (
        <div className={`sidebar_2 ${!sidebarAction ? 'collapsed' : ''}`}>
            <div className="sidebar_2-inner" style={{ marginLeft: !sidebarAction ? '0px' : '', border: !sidebarAction && 'none', height:sidebarAction?'85vh':'0vh',scrollbarWidth:!sidebarAction? 'none':'',boxShadow:!sidebarAction && 'none' }}>
                {courseDetails?.data?.module_details?.map((module, index) => {
                    const moduleId = module._id || module.module_id;  // Use _id or module_id
                    const moduleTitle = module.title || module.module_name;  // Use title or module_name
                    const moduleProgress = getModuleProgress(moduleId);
 
                    return (
                        <div key={moduleId} className="sidebar_2-module" onClick={() => handleToModuleRender(moduleId, moduleTitle)}>
                            {isCollapsed && (
                                <Tooltip
                                    id={`module-tooltip-${moduleId}`}
                                    place="right"
                                    effect="solid"
                                    content={moduleTitle}
                                />
                            )}
                            <div
                                className={`sidebar_2-module-header ${expandedModuleId === module._id ? 'expanded' : ''}`}
                                onClick={() => toggleModule(moduleId)}
                                data-tooltip-id={`module-tooltip-${moduleId}`}
                            >
                            {/* <div
                                className={`sidebar_2-module-header ${expandedModuleId === module._id ? 'expanded' : ''}`}
                                onClick={(e) => {
                                    if (index > 0) {
                                        e.stopPropagation(); // Prevent unintended toggling
                                        handleLockClick(moduleId);
                                    } else {
                                        setExpandedModuleId((prevId) => (prevId === moduleId ? null : moduleId)); // Expand or collapse unlocked modules
                                    }
                                }}
                                data-tooltip-id={`module-tooltip-${moduleId}`}
                            > */}
 
 
                                <div className="sidebar_2-module-header-1">
                                    <div className="sidebar_2-module-header-left">
                                        <h2 className="sidebar2_heading_2">
                                            {isCollapsed
                                                ? `M${index + 1}`
                                                : `${index + 1}. ${moduleTitle}`}
                                        </h2>
                                        {/* {index > 0 && (
                                            <img
                                                src={lockIcon}
                                                alt="Lock Icon"
                                                className="module-lock-icon"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering parent click
                                                    // handleLockClick(moduleId);
                                                }}
                                            />
                                        )} */}
                                    </div>
 
                                    {!isCollapsed && (
                                        <div className="sidebar_2-module-header-right">
                                            {expandedModuleId === moduleId ? (
                                                <ChevronUp size={24} />
                                            ) : (
                                                <ChevronDown size={24} />
                                            )}
                                        </div>
                                    )}
                                </div>
 
                                {expandedModuleId === moduleId && (
                                    <div className={`sidebar_2-module-topics ${isCollapsed ? 'collapsed' : 'expanded'}`}>
                                        {module?.topics?.map((topic, index) => {
                                            const topicId = topic._id || topic.topic_id;
                                            const topicTitle = topic.title || topic.topic_name;
                                            const topicProgress = getTopicProgress(moduleId, topicId);
 
                                            return (
                                                <div
                                                    key={topicId}
                                                    className={`sidebar_2-topic ${activeTopicId === topicId ? 'active' : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleToTopicRender(topicId, topicTitle, topic.media_content_ids[0]?._id, topic.question_bank_configs, topic.media_content_ids)
                                                    }
                                                    }
                                                    data-tooltip-id={`topic-tooltip-${topicId}`}
                                                >
                                                    {isCollapsed && (
                                                        <Tooltip
                                                            id={`topic-tooltip-${topicId}`}
                                                            place="right"
                                                            effect="solid"
                                                            content={topicTitle}
                                                        />
                                                    )}
                                                    <div className='sidebar_2-topic_right'>
                                                        <div className="sidebar_2-topic-info" style={{color:activeTopicId === topicId ? '#000080' :''}}>
                                                            <h3 style={{fontWeight:activeTopicId === topicId ? 'bold' :''}}>{isCollapsed ? `${'- T'}${index + 1}` : `${index + 1}.`}</h3>
                                                            <h3 className='sidebar2-topic-title' style={{fontWeight:activeTopicId === topicId ? 'bold' :''}}>{topicTitle}</h3>
                                                        </div> 
                                                    </div>
                                                    {!isCollapsed && (
                                                        <>
                                                            {topic?.media_content_ids[0]?.content_ids?.some(each => each.format === '.mp4') && (
                                                                <div className="sidebar2_progress-wrapper">
                                                                    <div className="sidebar2_progress-bar-topic">
                                                                        <div
                                                                            className="sidebar2_progress-topic"
                                                                            style={{ width: `${topicProgress}%` }}
                                                                        ></div>
                                                                    </div>
                                                                    <div
                                                                        className='sidebar_2-each-icon-cont-video'
                                                                        style={{
                                                                            borderRadius: isCombinePage && activeTopicId === topicId && '15px',
                                                                            padding: isCombinePage && activeTopicId === topicId && '0px 9px 0px 0px',
                                                                            backgroundColor: isCombinePage && activeTopicId === topicId && '#000080',
                                                                            color:isCombinePage && activeTopicId === topicId && '#fff',
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={topicsIcon}
                                                                            alt="Lab Icon"
                                                                            className="sidebar_2-topic-icon"
                                                                        />
                                                                        <p style={{ color:isCombinePage && activeTopicId === topicId && '#fff',}}>60 min</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className='sidebar_2-topic-icons-parent'>
                                                        {!isCollapsed && activeTopicId === topicId && (
                                                            <div className="sidebar_2-topic-icons">
                                                                {topic?.media_content_ids[0]?.content_ids?.some(each => each.format === 'HTML') && (
                                                                    <div
                                                                        className='sidebar_2-each-icon-cont-1'
                                                                        style={{
                                                                            borderRadius: isNotesPage && activeTopicId === topicId && '15px',
                                                                            backgroundColor: isNotesPage && activeTopicId === topicId && '#000080'
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={notesIcon}
                                                                            alt="Lab Icon"
                                                                            className="sidebar_2-topic-icon"
                                                                        />
                                                                        <p style={{ color:isNotesPage && activeTopicId === topicId && '#fff',}}>Notes</p>
                                                                    </div>
                                                                )}
                                                                {topic?.question_bank_configs?.some(config => config.type === 'Quiz') && (
                                                                    <div
                                                                        className='sidebar_2-each-icon-cont-2'
                                                                        style={{
                                                                            borderRadius: isQuizPage && activeTopicId === topicId && '15px',
                                                                            backgroundColor: isQuizPage && activeTopicId === topicId && '#000080'
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={labIcon}
                                                                            alt="Quiz Icon"
                                                                            className="sidebar_2-topic-icon"
                                                                        />
                                                                        <p style={{ color:isQuizPage && activeTopicId === topicId && '#fff',}}>Quiz</p>
                                                                    </div>
                                                                )}
                                                                {topic?.question_bank_configs?.some(config => config.type === 'Lab') && (
                                                                    <div
                                                                        className='sidebar_2-each-icon-cont-3'
                                                                        style={{
                                                                            borderRadius: isLabPage && activeTopicId === topicId && '15px',
                                                                            backgroundColor: isLabPage && activeTopicId === topicId && '#000080'
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={quizIcon}
                                                                            alt="Lab Icon"
                                                                            className="sidebar_2-topic-icon"
                                                                        />
                                                                        <p style={{ color:isLabPage && activeTopicId === topicId && '#fff',}}>Lab</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <ClaimRewardModule modulesFromProgress={modulesFromProgress} progressId={progressMainId} />
                                    </div>
                                )}
                            </div>
 
 
                            {/* Modal for unlocking */}
                            {/* <Modal open={isModalOpen} onClose={handleCloseModal}>
                                <div className="modal-content">
                                    <p style={{ fontSize: '20px' }}>Would you like to unlock this module?</p>
                                    <div className="modal-actions">
                                        <button onClick={handleCloseModal} className="no-button">No</button>
                                        <button onClick={handleUnlock} className="yes-button">Yes</button>
                                    </div>
                                </div>
                            </Modal> */}
                            {/* <Modal open={isModalOpen} onClose={handleCloseModal}> */}
                            <Modal open={isModalOpen} >
                                <div className="modal-content">
                                    <p style={{ fontSize: '20px' }}>Would you like to unlock this module?</p>
                                    <div className="modal-actions">
                                        <button className="no-button">No</button>
                                        <button className="yes-button">Yes</button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
 
 
 
                    );
                })}
            </div>
        </div>
    );
}
export default Sidebar2;
 