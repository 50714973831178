import {SET_COURSE_TYPE} from '../actions/typeOfCourseActions';

const initialState = {
    courseType: false
  };
  
  function typeOfCourse(state = initialState, action) {
    switch (action.type) {
      case 'SET_COURSE_TYPE':
        return {
          ...state,
          courseType:action.payload
        };
      default:
        return state;
    }
  }
  
  
  export default typeOfCourse;