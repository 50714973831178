
import React from 'react';
 
const About = () => {
  return (
    <div className="about-content">
      <div className="course-description">
        <h4>What You’ll Gain ?</h4>
        <ul>
          <li>Understand the core components of computer hardware and software.</li>
          <li>Grasp the fundamentals of networking and the internet, including the OSI model and IP addressing.</li>
          <li>Learn about the basics of cybersecurity, including threats, vulnerabilities, and protective measures.</li>
          <li>Gain insights into operating systems, their functionalities, and management.</li>
          <li>Discover the importance of IT support and service management in organizational contexts.</li>
        </ul>
      </div>
    </div>
  );
};
 
export default About;