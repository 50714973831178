
import React, { useState, useRef, useEffect } from 'react';
import '../../assets/Css/CourseDetails.css';
import arrowleft from '../../assets/Images/arrow-left.png';
import skillicon from '../../assets/Images/coursedetails.png';
import profilepic from '../../assets/Images/profile-2user.png';
import closeicon from '../../assets/Images/closeicon.png';
import prize from '../../assets/Images/cup.png';
import ranking from '../../assets/Images/level.png';
import clock from '../../assets/Images/clock.png';
import About from './CourseDetailsAbout';
import Recommendations from './CourseDetailsRecommendations';
import Whatiwilllearn from './CourseDetailswhatiwilllearn';
import Message from './CourseDetailsMessage';
import Testimonials from './CourseDetailsTestimonials';
import Modules from './CourseDetailsModules';
import recommendationsicon from '../../assets/Images/like@2x.png';
import Testimonialsicon from '../../assets/Images/Testimonials.png';
import Curriculum from '../../assets/Images/Curriculumi-icon.png';
import abouticon from '../../assets/Images/about.png';
import Footer from '../../Footer/footer';
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import CourseDetailsSkeleton from './CourseDetailsSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { adminaxios } from '../../axios';
import { courseType } from '../../actions/typeOfCourseActions';



const CourseCard = () => {
  const [activeTab, setActiveTab] = useState('about');
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progressData, setProgressData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  console.log('progress-data', progressData);
  const aboutRef = useRef(null);
  const WhatiwilllearnRef = useRef(null);
  const MessageRef = useRef(null);
  const modulesRef = useRef(null);
  const recommendationsRef = useRef(null);
  const testimonialsRef = useRef(null);

  const navigate = useNavigate();
  const { id: courseId } = useParams();

  const storeQuizConfigId = useSelector((state) => state.dynamicApiId.quizConfigId);
  const storeLabConfigId = useSelector((state) => state.dynamicApiId.labConfigId);
  const storeNotesId = useSelector((state) => state.dynamicApiId.notesId);
  const storeVideoId = useSelector((state) => state.dynamicApiId.videoId);
  const storeAssesmentQuizChecking = useSelector((state) => state.dynamicApiId.assesmentQuizCheck);



  
  useEffect(() => {
    const fetchCourseData = async () => {
      setLoading(true);     
      try {
        const courseResponse = await adminaxios.get(`/course_details_page/${courseId}/`);
        setCourseData(courseResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching course data:", error);
        setLoading(false);
      }
    };

    const fetchProgressData = async () => {
      try {
        const progressResponse = await adminaxios.get(`/skill_based_progress/${courseId}/`);
        setProgressData(progressResponse.data); // Store the skill-based progress data
      } catch (error) {
        console.error("Error fetching skill-based progress:", error);
      }
    };

    fetchCourseData();
    fetchProgressData(); // Fetch progress data when component mounts
  }, [courseId]);

  const handleResumeButton = () => {
    if (storeVideoId) {
      navigate(`/combine/${courseId}`);
    } else if (storeNotesId) {
      navigate(`/notes/${courseId}`);
    } else if (storeQuizConfigId) {
      navigate(storeAssesmentQuizChecking ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
    } else if (storeLabConfigId) {
      navigate(`/lab-list/${courseId}`);
    }
  };



  const handleBackButton = () => {
    if (location.pathname.includes('/course-details/')) {
      dispatch(courseType(false));
    }
    navigate('/course-list');
    localStorage.removeItem('localStorageCourseId');
    localStorage.removeItem('role_based_courseId');
  };

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleScrollTo = (ref, tabName) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setActiveTab(tabName);
    }
  };

  useEffect(() => {
    const sections = [
      { ref: aboutRef, name: 'about' },
      { ref: modulesRef, name: 'modules' },
      { ref: recommendationsRef, name: 'recommendations' },
      { ref: testimonialsRef, name: 'testimonials' }

    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const section = sections.find(section => section.ref.current === entry.target);
            if (section) {
              setActiveTab(section.name);
            }
          }
        });
      },
      {
        root: null, // viewport
        rootMargin: '0px',
        threshold: 0.6

      }
    );

    sections.forEach(section => {
      if (section.ref.current) {
        observer.observe(section.ref.current);
      }
    });

    return () => {
      sections.forEach(section => {
        if (section.ref.current) {
          observer.unobserve(section.ref.current);
        }
      });
    };
  }, []);

  // Show skeleton loader while data is being fetched
  if (loading) {
    return <CourseDetailsSkeleton />;
  }

  return (

    <div className="course-card">
      <div className="course-first-half">
        <header className="course-header">
          <div className='course-details-page-back-button'>
            <img src={arrowleft} onClick={handleBackButton} alt='arrow-button' />
            <button className="course-details-back-button" onClick={handleBackButton}>Back</button>
          </div>
        </header>
        <div className="course-content">
          <div className="course-main">
            <div className="course-title-section">
              <div className='course-title-logo'>
                <img src={skillicon} alt="Course Logo" className="course-logo" />
                <p className="course-title">{courseData.title}</p>
              </div>
              <div className='Course-details-paragraph'>
                <p>{courseData.description}</p>
              </div>
              <div className="course-tags">
                {courseData.skills.slice(0, 4).map((skill, index) => (
                  <span key={index}>{skill}</span>
                ))}
                {courseData.skills.length > 4 && (
                  <span className="no-pill" onClick={handleModalToggle}>
                    {courseData.skills.length - 4}+ Skills
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="course-details">
            <h3>Course Details</h3>
            <div className="details">
              <span><img src={profilepic} alt="profile-pic" />{`${courseData.enrollments.length === 0 ? '2500' : ''}`} Enrolled</span>
              <span><img src={clock} alt="clock" />{`${courseData.total_duration === 0 ? '60' : ''}`} min</span>
              <span><img src={ranking} alt="ranking" />{courseData.difficulty_level.charAt(0).toUpperCase() + courseData.difficulty_level.slice(1)} Level</span>
              <span><img src={prize} alt="prize" />Certificate of completion</span>
            </div>
            {/* Progress Bar Section */}
            <div className='resume-button-progress'>
              <div className="course-progress">
                <label>Overall progress</label>
                <div className='progressbar-for-course'>
                  <div className="progress-bar">
                    {/* Dynamically update progress bar width based on skill-based progress */}
                    <div className="progress" style={{ width: `${progressData?.skill_progress_percentage || 0}%` }}></div>
                  </div>
                  {/* Show progress percentage dynamically */}
                  <span className="coursedetails-progress-percentage">{`${Math.round(progressData?.skill_progress_percentage) || 0}%`}</span>
                </div>
              </div>
              <button className="resume-button" onClick={handleResumeButton}>Resume</button>
            </div>
          </div>
        </div>
      </div>


      <div className="course-management">
        <nav className="course-tabs">
          <button
            onClick={() => handleScrollTo(aboutRef, 'about')}
            className={`tab-button ${activeTab === 'about' ? 'active' : ''}`}
          >
            <img src={abouticon} alt='about' className='tabs-icons' />
            About
          </button>
          <button
            onClick={() => handleScrollTo(modulesRef, 'modules')}
            className={`tab-button ${activeTab === 'modules' ? 'active' : ''}`}
          >
            <img src={Curriculum} alt='curriculum' className='tabs-icons' />
            Curriculum
          </button>
          <button
            onClick={() => handleScrollTo(recommendationsRef, 'recommendations')}
            className={`tab-button ${activeTab === 'recommendations' ? 'active' : ''}`}
          >
            <img src={recommendationsicon} alt='recommendations' className='tabs-icons' />
            Recommendations
          </button>
          <button
            onClick={() => handleScrollTo(testimonialsRef, 'testimonials')}
            className={`tab-button ${activeTab === 'testimonials' ? 'active' : ''}`}
          >
            <img src={Testimonialsicon} alt='testimonials' className='tabs-icons' />
            Testimonials
          </button>
          {/* <button className="scroll-up-button" onClick={scrollToTop}>
          Scroll Up
          </button> */}
        </nav>

        <div className="course-details-content">
          <section ref={aboutRef}>
            <About />
          </section>
          <section>
            <Whatiwilllearn />
          </section>
          <section ref={modulesRef}>
            <Modules modules={courseData.curriculum} />
          </section>
          <section ref={recommendationsRef}>
            <Recommendations recommendations={courseData.recommendations} />
          </section>
          <section >
            <Message />
          </section>
          <section ref={testimonialsRef}>
            <Testimonials testimonials={courseData.testimonials} />
          </section>
        </div>
      </div>

      <div className='footer-styles'>
        <Footer />
      </div>

      {/* Modal for Skills */}
      {showModal && (
        <div className="modal-overlay" onClick={handleModalToggle}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Skills:</h3>
              <img src={closeicon} alt="closeicon" onClick={handleModalToggle} className="modal-close-button" />
            </div>
            <div className="modal-body">
              {courseData.skills.map((skill, index) => (
                <span key={index} className="course-details-skill-tag">{skill}</span>
              ))}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default CourseCard;


