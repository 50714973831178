import React, { useState, useEffect } from 'react';
import { adminaxios } from '../../axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import folder from '../../assets/Images/folder-2.png';
import lockIcon from '../../assets/Images/lock_lms_sidebar.png';
import videoplay from '../../assets/Images/videoplay.png';
import resumeIcon from '../../assets/Images/play-circle.svg'
import resumeicon from '../../assets/Images/pause-circle@2x.png';
import { Button } from '@mui/material';
import unlock from '../../assets/Images/unlock.png';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import '../../assets/Css/RolebasedDetails.css';
import { useParams, useNavigate } from 'react-router-dom'; // Added useNavigate
import { useDispatch, useSelector } from 'react-redux';
import {
    setModuleId,
    setTopicId,
    setMediaContentId,
    setLabConfigId,
    setQuizConfigId,
    setModuleTitle,
    setTopicTitle,
    setNotesId,
    setVideoId,
    setPowerPointId,
    isAssesmentLabCondition,
    isAssesmentQuizCondition,
  } from '../../actions/apisIdsActions';
import Animations from './RolebasedModuleSkeleton';

const Modules = ({ roleBasedProgress, onModulesLoad }) => {
    const [modules, setModules] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedPanels, setExpandedPanels] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModuleId, setSelectedModuleId] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const { id: courseId } = useParams();
    const navigate = useNavigate(); // Initialize useNavigate
    const dispatch = useDispatch();
    const dynamicCourseId = useSelector((state) => state.dynamicApiId.courseId);
    
    useEffect(() => {
        const fetchModules = async () => {
            setLoading(true);
            try {
                const response = await adminaxios.get(`/course_details_page/${dynamicCourseId}`);
                const data = response.data;
                setLoading(false); // Placeholder for loading state, replace with actual loading state from API response
                setCourses(data.curriculum); // Placeholder for course data, replace with actual course data from API response

                if (data && data.curriculum) {
                    // Map the module data using module_ids
                    const transformedModules = data.curriculum.map((module, index) => ({
                        id: module._id,  // Use the provided module_id here
                        title: `Course ${index + 1} - ${module.course_title}`,
                        content: { text: module.course_title, time: '04:30' }, // Placeholder for time
                        details: module.module_details.map((detail) => ({
                            text: detail.title,
                            time: '04:30' // Placeholder for time
                        })),
                    }));

                    setModules(transformedModules);
                    if (onModulesLoad) {
                        onModulesLoad(transformedModules);
                    }
                } else {
                    console.error('API response does not contain expected curriculum data.');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching modules:', error);
                setLoading(false);
            }
        };

        fetchModules();
    }, [courseId, onModulesLoad,dynamicCourseId]);

  
    // Helper function to get progress for each module
    const getModuleProgress = (moduleId) => {
        console.log('Checking progress for module:', moduleId);  // Log the module ID
        const courseProgress = roleBasedProgress?.skill_based_courses?.find(
          (course) => course.skill_course_id === moduleId  // Check if moduleId matches skill_course_id
        );
        console.log('Matched course progress:', courseProgress);  // Log the matched progress data
        return courseProgress ? courseProgress.skill_progress_percentage : 0;
      };
      
    const handleAccordionChange = (id) => (event, isExpanded) => {
        setExpandedPanels((prevExpanded) =>
            isExpanded ? [...prevExpanded, id] : prevExpanded.filter((panelId) => panelId !== id)
        );
    };

    // Function to handle "Resume" button click
    const handleResumeClick = async (courseId) => {
        try {
            const response = await adminaxios.get(`/courses/${courseId}/`);
            const { data } = response.data;
            const firstModule = data.module_details[0];
            const firstTopic = firstModule.topics[0];
            const labConfig = firstTopic.question_bank_configs.find((config) => config.type === 'Lab');
            const quizConfig = firstTopic.question_bank_configs.find((config) => config.type === 'Quiz');

            const labId = labConfig ? labConfig._id : null;
            const quizId = quizConfig ? quizConfig._id : null;
            const quizAssesment = quizConfig ? quizConfig.is_assessment : null;
            const labAssesment = labConfig ? labConfig.is_assessment : null;

            const htmlContent = firstTopic.media_content_ids
                .flatMap((media) => media.content_ids)
                .find((content) => content.format === 'HTML');
            const mp4Content = firstTopic.media_content_ids
                .flatMap((media) => media.content_ids)
                .find((content) => content.format === '.mp4');
            const PPtContent = firstTopic.media_content_ids
                .flatMap((media) => media.content_ids)
                .find((content) => content.format === '.pptx');

            const htmlId = htmlContent ? htmlContent.content_id : null;
            const videoId = mp4Content ? mp4Content.content_id : null;
            const PptId = PPtContent ? PPtContent.content_id : null;

            // Store in localStorage
            localStorage.setItem('role_based_courseId', courseId);
            localStorage.setItem('LocalModuleId', firstModule._id);
            localStorage.setItem('LocalTopicId', firstTopic._id);
            localStorage.setItem('LocalMediaContentId', firstTopic.media_content_ids[0]._id);
            localStorage.setItem('LocalLabId', labId);
            localStorage.setItem('LocalQuizId', quizId);
            localStorage.setItem('ModuleName', firstModule.title);
            localStorage.setItem('TopicName', firstTopic.title);
            localStorage.setItem('HtmlId', htmlId);
            localStorage.setItem('VideoId', videoId);
            localStorage.setItem('PPT_id', PptId);
            localStorage.setItem('Quiz_assesment', quizAssesment);
            localStorage.setItem('Lab_assesment', labAssesment);

            // Dispatch Redux actions to update the state
            dispatch(setModuleId(firstModule._id));
            dispatch(setTopicId(firstTopic._id));
            dispatch(setMediaContentId(firstTopic.media_content_ids[0]._id));
            dispatch(setLabConfigId(labId));
            dispatch(setQuizConfigId(quizId));
            dispatch(setModuleTitle(firstModule.title));
            dispatch(setTopicTitle(firstTopic.title));
            dispatch(setNotesId(htmlId));
            dispatch(setVideoId(videoId));
            dispatch(setPowerPointId(PptId));
            dispatch(isAssesmentLabCondition(labAssesment));
            dispatch(isAssesmentQuizCondition(quizAssesment));

            // Navigate to the selected course page (if required)
            
            if (videoId) {
                navigate(`/combine/${courseId}`);
            } else if (htmlId) {
                navigate(`/notes/${courseId}`);
            } else if (quizId) {
                navigate( quizAssesment ? `/timedquiz/${courseId}` : `/quiz/${courseId}`);
            } else if (labId) {
                navigate(`/lab-list/${courseId}`);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleLockClick = (moduleId) => {
        setSelectedModuleId(moduleId);
        setIsModalOpen(true);
    };

    
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedModuleId(null);
    };

    
    const handleUnlock = () => {
        console.log(`Unlocking module with ID: ${selectedModuleId}`);
        // Add your unlock logic here
        setIsModalOpen(false);
    };

    return (
        <div className="course-details-second">
            <div className="course-single-moduletimed-quiz">
                <h4>Courses To Be Covered</h4>
                {/* Add Animation Component here to show loading below the heading */}
                {loading && <Animations />}

                {/* Render the modules only after loading is complete */}
                {!loading && (
                    <div className="course-modules-container">
                        {modules.slice(0, showMore ? modules.length : 4).map((module, index) => (
                            <div className="module-card-wrappertimed-quiz" key={module.id}>
                                <Accordion
                                    expanded={expandedPanels.includes(module.id)}
                                    onChange={handleAccordionChange(module.id)}
                                    TransitionProps={{ unmountOnExit: true }} // Ensure smooth mounting/unmounting transitions
                                    className={expandedPanels.includes(module.id) ? 'accordion-expanded' : ''}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${module.id}-content`}
                                        id={`panel${module.id}-header`}
                                    >
                                        <div className="accordion-summary-content">
                                            <span className="accordion-summary-title">{module.title}</span>
                                            <div className="accordion-summary-row">
                                            <Tooltip title="Lock" placement="top" arrow>
                                                    <img
                                                        src={lockIcon}
                                                        onClick={() => handleLockClick(module.id)} // Pass module_id
                                                        className="rolebased-resume-button"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Resume" placement="top" arrow>
                                                    <img
                                                        src={resumeIcon}
                                                        onClick={() => handleResumeClick(module.id)} // Pass module_id
                                                        className="rolebased-resume-button"
                                                    />
                                                </Tooltip>
                                                <div className="rolebased-progress-container">
                                                    <div className="rolebased-progress-bar">
                                                        <div
                                                            className="rolebased-resume-progress"
                                                            style={{ width: `${getModuleProgress(module.id)}%` }}  // Dynamically set the progress width
                                                        ></div>
                                                    </div>
                                                    <span className="progress-percentage">
                                                {`${Math.round(getModuleProgress(module.id))}%`} {/* Display percentage */}
                                            </span>
                                                </div>
                                            </div>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-details">
                                        <p className="course-module-divider"></p>
                                        <div className="course-module-container">
                                            <div className="course-module-content">
                                                <img src={folder} alt="Document Icon" className="course-content-icon" />
                                                <span className="course-content-text">{module.content.text}</span>
                                                <div className="course-detail-time">
                                                    <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                                                    <span>{module.content.time}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="course-module-subcontent">
                                            <img src={unlock} alt="Lock Icon" className="course-subcontent-icon" />
                                            <span>Get the materials</span>
                                        </div>
                                        {module.details.map((detail, detailIndex) => (
                                            <div key={detailIndex} className="course-module-detail">
                                                <div className="course-detail-text">
                                                    <span>{detail.text}</span>
                                                </div>
                                                <div className="course-detail-time">
                                                    <img src={videoplay} alt="Video Icon" className="course-video-icon" />
                                                    <span>{detail.time}</span>
                                                </div>
                                            </div>
                                        ))}


                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))}
                    </div>
                )}

                {/* See More/Less Button - only visible if modules.length > 4 and after loading */}
                {!loading && modules.length > 4 && (
                    <Button onClick={() => setShowMore(!showMore)} className="course-see-more-button">
                        {showMore ? 'Show less' : 'See more'}
                        <ExpandMoreIcon className={`expand-icon ${showMore ? 'rotated' : ''}`} />
                    </Button>
                )}
                {/* Modal for unlocking */}
                <Modal open={isModalOpen} onClose={handleCloseModal}>
                                <div className="modal-content">
                                    <p style={{fontSize:'20px'}}>Would you like to unlock this module?</p>
                                    <div className="modal-actions">
                                        <button onClick={handleCloseModal} className="no-button">No</button>
                                        <button onClick={handleUnlock} className="yes-button">Yes</button>
                                    </div>
                                </div>
                            </Modal>
            </div>
        </div>
    );
};

export default Modules;
