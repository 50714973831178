import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import bronzeImage from '../assets/Images/lms_dashboard_bronze (1).png';


const StyledRecommendationCard = styled(Card)(() => ({
  borderRadius: '20px',
  maxWidth: '90%',
  margin: 'auto',
  marginTop:'9.5%',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  padding: 0,
}));

const BadgeImage = styled('img')(() => ({
  width: '65px',
  height: 'auto',
  opacity: 1,
}));

const RecommendationCard = () => (
  <>
  <StyledRecommendationCard>
    <CardContent>
      <Typography variant="h6" color="#252525" fontWeight="400" textAlign="left">
        Recommendation
      </Typography>

      <Box display="flex" alignItems="center" justifyContent="center" mt={1} gap="5px">
        <BadgeImage src={bronzeImage} alt="Bronze Badge" />

        <Box textAlign="left">
          <Typography variant="subtitle1" color="textPrimary" fontWeight="600"fontSize="10px">
            You're close to Bronze!
          </Typography>
          <Typography variant="body2" color="textSecondary" fontSize='12px' >
            Earn more points to reach the next level and unlock exclusive rewards.
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </StyledRecommendationCard>
  </>
);

export default RecommendationCard;

