import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const publicRoute = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('tokens');

    useEffect(() => {
      // Redirect authenticated users to the course list
      if (token && token !== 'null' && token !== 'undefined') {
        navigate('/course-list');
      }
    }, [token, navigate]);

    // Render the public route component if no token is found
    return !token || token === 'null' || token === 'undefined' ? (
      <WrappedComponent {...props} />
    ) : null;
  };
};

export default publicRoute;
