// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import '../assets/Css/Signup.css';
// import Circle1 from '../assets/Images/Ellipse_Full.png';
// import Circle2 from '../assets/Images/Ellipse_Half.png';
// import companyLogo from '../assets/Images/Company_logo.png';
// import eyeOpen from '../assets/Images/eye.png';
// import eyeClose from '../assets/Images/eye-slash.png';
// import { CircularProgress } from '@mui/material';
// import axios from 'axios';
// import { setCredentials } from '../reducers/authSlice';


// const Signup = () => {
//     const [togglePassword, setTogglePassword] = useState(false);
//     const [togglePasswordTwo, setTogglePasswordTwo] = useState(false);
//     const [username, setUsername] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [emailError, setEmailError] = useState('');
//     const [usernameError, setUsernameError] = useState('');
//     const [error, setError] = useState('');
//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     const handleSignup = async (event) => {
//         event.preventDefault();
//         setLoading(true);
//         setError('');

//         if (password !== confirmPassword) {
//             setError('Passwords do not match');
//             setLoading(false);
//             return;
//         }

//         const payload = {
//             username,
//             email,
//             password,
//             password2: confirmPassword,
//         };

//         try {
//             const response = await axios.post('http://lms.nquantum.in:8000/api/register/ ', payload);
//             console.log('Signup success:', response.data);
//             navigate.push('/verifyEmail');
//         } catch (err) {
//             if (err.response && err.response.data) {
//                 console.error('Signup error:', err.response.data);
//                 const { data } = err.response;
//                 if (data.email) {
//                     setEmailError(data.email[0]);
//                 }
//                 if (data.username) {
//                     setUsernameError(data.username[0]);
//                 }
//             } else {
//                 console.error('Signup error:', err);
//             }
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="signup-container">
//             <div className='signup-left'>
//             <div className="signup-logo">
//                 <img src={companyLogo} alt="company-logo" />
//             </div>
//             <div className="signup-description">
//                 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
//                 standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
//                 a type specimen book.
//             </div>
//             </div>
//             <div className='signup-right'>
//             <div className="signup-circles">
//                 <img src={Circle1} alt="Circle 1" className="signup-circle signup-circle1" />
//                 <img src={Circle2} alt="Circle 2" className="signup-circle signup-circle2" />
//             </div>
//             <div className="signup-box">
//                 <h2>Sign Up</h2>
//                 <form onSubmit={handleSignup}>
//                     <div className="signup-form-group">
//                         <input
//                             className="signup-form-control"
//                             type="text"
//                             placeholder="Username"
//                             required
//                             onChange={(e) => setUsername(e.target.value)}
//                             value={username}
//                         />
//                     </div>
//                     <div className="signup-form-group">
//                         <input
//                             className="signup-form-control"
//                             type="email"
//                             placeholder="Email"
//                             required
//                             onChange={(e) => setEmail(e.target.value)}
//                             value={email}
//                         />
//                     </div>
//                     <div className="signup-form-group">
//                         <input
//                             className="signup-form-control"
//                             type={togglePassword ? 'text' : 'password'}
//                             placeholder="Password"
//                             required
//                             onChange={(e) => setPassword(e.target.value)}
//                             value={password}
//                         />
//                         <div className="register-pw-one-position" onClick={() => setTogglePassword(!togglePassword)}>
//                             <span>
//                                 <img src={togglePassword ? eyeOpen : eyeClose} alt="icon" className="signup-eye-icon" />
//                             </span>
//                         </div>
//                     </div>
//                     <div className="signup-form-group">
//                         <input
//                             className="signup-form-control"
//                             type={togglePasswordTwo ? 'text' : 'password'}
//                             placeholder="Confirm-Password"
//                             required
//                             onChange={(e) => setConfirmPassword(e.target.value)}
//                             value={confirmPassword}
//                         />
//                         <div className="register-pw-two-position" onClick={() => setTogglePasswordTwo(!togglePasswordTwo)}>
//                             <span>
//                                 <img src={togglePasswordTwo ? eyeOpen : eyeClose} alt="icon" className="signup-eye-icon-2" />
//                             </span>
//                         </div>
//                     </div>
//                     {error && <p style={{ color: 'red' }}>{error}</p>}
//                     {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
//                     {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
//                     <button type="submit" className="register-button" disabled={loading}>
//                         Sign up {loading && <CircularProgress size={8} color="inherit" style={{ marginLeft: '10px', marginTop: '3px' }} />}
//                     </button>
//                 </form>
//                 <div className="signup-link">
//                     Already registered? <Link to="/">Sign in</Link> here
//                 </div>
//             </div>
//             </div>
//         </div>
//     );
// };


// export default Signup;




import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/Css/Signup.css';
import Circle1 from '../assets/Images/Ellipse_Full.png';
import Circle2 from '../assets/Images/Ellipse_Half.png';
// import Certificate_dlogo from '../assets/Images/Certificate_dlogo.png';
// import companyLogo from '../assets/Images/Company_logo.png';
import { CircularProgress } from '@mui/material';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { loginaxios } from '../axios';
import skill_synq_logo from '../assets/Images/Skillsynqlogo.png';

const Signup = () => {
    const [firstname, setFirstname] = useState('');
    const [email, setEmail] = useState('');
    const [currentYear, setCurrentYear] = useState('');
    const [branch, setBranch] = useState('');
    const [password, setPassword] = useState('');
   // const [togglePassword, setTogglePassword] = useState(false);
    const [reEnterPassword, setReEnterPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false); // State to toggle visibility
    const [showPassword2, setShowPassword2] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSignup = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

         // Password validation check
         if (password.length < 6 || reEnterPassword.length < 6) {
            setError('Password must be at least 6 characters long.');
            setLoading(false);
            return;
        }


        if (password !== reEnterPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        const payload = {
            "username": firstname,
            "password": password,
            "password2": reEnterPassword,
            "email": email
        };

        try {
            const response = await loginaxios.post('/register/', payload);
            console.log('Signup success:', response.data);
            if (response.data.message === 'User registered successfully. Please check your email for the OTP to verify your account.') {
                localStorage.setItem('user_id_registration', response.data.user_id);
                localStorage.setItem('user_name_registration', response.data.username);
                localStorage.setItem('user_email_registration', response.data.email);
                console.log('User registration',response.data.user_id)
                navigate('/otp-confirm', { state: { flowType: 'signup' } });

            }

        }catch (err) {
            const errorData = err.response?.data;
            const statusCode = err.response?.status;
        
            if (statusCode === 500) {
                // Handle 500 Internal Server Error
                setError('Getting some error, please try again later.');
            } else if (errorData) {
                // Check for specific fields and combine error messages
                const usernameError = errorData.username?.includes('A user with that username already exists.');
                const emailError = errorData.email?.includes('A user with that email already exists.');
        
                if (usernameError && emailError) {
                    // Combined error message for both username and email
                    setError('A user with the username and email already exists.');
                } else if (usernameError) {
                    // Only username error
                    setError('A user with the username already exists.');
                } else if (emailError) {
                    // Only email error
                    setError('A user with the email already exists.');
                } else {
                    // Generic error handling
                    const errorMessage = Object.values(errorData)
                        .flat()
                        .join(' ');
                    setError(errorMessage);
                }
            } else {
                // Fallback in case the payload is not available
                setError('Failed to sign up. Please try again.');
            }
        
            console.error('Signup error:', errorData || err.message);
            setLoading(false);
        }
        
        
    };

    return (
        <div className="signup-container">
            <div className="signup-left">
                <div className="signup-logo">
                    <img src={skill_synq_logo} alt="Company Logo" />
                </div>
                <div className="signup-description">
                At SkillsynQ, we make education accessible, engaging, and impactful. Our LMS supports learners of all backgrounds—students, professionals, and organizations—turning learning into a transformative journey toward success.
           </div>
            </div>
            <div className="signup-right">
                <div className="signup-circles">
                    <img src={Circle1} alt="Circle 1" className="signup-circle signup-circle1" />
                    <img src={Circle2} alt="Circle 2" className="signup-circle signup-circle2" />
                </div>
                <div className="signup-box">
                    <h2>Sign Up</h2>
                    <form onSubmit={handleSignup}>
                        <div className="signup-form-group">
                            
                            <p className='form_paragraph'>Full Name and Password must be atleast 6 characters long and should not contain spaces.</p>
                            {/* <label className='label-styles'>Fullname and Password must be more than<b>6 Characters</b></label> */}
                           
                            <div className="signup-form-group">
                                <input
                                    id="fullname"
                                    className="signup-form-control"
                                    type="text"
                                    placeholder="Full Name"
                                    required
                                    onChange={(e) => setFirstname(e.target.value)}
                                    value={firstname}
                                />

                            </div>

                        </div>
                        <div className="signup-form-group">
                            <input
                                className="signup-form-control"
                                type="email"
                                placeholder="Email"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                        <div className="signup-form-group">
                            <input
                                className="signup-form-control"
                                type={showPassword1 ? "text" : "password"} 
                                placeholder="Password"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                             <span
                        className="eye-icon"
                        onClick={() => setShowPassword1(!showPassword1)} // Toggle visibility
                    >
                        {showPassword1 ?  <FaEye /> : <FaEyeSlash />}
                    </span>
                        </div>
                        <div className="signup-form-group">
                            <input
                                className="signup-form-control"
                                type={showPassword2 ? "text" : "password"} 
                                placeholder="Re-enter Password"
                                required
                                onChange={(e) => setReEnterPassword(e.target.value)}
                                value={reEnterPassword}
                            />
                             <span
                        className="eye-icon"
                        onClick={() => setShowPassword2(!showPassword2)} // Toggle visibility
                    >
                        {showPassword2 ?  <FaEye /> : <FaEyeSlash />}
                    </span>
                        </div>

                        {error && <p style={{ color: 'red', fontSize:'12px' }}>{error}</p>}
                        <button type="submit" className="register-button" disabled={loading}>
                            Sign Up
                            {loading && <CircularProgress size={8} color="inherit" style={{ marginLeft: '10px', marginTop: '3px' }} />}
                        </button>
                    </form>
                    <div className="signup-link">
                        Already registered? <Link to="/">Sign in</Link> here
                    </div>

                </div>
                {/* <div className="powered-by">
                    Powered by <nbsp></nbsp>
                    <img 
                        src={skill_synq_logo}
                        alt="Skillsynq Logo"  
                    />
                </div> */}
            </div>
        </div>
    );
};


export default Signup;